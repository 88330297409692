import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Input } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginInProgress, loginUser } from '../../features/auth.store';

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import to from '../../utils/to';
import eng from '../../utils/eng';
import history from '../../utils/history';
import PublicPageLayout from './public-page-layout';

const Login = () => {
  const dispatch = useDispatch();

  const handleLogin = async (values) => {
    const [err] = await to(dispatch(loginUser(values)).unwrap());
    if (err) {
      return toast.error(err.status === 401 ? eng.toast.invalidUsernamePassword : err.message);
    }
    history.push('/dashboard');
    toast.success(eng.toast.loginSuccess);
  };

  const isLoginInProgress = useSelector(getLoginInProgress);

  return (
    <PublicPageLayout>
      <div className="title fw-700">Hello! </div>
      <div className="sub-title fw-400">Sign in to continue!</div>

      <Formik
        initialValues={{ email: '', password: '', rememberMe: true }}
        onSubmit={handleLogin}
        className="d-flex f-col"
        validateOnBlur
        validationSchema={Yup.object({
          email: Yup.string().email('Invalid email address').required('Required'),
          password: Yup.string().required('Required'),
        })}
      >
        <Form>
          <div className="login-field">
            <label htmlFor="email">Email ID</label>
            <Field name="email">
              {({ field, meta }) => (
                <Input
                  placeholder="Enter your email"
                  className={`${meta.touched && meta.error ? 'has-error' : ''}`}
                  suffix={meta.touched ? meta.error ? <CloseOutlined /> : <CheckOutlined /> : <span />}
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className="login-field">
            <label htmlFor="password">Password</label>
            <Field name="password">
              {({ field, meta }) => (
                <Input.Password
                  placeholder="Enter your password"
                  className={`${meta.touched && meta.error ? 'has-error' : ''}`}
                  {...field}
                />
              )}
            </Field>
          </div>

          <div className="remember-me-forgot-password d-flex jc-space-between">
            <Field as={Checkbox} type="checkbox" name="rememberMe">
              Remember Me
            </Field>
            <Link to="/forgot-password">Forgot Password ?</Link>
          </div>

          <Button disabled={isLoginInProgress} className="upper-case" type="primary" size="large" htmlType="submit">
            Login to continue
          </Button>
        </Form>
      </Formik>
    </PublicPageLayout>
  );
};

export default Login;
