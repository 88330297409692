import React, { useEffect, useState } from 'react';
import { Collapse, DatePicker } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import FormSection from '../../helpers/FormSection';
import { NonFormikFormField } from '../../helpers/FormField';
import moment from 'moment';
import PriorityOptionSelector from './PriorityOptionSelector';
import { useDispatch } from 'react-redux';
import { updateAnswer } from '../../../../features/service-form.store';

const { Panel } = Collapse;

const DateFieldQuestion = ({ title, path, answerKey, answers, subKey, gasType }) => {
  const [date, setDate] = useState('');
  // const [priority, setPriority] = useState('LOW');

  const dispatch = useDispatch();

  useEffect(() => {
    if (date) {
      dispatch(
        updateAnswer({
          isArray: false,
          gasType,
          path: [...path, answerKey, subKey],
          value: date.toDate().getTime(),
        }),
      );
    }
  }, [date]);

  useEffect(() => {
    if (answers && answers['sensorReadings']) {
      const data = answers['sensorReadings'].filter(({ gasType: gt }) => gt === gasType)[0];
      setDate(moment(data && data[subKey]));
    } else if (answers && answers[answerKey]) {
      setDate(moment(answers[answerKey]));
    }
  }, []);

  return (
    <Collapse ghost expandIcon={() => null} collapsible="header" className="question" activeKey={[title]}>
      <Panel header={title} key={title}>
        <FormSection className="no-padding">
          {/*<PriorityOptionSelector setPriority={setPriority} priority={priority} />*/}
          <NonFormikFormField labelText="Date" value={date} InputComp={DatePicker} onChange={(e) => setDate(e)} />
        </FormSection>
      </Panel>
    </Collapse>
  );
};

export default DateFieldQuestion;
