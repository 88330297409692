import React, { useState } from "react";
import { useParams } from 'react-router-dom'
import SubPageLayout from "../../../helpers/SubPageLayout";
import { Progress, Modal, List, Upload, Button } from "antd";
import { EyeOutlined, UploadOutlined } from "@ant-design/icons";
import { Labels14f600, LabelCommon, Labels14f300 } from "../components/labels";
import './styles.scss'

const mockData = {
    done: [
        {
            title: 'Prebuild',
            subtitle: 'Short description',
            progress: 100,
            footer: '02 Jul 2022',
        },
        {
            title: 'Fabrication',
            subtitle: 'Short description',
            progress: 100,
            footer: '02 Jul 2022',
        },
        {
            title: 'Engineering',
            subtitle: 'Short description',
            progress: 100,
            footer: '02 Jul 2022',
        }
    ],
    inProgress: [
        {
            title: 'Paint & blast',
            subtitle: 'Short description',
            progress: 90,
            footer: '02 Jul 2022',
        },
        {
            title: 'Materials',
            subtitle: 'Short description',
            progress: 70,
            footer: '02 Jul 2022',
        },
        {
            title: 'Stickers',
            subtitle: 'Short description',
            progress: 20,
            footer: '02 Jul 2022',
        },
        {
            title: 'Build and pre electrical',
            subtitle: 'Short description',
            progress: 80,
            footer: '02 Jul 2022',
        }
    ],
    todo: [
        {
            title: 'electrical 1',
            subtitle: 'Short description',
            progress: 0,
            footer: '02 Jul 2022',
        },
        {
            title: 'electrical 2',
            subtitle: 'Short description',
            progress: 0,
            footer: '02 Jul 2022',
        },
        {
            title: 'electrical 3',
            subtitle: 'Short description',
            progress: 0,
            footer: '02 Jul 2022',
        },
        {
            title: 'electrical 4',
            subtitle: 'Short description',
            progress: 0,
            footer: '02 Jul 2022',
        },
        {
            title: 'electrical 5',
            subtitle: 'Short description',
            progress: 0,
            footer: '02 Jul 2022',
        },
        {
            title: 'electrical 6',
            subtitle: 'Short description',
            progress: 0,
            footer: '02 Jul 2022',
        }
    ],
    summary: [
        {
            title: 'Vanna Lonk started Materials ',
            date: 'June 24, 2020',
            time: '11:23 AM',
            status: 'finished'
        },
        {
            title: 'Vanna Lonk started Materials ',
            date: 'June 24, 2020',
            time: '11:23 AM',
            status: 'started'
        },
        {
            title: 'Vanna Lonk started Materials ',
            date: 'June 24, 2020',
            time: '11:23 AM',
            status: 'finished'
        },
        {
            title: 'Vanna Lonk started Materials ',
            date: 'June 24, 2020',
            time: '11:23 AM',
            status: 'started'
        },
        {
            title: 'Vanna Lonk started Materials ',
            date: 'June 24, 2020',
            time: '11:23 AM',
            status: 'finished'
        },
        {
            title: 'Vanna Lonk started Materials ',
            date: 'June 24, 2020',
            time: '11:23 AM',
            status: 'started'
        },
        {
            title: 'Vanna Lonk started Materials ',
            date: 'June 24, 2020',
            time: '11:23 AM',
            status: 'finished'
        },
        {
            title: 'Vanna Lonk started Materials ',
            date: 'June 24, 2020',
            time: '11:23 AM',
            status: 'started'
        },
        {
            title: 'Vanna Lonk started Materials ',
            date: 'June 24, 2020',
            time: '11:23 AM',
            status: 'finished'
        },
        {
            title: 'Vanna Lonk started Materials ',
            date: 'June 24, 2020',
            time: '11:23 AM',
            status: 'started'
        },
        {
            title: 'Vanna Lonk started Materials ',
            date: 'June 24, 2020',
            time: '11:23 AM',
            status: 'finished'
        },
        {
            title: 'Vanna Lonk started Materials ',
            date: 'June 24, 2020',
            time: '11:23 AM',
            status: 'started'
        },
        {
            title: 'Vanna Lonk started Materials ',
            date: 'June 24, 2020',
            time: '11:23 AM',
            status: 'finished'
        },
        {
            title: 'Vanna Lonk started Materials ',
            date: 'June 24, 2020',
            time: '11:23 AM',
            status: 'started'
        }
    ],
    files: [
        {
            id: 1,
            title: 'Sampling-Based Robust Control of Autonomous Systems with Non-Gaussian Noise',
            file: '/tmp/1.pdf'
        },
        {
            id: 2,
            title: 'Exploration-Exploitation in Multi-Agent Learning Catastrophe Theory Meets Game Theory',
            file: '/tmp/2.pdf'
        },
        {
            id: 1,
            title: 'Sampling-Based Robust Control of Autonomous Systems with Non-Gaussian Noise',
            file: '/tmp/1.pdf'
        },
        {
            id: 2,
            title: 'Exploration-Exploitation in Multi-Agent Learning Catastrophe Theory Meets Game Theory',
            file: '/tmp/2.pdf'
        },
        {
            id: 1,
            title: 'Sampling-Based Robust Control of Autonomous Systems with Non-Gaussian Noise',
            file: '/tmp/1.pdf'
        },
        {
            id: 2,
            title: 'Exploration-Exploitation in Multi-Agent Learning Catastrophe Theory Meets Game Theory',
            file: '/tmp/2.pdf'
        },
        {
            id: 1,
            title: 'Sampling-Based Robust Control of Autonomous Systems with Non-Gaussian Noise',
            file: '/tmp/1.pdf'
        },
        {
            id: 2,
            title: 'Exploration-Exploitation in Multi-Agent Learning Catastrophe Theory Meets Game Theory',
            file: '/tmp/2.pdf'
        }
    ]
}

const getBGStatus = (value) => {
    if (value > 80) {
        return 'success'
    } else if (value > 45) {
        return 'normal'
    } else {
        return 'exception'
    }
}

const ChamberView = () => {

    let { id } = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleOnFilesOpenClick = () => setIsModalOpen(true)

    const CardContainer = ({ data = [], title }) => (
        <div className="card-container">
            <div className="d-flex jc-space-between space-left space-right space-top space-bottom">
                <Labels14f600>{title}</Labels14f600>
                <Labels14f600>{data.length < 10 ? `0${data.length}` : data.length}</Labels14f600>
            </div>
            <div className="card-wrapper">
                {
                    data.map(({ title, subtitle, progress, footer }) => (
                        <div className="card">
                            <LabelCommon fontSize={18} fontWeight={500} color="242424" className="title">{title}</LabelCommon>
                            <LabelCommon fontSize={12} fontWeight={350} color="grey" className="subtitle" >{subtitle}</LabelCommon>
                            <div className="progress">
                                <div className="d-flex jc-space-between">
                                    <LabelCommon fontSize={12} fontWeight={300} color="grey"  >Progress</LabelCommon>
                                    <LabelCommon fontSize={12} fontWeight={500} color="black"  >{`${progress}%`}</LabelCommon>
                                </div>
                                <Progress
                                    percent={progress}
                                    showInfo={false}
                                    status={getBGStatus(progress)}
                                />
                            </div>
                            <div className="footer">
                                <div className="left-button">
                                    <LabelCommon fontSize={10} fontWeight={200} color="blue"  >{footer}</LabelCommon>
                                </div>
                                <img width={20} src="/icons/folder-button.svg" onClick={handleOnFilesOpenClick} />
                            </div>
                        </div>

                    ))
                }
            </div>
        </div>
    )

    const SummaryContainer = ({ data = [], status }) => {
        return (
            <div className="card-container">
                <div className="jc-space-between space-left space-right space-top space-bottom">
                    <LabelCommon fontSize={24} fontWeight={600} lineHeight={1.5}>Build Progress</LabelCommon>
                    <LabelCommon fontSize={12} fontWeight={500} color="red">{status}</LabelCommon>
                    <LabelCommon fontSize={12} fontWeight={500} color="grey" className="space-top">Time Line</LabelCommon>
                </div>
                <hr />
                <div className="card-wrapper h40">
                    <div className="verticle-line" />
                    {data.map(({ title, date, time, status }) =>
                        <div className="summary-card">
                            <div className={`icon ${status}`}>
                                <img width={15} src="/icons/tools.svg" />
                            </div>
                            <div className="text-container">
                                <LabelCommon fontSize={12} fontWeight={600} color="black" >{title}</LabelCommon>
                                <Labels14f300>{`${date} | ${time}`}</Labels14f300>
                            </div>
                        </div>)}
                </div>
            </div>
        )
    }

    const renderFileListItem = (item) => (
        <List.Item>
            <div className="d-flex jc-space-between ai-center space-left space space-right w-100">
                <LabelCommon fontSize={14} fontWeight={450} className="flex space-right" >{item.title}</LabelCommon>
                <EyeOutlined width={60} className="cursor-pointer" onClick={() => window.open(item.file)} />
            </div>
        </List.Item>
    )

    return (
        <SubPageLayout
            title={`Overview > ${id}`}
            subtitle={<LabelCommon fontSize={14} fontWeight={450} color="grey">{`Chambers > ${id}`}</LabelCommon>}
        >
            <div className="d-flex overview-chamber-view">
                <CardContainer title="Done" data={mockData.done} />
                <CardContainer title="In progress" data={mockData.inProgress} />
                <CardContainer title="Works to do" data={mockData.todo} />
                <SummaryContainer status="4 working days remaining" data={mockData.summary} />
            </div>
            <Modal
                title="Files"
                visible={isModalOpen}
                footer={null}
                onCancel={() => setIsModalOpen(false)}
                closable={true}
                className
            >
                <List
                    dataSource={mockData.files}
                    renderItem={renderFileListItem}
                    className="chamber-overview-files-list space-all-p"
                />
                <div className="flex space-all-p w-100">
                    <Upload>
                        <Button  icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </div>
            </Modal>
        </SubPageLayout>
    )
}

export default ChamberView;