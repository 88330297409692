import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { Button, Input } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeForgotPassword, getLoginInProgress } from '../../features/auth.store';
import to from '../../utils/to';
import { toast } from 'react-toastify';
import eng from '../../utils/eng';
import PublicPageLayout from './public-page-layout';
import get from 'lodash.get';

const ChangePassword = () => {
  const isLoading = useSelector(getLoginInProgress);
  const dispatch = useDispatch();

  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const handleChangePassword = async ({ password }) => {
    const [err] = await to(dispatch(changeForgotPassword({ password, token: query.get('token') })).unwrap());
    if (err) {
      return toast.error(`${eng.somethingWentWrong} - ${get(err, ['error', 'message'])}`);
    }
    history.push('/login');
    return toast.success(eng.toast.changeForgotPasswordSuccess);
  };

  useEffect(() => {
    if (!query.get('token')) {
      toast.warn('Invalid url. Please go to the email and try again!');
    }
  }, [query]);

  return (
    <PublicPageLayout>
      <div className="title fw-700">Change your password! </div>
      <div className="sub-title fw-400">Enter your new password here</div>

      <Formik
        initialValues={{ password: '', confirm: '' }}
        onSubmit={handleChangePassword}
        className="d-flex f-col"
        validateOnBlur
        validationSchema={Yup.object({
          password: Yup.string().required('Required').min(6, 'Select a strong password'),
          confirm: Yup.string().oneOf([Yup.ref('password')], 'Confirmation Failed'),
        })}
      >
        <Form>
          <div className="login-field">
            <label htmlFor="password">Password</label>
            <Field name="password">
              {({ field, meta }) => (
                <Input.Password
                  placeholder="Enter your new password"
                  className={`${meta.touched && meta.error ? 'has-error' : ''}`}
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className="login-field">
            <label htmlFor="confirm">Confirm</label>
            <Field name="confirm">
              {({ field, meta }) => (
                <Input.Password
                  placeholder="Enter your password again"
                  className={`${meta.touched && meta.error ? 'has-error' : ''}`}
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className="remember-me-forgot-password d-flex jc-space-between">
            <Button disabled={isLoading} className="upper-case" type="primary" size="large" htmlType="submit">
              Submit
            </Button>
            <Link to="/login">Login</Link>
          </div>
        </Form>
      </Formik>
    </PublicPageLayout>
  );
};

export default ChangePassword;
