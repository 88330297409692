import React, { useState } from 'react';
import get from 'lodash.get';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'antd';
import {
  DownOutlined,
  ToolOutlined,
  CloseOutlined,
  RightOutlined,
  UsergroupAddOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import { format } from 'date-fns';
import { DATE_FORMAT, serviceFormTypesObj } from '../../../utils/constant';
import { yesNo } from '../../../utils/utils';
import './styles/chambers-view-tile.scss';
import { useSelector } from 'react-redux';
import { isFeatureEnabled } from '../../../features/auth.store';
import QrCode from './QRCode';

const printArray = (chamber, path) =>
  get(chamber, path, [])
    .map(({ displayName }) => displayName)
    .join(', ');

const ChambersViewTile = ({ src, ...chamber }) => {
  const [detailedSection, showDetailedSection] = useState(false);
  const [serviceResumeModal, showServiceResumeModal] = useState(false);
  const getFeatureEnable = useSelector(isFeatureEnabled);
  const featureServiceChamber = getFeatureEnable('service_a_chamber');
  const featureAuditChamber = getFeatureEnable('audit_service_a_chamber');
  const featureCommissionChamber = getFeatureEnable('commission_service_a_chamber');
  const [serviceType, setServiceType] = useState(serviceFormTypesObj.SERVICE);

  const handleInitService = (type) => {
    showServiceResumeModal(true);
    setServiceType(type);
  };

  return (
    <div className="chambers-view">
      <Modal
        visible={serviceResumeModal}
        footer={null}
        closable={false}
        width={450}
        className="resume-service-modal"
        onCancel={() => showServiceResumeModal(false)}
      >
        <div className="resume-service-modal-content">
          <div className="header-icon">
            <WarningOutlined />
          </div>
          <div className="header-text fw-600">You are about to conduct {serviceType.action} on</div>
          <div className="mine">MineArc Systems</div>
          <div className="site fw-600">{get(chamber, 'name')}</div>
          <div className="chamber fw-500">{get(chamber, 'buildLocationEnum.displayName')}</div>
          <div className="question fw-400">
            Are you sure you want to perform {serviceType.action} on the selected chamber?
          </div>

          <div className="buttons d-flex jc-space-around">
            <Button className="flex space-left space-right" onClick={() => showServiceResumeModal(false)}>
              Cancel
            </Button>
            <Link to={`/chambers/${chamber.id}/service?type=${serviceType.name}`}>
              <Button type="primary" className="flex space-left space-right">
                {serviceType.displayName}
              </Button>
            </Link>
          </div>
        </div>
      </Modal>
      <div className="chambers-view-tile d-flex d-row jc-space-between ai-center bg-white space-top space-bottom rounded-border">
        <div className="left d-flex">
          <img
            className="rounded-border"
            src={`https://res.cloudinary.com/day4l7awk/image/upload/h_100,w_100/chamber-types/${get(
              chamber,
              'chamberTypeEnum.name',
            )}.png`}
            alt={src}
          />
          <div className="names">
            <div className="title fw-500">{get(chamber, 'name')}</div>
            <div className="sub-title">Last known location</div>
            <div className="sub-title">RSP Kit Number: {get(chamber, 'rspKitNo')}</div>
            <div className="sub-title">{get(chamber, 'buildLocationEnum.displayName')}</div>
          </div>
        </div>
        <div className="right">
          {featureServiceChamber && (
            <Button
              type="primary"
              className="btn-spaces-vertical space-right"
              onClick={() => handleInitService(serviceFormTypesObj.SERVICE)}
            >
              Conduct Service <ToolOutlined />
            </Button>
          )}
          {featureAuditChamber && (
            <Button
              className="btn-spaces-vertical space-right"
              onClick={() => handleInitService(serviceFormTypesObj.AUDIT)}
            >
              Conduct Audit
            </Button>
          )}
          {featureCommissionChamber && (
            <Button
              className="btn-spaces-vertical space-right"
              onClick={() => handleInitService(serviceFormTypesObj.COMMISSION)}
            >
              Conduct Commission
            </Button>
          )}
          <Button onClick={() => showDetailedSection(!detailedSection)}>
            {detailedSection ? (
              <CloseOutlined />
            ) : (
              <>
                Details <DownOutlined />
              </>
            )}
          </Button>
        </div>
      </div>
      {detailedSection && (
        <div className="detail-section bg-white rounded-border">
          <div className="type">
            Type: <a>{get(chamber, 'chamberTypeEnum.displayName')}</a>
          </div>
          <div className="service-cards d-flex">
            <div className="service-card rounded-border">
              <div className="title d-flex ai-center">
                <img src="/icons/service-date-icon.svg" />
                <div className="text">Last Serviced</div>
              </div>
              <div className="date">
                {get(chamber, 'lastServicedDate')
                  ? format(new Date(get(chamber, 'lastServicedDate')), DATE_FORMAT)
                  : 'N/A'}
              </div>
            </div>
            <div className="service-card rounded-border">
              <div className="title d-flex ai-center">
                <img src="/icons/service-date-icon.svg" />
                <div className="text">Next Service Due</div>
              </div>
              <div className="date">N/A</div>
            </div>
          </div>
          <div className="view-past fw-500">
            View past service reports <RightOutlined />
          </div>
          <div className="person-count fw-500">
            <UsergroupAddOutlined /> person
          </div>
          <div className="details-table">
            <div className="dt-row">
              <div className="dt-key">Chamber Type</div>
              <div className="dt-value">{get(chamber, 'chamberTypeEnum.displayName')}</div>
              <div className="dt-key">Scrubber Type</div>
              <div className="dt-value">{get(chamber, 'scrubberTypeEnum.displayName')}</div>
            </div>
            <div className="dt-row">
              <div className="dt-key">Chemical Type</div>
              <div className="dt-value">{get(chamber, 'chemicalTypeEnum.displayName')}</div>
              <div className="dt-key">Compressed air filtration</div>
              <div className="dt-value">{get(chamber, 'airFilterTypeEnum.displayName')}</div>
            </div>
            <div className="dt-row">
              <div className="dt-key">Digital gas monitoring</div>
              <div className="dt-value">{get(chamber, 'gasMonitorTypeEnum.displayName')}</div>
              <div className="dt-key">Gas sensors</div>
              <div className="dt-value">{printArray(chamber, 'gasSensorTypeEnums')}</div>
            </div>
            <div className="dt-row">
              <div className="dt-key">Interior Lighting</div>
              <div className="dt-value">{get(chamber, 'interiorLightingTypeEnum.displayName')}</div>
              <div className="dt-key">Options</div>
              <div className="dt-value">{printArray(chamber, 'optionsTypeEnums')}</div>
            </div>
            <div className="dt-row">
              <div className="dt-key">CO tube quantity</div>
              <div className="dt-value">{get(chamber, 'coTubeQuantity')}</div>
              <div className="space" />
              <div className="dt-key">co2TubeQuantity</div>
              <div className="dt-value">{get(chamber, 'co2TubeQuantity')}</div>
            </div>
            <div className="dt-row">
              <div className="dt-key">Battery quantity</div>
              <div className="dt-value">{get(chamber, 'batteryQuantity')}</div>
              <div className="space" />
              <div className="dt-key">Additional Language</div>
              <div className="dt-value">{get(chamber, 'additionalLanguages')}</div>
            </div>
            <div className="dt-row">
              <div className="dt-key">Capacity</div>
              <div className="dt-value">{get(chamber, 'capacity')}</div>
              <div className="space" />
              <div className="dt-key">Duration</div>
              <div className="dt-value">{get(chamber, 'duration')}</div>
            </div>
            <div className="dt-row">
              <div className="dt-key">CO chemical quantity</div>
              <div className="dt-value">{get(chamber, 'coChemicalQuantity')}</div>
              <div className="space" />
              <div className="dt-key">CO2 chemical quantity</div>
              <div className="dt-value">{get(chamber, 'co2ChemicalQuantity')}</div>
            </div>
            <div className="dt-row">
              <div className="dt-key">Moisture removal quantity</div>
              <div className="dt-value">{get(chamber, 'moistureRemovalQuantity')}</div>
              <div className="space" />
              <div className="dt-key">Medical oxygen cylinders (main chamber) quantity</div>
              <div className="dt-value">{get(chamber, 'mainChamberOxygenLevel')}</div>
            </div>
            <div className="dt-row">
              <div className="dt-key">Medical oxygen cylinders (PPMS) quantity</div>
              <div className="dt-value">{get(chamber, 'ppmsOxygenLevel')}</div>
              <div className="space" />
              <div className="dt-key">Medical oxygen cylinders (Airlock) quantity</div>
              <div className="dt-value">{get(chamber, 'airlockOxygenLevel')}</div>
            </div>
            <div className="dt-row">
              <div className="dt-key">Oxygen candle</div>
              <div className="dt-value">{yesNo(get(chamber, 'oxygenCandle'))}</div>
              <div className="space" />
              <div className="dt-key">Manual gas tubes</div>
              <div className="dt-value">{yesNo(get(chamber, 'manualGasTubes'))}</div>
            </div>
            <div className="dt-row">
              <div className="dt-key">Fire extinguisher - external</div>
              <div className="dt-value">{yesNo(get(chamber, 'externalFireExtinguisher'))}</div>
              <div className="space" />
              <div className="dt-key">Fire extinguisher - internal</div>
              <div className="dt-value">{yesNo(get(chamber, 'internalFireExtinguisher'))}</div>
            </div>
            <div className="dt-row">
              <div className="dt-key">Fire blanket - internal</div>
              <div className="dt-value">{yesNo(get(chamber, 'fireBlanket'))}</div>
              <div className="space" />
              <div className="dt-key">Transformer</div>
              <div className="dt-value">{yesNo(get(chamber, 'transformer'))}</div>
            </div>
            <div className="dt-row">
              <div className="dt-key">Communications box</div>
              <div className="dt-value">{yesNo(get(chamber, 'communicationsBox'))}</div>
              <div className="space" />
              <div className="dt-key">Toilet</div>
              <div className="dt-value">{yesNo(get(chamber, 'toilet'))}</div>
            </div>
            <div className="dt-row">
              <div className="dt-key">Long-life food</div>
              <div className="dt-value">{yesNo(get(chamber, 'longLifeFood'))}</div>
              <div className="space" />
              <div className="dt-key"></div>
              <div className="dt-value"></div>
            </div>
          </div>
          <div className="d-flex jc-space-around">
            {/*<Button type="primary">Edit Details</Button>*/}
            <QrCode id={get(chamber, 'qrCode')} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChambersViewTile;
