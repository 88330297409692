import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { GET, POST } from '../service/base-api-service';
import { getIsAdminApi } from './auth.store';
import { monthToNumbers } from '../utils/utils';
import { serviceFormTypesObj } from '../utils/constant';

const initialState = {
  isLoading: false,
  isLoadingError: false,
  detailedReports: null,
  selectedCompany: '',
  selected: {
    serviced:{
      tenant: '',
      month: '',
      year: new Date().getFullYear().toString(),
      reports: [],
    },
    'after-service':{
      tenant: '',
      month: '',
      year: new Date().getFullYear().toString(),
      reports: [],
    }
  }
};

export const fetchReports = createAsyncThunk('fetchReports', async ({ month, type, company }, { getState }) => {
 
  const { from, to } = monthToNumbers(month);
  const isAdminApi = getIsAdminApi(getState());
  const year = getYear(getState())(type);
  const toDate = to === '04' ? '30' : '31';
  const adminPrefix = getIsAdminApi(getState()) ? 'admin/' : '';
  const serviceType = type === 'serviced' ? 'service' : 'afterService';
  let url = `v1/${adminPrefix}report/${serviceType}/meta/01.${from}.${year}-00:00:00/${toDate}.${to}.${year}-23:59:59${
    isAdminApi ? `?tenantId=${company}` : '?'
  }`;
  const [d1, d2, d3] = await Promise.all([
    GET(`${url}&type=${serviceFormTypesObj.SERVICE.name}`),
    GET(`${url}&type=${serviceFormTypesObj.AUDIT.name}`),
    GET(`${url}&type=${serviceFormTypesObj.COMMISSION.name}`),
  ]);

  return [...d1, ...d2, ...d3];
});

export const getDetailedReport = createAsyncThunk('detailedReport', async ({ id, tenantId }, { getState }) => {
  let url = `v1/report/serviceForm/${id}`;
  if (getIsAdminApi(getState())) url = `v1/admin/report/serviceForm/${id}?tenantId=${tenantId}`;
  return GET(url);
});

export const updateQuestion = createAsyncThunk('updateQuestion', async ({id, tenantId, question, fixed}, { dispatch, getState }) => {
  const {serviceFormId} = detailedReports(getState());
  let url = `v1/report/serviceForm/update-question/${serviceFormId}`;
  if (getIsAdminApi(getState())) url = `v1/admin/report/serviceForm/update-question/${serviceFormId}?tenantId=${tenantId}`;
  await POST(url, {
    question,
    fixed: !fixed
  });
  return dispatch(getDetailedReport({ id, tenantId }))
});

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setYear: (state, { payload }) => {
      state.selected[payload.type].year = payload.value
    },
    setTenant: (state, { payload }) => {
      state.selected[payload.type].tenant = payload.value
    },
    setMonth: (state, { payload }) => {
      state.selected[payload.type].month = payload.value
    },
    initiate: (state, { payload }) => {
      const keys = Object.keys(state.selected);
      keys.forEach(key=>{
        state.selected[key].tenant = payload
      })
    }
  },
  extraReducers: {
    [fetchReports.pending]: (state, { meta: { arg } }) => {
      state.month = arg.month;
      state.isLoading = true;
    },
    [fetchReports.rejected]: (state) => {
      state.isLoading = false;
      state.reports = [];
    },
    [fetchReports.fulfilled]: (state, { meta: { arg }, payload }) => {
      state.selected[arg.type].reports = payload;
      state.isLoading = false;
    },

    [getDetailedReport.pending]: (state) => {
      state.detailedReports = null;
      state.isLoading = true;
    },
    [getDetailedReport.rejected]: (state) => {
      state.detailedReports = null;
      state.isLoading = false;
    },
    [getDetailedReport.fulfilled]: (state, { payload }) => {
      state.detailedReports = payload;
      state.isLoading = false;
    },
  },
});

export const { setSelection, setType, setYear, setMonth, setTenant, initiate } = reportsSlice.actions;

const getReportsStore = (state) => state.reports;

export const getYear = createSelector(getReportsStore, (store) => (type) => store.selected[type].year);
export const getMonth = createSelector(getReportsStore, (store) => (type) => store.selected[type].month);
export const getTenant = createSelector(getReportsStore, (store) => (type) => store.selected[type].tenant);
export const getReports = createSelector(getReportsStore, (store) => (type) => store.selected[type].reports);
export const getReportsLoading = createSelector(getReportsStore, (store) => store.isLoading);
export const detailedReports = createSelector(getReportsStore, (store) => store.detailedReports);

export default reportsSlice.reducer;
