import React, { useEffect, useState } from 'react';
import { DetailsCard } from '../helpers/Components';
import { Switch } from 'antd';
import SearchFilterInput from '../helpers/SearchFilterInput';
import MyTable from '../helpers/MyTable';
import SubPageLayout from '../helpers/SubPageLayout';
import UsersAdd from './UsersAdd';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRole, isFeatureEnabled } from '../../../features/auth.store';
import {
  getPaginatedUserList,
  getUsersList,
  getUsersLoading,
  getUserTableInfo,
  updateUser,
  UsersAction,
} from '../../../features/user.store';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constant';
import { ROLE } from '../../../utils/features';
import UserEdit from './UsersEdit';
import SubPageHeader from '../helpers/SubPageHeader';

const columns = (dispatch, {userTableInfo, handleChangeOrder, userRole}) => {
  const columnsList = [
    {
      title: 'User Name',
      dataIndex: 'fullName',
      render: (name, record) => (
        <DetailsCard
          src={record.profilePictureURL}
          name={name}
          id={record.id}
          className="d-flex f-row ai-center"
          as="div"
        />
      ),
      sorter: true,
      sortDirections: [userTableInfo.isAscending ? 'descend' : 'ascend'],
      onHeaderCell: (() => ({
        onClick: handleChangeOrder
      }))
    },
    {
      title: 'Phone Number',
      dataIndex: 'telephoneNumber',
    },
    {
      title: 'Role',
      dataIndex: ['role', 'displayName'],
    },
    {
      title: 'Enabled',
      dataIndex: 'isLocked',
      render: (data, record) => (
        <Switch checked={!data} onChange={() => dispatch(updateUser({ userId: record.id, body: { isLocked: !data } }))} />
      ),
    },
  ];
  if(ROLE.SA === userRole){
    columnsList.push({
      title: 'Edit',
      render: (data, record) => <UserEdit disabled={ROLE.SA !== userRole} id={record.id} fullname={record.fullName} />,
    })
  }
  return columnsList
};

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled User',
    // Column configuration not to be checked
    name: record.name,
  }),
};

const Users = () => {
  const getFeatureEnabled = useSelector(isFeatureEnabled);
  const featureAddUsers = getFeatureEnabled('add_organizations');

  const dispatch = useDispatch();
  const userTableInfo = useSelector(getUserTableInfo);
  const userList = useSelector(getUsersList);
  const userListLoading = useSelector(getUsersLoading);
  const userRole = useSelector(getUserRole);
  
  const companyDropdown = [].indexOf(userRole) !== -1;

  useEffect(() => {
    dispatch(getPaginatedUserList({
      page: 0, 
      ...userTableInfo,
      sortColumn: 'fullName'
    }));
  }, []);

  const handleChangeOrder = () => {
    dispatch(UsersAction.setSortColumn({ isAscending: !userTableInfo.isAscending, sortColumn: 'fullName' }));
  }

  return (
    <SubPageLayout title="Users" extra={featureAddUsers && <UsersAdd />} subtitle="Users">
      {companyDropdown && (
        <SearchFilterInput
          placeholder="Search."
          companyDropdown
          companyProps={{
            onChange: (val) => dispatch(UsersAction.setSelectedCompany(val)),
            onLoad: (list) => dispatch(UsersAction.setSelectedCompany(list[0].identifier)),
            selectFirstOnLoad: true,
            placeholder: 'Select Company',
          }}
        />
      )}
      <SubPageHeader 
        defaultValue={userTableInfo.searchKey}
        searchLoading={false}
        searchPlaceholder="Search Users"
        onSearch={(value)=>dispatch(UsersAction.setSearch(value))}
      />
      <MyTable
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns(dispatch,
          {
            userTableInfo,
            handleChangeOrder,
            userRole
          }
        )}
        pagination={{
          position: ['none', 'bottomCenter'],
          pageSize: DEFAULT_PAGE_SIZE,
          total: userTableInfo.totalPages * DEFAULT_PAGE_SIZE,
        }}
        onChange={(pagination, filters, sorter, extra) => {
          dispatch(UsersAction.setPage(pagination.current - 1));
        }}
        loading={userListLoading}
        rowKey="id"
        dataSource={userList}
      />
    </SubPageLayout>
  );
};

export default Users;
