import React from 'react';
import { Button } from 'antd';
import './styles/site-details.scss';
import { contactUtil } from '../../../utils/utils';

const ContractCards = ({ label, fullName, role, email, telephoneNumber }) => (
  <div className="contract-cards" style={{ width: 250 }}>
    <div className="title fw-600">{label}</div>
    <div className="mgrName fw-500">{fullName}</div>
    <div className="mgrDetails fw-400">{role.displayName}</div>
    <div className="mgrDetails fw-400 space-top">{email}</div>
    <div className="mgrDetails fw-400">{telephoneNumber}</div>
  </div>
);

const SiteDetails = (props) => {
  const { address, onClose } = props;
  const contacts = [];
  for (let i = 0; i < 4; i++) {
    if (props[contactUtil(i).key]) {
      contacts.push({ ...props[contactUtil(i).key], label: contactUtil(i).label });
    }
  }

  return (
    <div className="site-details poppins">
      <h3 className="fw-500">Site Details</h3>
      <div className="address">
        <img src="/icons/location.svg" /> {address}
      </div>
      <div className="contract-container d-flex">
        {contacts.map((c, index) => (
          <ContractCards {...c} key={index} />
        ))}
      </div>
      <div className="d-flex jc-end">
        <Button type="primary" className="close-button" onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default SiteDetails;
