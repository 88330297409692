import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateAnswer } from '../../../../features/service-form.store';
import { Collapse, Select } from 'antd';
import FormSection from '../../helpers/FormSection';
import { NonFormikFormField } from '../../helpers/FormField';

const { Panel } = Collapse;
const { Option } = Select;

const options = ['Mitsubishi Electric', 'Kelvinator', 'Actron', 'Daikin', 'Fujitsu', 'Other'];

const AcManufactureSelection = ({ title, path, answerKey, answers, extra, subKey, gasType }) => {
  const [value, setValue] = useState('');
  const [otherValue, setOtherValue] = useState('');
  const [touched, setTouched] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setTouched(true);
    if (touched) {
      dispatch(
        updateAnswer({
          isArray: false,
          path: [...path, answerKey, subKey],
          value: value === 'Other' ? otherValue : value,
          gasType,
        }),
      );
    }
  }, [value]);

  useEffect(() => {
    if (answers && answers[answerKey]) {
      const v = answers[answerKey];
      setValue(v);
      setOtherValue(v === 'Other' ? v : '');
    }
  }, []);

  return (
    <Collapse ghost expandIcon={() => null} collapsible="header" className="question" activeKey={[title]}>
      <Panel header={title} key={title}>
        <FormSection className="no-padding">
          <Select className="w-100" showSearch value={value} placeholder="Select" onChange={(e) => setValue(e)}>
            {options.map((k) => (
              <Option key={k} value={k}>
                {k}
              </Option>
            ))}
          </Select>
          <NonFormikFormField
            labelText="Other"
            value={otherValue}
            onChange={(e) => setOtherValue(e.target.value)}
            disabled={value !== 'Other'}
          />
        </FormSection>
      </Panel>
    </Collapse>
  );
};

export default AcManufactureSelection;
