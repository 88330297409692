import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import FormSection from '../../helpers/FormSection';
import { NonFormikFormField } from '../../helpers/FormField';
import { useDispatch } from 'react-redux';
import { updateAnswer } from '../../../../features/service-form.store';

const { Panel } = Collapse;

const TextFieldQuestion = ({ title, path, answerKey, answers, extra, subKey, gasType, subHeader }) => {
  const [value, setValue] = useState('');
  const [touched, setTouched] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setTouched(true);
    if (touched) {
      if (path === 'notes') {
        dispatch(
          updateAnswer({
            isArray: false,
            path: ['notes', answerKey, 'description'],
            value,
          }),
        );
      } else {
        dispatch(
          updateAnswer({
            isArray: false,
            path: [...path, answerKey, subKey],
            value,
            gasType,
          }),
        );
      }
    }
  }, [value]);

  useEffect(() => {
    if (path === 'notes') {
      setValue(answers);
    } else {
      if (answers && answers[answerKey]) {
        if (subKey && gasType && answerKey === 'sensorReadings') {
          const data = answers[answerKey].filter(({ gasType: gt }) => gt === gasType)[0];
          data && setValue(data[subKey]);
        } else {
          setValue(answers[answerKey]);
        }
      }
    }
  }, []);

  return (
    <Collapse ghost expandIcon={() => null} collapsible="header" className="question" activeKey={[title]}>
      <Panel
        header={
          <div>
            {title} <br />
            {subHeader && <div className="panel-sub">{subHeader}</div>}
          </div>
        }
        key={title}
      >
        <FormSection className="no-padding">
          {extra}
          <NonFormikFormField labelText="Text" value={value} onChange={(e) => setValue(e.target.value)} />
        </FormSection>
      </Panel>
    </Collapse>
  );
};

export default TextFieldQuestion;
