import React, { useState } from 'react';
import { range } from 'lodash'
import { List, Switch } from 'antd';
import SubPageLayout from '../../../helpers/SubPageLayout';
import { TopBar, FiltersBar } from './ConfigPanels';
import { ProgressSummaryCard, SummaryCard } from './SummaryCards'
import { Labels14f300 } from '../components/labels';
import './styles.scss'

const mockData = range(10)

const Overview = () => {

  const [showBuildSummary, setShowBuildSummary] = useState(true)

  const handleOnProgressSummaryClick = (value) => {
    window.location = `/dashboard/build/overview/page/${value}`
  }

  const handleOnBuildSummaryClick = (value) => {
    window.location = `/dashboard/build/reporting/summary/${value}`
  }

  const renderSummaryIPItem = (item) => {
    return (
      <List.Item key={item}>
        <ProgressSummaryCard onClickSeeMore={handleOnProgressSummaryClick} />
      </List.Item>
    )
  }

  const renderSummaryItem = (item) => {
    return (
      <List.Item key={item}>
        <SummaryCard onClickSeeMore={handleOnBuildSummaryClick} />
      </List.Item>
    )
  }

  const handleOnSwitchShowBuildSummary = () => setShowBuildSummary(!showBuildSummary)

  return (
    <>
      <TopBar
        placeholder="Enter site name or chamber id"
        rightComponent={
          <div className="bc-switch">
            <label>Build Complete Summary</label>
            <Switch defaultChecked onChange={handleOnSwitchShowBuildSummary} />
          </div>
        }
      />
      <div className='d-flex overview'>
        <div className={`left ${!showBuildSummary ? 'left-100v ' : ''}`}>
          <SubPageLayout
            title="Build In Progress Summary"
          >
            <FiltersBar />
            <div className='list-wrapper'>
              <List
                dataSource={mockData}
                renderItem={renderSummaryIPItem}
                className="progress-summary-list"
              />
            </div>
          </SubPageLayout>
        </div>
        {
          showBuildSummary && <div className='right'>
            <SubPageLayout
              title="Build Summary"
            >
              <Labels14f300>Last 30 days</Labels14f300>
              <div className='list-wrapper'>
                <List
                  dataSource={mockData}
                  renderItem={renderSummaryItem}
                  className="summary-list"
                />
              </div>
            </SubPageLayout>
          </div>
        }
      </div>
    </>
  );
};

export default Overview;
