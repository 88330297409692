import React, { useRef } from 'react';
import FormSection from './FormSection';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormField from './FormField';
import { Button, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword, getChangeFormLoading, getUser, updateProfileInfo } from '../../../features/auth.store';
import { toast } from 'react-toastify';
import eng from '../../../utils/eng';
import to from '../../../utils/to';
import { phoneRegEx } from '../../../utils/constant';
import { Uploader } from './Components';
import get from 'lodash.get';

export const PasswordEditForm = () => {
  const loading = useSelector(getChangeFormLoading);
  const dispatch = useDispatch();
  const formRef = useRef();

  const handleSave = async (values) => {
    const [err] = await to(
      dispatch(changePassword({ password: values.currentPassword, newPassword: values.newPassword })).unwrap(),
    );
    formRef.current.resetForm();
    if (err) {
      return toast.error(eng.somethingWentWrong);
    }
    toast.success(eng.toast.updatePasswordSuccess);
  };

  return (
    <div>
      <FormSection title="Edit Password">
        <Formik
          initialValues={passwordInitValue}
          onSubmit={handleSave}
          className="d-flex f-col"
          validationSchema={passwordValidation}
          innerRef={formRef}
        >
          <Form>
            <div className="d-flex">
              <div className="flex">
                <FormField
                  InputComp={Input.Password}
                  name="currentPassword"
                  labelText="Current Password"
                  placeholder="password here"
                  visibilityToggle={false}
                />
                <FormField
                  InputComp={Input.Password}
                  name="newPassword"
                  labelText="New Password"
                  placeholder="password here"
                  visibilityToggle={false}
                />
                <FormField
                  InputComp={Input.Password}
                  name="confirm"
                  labelText="Confirm New Password"
                  placeholder="password here"
                  visibilityToggle={false}
                />
                <div className="d-flex jc-end space-top-p space-bottom">
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Update
                  </Button>
                </div>
              </div>
              <div className="flex" />
            </div>
          </Form>
        </Formik>
      </FormSection>
    </div>
  );
};

const passwordInitValue = {
  currentPassword: '',
  newPassword: '',
  confirm: '',
};

const passwordValidation = Yup.object({
  currentPassword: Yup.string().required('Required'),
  newPassword: Yup.string().required('Required').min(6, 'Select a strong password'),
  confirm: Yup.string().oneOf([Yup.ref('newPassword')], 'Confirmation Failed'),
});

export const ProfileInfoEdit = () => {
  const loading = useSelector(getChangeFormLoading);
  const userData = useSelector(getUser);
  const imageUploadRef = useRef();
  const dispatch = useDispatch();

  const handleSave = async (values) => {
    const image = get(imageUploadRef, ['current', 'fileList', 0, 'originFileObj'], null);
    const [err] = await to(dispatch(updateProfileInfo({ image, ...values })).unwrap());
    if (err) {
      return toast.error(eng.somethingWentWrong);
    }
    return toast.success(eng.toast.updateProfileInfoSuccess);
  };

  return (
    <div>
      <FormSection title="Edit Password">
        <Formik
          initialValues={{
            fullName: userData.user_full_name,
            telephoneNumber: userData.user_telephone_number,
          }}
          onSubmit={handleSave}
          className="d-flex f-col"
          validationSchema={profileInfoValidation}
        >
          <Form>
            <div className="d-flex">
              <div className="flex space-right">
                <FormField name="fullName" labelText="Full Name" placeholder="Input here" />
                <div className="d-flex">
                  <div>
                    <span>Upload New Image:</span>
                    <Uploader
                      accept="image/*"
                      beforeUpload={() => Promise.reject()}
                      className="w-200px h-200px space-top flex"
                      maxCount={1}
                      ref={imageUploadRef}
                      text="Upload your image"
                    />
                  </div>
                  <div className="flex d-flex f-col space-left">
                    <span>Current Image:</span>
                    {userData && (
                      <img
                        alt="profile"
                        className="space-top rounded-border"
                        width={200}
                        src={userData.user_profile_picture}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex">
                <FormField name="telephoneNumber" labelText="Telephone Number" placeholder="Input here" />
              </div>
            </div>
            <div className="d-flex jc-center space-top-p space-bottom">
              <Button type="primary" htmlType="submit" loading={loading}>
                Update
              </Button>
            </div>
          </Form>
        </Formik>
      </FormSection>
    </div>
  );
};

const profileInfoValidation = Yup.object({
  fullName: Yup.string().required('Required'),
  telephoneNumber: Yup.string().required('Required').matches(phoneRegEx, 'Invalid Phone Number'),
});
