import React from 'react';

const nameStyle = {
  fontSize: 40,
  fontWeight: 700,
};

const LoadingScreen = () => {
  return (
    <div className="loading-screen h-100 f-col d-flex jc-space-around ai-center">
      <img src="/logo.svg" width="300" />
      <h1 className="poppins techila-name" style={nameStyle}></h1>
    </div>
  );
};

export default LoadingScreen;
