import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { GET } from '../service/base-api-service';

const initialState = {
  adminRoles: null,
  scrubberTypes: null,
  optionsTypes: null,
  interiorLightingTypes: null,
  gasSensorTypes: null,
  gasMonitorTypes: null,
  documentCategories: null,
  chemicalTypes: null,
  chamberTypes: null,
  buildLocations: null,
  airFilterTypes: null,
  taskTypes: null // TODO change task s according to the BE
};

const fetchAdminRole = createAsyncThunk('enum/adminRoles', () => GET('v1/admin/roles'));

export const fetchEventTypes = createAsyncThunk('enum/eventTypes', (viewType) => GET(`v1/enum/${viewType.toLowerCase()}/eventTypes`));

export const fetchAdminRoleIfNotInLocalStore = () => (dispatch, getState) => {
  const adminRoles = getAdminRoles(getState());
  if (adminRoles.length === 0) dispatch(fetchAdminRole());
};

export const getEnums = createAsyncThunk('fetch/enum', async ({ type }, { getState }) => {
  const values = enumState(getState())[type];
  if (!values || values.length === 0) {
    const values = await GET(`v1/enum/${type}`);
    return { type, values: values.map(({ name, displayName }) => ({ label: displayName, value: name })) };
  }
  return { type, values };
});

const enumSlice = createSlice({
  name: 'enum',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAdminRole.fulfilled]: (state, { payload }) => {
      state.adminRoles = payload;
    },
    [getEnums.fulfilled]: (state, { payload: { type, values } }) => {
      state[type] = values;
    },
    [fetchEventTypes.fulfilled]: (state, { payload }) => {
      state.eventTypes = payload;
    },
  },
});

export default enumSlice.reducer;

const enumState = (state) => state.enum;

export const getAdminRoles = createSelector(enumState, (state) => state.adminRoles || []);
export const getEventTypes = createSelector(enumState, (state) => state.eventTypes || []);

export const getBuildLocations = createSelector(enumState, (state) => state.buildLocations || []);
export const getAllEnum = createSelector(enumState, (state) => state);
