import React, { useState } from 'react';
import { Avatar, Popover } from 'antd';
import { NavLink, useLocation, Link } from 'react-router-dom';
import { DownOutlined, UserOutlined, UpOutlined } from '@ant-design/icons';

import './styles/layout.scss';
import { getUser, isFeatureEnabled, logout } from '../../../features/auth.store';
import { useDispatch, useSelector } from 'react-redux';

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [reportsExpand, setReportExpand] = useState(false);
  const [scheduleExpand, setScheduleExpand] = useState(false);
  const [buildExpand, setBuildExpand] = useState(false);
  const [buildReportsExpand, setBuildReportsExpand] = useState(false)

  const handleLogout = async () => {
    await dispatch(logout());
  };

  const userData = useSelector(getUser);
  const getFeatureEnabled = useSelector(isFeatureEnabled);
  const featureViewCompany = getFeatureEnabled('view_company');
  const featureViewUsers = getFeatureEnabled('view_users');
  const featureShedule = getFeatureEnabled('view_schedule');
  const featureServiceReport = getFeatureEnabled('view_service_report');
  const featureAfterServiceReport = getFeatureEnabled('view_after_service_report');
  const featureViewDocuments = getFeatureEnabled('view_documents');
  const featureViewChambersOfSite = getFeatureEnabled('view_chambers_of_a_site');
  const featureViewSites = getFeatureEnabled('view_sites_of_the_organization');
  const featureBuild = getFeatureEnabled('view_build');

  return (
    <div className="layout d-flex f-col mulish">
      <div className="header d-flex f-row jc-space-between">
        <img className="logo" src="/header-logo.svg" alt="logo" />

        <Popover
          title="User Account"
          placement="bottomRight"
          content={
            <div>
              <Link to="/profile">Edit Profile</Link>
            </div>
          }
        >
          <div className="profile-dropdown d-flex ai-center jc-space-between">
            <Avatar icon={<UserOutlined />} src={<img src={userData.user_profile_picture} alt="img" width={60} />} />
            <div className="text">
              <div className="name fw-600">{userData.user_full_name}</div>
              <div className="email fw-400">{userData.sub}</div>
            </div>
          </div>
        </Popover>
      </div>
      <div className="body h-100 d-flex">
        <div className="side-bar h-100 d-flex f-col">
          <div className="profile-info d-flex">
            <Avatar size={64} icon={<UserOutlined />} shape="square" />
            <div className="profile-details d-flex f-col">
              <h3 className="name fw-700">{userData.user_full_name}</h3>
              <div className="email fw-700">{userData.sub}</div>
            </div>
          </div>
          <hr className="divider" />

          <div className="side-bar-menu d-flex f-col jc-space-between">
            <div className="main-menus">
              {featureViewCompany && (
                <NavLink to="/companies" className="menu-item">
                  <img alt="i" src="/icons/companies.svg" className="icon" />
                  <div className="title">Companies</div>
                </NavLink>
              )}
              {featureViewSites &&
                <NavLink to="/sites" className="menu-item">
                  <img alt="i" src="/icons/sites.svg" className="icon" />
                  <div className="title">Sites</div>
                </NavLink>
              }
              {
                featureViewChambersOfSite &&
                <NavLink to="/chambers" className="menu-item">
                  <img alt="i" src="/icons/chambers.svg" className="icon" />
                  <div className="title">Chambers</div>
                </NavLink>
              }
              {featureViewUsers && (
                <NavLink to="/users" className="menu-item">
                  <img alt="i" src="/icons/users.svg" className="icon" />
                  <div className="title">Users</div>
                </NavLink>
              )}
              {
                (featureServiceReport || featureAfterServiceReport) &&
                <a
                  className={`menu-item ${location.pathname.startsWith('/reports') ? 'active' : ''}`}
                  onClick={() => setReportExpand(!reportsExpand)}
                >
                  <img alt="i" src="/icons/reports.svg" className="icon" />
                  <div className="title d-flex jc-space-between w-100">
                    Reports <div>{reportsExpand ? <UpOutlined /> : <DownOutlined />}</div>
                  </div>
                </a>
              }
              {(featureServiceReport || featureAfterServiceReport) && reportsExpand && (
                <div className="sub-menu">
                  {
                    featureServiceReport &&
                    <NavLink to="/reports/serviced" className="sub-menu-item">
                      <img alt="i" src="/icons/serviced.svg" className="icon" />
                      <div className="title">Service Reports</div>
                    </NavLink>
                  }
                  {
                    featureAfterServiceReport && <NavLink to="/reports/after-service" className="sub-menu-item">
                      <img alt="i" src="/icons/after-service.svg" className="icon" />
                      <div className="title">Service Summaries</div>
                    </NavLink>
                  }
                </div>
              )}
              {
                featureViewDocuments &&
                <NavLink to="/documents" className="menu-item">
                  <img alt="i" src="/icons/documents.svg" className="icon" />
                  <div className="title">Documents</div>
                </NavLink>
              }

              {
                featureShedule && <a
                  className={`menu-item`}
                  onClick={() => setScheduleExpand(!scheduleExpand)}
                >
                  <img alt="i" src="/icons/calendar-icon.svg" className="icon" />
                  <div className="title d-flex jc-space-between w-100">
                    Scheduler <div>{scheduleExpand ? <UpOutlined /> : <DownOutlined />}</div>
                  </div>
                </a>
              }
              {
                featureShedule && scheduleExpand &&
                <div className="sub-menu">
                  <NavLink to="/schedule/service" className="sub-menu-item">
                    <img alt="i" src="/icons/sh-emp.svg" className="icon" />
                    <div className="title">Employee Scheduling</div>
                  </NavLink>
                  <NavLink to="/schedule/product" className="sub-menu-item">
                    <img alt="i" src="/icons/sh-pro.svg" className="icon" />
                    <div className="title">Product Scheduling</div>
                  </NavLink>
                  <NavLink to="/schedule/training-school" className="sub-menu-item">
                    <img alt="i" src="/icons/sh-tr.svg" className="icon" />
                    <div className="title">Training School Scheduling</div>
                  </NavLink>
                </div>
              }

              {
                featureBuild && <a
                  className={`menu-item`}
                  onClick={() => setBuildExpand(!buildExpand)}
                >
                  <img alt="i" src="/icons/build-icon.svg" className="icon" />
                  <div className="title d-flex jc-space-between w-100">
                    Build <div>{scheduleExpand ? <UpOutlined /> : <DownOutlined />}</div>
                  </div>
                </a>
              }
              {
                featureBuild && buildExpand &&
                <div className="sub-menu">
                  <NavLink to="/build/overview" className="sub-menu-item">
                    <div className="title">Overview</div>
                  </NavLink>
                  <NavLink to="/build/product-roster" className="sub-menu-item">
                    <div className="title">Production Roster</div>
                  </NavLink>

                  <NavLink to="/build/admin-view" className="sub-menu-item">
                    <div className="title">Admin View</div>
                  </NavLink>

                  <a
                    className={`sub-menu-item`}
                    onClick={() => setBuildReportsExpand(!buildReportsExpand)}
                  >
                    <div className="title d-flex jc-space-between w-100">
                      Reporting <div>{buildReportsExpand ? <UpOutlined /> : <DownOutlined />}</div>
                    </div>
                  </a>
                  {
                    buildReportsExpand && <div className="sub-menu">
                      <NavLink to="/build/reporting/summary" className="sub-menu-item">
                        <div className="title">-Built Summary</div>
                      </NavLink>
                    </div>
                  }
                </div>
              }
            </div>

            <a className="menu-item logout" onClick={handleLogout}>
              <img alt="i" src="/icons/logout.svg" className="icon" />
              <div className="title">Logout</div>
            </a>
          </div>
        </div>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
