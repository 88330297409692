import React, { useRef, useState } from 'react';
import FormField, { FormFieldOverrideOnChange } from '../helpers/FormField';
import { Uploader } from '../helpers/Components';
import FormSection from '../helpers/FormSection';
import { Button, DatePicker, Modal, Spin } from 'antd';
import { Form, Formik, useField } from 'formik';
import * as Yup from 'yup';
import { USER_TYPES, phoneRegEx } from '../../../utils/constant';
import { PlusOutlined } from '@ant-design/icons';
import { AdminRolesDropdown, CompanySelectDropDown, SiteSelectMultiDropdown, RegionSelectMultiDropdown } from '../helpers/drop-downs-for-enums';
import { useDispatch, useSelector } from 'react-redux';
import { addNewUser, UsersAction, getUserTableInfo } from '../../../features/user.store';
import get from 'lodash.get';
import { omit, has, isEmpty } from 'lodash'
import to from '../../../utils/to';
import { toast } from 'react-toastify';
import eng from '../../../utils/eng';

const UsersAdd = () => {
  const [showAddUser, setShowAddUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const closeAddUserModal = () => {
    setShowAddUser(false);
  };

  const dispatch = useDispatch();

  const formRef = useRef();
  const imageUploadRef = useRef();

  const tableInfo = useSelector(getUserTableInfo);

  const handleOK = () => {
    formRef.current.submitForm();
  };

  const handleSave = async (values) => {
    setLoading(true);
    const image = get(imageUploadRef, ['current', 'fileList', 0, 'originFileObj'], null);
    let formValue = values;
    if(has(values, 'siteId') && !isEmpty(get(values, 'siteId'))){
        formValue = {
          ...values,
          assignedSiteIds : get(values, 'siteId').map(id=>Number.parseInt(id))
        }
        formValue = omit(formValue, ['siteId']);
    }
    if(has(values, 'regions') && !isEmpty(get(values, 'regions'))){
      formValue = {
        ...values,
        regions : get(values, 'regions')
      }
    }

    const [err] = await to(dispatch(addNewUser({ formValue, image })).unwrap());
    if (err) {
      toast.error(err.message || eng.somethingWentWrong);
    } else {
      toast.success(eng.toast.addUserSuccessfully);
      formRef.current.resetForm();
      setShowAddUser(false);
      dispatch(UsersAction.setPage(0));
    }
    setLoading(false);
  };

  const isSiteSelectionHide = () =>{
    const user = get(formRef, ['current','values','role','id'], '')
    return user !== USER_TYPES.SITE_SERVICE_TECHNICIAN.id && 
    user !== USER_TYPES.SERVICE_PROVIDER.id && 
    user !== USER_TYPES.ROLE_SITE_USER.id;
  }

  const isRegionSelectionHide = () =>{
    const roleId = get(formRef, ['current','values','role','id'], '');
    return roleId !== USER_TYPES.MINE_ARC_SERVICE_COORDINATOR.id &&
    roleId !== USER_TYPES.MINE_ARC_SERVICE_TECHNICIAN.id &&
    roleId !== USER_TYPES.MINE_ARC_USER.id;
  }

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={() => setShowAddUser(true)}>
        Add Users
      </Button>
      <Modal
        title="Add User"
        visible={showAddUser}
        onOk={handleOK}
        onCancel={closeAddUserModal}
        width={1120}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <FormSection>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={YupValidation}
            validateOnBlur
            onSubmit={handleSave}
          >
            <Form>
              <Spin spinning={loading}>
                <div className="d-flex">
                  <div className="flex space-right">
                    <FormField name="fullName" labelText="User Name" placeholder="Input here" />
                    <FormField name="email" labelText="Email Address" placeholder="Input here" />
                    <FormField name="telephoneNumber" labelText="Telephone Number" placeholder="+61" />
                    <FormField
                      name="tenantIdentifier"
                      labelText="Company"
                      placeholder="Input Here"
                      InputComp={CompanySelectDropDown}
                      extraOnChange={(val) => dispatch(UsersAction.setSelectedCompanyToTable(val))}
                    />
                  </div>
                  <div className="flex space-left">
                    <FormField
                      name="role.id"
                      labelText="Company Role"
                      placeholder="Input Here"
                      InputComp={AdminRolesDropdown}
                    />
                    <FormField name="certificationNumber" labelText="Certification Number" placeholder="Input here" />
                    <FormFieldOverrideOnChange
                      name="certificationExpiry"
                      labelText="Certification Expiry"
                      InputComp={(props) => <DatePicker {...props} className="w-100" />}
                    />
                    <FormFieldOverrideOnChange
                        allowClear 
                        mode="multiple"
                        hide={isSiteSelectionHide}
                        name="siteId"
                        labelText="Sites"
                        placeholder="Input Here"
                        company={tableInfo.company}
                        InputComp={SiteSelectMultiDropdown}
                      />
                    <FormFieldOverrideOnChange
                        allowClear 
                        mode="multiple"
                        hide={isRegionSelectionHide}
                        name="regions"
                        labelText="Regions"
                        placeholder="Input Here"
                        InputComp={RegionSelectMultiDropdown}
                      />
                    <Uploader
                      accept="image/*"
                      beforeUpload={() => Promise.reject()}
                      className="w-200px h-200px"
                      maxCount={1}
                      ref={imageUploadRef}
                    />
                  </div>
                </div>
              </Spin>
            </Form>
          </Formik>
        </FormSection>
      </Modal>
    </>
  );
};

const initialValues = {
  fullName: '',
  email: '',
  telephoneNumber: '',
  tenantIdentifier: null,
  role: {
    id: null,
  },
  siteId: [],
  regions: []
};

const YupValidation = Yup.object({
  fullName: Yup.string().required('Required'),
  email: Yup.string().required('Required').email('Invalid email address'),
  telephoneNumber: Yup.string().required('Required').matches(phoneRegEx, 'Invalid Phone Number'),
  tenantIdentifier: Yup.string().required('Required'),
  role: Yup.object({
    id: Yup.number().required('Required'),
  }),
  regions: Yup.array().of(Yup.string()),
  siteId: Yup.array().of(Yup.string())
});

export default UsersAdd;
