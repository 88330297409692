import React, { useEffect } from 'react';
import SubPageLayout from '../helpers/SubPageLayout';
import SearchFilterInput from '../helpers/SearchFilterInput';
import { SiteViewTile } from '../helpers/Components';
import {
  getPaginatedSiteList,
  getSiteList,
  getSiteListLoading,
  getSiteTableInfo,
  SiteAction,
} from '../../../features/site.store';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { getUserRole } from '../../../features/auth.store';
import { ROLE } from '../../../utils/features';

const SitesView = () => {
  const dispatch = useDispatch();
  const siteTableInfo = useSelector(getSiteTableInfo);
  const siteList = useSelector(getSiteList);
  const siteListLoading = useSelector(getSiteListLoading);
  const userRole = useSelector(getUserRole);

  const companyDropdown = [ROLE.ST, ROLE.SU].indexOf(userRole) === -1;

  useEffect(() => {
    if (!companyDropdown) {
      dispatch(getPaginatedSiteList({ page: 0, isAscending: false, sortColumn: 'id' }));
    }
  }, [companyDropdown]);

  return (
    <SubPageLayout title="Site List">
      {companyDropdown && (
        <SearchFilterInput
          placeholder="Search."
          companyDropdown
          companyProps={{
            onChange: (val) => dispatch(SiteAction.setSelectedCompany(val, false)),
            onLoad: (list) => dispatch(SiteAction.setSelectedCompany(list[0].identifier, false)),
            value: siteTableInfo.company,
            selectFirstIfValueIsEmpty: true,
            placeholder: 'Select Company',
          }}
        />
      )}
      <Spin spinning={siteListLoading}>
        <div className="d-flex f-wrap poppins">
          {siteList.map((a) => (
            <SiteViewTile {...a} key={a.id} />
          ))}
        </div>
      </Spin>
    </SubPageLayout>
  );
};

export default SitesView;
