import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Input, Modal } from 'antd';
import FormField, { FormFieldOverrideOnChange } from '../helpers/FormField';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import FormSection from '../helpers/FormSection';
import {
    getCalenderIsLoading,
    getEditEvent,
    getViewType
} from '../../../features/scheduler.store'
import './styles/schedule.scss';
import { 
    TaskTypesDropDown, 
    EventUserMultiSearchDropDown, 
    ScheduleStatusDropDown, 
    YesNoDropDown,
    LocationDropDown,
    RecurranceTypesDropDown,
    DateRangeSelector,
} from '../helpers/drop-downs-for-enums';

const ServiceScheduleEventForm = ({
    onSubmit,
    onCancel,
    showModel,
}) => {
    const formRef = useRef();

    const isLoading = useSelector(getCalenderIsLoading);
    const editEvent = useSelector(getEditEvent)
    const viewType = useSelector(getViewType);

    const handleOnSubmit = (value) => {
        formRef.current.resetForm()
        onSubmit && onSubmit(value, editEvent!==null)
    }

    const handleOnCancel = () => {
        onCancel && onCancel()
    }

    return (
        <Modal
                title="Schedule Event"
                visible={showModel}
                onOk={() => { formRef.current.submitForm(); }}
                onCancel={handleOnCancel}
                width={1120}
        >
         <FormSection>
                <Formik
                    innerRef={formRef}
                    initialValues={editEvent || initialValues}
                    validationSchema={YupValidation}
                    validateOnBlur
                    onSubmit={handleOnSubmit}
                    enableReinitialize={true}
                >
                    {props => 
                    <Form>
                        <Spin spinning={isLoading}>
                            <div className="d-flex">
                                <div className="flex space-right">
                                    <FormField name="name" labelText="Name" placeholder="Input here" />
                                    <FormField name="type" labelText="Type of Task" InputComp={TaskTypesDropDown} viewType={viewType} selectFirstOnLoad/>
                                    <FormField name="site" labelText="Site" selectFirstOnLoad InputComp={LocationDropDown}/>
                                    <FormField name="recurrence" labelText="Repeat Event Options" selectFirstOnLoad InputComp={RecurranceTypesDropDown}/>
                                    <FormFieldOverrideOnChange name="dateRange" labelText="Date" InputComp={DateRangeSelector}/> 
                                    <FormFieldOverrideOnChange name="invitees" labelText="Technician" viewType={viewType} InputComp={EventUserMultiSearchDropDown}/>
                                </div>
                                <div className="flex space-left">
                                    <FormField name="description" labelText="Description" placeholder="Input here" />
                                    <FormField name="status" labelText="Status" selectFirstOnLoad viewType={viewType} InputComp={ScheduleStatusDropDown}/>
                                    <FormField name="vehicle" labelText="Vehicle" InputComp={YesNoDropDown}/>
                                    <FormField name="notes" labelText="Notes" rows={4} InputComp={Input.TextArea}/>
                                </div>
                            </div>
                        </Spin>
                    </Form>}
                </Formik>
        </FormSection>
        </Modal>
    );
};

const initialValues = {
    name: '',
    type: '',
    site: '',
    recurrence: '',
    dateRange: null,
    description: '',
    status: '',
    vehicle: '',
    notes: '',
    invitees: [],
};

const YupValidation = Yup.object({
    name: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    site: Yup.string(),
    dateRange: Yup.array().of(Yup.string()).required('Required'),
    description: Yup.string(),
    status: Yup.string().required('Required'),
    vehicle: Yup.string(),
    notes: Yup.string(),
    recurrence: Yup.string(),
    invitees: Yup.array().of(Yup.object({
        fullname: Yup.string(),
        id: Yup.number()
    })),
});

export default ServiceScheduleEventForm;
