const fillOptions = async () => {
  const optionList = document.getElementsByClassName('ant-collapse-item ant-collapse-item-active');
  const items = optionList[0].getElementsByClassName('ant-radio-button-wrapper');
  for (let j = 0; j < items.length; j++) {
    const item = items[j];
    item.click();

    let a = document.getElementsByClassName('header-icons');
    for (let k = 0; k < a.length; k++) {
      let he = a[k];
      let check = he.children[0];
      check.click();

      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  }
};

window._fillOptions = fillOptions;
