export const mockChamber = {
    "createdDate": 1647492427433,
    "lastModifiedDate": 1647492427433,
    "createdBy": "hello@techilasoftware.com",
    "updatedBy": "hello@techilasoftware.com",
    "id": 11,
    "qrCode": "54f91ef3-2d09-4c54-a5d0-ca695d37f2e2",
    "name": "MA 1947",
    "status": "Awaiting Dispatch",
    "site": {
        "createdDate": 1646538593267,
        "lastModifiedDate": 1646538593267,
        "createdBy": "hello@techilasoftware.com",
        "updatedBy": "hello@techilasoftware.com",
        "id": 3,
        "name": "Dummy Site",
        "address": "Address",
        "enabled": true,
        "mainContact": {
            "id": 9,
            "fullName": "Site User",
            "isLocked": false,
            "email": "randika+siteuser@techilasoftware.com",
            "telephoneNumber": "0711953466",
            "profilePictureURL": "https://d29fhpw069ctt2.cloudfront.net/icon/image/37746/preview.svg",
            "tenantIdentifier": "f2a5737e-2038-4108-a70e-92d48589bb54",
            "role": {
                "id": 6,
                "name": "ROLE_SITE_USER",
                "displayName": "Site User Role",
                "description": "Users of a specific site."
            },
            "assignedSites": [],
            "regions": [
                "AUSTRALIA"
            ]
        },
        "tenantIdentifier": "f2a5737e-2038-4108-a70e-92d48589bb54",
        "tenantName": "New Tenant"
    },
    "buildLocationEnum": {
        "name": "AUSTRALIA",
        "displayName": "Australia"
    },
    "expectedDelivery": 1648701842404,
    "chamberTypeEnum": {
        "name": "MINE_SAFE_STANDARD_DESIGN",
        "displayName": "MineSAFE Standard Design"
    },
    "capacity": "10",
    "duration": "20",
    "scrubberTypeEnum": {
        "name": "SII",
        "displayName": "SII"
    },
    "chemicalTypeEnum": {
        "name": "CARTRIDGE",
        "displayName": "Cartridge"
    },
    "coChemicalQuantity": "10",
    "co2ChemicalQuantity": "None",
    "moistureRemovalQuantity": "None",
    "airFilterTypeEnum": {
        "name": "CAMS",
        "displayName": "CAMS"
    },
    "mainChamberOxygenLevel": "None",
    "ppmsOxygenLevel": "None",
    "airlockOxygenLevel": "None",
    "oxygenCandle": false,
    "gasSensorTypeEnums": [
        {
            "name": "O2",
            "displayName": "O2"
        },
        {
            "name": "CO",
            "displayName": "CO"
        }
    ],
    "gasMonitorTypeEnum": {
        "name": "AURA_FX",
        "displayName": "Aura-FX"
    },
    "manualGasTubes": false,
    "coTubeQuantity": "None",
    "co2TubeQuantity": "None",
    "o2TubeQuantity": "None",
    "batteryQuantity": "None",
    "interiorLightingTypeEnum": {
        "name": "LED",
        "displayName": "LED"
    },
    "externalFireExtinguisher": false,
    "internalFireExtinguisher": false,
    "fireBlanket": false,
    "transformer": false,
    "communicationsBox": false,
    "toilet": false,
    "longLifeFood": false,
    "additionalLanguages": "",
    "optionsTypeEnums": [
        {
            "name": "TOW_BAR",
            "displayName": "Tow bar"
        },
        {
            "name": "WHEELS",
            "displayName": "Wheels"
        },
        {
            "name": "PPMS",
            "displayName": "PPMS"
        },
        {
            "name": "AIRLOCK",
            "displayName": "Airlock"
        }
    ],
    "tenant": {
        "createdDate": 1646538591517,
        "lastModifiedDate": 1646538591517,
        "createdBy": "hello@techilasoftware.com",
        "updatedBy": "hello@techilasoftware.com",
        "identifier": "f2a5737e-2038-4108-a70e-92d48589bb54",
        "name": "New Tenant",
        "email": "randika@gmail.com",
        "phone": "+94770053466",
        "address": "address",
        "description": "description",
        "imageUrl": "https://www.figma.com/file/DaJGH7fn21TSirDch6T5Dv/Minearcapp?node-id=0%3A1",
        "isEnabled": true,
        "admin": {
            "id": 8,
            "fullName": "Tenant Admin",
            "isLocked": false,
            "email": "randika+tenantadmin@techilasoftware.com",
            "telephoneNumber": "+94770053466",
            "profilePictureURL": "https://d29fhpw069ctt2.cloudfront.net/icon/image/37746/preview.svg",
            "tenantIdentifier": "f2a5737e-2038-4108-a70e-92d48589bb54",
            "role": {
                "id": 5,
                "name": "ROLE_TENANT_ADMIN",
                "displayName": "Tenant Admin Role",
                "description": "Admin of a specific tenant."
            },
            "assignedSites": [],
            "regions": [
                "AUSTRALIA"
            ]
        }
    },
    "isEnabled": true,
    "lastServicedDate": null,
    "rspKitNo": "R12",
    "serviceFrequency": 3,
    "nextServiceDate": 1687142185307
}

export const mockCalender = [
    {
        id: 1,
        chamberInfo: {
            subText: 'PT Freeport | Indonesia',
            date: '22/10/2022',
            src: '/tmp/image1.svg',
            customer: 'customer name',
            ...mockChamber
        },
        items: [
            {
                fromDate: '2023-02-27',
                toDate: '2023-04-02',
                title: 'a',
                users: [
                    {
                        name: 'Vanna Lonk'
                    },
                    {
                        name: 'Steve Hardy'
                    }
                ],
                type: 1
            },
        ]
    },
    {
        id: 2,
        chamberInfo: {
            subText: 'PT Freeport | Indonesia',
            date: '22/10/2022',
            src: '/tmp/image1.svg',
            customer: 'customer name',
            ...mockChamber
        },
        items: [
            {
                fromDate: '2023-02-25',
                toDate: '2023-03-3',
                title: 'c',
                type: 2,
                users: [
                    {
                        name: 'Vanna Lonk'
                    },
                    {
                        name: 'Steve Hardy'
                    }
                ],
            }
        ]
    },
    {
        id: 3,
        chamberInfo: {
            subText: 'PT Freeport | Indonesia',
            date: '22/10/2022',
            src: '/tmp/image1.svg',
            customer: 'customer name',
            ...mockChamber
        },
        items: [
            {
                fromDate: '2023-03-2',
                toDate: '2023-03-3',
                title: 'c',
                type: 3,
                users: [
                    {
                        name: 'Vanna Lonk'
                    },
                    {
                        name: 'Steve Hardy'
                    }
                ],
            },
            {
                fromDate: '2023-03-6',
                toDate: '2023-03-10',
                title: 'c',
                type: 4,
                users: [
                    {
                        name: 'Vanna Lonk'
                    },
                    {
                        name: 'Steve Hardy'
                    }
                ],
            }
        ]
    },{
        id: 4,
        chamberInfo: {
            subText: 'PT Freeport | Indonesia',
            date: '22/10/2022',
            src: '/tmp/image1.svg',
            customer: 'customer name',
            ...mockChamber
        },
        items: [
            {
                fromDate: '2023-03-8',
                toDate: '2023-03-8',
                title: 'c',
                type: 1,
                users: [
                    {
                        name: 'Vanna Lonk'
                    },
                    {
                        name: 'Steve Hardy'
                    }
                ],
            }
        ]
    }
    ,{
        id: 5,
        chamberInfo: {
            subText: 'PT Freeport | Indonesia',
            date: '22/10/2022',
            src: '/tmp/image1.svg',
            customer: 'customer name',
            ...mockChamber
        },
        items: [
            {
                fromDate: '2023-03-4',
                toDate: '2023-03-8',
                title: 'c',
                type: 5,
                users: [
                    {
                        name: 'Vanna Lonk'
                    },
                    {
                        name: 'Steve Hardy'
                    }
                ],
            }
        ]
    },
    {
        id: 6,
        chamberInfo: {
            subText: 'PT Freeport | Indonesia',
            date: '22/10/2022',
            src: '/tmp/image1.svg',
            customer: 'customer name',
            ...mockChamber
        },
        items: [
            {
                fromDate: '2023-03-2',
                toDate: '2023-03-3',
                title: 'c',
                type: 1,
                users: [
                    {
                        name: 'Vanna Lonk'
                    },
                    {
                        name: 'Steve Hardy'
                    }
                ],
            }
        ]
    }
]

export const mockComments = [
    {
        name: 'Ja Pe',
        comment: 'Penalty for late delivery.',
        status: 'shipping',
        date: 1647492427433,
    
    },
    {
        name: 'Ma Be',
        comment: 'Original due date not achievable.',
        status: 'delivery',
        date: 1647492427433,
    
    },
    {
        name: 'Sa We',
        comment: 'Ordered for showroom.',
        status: 'internal',
        date: 1647492427433,
    
    }
]

export const mockHistory = [
    {
        name: 'Ja Pe',
        text: 'Changed Invoice Month from August to September',
        date: 1647492427433,
    
    },
    {
        name: 'Ma Be',
        text: 'Changed Invoice Month from August to September',
        date: 1647492427433,
    
    },
    {
        name: 'Sa We',
        text: 'Changed Invoice Month from August to September',
        date: 1647492427433,
    
    }
]
