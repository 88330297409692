import React, { useEffect, useState } from 'react';
import { Checkbox, Input } from 'antd';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';

const FormField = ({ labelText, className, InputComp, extraOnChange, ...rest }) => {
  const [field, meta] = useField(rest);
  if(rest && rest.hide && rest.hide()){
    return <></>
  }
  return (
    <div className={`form-field ${meta.touched && meta.error ? 'error' : ''} ${className || ''}`}>
      <div className="label">{labelText}</div>
      {InputComp ? (
        <InputComp
          {...field}
          {...rest}
          onChange={(...params) => {
            field.onChange(field.name)(...params);
            extraOnChange && extraOnChange(...params);
          }}
        />
      ) : (
        <Input {...field} {...rest} />
      )}
      {meta.touched && meta.error && <span className="error-message">{meta.error}</span>}
    </div>
  );
};

export const FormFieldDrawer = ({ labelText, className, InputComp, extraOnChange, ...rest }) => {
  const [field, meta] = useField(rest);
  if(rest && rest.hide && rest.hide()){
    return <></>
  }
  return (
    <div className={`form-field ${meta.touched && meta.error ? 'error' : ''} ${className || ''}`}>
      <div className="label pd-0">{labelText}</div>
      {InputComp ? (
        <InputComp
          {...field}
          {...rest}
          onChange={(...params) => {
            field.onChange(field.name)(...params);
            extraOnChange && extraOnChange(...params);
          }}
        />
      ) : (
        <Input {...field} {...rest} />
      )}
      {meta.touched && meta.error && <span className="error-message">{meta.error}</span>}
    </div>
  );
};

export const FormFieldOverrideOnChangeDrawer = ({ labelText, className, InputComp, hide, ...rest }) => {
  const [field, meta] = useField(rest);
  const { setFieldValue } = useFormikContext();

  if(hide && hide()){
    return <></>
  }

  return (
    <div className={`form-field ${meta.touched && meta.error ? 'error' : ''} ${className || ''}`}>
      <div className="label pd-0">{labelText}</div>
      <InputComp
        {...field}
        {...rest}
        onChange={(value) => {
          setFieldValue(rest.name, value);
        }}
      />
      {meta.touched && meta.error && <span className="error-message">{meta.error}</span>}
    </div>
  );
};

export const InputFiledWithNone = (props) => {
  const [isNone, setNone] = useState(false);
  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {
    setFieldValue(props.name, isNone ? 'None' : '');
  }, [isNone]);

  useEffect(() => {
    const v = values[props.name] || '';
    if (v === 'None') {
      setNone(true);
    }
    setFieldValue(props.name, isNone ? 'None' : v);
  }, []);

  return (
    <FormField
      addonAfter={
        <Checkbox checked={isNone} onChange={(e) => setNone(e.target.checked)}>
          None
        </Checkbox>
      }
      {...props}
      disabled={isNone}
    />
  );
};

export const NonFormikFormField = ({ labelText, className, InputComp, onChange, style, hasError, ...rest }) => {
  return (
    <div className={`form-field ${hasError ? 'error' : ''} ${className || ''}`} style={style}>
      <div className="label">{labelText}</div>
      {InputComp ? <InputComp {...rest} onChange={onChange} /> : <Input {...rest} onChange={onChange} />}
    </div>
  );
};

export const FormFieldOverrideOnChange = ({ labelText, className, InputComp, hide, ...rest }) => {
  const [field, meta] = useField(rest);
  const { setFieldValue } = useFormikContext();

  if(hide && hide()){
    return <></>
  }

  return (
    <div className={`form-field ${meta.touched && meta.error ? 'error' : ''} ${className || ''}`}>
      <div className="label">{labelText}</div>
      <InputComp
        {...field}
        {...rest}
        onChange={(value) => {
          setFieldValue(rest.name, value);
        }}
      />
      {meta.touched && meta.error && <span className="error-message">{meta.error}</span>}
    </div>
  );
};

export const FormFieldOverrideMomentOnChange = ({ labelText, className, InputComp, ...rest }) => {
  const [field, meta] = useField(rest);
  const { setFieldValue } = useFormikContext();

  return (
    <div className={`form-field ${meta.touched && meta.error ? 'error' : ''} ${className || ''}`}>
      <div className="label">{labelText}</div>
      <InputComp
        {...field}
        {...rest}
        value = { moment.isMoment(field.value) ? field.value : moment(field.value)}
        onChange={(value) => {
          setFieldValue(rest.name, value);
        }}
      />
      {meta.touched && meta.error && <span className="error-message">{meta.error}</span>}
    </div>
  );
};

export default FormField;
