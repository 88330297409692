import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import SubPageLayout from '../helpers/SubPageLayout';
import FormSection from '../helpers/FormSection';
import FormField from '../helpers/FormField';
import ReactQuill from 'react-quill';
import get from 'lodash.get';
import 'react-quill/dist/quill.snow.css';
import './styles/company-add.scss';
import { Uploader } from '../helpers/Components';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdminRoleIfNotInLocalStore } from '../../../features/enum.store';
import { AdminRolesDropdown } from '../helpers/drop-downs-for-enums';
import { addNewCompany, getAddNewCompanyLoading } from '../../../features/company.store';
import to from '../../../utils/to';
import { phoneRegEx } from '../../../utils/constant';
import { toast } from 'react-toastify';
import eng from '../../../utils/eng';

const CompanyAdd = () => {
  const uploaderRef = useRef();
  const dispatch = useDispatch();
  const addNewCompanyLoading = useSelector(getAddNewCompanyLoading);
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchAdminRoleIfNotInLocalStore());
  }, []);

  const handleSave = async (values) => {
    const { name, email, phone, address, description, adminFullName, adminEmail, adminTelephoneNumber, adminRole } =
      values;
    const formValue = {
      name,
      email,
      phone,
      address,
      description,
      admin: {
        fullName: adminFullName,
        email: adminEmail,
        telephoneNumber: adminTelephoneNumber,
        role: {
          id: 5,
        },
      },
    };
    const image = get(uploaderRef, ['current', 'fileList', 0, 'originFileObj'], null);

    const [err] = await to(dispatch(addNewCompany({ formValue, image })).unwrap());
    if (err) {
      return toast.error(err.err || eng.somethingWentWrong);
    }
    toast.success(eng.toast.addCompanySuccessfully);
    history.push(`/companies`);
  };

  return (
    <SubPageLayout title="Add Company" className="add-company-form" loading={addNewCompanyLoading}>
      <Formik
        initialValues={initialValue}
        onSubmit={handleSave}
        className="d-flex f-col"
        validateOnBlur
        validationSchema={YupValidation}
      >
        <Form>
          <FormSection title="Company Information" className="space-bottom">
            <div className="d-flex">
              <div className="flex space-right">
                <FormField labelText="Company Name" placeholder="Input here" name="name" />
                <FormField labelText="Company Address" placeholder="Input here" name="address" />
              </div>
              <div className="flex space-left">
                <FormField labelText="Company Phone Number" name="phone" placeholder="+64" />
                <FormField labelText="Email" placeholder="Input here" name="email" />
              </div>
            </div>
          </FormSection>

          <div className="space-top space-bottom d-flex">
            <FormSection title="Company Description" className="flex company-description-editor">
              <Field name="description">
                {({ field, meta }) => (
                  <>
                    <ReactQuill
                      value={field.value}
                      onChange={field.onChange(field.name)}
                      className={meta.touched && meta.error ? 'error' : ''}
                      placeholder="enter description"
                    />
                    <span className="error-msg">{meta.error}</span>
                  </>
                )}
              </Field>
            </FormSection>
            <FormSection className="space-left upload-container">
              <Uploader accept="image/*" beforeUpload={() => Promise.reject()} maxCount={1} ref={uploaderRef} />
            </FormSection>
          </div>

          <FormSection title="Admin Details" className="space-top">
            <div className="d-flex">
              <div className="flex space-right">
                <FormField name="adminFullName" labelText="Name" placeholder="Input here" />
                <FormField name="adminEmail" labelText="Email Address" placeholder="Input here" />
                <FormField name="adminTelephoneNumber" labelText="Telephone Number" placeholder="Input here" />
              </div>
              <div className="flex space-left">
                <FormField
                  name="adminRole"
                  labelText="Company Role"
                  placeholder="Input Here"
                  InputComp={AdminRolesDropdown}
                  disabled
                />
              </div>
            </div>
          </FormSection>
          <div className="d-flex space-top jc-end">
            {/*<Button className="space-right" onClick={() => console.log(uploaderRef)}>*/}
            {/*  Save to Draft*/}
            {/*</Button>*/}
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </div>
        </Form>
      </Formik>
    </SubPageLayout>
  );
};

const initialValue = {
  name: '',
  email: '',
  phone: '',
  address: '',
  description: '',
  adminFullName: '',
  adminEmail: '',
  adminTelephoneNumber: '',
  adminProfilePictureURL: '',
  adminRole: 'Tenant Admin Role',
};

const YupValidation = Yup.object({
  name: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  email: Yup.string().required('Required').email('Invalid email address'),
  phone: Yup.string().required('Required').matches(phoneRegEx, 'Invalid Phone Number'),
  description: Yup.string().required('Required').max(1000),
  adminFullName: Yup.string().required('Required'),
  adminEmail: Yup.string().required('Required').email('Invalid email address'),
  adminTelephoneNumber: Yup.string().required('Required').matches(phoneRegEx, 'Invalid Phone Number'),
  adminRole: Yup.string().required('Required'),
});

export default CompanyAdd;
