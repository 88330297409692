import React, { useRef } from "react";
import moment from "moment";
import { Drawer, Row, Col, Spin, Button, List } from "antd";
import { EditableInput } from "./components";
import { LabelCommon, Labels14f300, Labels14f450 } from "../components/labels";
import { CommonDropdown } from "../../../helpers/drop-downs-for-enums";
import { mockComments, mockHistory } from "../../../../../features/mockData/build";
import RoundedImage from "../components/roundedImage";
import { StatusLabel } from "./components";
import { FormOutlined, DeleteOutlined } from "@ant-design/icons";

const AdminDrawer = ({ title, isOpen, onClose }) => {

    const renderComment = (item) => {
        return (
            <Row className="d-flex space-bottom jc-center ai-center comment">
                <Col span={4}>
                    <RoundedImage name={item.name} size={30} />
                </Col>
                <Col span={13}>
                    <div>
                        <Labels14f450 >{item.comment}</Labels14f450>
                        <StatusLabel label={item.status} />
                    </div>
                </Col>
                <Col span={7} className="d-flex jc-space-between ai-center">
                    <Labels14f300>{moment(item.date).format('MM/DD/YYYY')}</Labels14f300>
                    <div className="d-flex jc-space-between ai-center">
                    <FormOutlined/>
                    <DeleteOutlined/>
                    </div>
                </Col>
            </Row>
        )
    }

    const renderHistory = (item) => {
        return (
            <Row className="d-flex space-bottom jc-center ai-center ">
                <Col span={4}>
                    <RoundedImage name={item.name} size={30} />
                </Col>
                <Col span={20}>
                    <div>
                        <Labels14f450 >{item.text}</Labels14f450>
                        <Labels14f300>{moment(item.date).format('MM/DD/YYYY')}</Labels14f300>
                    </div>
                </Col>
            </Row>
        )
    }

    return (
        <Drawer className="admin-drawer" title={title} visible={isOpen} onClose={onClose} >
            <Spin spinning={false}>
                <div className="header-comp">
                    <Row className="space-bottom-p">
                        <Col className="space-right-p-1" span={12}><EditableInput label="Deposit" left="deposit.svg" /></Col>
                        <Col className="space-left-p-1" span={12}><EditableInput label="INCO" left="inco.svg" /></Col>
                    </Row>
                    <Row className="space-bottom-p">
                        <Col className="space-right-p-1" span={12}><EditableInput label="Balance Payment" left="balance.svg" /></Col>
                        <Col className="space-left-p-1" span={12}><EditableInput label="Fabrication" left="fabrication.svg" /></Col>
                    </Row>
                    <Row className="space-bottom-p">
                        <Col className="space-right-p-1" span={12}><EditableInput label="Stickers Done" left="sticker.svg" /></Col>
                        <Col className="space-left-p-1" span={12}><EditableInput label="Paint" left="paint.svg" /></Col>
                    </Row>
                </div>
                <div className="">
                    <div className="comments-card">
                        <div className="d-flex header">
                            <LabelCommon fontSize={18} fontWeight={600}>Comments</LabelCommon>
                            <div className="d-flex ai-center">
                                <CommonDropdown options={[
                                    { label: 'All', value: 'all' },
                                    { label: 'Archived', value: 'archived' }
                                ]} size="small" showSearch={false} value="all" />
                                <Button>
                                    Add Comment
                                </Button>
                            </div>
                        </div>
                        <hr />
                        <List
                            dataSource={mockComments}
                            renderItem={renderComment}
                        />
                    </div>
                </div>
                <div className="">
                    <div className="status-card">
                        <div className="d-flex">
                            <LabelCommon fontSize={18} fontWeight={600}>History</LabelCommon>
                        </div>
                        <hr />
                        <List
                            dataSource={mockHistory}
                            renderItem={renderHistory}
                        />
                    </div>
                </div>

            </Spin>
        </Drawer>
    )
}

export default AdminDrawer