import React, { useCallback, useEffect, useState } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import FormSection from '../../helpers/FormSection';
import { NonFormikFormField } from '../../helpers/FormField';
import { Collapse, Radio } from 'antd';
import PriorityOptionSelector from './PriorityOptionSelector';
import { useDispatch } from 'react-redux';
import { updateAnswer } from '../../../../features/service-form.store';
import ImageUploadButton from './ImageUploadButton';

const { Panel } = Collapse;

const PassFailQuestion = ({ title, options, path, answers, subKey, gasType, subHeader }) => {
  const [passFail, setPassFail] = useState(null);
  const [reason, setReason] = useState('');
  const [note, setNote] = useState('');
  const [priority, setPriority] = useState('LOW');
  const [imageUrl, setImageUrl] = useState('');

  const setPass = useCallback(() => setPassFail(true), []);
  const setFail = useCallback(() => setPassFail(false), []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (passFail !== null) {
      dispatch(
        updateAnswer({
          isArray: true,
          path,
          subKey,
          gasType,
          value: {
            question: title,
            pass: passFail,
            selectedAnswer: reason,
            note,
            priority,
            imageUrl: imageUrl ? imageUrl : null,
          },
        }),
      );
    }
  }, [passFail, reason, note, priority, imageUrl]);

  useEffect(() => {
    let thisQuestion;
    if (answers && answers.questions && answers.questions[title]) {
      thisQuestion = answers.questions[title];
    } else if (subKey === 'calibrated' && answers) {
      if (answers['sensorReadings']) {
        const data = answers['sensorReadings'].filter(({ gasType: gt }) => gt === gasType)[0];
        thisQuestion = data && data[subKey];
      }
    }
    if (thisQuestion) {
      setPassFail(thisQuestion.pass);
      setReason(thisQuestion.selectedAnswer);
      setNote(thisQuestion.note);
      setPriority(thisQuestion.priority);
      setImageUrl(thisQuestion.imageUrl);
    }
  }, []);

  return (
    <Collapse
      ghost
      expandIcon={() => null}
      collapsible="header"
      className="question"
      activeKey={passFail !== null ? [title] : []}
    >
      <Panel
        header={
          <div>
            {title} <br />
            {subHeader && <div className="panel-sub">{subHeader}</div>}
          </div>
        }
        key={title}
        extra={
          <div className="header-icons">
            <CheckCircleOutlined className={`header-icon ${passFail === true ? 'active' : ''}`} onClick={setPass} />
            <CloseCircleOutlined className={`header-icon ${passFail === false ? 'active' : ''}`} onClick={setFail} />
            <ImageUploadButton imageUrl={imageUrl} setImageUrl={setImageUrl} />
          </div>
        }
      >
        <FormSection className="no-padding">
          {passFail === false && (
            <>
              <PriorityOptionSelector priority={priority} setPriority={setPriority} />
              <NonFormikFormField
                labelText="Reason"
                value={reason}
                InputComp={(props) => (
                  <Radio.Group {...props}>
                    {options.map((v) => (
                      <div className="radio-option" key={v}>
                        <Radio value={v}>{v}</Radio>
                      </div>
                    ))}
                  </Radio.Group>
                )}
                onChange={(e) => setReason(e.target.value)}
              />
            </>
          )}
          <NonFormikFormField labelText="Note" value={note} onChange={(e) => setNote(e.target.value)} />
        </FormSection>
      </Panel>
    </Collapse>
  );
};

export default PassFailQuestion;
