import to from './to';
import { DOWNLOAD } from '../service/base-api-service';
import { toast } from 'react-toastify';

export const downloadPdf = async (serviceFormId, isAdmin, tenantId, ASR = false) => {
  const [err, data] = await to(
    DOWNLOAD(`v1/${isAdmin ? 'admin/' : ''}report/${ASR ? 'afterS' : 's'}ervice/${serviceFormId}?tenantId=${tenantId}`),
  );
  if (err) {
    return toast.error('Unable to download');
  }

  const url = window.URL.createObjectURL(new Blob([data.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', serviceFormId + '.pdf'); //or any other extension
  document.body.appendChild(link);
  link.click();
};
