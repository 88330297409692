import React, { useRef, useState } from 'react';
import get from 'lodash.get';
import { Button, Modal } from 'antd';
import { Uploader } from './Components';
import FormSection from './FormSection';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getCompanyDetailsById, updateCompany } from '../../../features/company.store';
import to from '../../../utils/to';
import { Field, Form, Formik } from 'formik';
import FormField from './FormField';
import * as Yup from 'yup';
import { phoneRegEx } from '../../../utils/constant';
import ReactQuill from 'react-quill';

export const CompanyDetailImageEditors = ({ id }) => {
  const [visibility, setVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const uploaderRef = useRef();

  const dispatch = useDispatch();

  const handleOK = async () => {
    setLoading(true);
    const image = get(uploaderRef, ['current', 'fileList', 0, 'originFileObj'], null);
    if (image) {
      const [err] = await to(dispatch(updateCompany({ id, image, body: {} })).unwrap());
      if (err) {
        toast.error('Could not update. Please try again!');
      } else {
        toast.success('Successfully updated!');
        setVisibility(false);
        dispatch(getCompanyDetailsById({ id }));
      }
    } else {
      toast.error('Select an image to update!');
    }
    setLoading(false);
  };

  const closeModal = () => {
    setVisibility(false);
  };

  return (
    <>
      <Button type="link" onClick={() => setVisibility(true)}>
        Edit
      </Button>
      <Modal
        title="Update Image"
        visible={visibility}
        onOk={handleOK}
        onCancel={closeModal}
        width={800}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <FormSection className="space-left upload-container" style={{ marginBottom: 20 }}>
          <Uploader accept="image/*" beforeUpload={() => Promise.reject()} maxCount={1} ref={uploaderRef} />
        </FormSection>
      </Modal>
    </>
  );
};

export const CompanyDetailInfoEditors = ({ company }) => {
  const [visibility, setVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const dispatch = useDispatch();

  const handleSave = async (values) => {
    setLoading(true);
    const [err] = await to(dispatch(updateCompany({ id: company.identifier, body: values })).unwrap());
    if (err) {
      toast.error('Could not update. Please try again!');
    } else {
      toast.success('Successfully updated!');
      setVisibility(false);
      dispatch(getCompanyDetailsById({ id: company.identifier }));
    }
    setLoading(false);
  };

  const closeModal = () => {
    setVisibility(false);
  };

  return (
    <>
      <Button type="link" onClick={() => setVisibility(true)}>
        Edit
      </Button>
      <Modal
        title="Edit Information"
        visible={visibility}
        onOk={() => formRef.current.submitForm()}
        onCancel={closeModal}
        width={800}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <Formik
          initialValues={{
            name: company.name,
            email: company.email,
            phone: company.phone,
            address: company.address,
          }}
          onSubmit={handleSave}
          className="d-flex f-col"
          validateOnBlur
          validationSchema={YupValidationInfo}
          innerRef={formRef}
        >
          <Form>
            <FormSection title="Company Information" className="space-bottom">
              <div className="d-flex">
                <div className="flex space-right">
                  <FormField labelText="Company Name" placeholder="Input here" name="name" />
                  <FormField labelText="Company Address" placeholder="Input here" name="address" />
                </div>
                <div className="flex space-left">
                  <FormField labelText="Company Phone Number" name="phone" placeholder="+64" />
                  <FormField labelText="Email" placeholder="Input here" name="email" disabled />
                </div>
              </div>
            </FormSection>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export const CompanyDetailDescriptionEditors = ({ company }) => {
  const [visibility, setVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const dispatch = useDispatch();

  const handleSave = async (values) => {
    setLoading(true);
    const [err] = await to(dispatch(updateCompany({ id: company.identifier, body: values })).unwrap());
    if (err) {
      toast.error('Could not update. Please try again!');
    } else {
      toast.success('Successfully updated!');
      setVisibility(false);
      dispatch(getCompanyDetailsById({ id: company.identifier }));
    }
    setLoading(false);
  };

  const closeModal = () => {
    setVisibility(false);
  };

  return (
    <>
      <Button type="link" onClick={() => setVisibility(true)}>
        Edit
      </Button>
      <Modal
        title="Edit Description"
        visible={visibility}
        onOk={() => formRef.current.submitForm()}
        onCancel={closeModal}
        width={800}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <Formik
          initialValues={{
            description: company.description,
          }}
          onSubmit={handleSave}
          className="d-flex f-col"
          validateOnBlur
          validationSchema={YupValidationDescription}
          innerRef={formRef}
        >
          <Form>
            <FormSection title="Company Description" className="flex company-description-editor">
              <Field name="description">
                {({ field, meta }) => (
                  <>
                    <ReactQuill
                      value={field.value}
                      onChange={field.onChange(field.name)}
                      className={meta.touched && meta.error ? 'error' : ''}
                      placeholder="enter description"
                    />
                    <span className="error-msg">{meta.error}</span>
                  </>
                )}
              </Field>
            </FormSection>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

const YupValidationInfo = Yup.object({
  name: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  email: Yup.string().required('Required').email('Invalid email address'),
  phone: Yup.string().required('Required').matches(phoneRegEx, 'Invalid Phone Number'),
});

const YupValidationDescription = Yup.object({
  description: Yup.string().required('Required').max(1000),
});
