import React, { useEffect, useState } from 'react';
import TextFieldQuestion from './TextFieldQuestion';
import { Button, Checkbox } from 'antd';
import { updateAnswer } from '../../../../features/service-form.store';
import ImageUploadButton from './ImageUploadButton';
import { useDispatch } from 'react-redux';

const NoteSection = ({ answers }) => {
  const dispatch = useDispatch();

  return (
    <>
      {answers.notes.map((value, index) => (
        <TextFieldQuestion
          key={index}
          title={`Note ${index + 1}`}
          answers={value.description}
          answerKey={index}
          path="notes"
          extra={
            <div className="d-flex jc-space-between">
              <Checkbox
                checked={value.addToASR}
                onChange={(e) => {
                  dispatch(
                    updateAnswer({
                      isArray: false,
                      path: ['notes', index, 'addToASR'],
                      value: e.target.checked,
                    }),
                  );
                }}
              >
                Add After Service Report
              </Checkbox>
              <ImageUploadButton
                imageUrl={value.imageUrl}
                additionalClass="note"
                setImageUrl={(url) => {
                  dispatch(
                    updateAnswer({
                      isArray: false,
                      path: ['notes', index, 'imageUrl'],
                      value: url,
                    }),
                  );
                }}
              />
            </div>
          }
        />
      ))}
      <div className="d-flex jc-center">
        <Button
          onClick={() => {
            dispatch(
              updateAnswer({
                isArray: false,
                path: ['notes', answers.notes.length, 'description'],
                value: '',
              }),
            );
          }}
        >
          Add New Note
        </Button>
      </div>
    </>
  );
};

export default NoteSection;
