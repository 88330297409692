import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { GET, POST, PUT } from '../service/base-api-service';
import { DEFAULT_PAGE_SIZE } from '../utils/constant';
import { getUserTableInfo } from './user.store';
import { getIsAdminApi } from './auth.store';
import moment from 'moment';

const initialState = {
  chambersList: [],
  isLoading: false,
  tableInfo: {
    company: '',
    site: null,
    totalPages: 0,
    currentPage: -1,
    sortColumn: 'id',
    isAscending: true,
  },
  chamberMoveInfo: {
    fromCompany: {
      identifier: '',
      tenantName: ''
    },
    fromSite: {
      siteId: '',
      siteName: ''
    },
    toCompany: '',
    toSite: '',
    selectedChamber: {
      chamberId: '',
      chamberName: '',
    }
  },
  editingChamber: null,
  isLoadingError: false,
  addNewChamberLoading: false,
};

export const getChamberById = createAsyncThunk('chamber-edit/byId', async ({ id }, { getState }) => {
  return GET(`v1/${getIsAdminApi(getState()) ? 'admin/' : ''}chamber/${id}`);
});

export const getPaginatedChamberList = createAsyncThunk(
  'chambersList/get',
  async ({ page, sortColumn = 'id', isAscending = false, site }, { dispatch, getState }) => {
    dispatch(setTableInfo({ currentPage: page, sortColumn, isAscending }));
    const tableInfo = getChamberTableInfo(getState());

    let url = `v1/site/chambers/${site || tableInfo.site}`;
    if (getIsAdminApi(getState())) url = `v1/admin/site/chambers/${site || tableInfo.site}`;

    return GET(url, {
      params: { page, sortColumn, isAscending, size: DEFAULT_PAGE_SIZE },
    });
  },
);

export const moveChamber = createAsyncThunk(
  'chambers/move',
  async (_, { dispatch, getState }) => {
    
    const {
      toCompany,
      toSite,
      selectedChamber: {
        chamberId
      }
    } = getChamberMoveInfo(getState());
    return POST(`v1/admin/chamber/${chamberId}/${toSite}`);
  },
);

export const enableDisabledChamber = createAsyncThunk('chamber/enableDisable', async ({ value, id }) => {
  return POST(`v1/admin/chamber/${id}/${value ? 'enable' : 'disable'}`);
});

export const addNewChamber = createAsyncThunk('chamber/createNew', async ({ values }) => {
  return POST(`v1/admin/chamber/`, values);
});

export const updateChamber = createAsyncThunk('chamber/update', async ({ values, id }) => {
  return PUT(`v1/admin/chamber/${id}`, values);
});

export const ChambersActions = {
  // setPage: (page) => (dispatch, getState) => {
  //   const tableInfo = getUserTableInfo(getState());
  //   if (tableInfo.currentPage !== page) dispatch(fetchMinimalSiteList({ page }));
  // },
  setSelectedCompany: (company) => (dispatch, getState) => {
    const previousCompany = getUserTableInfo(getState()).company;
    if (company !== previousCompany) {
      dispatch(setTableInfo({ site: null, company, resetResults: true }));
    }
  },
  setSelectedSite: (site) => (dispatch, getState) => {
    const previousSite = getUserTableInfo(getState()).site;
    if (site !== previousSite) {
      dispatch(setTableInfo({ site, resetResults: true }));
      site && dispatch(getPaginatedChamberList({ site }));
    }
  },
  setMoveModalSelectedCompany: (company) => (dispatch, getState) => {
    const previousCompany = getChamberMoveInfo(getState()).toCompany;
    if (company !== previousCompany) {
      dispatch(setMoveFromCompany(company));
    }
  },
};

export const chamberSlice = createSlice({
  name: 'chamber',
  initialState,
  reducers: {
    setTableInfo: (state, { payload }) => {
      const { resetResults, ...rest } = payload;
      state.tableInfo = { ...state.tableInfo, ...rest };
      if (resetResults) {
        state.chambersList = [];
      }
    },
    resetChamberStore: () => initialState,
    clearChamberEditing: (state) => {
      state.editingChamber = null;
    },
    setMoveToCompany: (state, { payload }) => {
      state.chamberMoveInfo.toCompany = payload
    },
    setMoveFromCompany: (state, { payload }) => {
      state.chamberMoveInfo.fromCompany = payload
    },
    setMoveToSite: (state, { payload }) => {
      state.chamberMoveInfo.toSite = payload
    },
    setMoveFromSite: (state, { payload }) => {
      state.chamberMoveInfo.fromSite = payload
    },
    setMoveSelectedChamber: (state, { payload }) => {
      state.chamberMoveInfo.selectedChamber = payload
    },
    claerMoveInfo: (state) => {
      state.chamberMoveInfo = {
        fromCompany: {
          identifier: '',
          tenantName: '',
        },
        fromSite: {
          siteId: '',
          siteName: '',
        },
        toCompany: '',
        toSite: '',
        selectedChamber: {
          chamberId: '',
          chamberName: '',
        }
      }
    },
  },
  extraReducers: {
    [getPaginatedChamberList.pending]: (state) => {
      state.isLoading = true;
    },
    [getPaginatedChamberList.rejected]: (state) => {
      state.isLoading = false;
      state.isLoadingError = true;
    },
    [getPaginatedChamberList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.chambersList = payload.results;
      state.tableInfo.totalPages = payload.totalPages;
    },
    [enableDisabledChamber.pending]: (state) => {
      state.isLoading = true;
    },
    [enableDisabledChamber.rejected]: (state) => {
      state.isLoading = false;
    },
    [enableDisabledChamber.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.chambersList = state.chambersList.map((chamber) => (chamber.id === payload.id ? payload : chamber));
    },
    [addNewChamber.pending]: (state) => {
      state.addNewChamberLoading = true;
    },
    [addNewChamber.rejected]: (state) => {
      state.addNewChamberLoading = false;
    },
    [addNewChamber.fulfilled]: (state) => {
      state.addNewChamberLoading = false;
    },
    [getChamberById.pending]: (state) => {
      state.isLoading = true;
    },
    [getChamberById.rejected]: (state) => {
      state.isLoading = false;
    },
    [getChamberById.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.editingChamber = payload;
    },
    [moveChamber.pending]: (state) => {
      state.addNewChamberLoading = true;
    },
    [moveChamber.rejected]: (state) => {
      state.addNewChamberLoading = false;
    },
    [moveChamber.fulfilled]: (state) => {
      state.addNewChamberLoading = false;
      state.chamberMoveInfo = {
        fromCompany: {
          identifier: '',
          tenantName: '',
        },
        fromSite: {
          siteId: '',
          siteName: '',
        },
        toCompany: '',
        toSite: '',
        selectedChamber: {
          chamberId: '',
          chamberName: '',
        }
      }
    },
  },
});

export const { setTableInfo, resetChamberStore, clearChamberEditing, setMoveFromCompany, setMoveFromSite, setMoveToCompany, setMoveToSite, claerMoveInfo, setMoveSelectedChamber } = chamberSlice.actions;

const getChamberStore = (state) => state.chamber;

export const getChamberList = createSelector(getChamberStore, (state) => state.chambersList);
export const getChamberListLoading = createSelector(getChamberStore, (state) => state.isLoading);
export const getChamberLoading = createSelector(getChamberStore, (state) => state.isLoading);
export const getChamberTableInfo = createSelector(getChamberStore, (state) => state.tableInfo);
export const getChamberMoveInfo = createSelector(getChamberStore, (state) => state.chamberMoveInfo);
export const addNewChamberLoading = createSelector(getChamberStore, (state) => state.addNewChamberLoading);
export const getEditingChamber = createSelector(getChamberStore, (state) => {
  const chamber = state.editingChamber;
  if (chamber) {
    return {
      ...chamber,
      tenantIdentifier: chamber.tenant.identifier,
      siteId: chamber.site.id,
      buildLocation: chamber.buildLocationEnum.name,
      chamberType: chamber.chamberTypeEnum.name,
      scrubberType: chamber.scrubberTypeEnum.name,
      chemicalType: chamber.chemicalTypeEnum.name,
      airFilterType: chamber.airFilterTypeEnum.name,
      gasSensorTypes: chamber.gasSensorTypeEnums.map(({ name }) => name),
      gasMonitorType: chamber.gasMonitorTypeEnum.name,
      interiorLightingType: chamber.interiorLightingTypeEnum.name,
      optionsTypes: chamber.optionsTypeEnums.map(({ name }) => name),
      expectedDelivery: moment(chamber.expectedDelivery),
    };
  }
  return null;
});

export default chamberSlice.reducer;
