import React from "react";

const getBGColor = (p) => {
    if(p < 'F') return 1;
    else if(p < 'K') return 2;
    else if(p < 'P') return 3;
    else if(p < 'V') return 4;
    else return 5;
}

const RoundedImage = ({src, name, size=40}) => {

    if(!src){
        return(
            <div className={`rounded_image rc-${getBGColor(name[0].toUpperCase())}`} style={{width: size+5, height: size+5, fontSize: size/2}}>
                {`${name[0]}${name.length>1 ? name[1]: ''}`.toUpperCase()}
            </div>
        )
    }
    return(
        <img width={size} src={src} className="rounded_image"/>
    )
}

export default RoundedImage;