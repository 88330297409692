import { useSelector } from 'react-redux';
import { isFeatureEnabled } from '../features/auth.store';

export const ROLE = {
  SA: 'ROLE_SUPER_ADMIN',
  TA: 'ROLE_TENANT_ADMIN',
  SU: 'ROLE_SITE_USER',
  ST: 'ROLE_SITE_SERVICE_TECHNICIAN',
  MAST: 'ROLE_MINE_ARC_SERVICE_TECHNICIAN',
  MAV: 'ROLE_MINE_ARC_USER',
  SC: 'ROLE_MINE_ARC_SERVICE_COORDINATOR',
  TC: 'ROLE_TRAINING_COORDINATOR',
  BDM: 'ROLE_BUSINESS_DEVELOPMENT_MANAGER',
  SP: 'ROLE_SERVICE_PROVIDER'
};

const FEATURES = {
  view_company: [ROLE.SA],
  add_company: [ROLE.SA],
  add_organizations: [ROLE.SA],
  add_users_to_organization: [],
  add_sites: [ROLE.SA],
  add_users_to_sites: [ROLE.SA],
  add_chambers: [ROLE.SA],
  add_chambers_to_sites: [ROLE.SA],
  view_service_report: [ROLE.SA, ROLE.TA, ROLE.SU, ROLE.ST, ROLE.MAST, ROLE.MAV, ROLE.SC, ROLE.SP], // allow all
  view_after_service_report: [ROLE.SA, ROLE.SU, ROLE.ST, ROLE.MAST, ROLE.MAV, ROLE.SC],
  add_documents: [ROLE.SA],
  view_documents: [ROLE.SA, ROLE.TA, ROLE.SU, ROLE.ST, ROLE.MAST, ROLE.MAV, ROLE.SC, ROLE.SP], // allow all
  service_a_chamber: [ROLE.ST, ROLE.MAST],
  audit_service_a_chamber: [ROLE.MAST],
  commission_service_a_chamber: [ROLE.MAST],

  edit_service_form: [ROLE.ST, ROLE.MAST, ROLE.SC],
  view_chambers_of_a_site: [ROLE.SA, ROLE.TA, ROLE.SU, ROLE.ST, ROLE.MAST, ROLE.MAV, ROLE.SC, ROLE.SP],
  view_sites_of_the_organization: [ROLE.SA, ROLE.TA, ROLE.ST, ROLE.MAST, ROLE.MAV, ROLE.SC, ROLE.SP],
  approve_the_service_form: [ROLE.MAST],

  view_users: [ROLE.SA, ROLE.TA, ROLE.MAV, ROLE.SP],
  view_schedule: [ROLE.TC, ROLE.SC, ROLE.BDM, ROLE.SP],
  view_build: [ROLE.BDM]
};

export const fromFeatureMatrix = (user, key) => {
  if (FEATURES[key] && FEATURES[key].length > 0) {
    return FEATURES[key].indexOf(user) >= 0;
  }
  return true;
};

export const useFeature = (key) => useSelector(isFeatureEnabled)(key);
