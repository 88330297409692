import React, { useEffect } from 'react';
import SubPageLayout from '../helpers/SubPageLayout';
import { useParams } from 'react-router-dom';
import { DetailsCard, DetailSection, StaticDetails } from '../helpers/Components';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompanyDetailsById,
  getCompanyList,
  getCompanyLoading,
  getSelectedCompany,
  setSelectedCompany,
} from '../../../features/company.store';
import LoadingScreen from '../../LoadingScreen';
import { resizeCloudinaryImages } from '../../../utils/utils';
import {
  CompanyDetailDescriptionEditors,
  CompanyDetailImageEditors,
  CompanyDetailInfoEditors,
} from '../helpers/company-detail-editors';

const CompanyDetails = () => {
  const companyDetails = useSelector(getSelectedCompany);
  const companyList = useSelector(getCompanyList);
  const dispatch = useDispatch();
  const params = useParams();

  const isLoading = useSelector(getCompanyLoading);

  useEffect(() => {
    const companyDetails = companyList.filter((company) => company.identifier === params.id)[0];
    if (companyDetails) {
      dispatch(setSelectedCompany(companyDetails));
    } else {
      dispatch(getCompanyDetailsById({ id: params.id }));
    }
  }, []);

  if (isLoading || !companyDetails.admin) {
    return <LoadingScreen />;
  }

  return (
    <SubPageLayout title={`${companyDetails.name} Profile`}>
      <div className="d-flex space-bottom">
        <DetailSection
          title="Image"
          extra={<CompanyDetailImageEditors id={companyDetails.identifier} />}
          className="flex space-right"
        >
          <img
            src={resizeCloudinaryImages(companyDetails.imageUrl, 250, 250)}
            alt="profile"
            className="rounded-border w-100"
          />
        </DetailSection>
        <DetailSection
          title="Company Information"
          extra={<CompanyDetailInfoEditors company={companyDetails} />}
          className="flex space-right space-left"
        >
          <StaticDetails title="Company Name" value={companyDetails.name} />
          <StaticDetails title="Email Address" value={companyDetails.email} />
          <StaticDetails title="Phone" value={companyDetails.phone} />
          <StaticDetails title="Address" value={companyDetails.address} />
        </DetailSection>
        <DetailSection
          title="Company Description"
          extra={<CompanyDetailDescriptionEditors company={companyDetails} />}
          className="flex space-left"
        >
          <StaticDetails
            title="Description"
            valueProps={{ dangerouslySetInnerHTML: { __html: companyDetails.description } }}
          />
        </DetailSection>
      </div>
      <div className="d-flex space-top-p">
        <DetailSection className="flex space-right" title="Admin Details">
          <div className="d-flex f-row">
            <div className="flex">
              <StaticDetails title="Name" value={companyDetails.admin.fullName} />
              <StaticDetails title="Email Address" value={companyDetails.admin.email} />
              <StaticDetails title="Phone" value={companyDetails.admin.telephoneNumber} />
            </div>
            <div className="flex">
              <StaticDetails title="Company Role" value={companyDetails.admin.role.displayName} />
            </div>
          </div>
        </DetailSection>
        <DetailSection className="flex space-left" title="User Details">
          <div className="d-flex f-row mulish">
            {companyDetails.user ? (
              <>
                <div className="flex">
                  <DetailsCard name="Lydia Fitzgerald" id="125448758" as="div" className="space-bottom-p" />
                  <DetailsCard name="Nell Cook" id="125448758" as="div" className="space-bottom-p" />
                  <DetailsCard name="Lydia Fitzgerald" id="125448758" as="div" className="space-bottom-p" />
                </div>
                <div className="flex">
                  <DetailsCard name="Lydia Fitzgerald" id="125448758" as="div" className="space-bottom-p" />
                  <DetailsCard name="Nell Cook" id="125448758" as="div" className="space-bottom-p" />
                  <DetailsCard name="Lydia Fitzgerald" id="125448758" as="div" className="space-bottom-p" />
                </div>
              </>
            ) : (
              <h4>No User Found</h4>
            )}
          </div>
        </DetailSection>
      </div>
    </SubPageLayout>
  );
};

export default CompanyDetails;
