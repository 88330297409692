import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { GET, POST } from '../service/base-api-service';
import { toQuestionArray, toQuestionHashmap } from '../utils/utils';
import to from '../utils/to';
import { getIsAdminApi, getUserRole } from './auth.store';
import get from 'lodash.get';
import { questionMeta, questionOptions } from '../utils/serviceFormOptions';
import { ROLE } from '../utils/features';

const initialState = {
  answers: {
    refugeChamberExternal: {
      visualChecks: null,
      mainDoor: null,
      window: null,
      externalFireExtinguisher: null,
      strobeLightsSirenAndAntenna: null,
      externalSignage: null,
      checkValves: null,
      structure: null,
      compressedAirFilterSystem: null,
      airConditionerOutDoorUnit: null,
      transformer: null,
      communicationsBox: null,
    },
    refugeChamberInternal: {
      internalLightingAndMotionSensor: null,
      internalSignage: null,
      manuals: null,
      internalCompressedAirSystem: null,
      internalFireBlanketOrExtinguisher: null,
      statsAndFlooring: null,
      toilet: null,
      communicationSystems: null,
      airConditionerIndoorUnit: null,
      scrubberSystem: null,
      escapeHatch: null,
      vaccumTest: null,
    },
    consumables: {
      freePourScrubberChemicals: null,
      cartridgeScrubberChemicals: null,
      auraFXGasMonitoring: null,
      digitalGasMonitor: null,
      manualGasSamplingPump: null,
      oxygenRegulators: null,
      medicalOxygenCylinders: null,
      oxygenCandle: null,
      firstAidWaterAndLongLifeFood: null,
      batteryVoltages: null,
      batterySystem: null,
    },
    chamberOptions: {
      ppms: null,
      airlockSystem: null,
      guardIAN: null,
      additionalLanguages: null,
      aods: null,
      wheels: null,
      towBar: null,
      blastResistance: null,
      fireResistance: null,
      thermalInsulation: null,
      maceyPlug: null,
      ppfs: null,
    },
    notes: [],
    isDraftReview: true,
    chamberLocation: '',
  },
  selectedChamber: {},
  isChamberDetailsLoading: false,
  isServiceFormLoading: false,
  isServiceFormSaving: false,
  serviceFormLoadingError: {
    hasError: false,
    info: {},
  },
  formUpdated: false,
};

export const getChamberDetailsById = createAsyncThunk('chamber/byId', async ({ id }, { getState }) => {
  return GET(`v1/${getIsAdminApi(getState()) ? 'admin/' : ''}chamber/${id}`);
});

export const getServiceFormForChamber = createAsyncThunk(
  'chamber/serviceForm',
  async ({ id, type }, { rejectWithValue, getState }) => {
    const [err, result] = await to(
      GET(`v1/${getIsAdminApi(getState()) ? 'admin/' : ''}chamber/${id}/serviceForm?type=${type}`),
    );
    if (err) {
      return rejectWithValue(err);
    }
    return { result, id };
  },
);

export const saveServiceForm = createAsyncThunk('chamber/serviceForm/Save', async ({ type }, { getState }) => {
  const answers = getSFAnswers(getState());
  const body = toQuestionArray(answers);
  return POST(`v1/${getIsAdminApi(getState()) ? 'admin/' : ''}chamber/service`, {
    ...body,
    isDraftService: true,
    serviceFormType: type,
  });
});

export const submitServiceForm = createAsyncThunk('chamber/serviceForm/submit', (_, { getState }) => {
  const answers = getSFAnswers(getState());
  const body = toQuestionArray(answers);

  const rest = { isDraftService: false };
  if (getUserRole(getState()) === ROLE.MAST) {
    rest.isDraftReview = false;
  }
  return POST(`v1/${getIsAdminApi(getState()) ? 'admin/' : ''}chamber/service`, { ...body, ...rest });
});

export const approveServiceForm = createAsyncThunk('chamber/serviceForm/submit', (_, { getState }) => {
  const answers = getSFAnswers(getState());
  const body = toQuestionArray(answers);
  return POST(`v1/${getIsAdminApi(getState()) ? 'admin/' : ''}chamber/service`, {
    ...body,
    isDraftService: false,
    isDraftReview: false,
    servicedDate: answers.servicedDate || new Date().getTime()
  });
});

const serviceFormSlice = createSlice({
  name: 'serviceForm',
  initialState,
  reducers: {
    updateAnswer: (store, { payload }) => {
      const { path, value, isArray, gasType, subKey } = payload;
      const [l1, l2, answerKey, subAnswerKey] = path;
      if (isArray) {
        if (l2 === 'auraFXGasMonitoring' && subKey === 'calibrated') {
          let { sensorReadings } = store.answers[l1][l2] || {};
          const index = (sensorReadings || []).findIndex((reading) => reading.gasType === gasType);
          if (index < 0) {
            if (sensorReadings) {
              store.answers[l1][l2].sensorReadings.push({ gasType, calibrated: value });
            } else {
              if (store.answers[l1][l2]) {
                store.answers[l1][l2].sensorReadings = [{ gasType, calibrated: value }];
              } else {
                store.answers[l1][l2] = { sensorReadings: [{ gasType, calibrated: value }] };
              }
            }
          } else {
            store.answers[l1][l2].sensorReadings[index].calibrated = value;
          }
        } else {
          if (store.answers[l1][l2]) {
            if (store.answers[l1][l2].questions) {
              store.answers[l1][l2].questions[value.question] = value;
            } else {
              store.answers[l1][l2].questions = { [value.question]: value };
            }
          } else {
            store.answers[l1][l2] = { questions: { [value.question]: value } };
          }
        }
      } else {
        if (l2 === 'auraFXGasMonitoring' && answerKey === 'sensorReadings') {
          let { sensorReadings } = store.answers[l1][l2] || {};
          const index = (sensorReadings || []).findIndex((reading) => reading.gasType === gasType);
          if (index < 0) {
            if (sensorReadings) {
              store.answers[l1][l2].sensorReadings.push({ gasType, [subAnswerKey]: value });
            } else {
              if (store.answers[l1][l2]) {
                store.answers[l1][l2].sensorReadings = [{ gasType, [subAnswerKey]: value }];
              } else {
                store.answers[l1][l2] = { sensorReadings: [{ gasType, [subAnswerKey]: value }] };
              }
            }
          } else {
            store.answers[l1][l2].sensorReadings[index][subAnswerKey] = value;
          }
        } else {
          if (store.answers[l1][l2]) {
            store.answers[l1][l2][answerKey] = value;
          } else {
            store.answers[l1][l2] = { [answerKey]: value };
          }
        }
      }
      store.formUpdated = true;
    },
    updateLocation: (store, { payload }) => {
      store.answers.chamberLocation = payload;
      store.formUpdated = true;
    },
    updateServiceDate: (store, { payload }) => {
      store.answers.servicedDate = payload;
      store.formUpdated = true;
    },
  },
  extraReducers: {
    [getChamberDetailsById.pending]: (state) => {
      state.isChamberDetailsLoading = true;
    },
    [getChamberDetailsById.rejected]: (state) => {
      state.isChamberDetailsLoading = false;
    },
    [getChamberDetailsById.fulfilled]: (state, { payload }) => {
      state.isChamberDetailsLoading = false;
      state.selectedChamber = payload;
    },
    [getServiceFormForChamber.pending]: (state) => {
      state.isServiceFormLoading = true;
      state.serviceFormLoadingError = initialState.serviceFormLoadingError;
      state.answers = initialState.answers;
    },
    [getServiceFormForChamber.rejected]: (state, { payload }) => {
      state.isServiceFormLoading = false;
      state.serviceFormLoadingError = {
        hasError: true,
        info: payload.error,
      };
      if (!payload?.error?.message) {
        state.serviceFormLoadingError.info = { details: ['Something went wrong'], message: 'Could not load' };
      }
    },
    [getServiceFormForChamber.fulfilled]: (state, { payload }) => {
      state.isServiceFormLoading = false;
      if (payload && payload.result && payload.result.serviceFormId) {
        state.answers = toQuestionHashmap(payload.result);
      } else {
        // state.answers = initialState.answers;
        state.answers.chamberId = payload.id;
        state.answers.isDraftReview = true;
      }
      state.formUpdated = false;
    },
    [saveServiceForm.pending]: (state) => {
      state.isServiceFormLoading = true;
    },
    [saveServiceForm.rejected]: (state) => {
      state.isServiceFormLoading = false;
    },
    [saveServiceForm.fulfilled]: (state, { payload }) => {
      state.isServiceFormLoading = false;
      state.answers.isDraftService = true;
      state.answers.serviceFormId = payload.serviceFormId;
      state.formUpdated = false;
    },
  },
});

export const { updateAnswer, updateLocation, updateServiceDate } = serviceFormSlice.actions;

const getStore = (store) => store.serviceForm;

export const getSFAnswers = createSelector(getStore, (store) => store.answers);
export const getSFChamber = createSelector(getStore, (store) => store.selectedChamber);

export const getChamberDetailsLoading = createSelector(getStore, (store) => store.isChamberDetailsLoading);
export const getServiceFormLoading = createSelector(getStore, (store) => store.isServiceFormLoading);
export const getServiceFormSaving = createSelector(getStore, (store) => store.isServiceFormSaving);
export const getServiceFormLoadingError = createSelector(getStore, (store) => store.serviceFormLoadingError);

export const getFormUpdated = createSelector(getStore, (store) => store.formUpdated);

export const enableSubmitButton = createSelector(
  getSFAnswers,
  getSFChamber,
  getUserRole,
  (answers, chamber, userType) => {
    return !['refugeChamberExternal', 'refugeChamberInternal', 'consumables', 'chamberOptions'].some((key1) => {
      const v = Object.keys(questionMeta[key1])
        .filter((key2) => {
          if (key2 === '_title') {
            return false;
          }
          const conditions = get(questionMeta, [key1, key2, 'conditions']);
          if (conditions) {
            return conditions(chamber, userType);
          }
          return true;
        })
        .some((key2) => {
          const keys = [key1, key2];
          const options = get(answers, keys) || {};
          const x = Object.values(get(options, 'questions') || {});
          const answer = x.length + Object.values(options).length - 1;
          const allOptions = get(questionOptions, keys).filter(({ conditions }) => {
            return !(conditions && !conditions(chamber, userType));
          }).length;
          const a = answer !== allOptions;
          // if (a) console.log(key1, key2);
          return a;
        });
      return v;
    });
  },
);

export const enableSubmitButton2 = createSelector(getSFAnswers, getSFChamber, (answers, chamber) => {
  return !['refugeChamberExternal', 'refugeChamberInternal', 'consumables', 'chamberOptions'].some((key1) => {
    const x = Object.keys(questionMeta[key1])
      .filter((key2) => {
        if (key2 === '_title') {
          return false;
        }
        const conditions = get(questionMeta, [key1, key2, 'conditions']);
        if (conditions) {
          return conditions(chamber);
        }
        return true;
      })
      .some((key2) => {
        const keys = [key1, key2];

        const options = get(answers, keys) || {};
        const x = Object.values(get(options, 'questions') || {});
        let isAllPassed = !x.some((a) => a.pass === false);

        let staticAnswersCount = Object.values(options).filter((val) => !!val).length;
        if (options.questions) {
          staticAnswersCount--;
        }

        const allOptions = get(questionOptions, keys).filter(({ conditions }) => {
          return !(conditions && !conditions(chamber));
        }).length;

        const res = { percent: ((x.length + staticAnswersCount) / allOptions) * 100 };

        if (keys[1] === 'auraFXGasMonitoring') {
          staticAnswersCount = 0;
          isAllPassed = true;
          if (options.masterBoardSoftwareVersion) {
            staticAnswersCount += 1;
          }
          if (options.displayBoardSoftwareVersion) {
            staticAnswersCount += 1;
          }
          const c =
            options?.sensorReadings?.reduce((p, c) => {
              isAllPassed = isAllPassed && c.calibrated?.pass !== false;
              return p + Object.values(c).filter((v) => !!v).length + 1;
            }, 0) || 0;
          const sac = staticAnswersCount + c;
          res.percent = (sac / allOptions) * 100;
        } else if (keys[1] === 'batteryVoltages') {
          let questionAnswer;
          if (options.questions) {
            questionAnswer = get(Object.values(options.questions), [0, 'pass']);
          }
          const p = questionAnswer === true || questionAnswer === false ? 50 : 0;
          const failed = questionAnswer === false;

          const batteryCount = options.recordBatteryVoltages || [];
          const hasAnyValue = batteryCount.some((data) => !!data);
          isAllPassed = !batteryCount.some((v) => !isNaN(v) && parseFloat(v) < 12.5) && !failed;
          res.percent = p + (hasAnyValue ? 50 : 0);
        }

        res.showInfo = x.length + staticAnswersCount === allOptions;
        if (keys[1] === 'auraFXGasMonitoring' || keys[1] === 'batteryVoltages') {
          res.showInfo = res.percent >= 100;
        }
        return res.percent < 100;
      });
    return x;
  });
});

export default serviceFormSlice.reducer;
