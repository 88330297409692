import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { GET, POST, POST_IMAGE, PUT } from '../service/base-api-service';
import to from '../utils/to';
import { DEFAULT_PAGE_SIZE } from '../utils/constant';
import { getIsAdminApi, getUser } from './auth.store';
import { ROLE } from '../utils/features';

const initialState = {
  siteList: [],
  tableInfo: {
    company: '',
    totalPages: 0,
    currentPage: -1,
    sortColumn: 'id',
    isAscending: true,
    searchKey: ''
  },
  minimalSiteList: {
    list: [],
    isLoading: false,
    companyId: '',
  },
  isLoading: false,
  addNewSiteLoading: false,
  editingSite: null,
  isLoadingError: false,
  selectedSiteId: '',
  availableUsers: [],
  availableUsersLoading: false,
};

export const getPaginatedSiteList = createAsyncThunk(
  'siteList/get',
  async ({ page, sortColumn = 'id', isAscending, company, searchKey }, { dispatch, getState }) => {
    dispatch(setTableInfo({ currentPage: page, sortColumn, isAscending, company, searchKey }));

    let url = 'v1/site/all';
    if (getIsAdminApi(getState())) url = 'v1/admin/site';
    if (getUser(getState()).scope === ROLE.SU) url = 'v1/site';

    return GET(url, {
      params: { page, sortColumn, isAscending, size: DEFAULT_PAGE_SIZE, tenantId: company, searchKey },
    });
  },
);

export const fetchSiteById = createAsyncThunk('site/get-by-id', async ({ id }) => {
  return GET(`v1/admin/site/${id}`);
});

export const fetchMinimalSiteList = createAsyncThunk('minimalSiteList/get', async ({ company }, { getState }) => {
  const previousFetchedCompany = getMinimalSiteCompany(getState());
  if (previousFetchedCompany === company) {
    const previousFetchedList = getMinimalSiteList(getState());
    return { company, list: previousFetchedList };
  }

  let url = 'v1/site/all/minimal';
  if (getIsAdminApi(getState())) url = 'v1/admin/site/minimal';
  const list = await GET(url, { params: { tenantId: company } });
  return { company, list };
});

export const getAvailableUsersForTenant = createAsyncThunk('availableUserList/get', async ({ tenantId }) => {
  return GET('v1/admin/site/users/' + tenantId);
});

export const enableDisabledSite = createAsyncThunk('site/enableDisable', async ({ value, id }) => {
  return POST(`v1/admin/site/${id}/${value ? 'enable' : 'disable'}`);
});

export const addNewSite = createAsyncThunk(
  'site/addNew',
  async ({ formValue, image, isEditing }, { rejectWithValue }) => {
    let err, data;
    if (image) {
      [err, data] = await to(POST_IMAGE(image));
      if (err) {
        return rejectWithValue({ err });
      }
    }
    if (isEditing) {
      return PUT(`v1/admin/site/${formValue.id}`, { ...formValue, imageUrl: data ? data.secure_url : null });
    } else {
      return POST('v1/admin/site', { ...formValue, imageUrl: data ? data.secure_url : null });
    }
  },
);

export const SiteAction = {
  setPage: (page) => (dispatch, getState) => {
    const tableInfo = getSiteTableInfo(getState());
    if (tableInfo.currentPage !== page) dispatch(getPaginatedSiteList({ 
      page, 
      sortColumn: 'name', 
      ...tableInfo 
    }));
  },
  setSelectedCompany: (newCompany) => (dispatch, getState) => {
    const {
      company,
      isAscending,
      searchKey
    } = getSiteTableInfo(getState());
    if (newCompany !== company) {
      dispatch(getPaginatedSiteList({ page: 0, company: newCompany, isAscending, sortColumn: 'name', searchKey }));
    }
  },
  changeGridOrder: (isAscending) => (dispatch, getState) => {
    const tableInfo = getSiteTableInfo(getState());
    dispatch(getPaginatedSiteList({ 
      ...tableInfo,
      page: 0, 
      isAscending, 
      sortColumn: 'name',
    }));
  },
  setSearch: (searchKey) => (dispatch, getState) => {
    const tableInfo = getSiteTableInfo(getState());
    dispatch(getPaginatedSiteList({ 
      ...tableInfo,
      page: 0, 
      sortColumn: 'name',
      searchKey
    }));
  },
};

export const sitesSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setTableInfo: (state, { payload }) => {
      state.tableInfo = { ...state.tableInfo, ...payload };
    },
    clearSiteEditing: (state) => {
      state.editingSite = null;
    },
  },
  extraReducers: {
    [getPaginatedSiteList.pending]: (state) => {
      state.isLoading = true;
      state.isLoadingError = false;
    },
    [getPaginatedSiteList.rejected]: (state) => {
      state.isLoading = false;
      state.isLoadingError = true;
    },
    [getPaginatedSiteList.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { results, currentPage, pageSize, totalPages, company } = action.payload;
      state.siteList = results ? results : [action.payload]; // site receive non-paginated response
      state.tableInfo = { ...state.tableInfo, currentPage, pageSize, totalPages };
    },
    [enableDisabledSite.pending]: (state) => {
      state.isLoading = true;
    },
    [enableDisabledSite.rejected]: (state) => {
      state.isLoading = false;
    },
    [enableDisabledSite.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.siteList = state.siteList.map((company) => (company.id === payload.id ? payload : company));
    },
    [addNewSite.pending]: (state) => {
      state.addNewSiteLoading = true;
    },
    [addNewSite.rejected]: (state) => {
      state.addNewSiteLoading = false;
    },
    [addNewSite.fulfilled]: (state) => {
      state.addNewSiteLoading = false;
    },
    [getAvailableUsersForTenant.pending]: (state) => {
      state.availableUsersLoading = true;
      state.availableUsers = [];
    },
    [getAvailableUsersForTenant.rejected]: (state) => {
      state.availableUsersLoading = false;
      state.availableUsers = [];
    },
    [getAvailableUsersForTenant.fulfilled]: (state, action) => {
      state.availableUsersLoading = false;
      state.availableUsers = action.payload;
    },
    [fetchMinimalSiteList.pending]: (state) => {
      state.minimalSiteList.isLoading = true;
      state.minimalSiteList.list = [];
    },
    [fetchMinimalSiteList.rejected]: (state) => {
      state.minimalSiteList.isLoading = false;
    },
    [fetchMinimalSiteList.fulfilled]: (state, { payload }) => {
      state.minimalSiteList.isLoading = false;
      state.minimalSiteList.list = payload.list;
      state.minimalSiteList.companyId = payload.company;
    },
    [fetchSiteById.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchSiteById.rejected]: (state) => {
      state.isLoading = false;
    },
    [fetchSiteById.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.editingSite = payload;
    },
  },
});

export const { setTableInfo, clearSiteEditing } = sitesSlice.actions;

const getSiteStore = (state) => state.site;

export const getSiteList = createSelector(getSiteStore, (state) => state.siteList);
export const getSiteListLoading = createSelector(getSiteStore, (state) => state.isLoading);
export const getAddNewSiteLoading = createSelector(getSiteStore, (state) => state.addNewSiteLoading);
export const getSiteTableInfo = createSelector(getSiteStore, (state) => state.tableInfo);
export const getEditingSite = createSelector(getSiteStore, (state) => state.editingSite);

export const getUsersForTenant = createSelector(getSiteStore, (state) => state.availableUsers);
export const getUsersForTenantLoading = createSelector(getSiteStore, (state) => state.availableUsersLoading);

export const getMinimalSites = createSelector(getSiteStore, (state) => state.minimalSiteList);
export const getMinimalSiteList = createSelector(getMinimalSites, (sites) => sites.list);
export const getMinimalSiteLoading = createSelector(getMinimalSites, (sites) => sites.isLoading);
export const getMinimalSiteCompany = createSelector(getMinimalSites, (sites) => sites.company);

export default sitesSlice.reducer;
