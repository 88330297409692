import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { NonFormikFormField } from '../../helpers/FormField';
import { useDispatch } from 'react-redux';
import { updateAnswer } from '../../../../features/service-form.store';
import FormSection from '../../helpers/FormSection';
import ImageUploadButton from './ImageUploadButton';
import PassFailQuestion from './PassFailQuestion';

const { Panel } = Collapse;

const BatteryValtage = ({ title, path, answerKey, answers, chamber }) => {
  const qty = parseInt(chamber.batteryQuantity);
  const [values, setValues] = useState(new Array(qty <= 30 ? 32 : 96).fill(''));
  const dispatch = useDispatch();

  const setValue = (v, index) => {
    const temp = [...values];
    temp[index] = v;
    setValues(temp);
  };

  useEffect(() => {
    if (values) {
      dispatch(
        updateAnswer({
          isArray: false,
          path: [...path, answerKey],
          value: values,
        }),
      );
    }
  }, [values]);

  useEffect(() => {
    if (answers && answers[answerKey]) {
      const temp = values.map((v, i) => (answers[answerKey][i] ? answers[answerKey][i] : v));
      setValues(temp);
    }
  }, []);

  return (
    <Collapse ghost expandIcon={() => null} collapsible="header" className="question" activeKey={[title]}>
      <Panel
        header={title}
        key={title}
        extra={
          <ImageUploadButton
            imageUrl={answers && answers.imageUrl}
            setImageUrl={(url) => {
              dispatch(
                updateAnswer({
                  isArray: false,
                  path: [...path, 'imageUrl'],
                  value: url,
                }),
              );
            }}
          />
        }
      >
        <FormSection className="no-padding dynamic-form">
          {/*<PriorityOptionSelector setPriority={setPriority} priority={priority} />*/}
          {values.map((v, index) => (
            <NonFormikFormField
              key={index}
              style={{ width: '10%' }}
              className="battery-voltage-textbox"
              labelText=""
              value={v}
              hasError={!isNaN(v) && parseFloat(v) < 12.5}
              onChange={(e) => setValue(e.target.value, index)}
            />
          ))}
        </FormSection>
      </Panel>
    </Collapse>
  );
};

export default BatteryValtage;
