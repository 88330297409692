import React, { useState, useRef } from "react";
import { range, isEmpty } from 'lodash'
import SubPageLayout from '../../../helpers/SubPageLayout';
import { TopBar } from "./TopBar";
import { Labels14f300, Labels16f600, Labels14f450, LabelCommon } from "../components/labels";
import moment from "moment";
import './styles.scss'
import { mockCalender, mockChamber } from "../../../../../features/mockData/build";
import ScheduleForm from "./drawer";
import ChamberInfo from "./chamber";

const DF = 'YYYY-MM-DD'

const Roster = () => {

    const [dateValue, setDateValue] = useState(moment())
    const [daysArray, setDaysArray] = useState(range(moment().daysInMonth()))
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenChamberInfo, setIsOpenChamberInfo] = useState(false)
    const refChamberList = useRef()
    const refCalenderContainer = useRef()

    const handleDateChange = (date) => {
        setDateValue(date)
        setDaysArray(range(date.daysInMonth()))
    }

    const renderHeader = () => {
        const tmpDate = dateValue.clone().startOf('month');
        return (
            <div className="header inner">
                {
                    daysArray.map(i => {
                        const ele = <div className={`header-item`}>
                            {tmpDate.format('DD ddd')}
                        </div>
                        tmpDate.add(1, 'days')
                        return ele;
                    })
                }
            </div>
        )
    }

    const handleOnClickMore = (id) => {
        setIsOpen(true)
    }

    const handleOnClickAssignWork = () => {
        setIsOpen(true)
    }

    const SingleBox = ({item}) => {

        return (
            <div className={`single-card type-${item.type} ${item.isLeftBounded ? 'is-left-bounded-b' : ''} ${item.isRightBounded ? 'is-right-bounded-b': ''}`}>
                <div className="left">
                    <img width={  !isEmpty(item.users) ? 20 : 25} src={`/icons/build-type-${item.type}.svg`}/>
                    { !isEmpty(item.users) && <LabelCommon 
                        className={`text type-${item.type}-font`}
                        >{item.users[0].name}</LabelCommon> 
                    }
                </div>
                <div className="right">
                    <img src="/icons/more-icon.svg" onClick={() => handleOnClickMore(item.id)}></img>
                </div>
            </div>
        )
    }

    const MultiBox = ({item}) => {

        return (
            <div className={`milti-card type-${item.type} ${item.isLeftBounded ? 'is-left-bounded-b' : ''} ${item.isRightBounded ? 'is-right-bounded-b': ''}`}>
                <div className="left">
                    <img width={ !isEmpty(item.users) ? 20 : 25} src={`/icons/build-type-${item.type}.svg`}/>
                    { !isEmpty(item.users) && <div>
                        <div className="text-wrapper">
                            <img src={`/icons/user-type-${item.type}.svg`}/>
                            <LabelCommon className={`text type-${item.type}-font`}>{item.users[0].name}</LabelCommon>
                        </div>
                        {item.users.length>1 && <div className="text-wrapper">
                            <img src={`/icons/user-type-${item.type}.svg`}/>
                            <LabelCommon className={`text type-${item.type}-font`}>{item.users[1].name}</LabelCommon>
                        </div>}
                        </div>  
                    }
                </div>
                <div className="right">
                    <img src="/icons/more-icon.svg" onClick={() => handleOnClickMore(item.id)}></img>
                </div>
            </div>
        )
    }

    const Card = ({ item }) => {
        return (
            <div style={{
                marginLeft: item.left,
                top: item.top,
                height: item.height,
                minWidth: item.width,
            }}
                className={`card-wrapper ${item.isLeftBounded ? 'is-left-bounded' : ''} ${item.isRightBounded ? 'is-right-bounded': ''}`}
            >
                {
                    item.isSingleBox ? <SingleBox item={item}/> : <MultiBox item={item}/>
                }
            </div>
        )
    }

    const formatItems = (items) => {
        const sorted = items.sort((a, b) => moment(a.fromDate, DF).diff(moment(b.fromDate, DF)));
        const updatedItems = [];
        let last_left_margine = 0;

        sorted.forEach((item, index) => {
            let start = moment(item.fromDate, DF)
            let end = moment(item.toDate, DF)
            const som = moment(dateValue).startOf('month')
            const eom = moment(dateValue).endOf('month')

            if((start.isBefore(som) && end.isBefore(som)) || (start.isAfter(eom) && end.isAfter(eom)))
                return

            let isLeftBounded = false
            let isRightBounded = false
            if(start.isBefore(som)){
                start = som;
                isLeftBounded = true
            }

            if(end.isAfter(eom)){
                end = eom
                isRightBounded = true
            }

            const added = moment(end).isSame(eom) ? 0 : 1
            const top = (index + 1) * 80 + 80
            const height = 80
            const left = ((start.date() - 1) - last_left_margine) * 80
            const width = (moment.duration(end.diff(start)).asDays() + added) * 80
            const isSingleBox = end.isSame(start);

            last_left_margine = end.date()

            updatedItems.push({
                start,
                end,
                top,
                left,
                height,
                width,
                isSingleBox,
                isLeftBounded,
                isRightBounded,
                ...item
            })
        });

        return updatedItems
    }

    const renderValues = () => {

        return (
            <div className="rows-values-container inner">
                {
                    mockCalender.map((chamber, index) => (
                        <div className="row">
                            {
                                formatItems(chamber.items).map(item => <Card item={item} index={index} />)
                            }
                        </div>
                    ))
                }
            </div>
        )
    }

    const renderCards = () => {
        return (
            <div className="rows-container inner">
                {
                    mockCalender.map(item => (
                        <div className="row">
                            {
                                daysArray.map(i => <div className="cell" />)
                            }
                        </div>
                    ))
                }
            </div>
        )
    }

    const handleScrollCalender = (e) => {
        refChamberList.current.scrollTop = e.target.scrollTop;
    }

    const handleScrollChamberView = (e) => {
        refCalenderContainer.current.scrollTop = e.target.scrollTop;
    }

    const handleOnFormClose = () => setIsOpen(false)

    const handleOnClickInfo = () => {

        setIsOpenChamberInfo(true)
    }

    return (
        <div className="roster">
            <SubPageLayout
                title="Build > Production Schedule"
            >
                <TopBar onDateChange={handleDateChange} dateValue={dateValue} onRightButtonClick={handleOnClickAssignWork} />
                <div className="wrapper">
                    <div className="chambers-list" ref={refChamberList} onScroll={handleScrollChamberView}>
                        {
                            mockCalender.map(({ chamberInfo }, index) => (
                                <div className={`chamber-item ${index == 0 ? 'item-0' : ''}`}>
                                    <img width={40} src={chamberInfo.src} />
                                    <div className="labels">
                                        <Labels16f600>{chamberInfo.name}</Labels16f600>
                                        <Labels14f450>{chamberInfo.subText}</Labels14f450>
                                        <Labels14f300>{chamberInfo.date}</Labels14f300>
                                    </div>
                                    <div className="cursor-pointer">
                                        <img src="/icons/more-icon.svg" onClick={() => handleOnClickInfo(chamberInfo.id)}></img>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="calender-wrapper" ref={refCalenderContainer} onScroll={handleScrollCalender}>
                        {renderHeader()}
                        {renderCards()}
                        {renderValues()}
                    </div>
                    <ScheduleForm title="Assign Work" isOpen={isOpen} onClose={handleOnFormClose} isLoading={false}/>
                    <ChamberInfo isOpen={isOpenChamberInfo} onCancel={()=>setIsOpenChamberInfo(false)} isLoading={false} data={mockChamber}/>
                </div>
            </SubPageLayout>
        </div>
    )
}

export default Roster;