import React from "react";
import { Button, Row, Col, List } from "antd";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { TopBar } from "../Overview/ConfigPanels";
import SubPageLayout from "../../../helpers/SubPageLayout";
import { useParams } from "react-router-dom";
import Chart from "../components/chart";
import { LabelCommon } from '../components/labels'
import RoundedImage from "../components/roundedImage";
import './styles.scss'

const mockData = {
    chartData: {
        'Shipping': 12,
        'Approvals': 10,
        'Build Post-Electrical': 24,
        'Electrician Testing': 20,
        'Build Pre-Electrical': 19,
        'Stickers': 6,
        'Materials': 12,
        'Paint & Blast': 4,
        'Fab': 11,
        'Prebuild': 6
    },
    team: [
        {
            src: '/tmp/image4.svg',
            name: 'Neal'
        },
        {
            src: '/tmp/image4.svg',
            name: 'Neal'
        },
        {
            src: null,
            name: 'Neal'
        }
    ],
    footer: [
        {
            name: 'Prebuild',
            start: '22/07/2022',
            end: '02/08/2022',
            comp: 'Completed in 10 days',
            image: '/tmp/image5.svg',
            type: 1,
            team: [
                {
                    src: '/tmp/image4.svg',
                    name: 'Neal'
                },
                {
                    src: '/tmp/image4.svg',
                    name: 'Neal'
                },
                {
                    src: null,
                    name: 'Neal'
                }
            ],
        },{
            name: 'Prebuild',
            start: '22/07/2022',
            end: '02/08/2022',
            comp: 'Completed in 10 days',
            image: '/tmp/image5.svg',
            type: 1,
            team: [
                {
                    src: '/tmp/image4.svg',
                    name: 'Neal'
                },
                {
                    src: '/tmp/image4.svg',
                    name: 'Neal'
                },
                {
                    src: null,
                    name: 'Neal'
                }
            ],
        }
    ]
}

const BuildSummaryChamberView = () => {

    const { id } = useParams()

    const TeamCol = ({ team }) => {
        return (
            <div className="d-flex">
                {team.map(({ name, src }) => <div className="tc">
                    <RoundedImage  name={name} src={src} size={40} />
                </div>)}
            </div>
        )
    }

    const renderItem = (item) => {

        return (
            <div className="footer-item">
                <div className="d-flex  ai-center">
                    <img width={40} src={item.image} className="d-flex space-right" />
                    <LabelCommon fontSize={16} fontWeight={600} color='blue'>{`${item.name}`}</LabelCommon>
                </div>
                <LabelCommon fontSize={12} fontWeight={600} color='blue'>{`Start Date: ${item.start}`}</LabelCommon>
                <LabelCommon fontSize={12} fontWeight={600} color='blue'>{`End Date: ${item.end}`}</LabelCommon>
                <LabelCommon fontSize={12} fontWeight={600} color='blue'>{`${item.comp}`}</LabelCommon>
                <TeamCol team={item.team}/>
                <DownOutlined className="down-arrow"/>
            </div>
        )
    }

    return (<>
        <TopBar placeholder="Enter Product Serial Number" />
        <div className="build-summary-chamber-view">
            <SubPageLayout
                title={`${id} Built Summary`}
                extra={<Button>Export to PDF</Button>}
            >
                <Row className="d-flex w-100">
                    <Col span={16} className="space-all-p">
                        <Chart data={mockData.chartData} />
                    </Col>
                    <Col span={8} className="space-all-p">
                        <Row>
                            <Col span={24} className="team-card card-bg">
                                <LabelCommon fontSize={14} fontWeight={600} className="space-left-p space-top-p">Team</LabelCommon>
                                <div className="d-flex space-left space-top space-right jc-space-between">
                                    <div className="d-flex">
                                        {
                                            mockData.team.map(({ name, src }) => <div className="space-right">
                                                <RoundedImage className="space-right" name={name} src={src} size={60} />
                                            </div>)
                                        }
                                    </div>
                                    <div className="jc-center ai-center d-flex">
                                        <RightOutlined className="right-arrow" size={55} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="jc-space-between flex">
                            <Col span={12} className="space-top-p space-bottom-p space-right-p-1">
                                <div className="card-bg common-height d-flex f-col">
                                    <div className="d-flex w-100 jc-space-around space-top">
                                        <img width={20} src="/icons/office.svg" />
                                        <div>OZ Minerals</div>
                                    </div>
                                    <div className="d-flex w-100 jc-space-around space-top">
                                        <img width={20} src="/icons/loc.svg" />
                                        <div>Prominent Hill</div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={12} className="space-top-p space-bottom-p space-right-p-1" >
                                <div className="card-bg common-height d-flex jc-space-around ai-center h-100">
                                    <img src="/icons/time-clock.svg" />
                                    <LabelCommon fontSize={20} fontWeight={600}>57 Days</LabelCommon>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8} className="card-bg common-height d-flex jc-space-around ai-center f-col space-all-p-1">
                                <LabelCommon textAlign="center" fontSize={12} fontWeight={600}>Origina Confirmed ETD</LabelCommon>
                                <LabelCommon fontSize={12} fontWeight={300}>30/08/2022</LabelCommon>
                            </Col>
                            <Col span={8} className="card-bg common-height d-flex jc-space-around ai-center f-col space-all-p-1">
                                <LabelCommon textAlign="center" fontSize={12} fontWeight={600}>Scheduled Completion Date</LabelCommon>
                                <LabelCommon fontSize={12} fontWeight={300}>26/10/2022</LabelCommon>
                            </Col>
                            <Col span={8} className="card-bg common-height d-flex jc-space-around ai-center f-col space-all-p-1">
                                <LabelCommon textAlign="center" fontSize={12} fontWeight={600}>Actual Completion Date</LabelCommon>
                                <LabelCommon fontSize={12} fontWeight={300}>28/10/2022</LabelCommon>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="d-flex f-col w-100 space-top footer">
                    <LabelCommon fontSize={20} fontWeight={600}>Build Stage Overview</LabelCommon>
                    <List dataSource={mockData.footer} renderItem={renderItem} />
                </div>
            </SubPageLayout>
        </div>
    </>)
}

export default BuildSummaryChamberView;