import React from "react";
import Search from 'antd/lib/transfer/search';
import { Space, DatePicker } from "antd";
import { CommonDropdown } from "../../../helpers/drop-downs-for-enums";

const mockItems = [
    { value: 'one', label: 'Item 1' },
    { value: 'two', label: 'Item 2' },
    { value: 'three', label: 'Item 3' }]


export const TopBar = ({placeholder, rightComponent}) => {
    return (
        <div className="d-flex top-bar">
            <Search
                placeholder={placeholder}
            />
            {rightComponent}
        </div>
    )
}

export const FiltersBar = () => {
    return (
        <Space direction="vertical" className="filter-bar">
            <Space wrap className="filter-selector">
                <CommonDropdown
                    options={mockItems}
                    placeholder="Status"
                />
                <CommonDropdown
                    options={mockItems}
                    placeholder="Stage"
                />
                <CommonDropdown
                    options={mockItems}
                    placeholder="Technician"
                />
                <DatePicker
                    placeholder="Due Date"
                />
                <CommonDropdown
                    options={mockItems}
                    placeholder="Company"
                />
            </Space>
        </Space>
    )
}