import React, { useEffect } from 'react';
import { Redirect, Route, Switch, Router } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import { toast, ToastContainer } from 'react-toastify';
import history from '../utils/history';
import { useDispatch, useSelector } from 'react-redux';
import { getIsValidating, validateByRefreshToken } from '../features/auth.store';
import to from '../utils/to';
import LoadingScreen from './LoadingScreen';
import eng from '../utils/eng';
import ForgetPassword from './Login/forget-password';
import ChangePassword from './Login/change-password';

const AppRouter = () => {
  const dispatch = useDispatch();

  const isValidating = useSelector(getIsValidating);

  useEffect(() => {
    (async () => {
      const [err] = await to(dispatch(validateByRefreshToken()).unwrap());
      if (err) {
        toast.error(err.status === 401 ? eng.toast.pleaseLoginAgain : err.message);
      }
    })();
  }, []);
  return (
    <Router history={history}>
      {isValidating ? (
        <LoadingScreen />
      ) : (
        <>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/forgot-password" component={ForgetPassword} />
            <Route path="/change-forgot-password" component={ChangePassword} />
            <Route path="/dashboard" component={Dashboard} />
            <Redirect from="/" to="/login" />
          </Switch>
          <ToastContainer />
        </>
      )}
    </Router>
  );
};

export default AppRouter;
