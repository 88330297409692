import moment from "moment";
import React, {useState} from "react";
import { Input } from "antd";
import { FormOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { LabelCommon } from '../components/labels'
import { CommonDropdown } from "../../../helpers/drop-downs-for-enums";

const months = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
]

const statuses = [
    "Awaiting Dispatch"
]

export const MonthSelector = (data) => {

    const handleChange = (data) => { }

    return(
        <div>
            <CommonDropdown onChange={handleChange} options={months.map(month => ({label: month, value: month}))} value={moment.unix(data).format('MMMM')}/>
        </div>
    )
}

export const StatusSelector = (data) => {

    return(
        <div>
            <CommonDropdown options={statuses.map(status => ({label: status, value: status}))} value={data}/>
        </div>
    )
}

export const EditableInput = ({left,label, value, onEdit = ()=>{}}) => {
    const [edit, setEdit] = useState(false)
    const [textValue, setTextValue] =  useState(value);

    const handleSetOk = () => {
        setEdit(false)
        onEdit(textValue)
    }

    return(
        <div className="d-flex editable-label">
            <img src={`/icons/${left}`}/>
            {edit ? <Input className="mid" value={textValue} onChange={(e) => setTextValue(e.target.value)}/> : 
            <LabelCommon fontSize={10} fontWeight={450} className="mid">{`${label}: ${textValue == undefined ? 'N/A' : textValue}`}</LabelCommon>} 
            {edit ? <div className="right d-flex">
                    <CheckOutlined  onClick={handleSetOk}/> 
                    <CloseOutlined onClick={()=>setEdit(false)}/>
                    </div>
                :<FormOutlined className="right" onClick={()=>setEdit(true)}/>
            }
        </div>
    )
}

export const StatusLabel = ({label}) => {

    switch(label){
        case 'delivery': return <LabelCommon fontSize={10} fontWeight={450} className="status delivery">delivery</LabelCommon>
        case 'internal': return <LabelCommon fontSize={10} fontWeight={450} className="status internal">internal</LabelCommon>
        case 'shipping': return <LabelCommon fontSize={10} fontWeight={450} className="status shipping">shipping</LabelCommon>
        default: <></>
    }
}