import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Spin, Input, Modal } from 'antd';
import FormField, { FormFieldOverrideOnChange } from '../helpers/FormField';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import FormSection from '../helpers/FormSection';
import {
    getCalenderIsLoading,
    getEditEvent,
    getViewType
} from '../../../features/scheduler.store'
import './styles/schedule.scss';
import { 
    TaskTypesDropDown, 
    EventUserMultiSearchDropDown, 
    ScheduleStatusDropDown, 
    DateRangeSelector,
    LocationDropDown
} from '../helpers/drop-downs-for-enums';

const TrainingScheduleEventForm = ({
    onSubmit,
    onCancel,
    showModel,
}) => {
    const formRef = useRef();

    const isLoading = useSelector(getCalenderIsLoading);
    const editEvent = useSelector(getEditEvent)
    const viewType = useSelector(getViewType);

    const handleOnSubmit = (value) => {
        formRef.current.resetForm()
        onSubmit && onSubmit(value, editEvent!==null)
    }

    const handleOnCancel = () => {
        onCancel && onCancel()
    }

    return (
        <Modal
                title="Schedule Event"
                visible={showModel}
                onOk={() => { formRef.current.submitForm(); }}
                onCancel={handleOnCancel}
                width={1120}
        >
         <FormSection>
                <Formik
                    innerRef={formRef}
                    initialValues={editEvent || initialValues}
                    validationSchema={YupValidation}
                    validateOnBlur
                    onSubmit={handleOnSubmit}
                    enableReinitialize={true}
                >
                    {props => 
                    <Form>
                        <Spin spinning={isLoading}>
                            <div className="d-flex">
                                <div className="flex space-right">
                                    <FormField name="name" labelText="Name" placeholder="Input here" />
                                    <FormField name="type" labelText="Task Type" InputComp={TaskTypesDropDown} viewType={viewType} selectFirstOnLoad/>
                                    <FormFieldOverrideOnChange name="dateRange" labelText="Date" InputComp={DateRangeSelector}/>
                                    <FormFieldOverrideOnChange name="persons" labelText="Person" person viewType={viewType} InputComp={EventUserMultiSearchDropDown}/>
                                    <FormField name="notes" labelText="Note" rows={4} InputComp={Input.TextArea}/>
                                </div>
                                <div className="flex space-left">
                                    <FormField name="description" labelText="Description" placeholder="Input here" />
                                    <FormField name="status" labelText="Status" selectFirstOnLoad viewType={viewType} InputComp={ScheduleStatusDropDown}/>
                                    <FormField name="location" labelText="Location" selectFirstOnLoad InputComp={LocationDropDown}/>
                                    <FormFieldOverrideOnChange name="invitees" labelText="Trainer" viewType={viewType} InputComp={EventUserMultiSearchDropDown}/>
                                </div>
                            </div>
                        </Spin>
                    </Form>}
                </Formik>
        </FormSection>
        </Modal>
    );
};

const initialValues = {
    name: '',
    type: '',
    dateRange: null,
    persons: [],
    notes: '',
    description: '',
    status: '',
    location: '',
    invitees: [],
};

const YupValidation = Yup.object({
    name: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    dateRange: Yup.array().of(Yup.string()).required('Required'),
    persons: Yup.array().of(Yup.object({
        fullname: Yup.string(),
        id: Yup.number()
    })),
    notes: Yup.string(),
    description: Yup.string(),
    status: Yup.string().required('Required'),
    location: Yup.string(),
    invitees: Yup.array().of(Yup.object({
        fullname: Yup.string(),
        id: Yup.number()
    })),
});

export default TrainingScheduleEventForm;
