export default {
  toast: {
    invalidUsernamePassword: 'Invalid Username or Password!',
    loginSuccess: 'Logged in success!',
    pleaseLoginAgain: 'Please Login again!',

    addUserSuccessfully: 'User added successfully!',
    addSiteSuccessfully: 'Site added successfully!',
    addCompanySuccessfully: 'Company added successfully!',
    addChamberSuccessfully: 'Chamber added successfully!',
    moveChamberSuccessfully: 'Chamber successfully moved!',
    updateChamberSuccessfully: 'Chamber updated successfully!',
    pleaseSelectFile: 'Please select a File!',
    addDocumentSuccessfully: 'Document added successfully!',

    saveServiceForm: 'Service Form saved successfully!',
    submitServiceForm: 'Service Form submitted successfully!',
    approveServiceFormSuccess: 'Service Form approved successfully!',

    updatePasswordSuccess: 'Updated Password Successfully!',
    updateProfileInfoSuccess: 'Updated Profile Info Successfully!',

    forgetPasswordRequested: 'Sent an email with rest password link!',
    changeForgotPasswordSuccess: 'Success!. Please try to log in again with new password!',
  },
  somethingWentWrong: 'Something went wrong!',
  chamberMoveError: 'Unable to move chamber!',
  unableToSave: 'Unable to save the results',
  unableToSubmit: 'Unable to submit the results',
  unableToApprove: 'Unable to approve the service form',
};
