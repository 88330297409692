import React from "react";
import { Modal, Spin, Row, Col } from "antd";
import moment from "moment";
import { LabelCommon } from "../components/labels";

const ChamberInfo = ({ title, isOpen, isLoading, onCancel, data }) => {

    const Field = ({ label, value }) => (
        <div className="d-flex space-all">
            <LabelCommon fontSize={12} fontWeight={600} lineHeight={1.5} style={{ minWidth: 250 }}>{`${label}`}</LabelCommon>
            <LabelCommon fontSize={12} fontWeight={300} lineHeight={1.5} >{`  ${value}`}</LabelCommon>
        </div>
    )

    return (
        <Modal
            title={title}
            visible={isOpen}
            destroyOnClose
            onCancel={onCancel}
            cancelText="Done"
            okButtonProps={{ style: { display: 'none' } }}
            style={{ minWidth: '70%' }}>
            <Spin spinning={isLoading}>
                <Row>
                    <Col span={12}>
                        <Field label="Customer" value={data.tenant.name} />
                        <Field label="Site" value={data.site.name} />
                        <Field label="Type" value={data.chamberTypeEnum.name} />
                        <Field label="Invoice Month" value={moment.unix(data.nextServiceDate).format('MMM')} />
                        <Field label="Original Confirmed ETD" value={moment.unix(data.nextServiceDate).format('MMM')} />
                        <Field label="Scheduled Completion Date" value={moment.unix(data.nextServiceDate).format('MMM')} />
                        <Field label="Actual Completion Date" value={moment.unix(data.nextServiceDate).format('MMM')} />
                        <Field label="Sailing Cut Off" value={moment.unix(data.nextServiceDate).format('MMM')} />
                        <Field label="Status" value={'Awaiting Dispatch'} />
                        <Field label="ETA from Paint" value={''} />
                        <Field label="Pick By" value={''} />
                        <Field label="Original Confirmed ETD" value={''} />
                        <Field label="Comments/Notes:" value={''} />
                    </Col>

                    <Col span={12}>
                        <Field label="Deposit" value={''} />
                        <Field label="Balance Payment" value={''} />
                        <Field label="Stickers Done" value={''} />
                        <Field label="Fabrication" value={''} />
                        <Field label="Paint" value={''} />
                        <Field label="Inco." value={''} />
                    </Col>
                </Row>
            </Spin>
        </Modal>
    )
}

export default ChamberInfo;