import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Layout from './helpers/layout';

import Companies from './SubPages/Companies';
import Sites from './SubPages/Sites';
import Chambers from './SubPages/Chambers';
import Users from './SubPages/Users';
import Reports from './SubPages/Reports';
import Documents from './SubPages/Documents';
import CompanyAdd from './SubPages/CompanyAdd';
import CompanyDetails from './SubPages/CompanyDetails';
import SitesAdd from './SubPages/SitesAdd';
import ChambersAdd from './SubPages/ChambersAdd';
import { getIsSuperAdmin, isFeatureEnabled } from '../../features/auth.store';
import { useSelector } from 'react-redux';
import SitesView from './SubPages/SitesView';
import ChambersView from './SubPages/ChambersView';
import ServiceForm from './SubPages/ServiceForm/ServiceForm';
import EditProfile from './SubPages/EditProfile';
import DetailedReport from './SubPages/DetailedReport';
import ServiceSchedule from './SubPages/ServiceSchedule';
import ProductSchedule from './SubPages/ProductSchedule';
import TrainingSchedule from './SubPages/TrainingSchedule';
import Overview from './SubPages/Build/Overview';
import ChamberView from './SubPages/Build/Overview/ChamberView';
import BuildSummary from './SubPages/Build/Reports/BuildSummary';
import BuildSummaryChamberView from './SubPages/Build/Reports/BuildSummaryChamberView';
import Roster from './SubPages/Build/Roster';
import AdminView from './SubPages/Build/AdminView';

const Dashboard = () => {
  const isSuperAdmin = useSelector(getIsSuperAdmin);
  const getFeatureEnabled = useSelector(isFeatureEnabled);
  const featureViewCompanyList = getFeatureEnabled('view_company');
  const featureAddCompany = getFeatureEnabled('add_company');
  const featureViewUsers = getFeatureEnabled('view_users');
  const featureViewBuild = getFeatureEnabled('view_build');

  return (
    <BrowserRouter basename="/dashboard">
      <Layout>
        <Switch>
          {featureAddCompany && <Route path="/companies/add" component={CompanyAdd} />}
          {featureViewCompanyList && <Route path="/companies/:id/details" component={CompanyDetails} />}
          {featureViewCompanyList && <Route path="/companies" component={Companies} />}
          <Route path="/sites/add" component={SitesAdd} />
          <Route path="/sites/:id/edit" component={SitesAdd} />
          <Route path="/sites" component={isSuperAdmin ? Sites : SitesView} />
          <Route path="/chambers/add" component={ChambersAdd} />
          <Route path="/chambers/:id/edit" component={ChambersAdd} />
          <Route path="/chambers/:id/service" component={ServiceForm} />
          <Route path="/chambers" component={isSuperAdmin ? Chambers : ChambersView} />
          {featureViewUsers && <Route path="/users" component={Users} />}
          <Route path="/reports/after-service/:id" component={DetailedReport} />
          <Route path="/reports/:type" component={Reports} />
          <Route path="/documents" component={Documents} />
          <Route path="/profile" component={EditProfile} />
          <Route path="/schedule/service" component={ServiceSchedule}/>
          <Route path="/schedule/product" component={ProductSchedule}/>
          <Route path="/schedule/product" component={ProductSchedule}/>
          <Route path="/schedule/training-school" component={TrainingSchedule}/>
          { featureViewBuild && <Route exact path="/build/overview" component={Overview}/>}
          { featureViewBuild && <Route exact path="/build/overview/page/:id" component={ChamberView}/>}
          { featureViewBuild && <Route exact path="/build/reporting/summary" component={BuildSummary}/>}
          { featureViewBuild && <Route exact path="/build/reporting/summary/:id" component={BuildSummaryChamberView}/>}
          { featureViewBuild && <Route exact path="/build/product-roster" component={Roster}/>}
          { featureViewBuild && <Route exact path="/build/admin-view" component={AdminView}/>}
          <Redirect from="/" to={isSuperAdmin ? '/companies' : '/sites'} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default Dashboard;
