import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import * as Yup from 'yup';
import moment from 'moment'
import { isEmpty, range } from 'lodash';
import ExportEventReport from '../helpers/ExportEventReport';
import {
    SchedulerAction,
    getEvents,
    getCalenderIsLoading,
    setTaskType,
    getTaskType,
    getSelectedMonth,
    getSelectedYear,
    setSelectedYearAndMonth
} from '../../../features/scheduler.store'
import SubPageLayout from '../helpers/SubPageLayout'
import './styles/schedule.scss';
import { SCHEDULE_VIEW_TYPE } from '../../../utils/constant';
import { 
    TaskTypesDropDown, 
} from '../helpers/drop-downs-for-enums';

const ProductSchedule = () => {

    const dispatch = useDispatch();

    const viewType = SCHEDULE_VIEW_TYPE.PRODUCT;
    const events = useSelector(getEvents)
    const isLoading = useSelector(getCalenderIsLoading);
    const taskType = useSelector(getTaskType);
    const selectedYear = useSelector(getSelectedYear);
    const selectedMonth = useSelector(getSelectedMonth);
    const [isExportModalVisible, setIsExportModalVisible] = useState(false)

    const [selectedYearMonth, setSelectedYearMonthLocal] = useState(
        isEmpty(selectedYear) ?
        moment().startOf('month').toDate() :
        moment(`${selectedYear}-${selectedMonth}-01`,'YYYY-MM-DD').toDate())

    useEffect(() => {
        dispatch(SchedulerAction.setEvents({viewType}));
    }, [selectedYear, selectedMonth, taskType]);

    const renderImage = (event) => {
        if(!isEmpty(event.user.url)){
            return <img className='profile-image' src={event.user.url} />
        }else if(!isEmpty(event.user.name)){
            const splittedName = event.user.name.split(' ');
            return (
                <div className='profile-image-with-text'>
                    <div className='text-data'>{splittedName[0][0]}</div>
                    {splittedName.length > 1 ? 
                        <div className='text-data'>{splittedName[1][0]}</div> : 
                        <></>
                    }
                </div>
            )
        }else{
            return <></>
        }
    }

    const renderEvents = (eventsList, dateValue) => {
        const view = eventsList && eventsList.length > 0 && 
            eventsList.filter(event=>event.start === dateValue.format('YYYY-MM-DD')).map(event => {
            return <div style={event.style} className='event-card'>
                {renderImage(event)}
                <div className='event-title-text'>
                    {event.name}
                </div>
            </div>
        })
        return view || <></>;
    }

    const handleResetDate = () =>{
        const dateValue = moment().startOf('month')
        setSelectedYearMonthLocal(dateValue.toDate())
        dispatch(setSelectedYearAndMonth({
            year: dateValue.year(), 
            month: dateValue.month()
        }))
    }

    const handleSetPlusDate = () =>{
        const dateValue = moment(selectedYearMonth).add(1, 'months')
        setSelectedYearMonthLocal(dateValue.toDate())
        dispatch(setSelectedYearAndMonth({
            year: dateValue.year(), 
            month: dateValue.month()
        }))
    }

    const handleSetMinusDate = () =>{
        const dateValue = moment(selectedYearMonth).add(-1, 'months')
        setSelectedYearMonthLocal(dateValue.toDate())
        dispatch(setSelectedYearAndMonth({
            year: dateValue.year(), 
            month: dateValue.month()
        }))
    }

    const handleTaskType = (val) => {
        dispatch(setTaskType(val))
    }

    const handleOnExport = () => {
        setIsExportModalVisible(true)
    }

    return (
        <SubPageLayout
            title="Product Scheduling"
            extra={
                <div className="d-flex">
                    <Button type="secondary" icon={<ExportOutlined />} onClick={handleOnExport}>
                        Export
                    </Button>
                </div>
            }
        >
            <div className="d-flex">
                <div className='task-type-selector'>
                    <TaskTypesDropDown
                        placeholder= 'Select Task Type'
                        onChange= {handleTaskType}
                        selectFirstOnLoad
                        includeAll
                        viewType={viewType}
                        value={taskType}
                    />
                </div>
            </div>
            <div>
                <Spin spinning={isLoading}>
                    <div className='events-wrapper'>
                        <div class="rbc-toolbar">
                            <span class="rbc-btn-group">
                                <button type="button" onClick={handleResetDate}>Today</button>
                                <button type="button" onClick={handleSetMinusDate}>Back</button>
                                <button type="button" onClick={handleSetPlusDate}>Next</button>
                            </span>
                            <span class="rbc-toolbar-label">
                                {moment(selectedYearMonth).format('MMMM YYYY')}
                            </span>
                        </div>
                        {
                            range(0, moment(selectedYearMonth).daysInMonth(), 1).map(day => {
                                const dateValue = moment(selectedYearMonth).clone().add(day, 'days');
                                return (
                                    <div className='pro-event'>
                                        <div className='date-card'> {dateValue.format('DD')} </div>
                                        {
                                            renderEvents(events, dateValue)
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </Spin>
            </div>
            <ExportEventReport show={isExportModalVisible} onCancel={()=>setIsExportModalVisible(false)}/>
        </SubPageLayout>
    );
};

const initialValues = {
    frequency: '',
    daterange: '',
    taskType: '',
    status: '',
    vehicle: '',
    employee: '',
    site: '',
    note: ''
};

const YupValidation = Yup.object({
    frequency: Yup.string(),
    daterange: Yup.string(),
    taskType: Yup.string(),
    status: Yup.string(),
    vehicle: Yup.string(),
    employee: Yup.string(),
    site: Yup.string(),
    note: Yup.string(),
});

export default ProductSchedule;
