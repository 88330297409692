import React from "react";

const getColor = (color)=>{
    switch(color){
        case 'black': return '#1C1C1C'
        case 'grey': return '#797979'
        case '242424': return '#242424'
        case 'blue' : return '#0085FF'
        case 'red' : return '#E10000'
        default: return '#1C1C1C';
    }
}

export const LabelCommon = ({
    children,
    fontSize=16,
    color='black',
    lineHeight=1,
    fontWeight=300,
    textAlign='left',
    ...props
}) => <div style={{
    fontSize,
    color: getColor(color),
    lineHeight,
    fontWeight,
    textAlign
}} {...props}>{children}</div>

export const Labels16f600=(props)=> <LabelCommon color="black" fontSize={12} lineHeight={1} fontWeight={600} {...props}>{props.children}</LabelCommon>
export const Labels16f450=(props)=> <LabelCommon color="black" fontSize={12} lineHeight={1} fontWeight={450}>{props.children}</LabelCommon>
export const Labels14f450=(props)=> <LabelCommon color="black" fontSize={10} lineHeight={1} fontWeight={450}>{props.children}</LabelCommon>
export const Labels14f300=(props)=> <LabelCommon color="black" fontSize={10} lineHeight={1.8} fontWeight={300}>{props.children}</LabelCommon>
export const Labels14f600=(props)=> <LabelCommon color="black" fontSize={14} lineHeight={1} fontWeight={600}>{props.children}</LabelCommon>
