import React from "react";
import { Progress } from "antd";
import { Labels16f600, Labels14f300 } from "../components/labels";

export const ProgressSummaryCard = ({ onClickSeeMore }) => {

    const renderChamberInfo = () => (
        <div className="d-flex chamber-info">
            <img width="40" src="/tmp/image1.svg" />
            <div className="text">
                <Labels16f600>MA 1947</Labels16f600>
                <Labels14f300>MS-ED2-12-ELV-36</Labels14f300>
                <Labels14f300>P Number  | MAQ Number</Labels14f300>
            </div>
        </div>
    )

    const getBGStatus = (value) => {
        if (value > 66) {
            return 'success'
        } else if (value > 33) {
            return 'normal'
        } else {
            return 'exception'
        }
    }

    const renderProgressInfo = () => (
        <div className="d-flex progress-info">
            <img width="40" src="/tmp/image2.svg" />
            <div className="info-card">
                <div className="d-flex">
                    <Labels16f600>Painting</Labels16f600>
                    <Labels16f600>80%</Labels16f600>
                </div>
                <Progress
                    percent={80}
                    showInfo={false}
                    status={getBGStatus(80)}
                />
            </div>
        </div>
    )

    const getPersonInfoCard = (name) => (
        <div className="d-flex wrapper">
            <img src="/icons/person.svg" />
            <Labels16f600>{name}</Labels16f600>
        </div>
    )

    const renderPersonInfo = () => (
        <div className="person-info">
            {getPersonInfoCard('Vanna Lonk')}
            {getPersonInfoCard('Steve Hardy')}
        </div>
    )

    const renderSiteInfo = () => (
        <div className="d-flex chamber-info">
            <img width="40" src="/tmp/image3.svg" />
            <div className="text">
                <Labels16f600>Byrnecut Australia</Labels16f600>
                <Labels14f300>Due Date: 08/10/2022</Labels14f300>
            </div>
        </div>
    )

    const handleOnClick = () => {
        onClickSeeMore("MA 2162")
    }

    const renderSeeMore = () => (
        <img width="20" src="/icons/see-more.svg" className="see-more" onClick={handleOnClick} />
    )

    return (
        <div className="card">
            {renderChamberInfo()}
            {renderProgressInfo()}
            {renderPersonInfo()}
            {renderSiteInfo()}
            {renderSeeMore()}
        </div>
    )
}

export const SummaryCard = ({onClickSeeMore}) => {
    
    const handleOnClick =()=>{
        onClickSeeMore('MA 1947')
    }

    return (
        <div className="d-flex card">
            <div className="d-flex">
                <img width="30" src="/tmp/image1.svg" />
                <div className="info">
                    <Labels16f600>MA 1947 completed on</Labels16f600>
                    <div className="d-flex">
                        <Labels14f300>July 02, 2020</Labels14f300>
                        <div className="pipe">|</div>
                        <Labels14f300>07:23 PM</Labels14f300>
                    </div>
                </div>
            </div>
            <img width="20" src="/icons/see-more.svg" className="see-more " onClick={handleOnClick}/>
        </div>
    )
}