import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd"
import { setMoveToCompany, setMoveToSite } from "../../../features/chamber.store";
import { getChamberMoveInfo } from "../../../features/chamber.store";
import { CompanySelectDropDown, SiteSelectDropdown } from "./drop-downs-for-enums";
import {NonFormikFormField} from './FormField';
import FormSection from './FormSection';

export const MoveChamberModal = ({
    show,
    onCancel,
    onSubmit
}) => {

    const chamberMoveInfo = useSelector(getChamberMoveInfo);
    const dispatch = useDispatch();

    return (
        <Modal
            title={`Move Chamber [${chamberMoveInfo.selectedChamber.chamberName}]`}
            cancelButtonProps={{ style: { display: 'none' } }}
            visible={show}
            onCancel={onCancel}
            onOk={onSubmit}
            okText="Move"
        >
            <FormSection>
                <div className="d-flex">
                    <div className="flex space-left">
                        <NonFormikFormField
                            disabled
                            name="fromTenant"
                            labelText="From Company"
                            value={chamberMoveInfo.fromCompany.tenantName}
                        />
                        <NonFormikFormField
                            disabled
                            name="fromSite"
                            labelText="From Site"
                            value={chamberMoveInfo.fromSite.siteName}
                        />
                    </div>
                    <div className="flex space-left">
                        <NonFormikFormField
                            name="toTenant"
                            labelText="To Company"
                            placeholder="Input Here"
                            value={chamberMoveInfo.toCompany}
                            InputComp={CompanySelectDropDown}
                            selectFirstOnLoad
                            onChange={(val) => dispatch(setMoveToCompany(val))}
                        />
                        <NonFormikFormField
                            name="toSite"
                            labelText="To Site"
                            placeholder="Input Here"
                            company={chamberMoveInfo.toCompany}
                            value={chamberMoveInfo.toSite}
                            selectFirstOnLoad
                            onChange={(val) => val && dispatch(setMoveToSite(val))}
                            InputComp={SiteSelectDropdown}
                        />
                    </div>
                </div>
            </FormSection>
        </Modal>
    )
}