import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SubPageLayout from '../helpers/SubPageLayout';
import { ActionsColumn, DetailsCard } from '../helpers/Components';
import { Button, Empty, Spin, Switch, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import SearchFilterInput from '../helpers/SearchFilterInput';
import MyTable from '../helpers/MyTable';
import { useDispatch, useSelector } from 'react-redux';
import QR from 'qrcode';
import printJS from 'print-js';
import { toast } from 'react-toastify';
import eng from '../../../utils/eng';
import { isFeatureEnabled, getIsSuperAdmin } from '../../../features/auth.store';
import {
  ChambersActions,
  enableDisabledChamber,
  getChamberList,
  getChamberListLoading,
  getChamberTableInfo,
  getPaginatedChamberList,
  setMoveFromCompany,
  setMoveFromSite,
  setMoveToCompany,
  setMoveToSite,
  setMoveSelectedChamber,
  claerMoveInfo,
  moveChamber,
} from '../../../features/chamber.store';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constant';
import get from 'lodash.get';
import { MoveChamberModal } from '../helpers/Modals';
import to from '../../../utils/to';

const columns = (dispatch, isSuperAdmin, onQrCodeClick, onMoveClick) => {
  const colomns = [
    {
      title: 'Chamber Name',
      dataIndex: 'name',
      render: (name, { id, chamberTypeEnum }) => (
        <DetailsCard
          name={name}
          id={id}
          className="d-flex f-row ai-center"
          as="div"
          src={`https://res.cloudinary.com/day4l7awk/image/upload/v1653323984/chamber-types/${get(
            chamberTypeEnum,
            'name',
          )}.jpg`}
        />
      ),
    },
    {
      title: 'Company',
      dataIndex: ['tenant', 'name'],
    },
    {
      title: 'Site',
      dataIndex: ['site', 'name'],
    },
    {
      title: 'Enabled',
      dataIndex: 'isEnabled',
      render: (data, record) => (
        <Switch checked={data} onChange={() => dispatch(enableDisabledChamber({ value: !data, id: record.id }))} />
      ),
    },
    {
      title: 'Actions',
      render: (data, record) => <ActionsColumn 
        showMove={isSuperAdmin}
        onMoveClick={() => onMoveClick(data)} 
        showEdit 
        editUrl={`/chambers/${record.id}/edit`} />,
      width: 130,
    },
  ];

  if(isSuperAdmin){
    colomns.splice(1,0,{
      title: 'Print QR',
      dataIndex: 'qrCode',
      render: (data) => <ActionsColumn showQR onQrCodeClick={() => onQrCodeClick(data)} />,
    })
  }
  return colomns;
};

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled User',
    // Column configuration not to be checked
    name: record.name,
  }),
};

const Chambers = () => {
  const history = useHistory();
  const getFeatureEnable = useSelector(isFeatureEnabled);
  const featureAddChambers = getFeatureEnable('add_chambers');
  const [currentPage, setPage] = useState(1);
  const [QRModalState, setQRModalState] = useState(false)
  const [QRModalStateLoading, setQRModalStateLoading] = useState(false)

  const dispatch = useDispatch();
  const chambersTableInfo = useSelector(getChamberTableInfo);
  const chambersList = useSelector(getChamberList);
  const chambersListLoading = useSelector(getChamberListLoading);
  const isSuperAdmin = useSelector(getIsSuperAdmin);

  const [showMove, setShowMove] = useState(false)

  let emptyViewDescription = '';
  if (chambersList.length === 0) {
    emptyViewDescription = 'There is no chambers found for your selection';
  }
  if (!chambersTableInfo.site) {
    emptyViewDescription = 'Select a site to load chambers';
  }

  const handleOnQrCode = (qrcode) => {
    setQRModalStateLoading(true)
    setQRModalState(true)
    setTimeout(() => {
      const canvas = document.getElementById('qr-canvas');
      QR.toCanvas(canvas, qrcode, { width: 250, height: 250 });
      setQRModalStateLoading(false)
    }, 400);
  }

  const handleOnMoveSubmit = async () =>{
    const[err] = await to(dispatch(moveChamber()).unwrap())
    if(err){
      toast.error(eng.chamberMoveError);
    }else{
      toast.success(eng.toast.moveChamberSuccessfully);
    }
    setShowMove(false)
    dispatch(getPaginatedChamberList({ page: currentPage - 1 }));
  }

  const handleOnCancelMove = () => {
    setShowMove(false)
    dispatch(claerMoveInfo())
  }

  const handleOnMove = (data) => {
    const {
      tenant: {
        identifier,
        name: tenantName
      },
      id: chamberId,
      name: chamberName,
      site:{
        id: siteId,
        name: siteName
      }
    } = data;
    dispatch(setMoveFromCompany({
      identifier,
      tenantName
    }))
    dispatch(setMoveFromSite({
      siteId,
      siteName
    }))
    dispatch(setMoveSelectedChamber({
      chamberId,
      chamberName
    }))
    dispatch(setMoveToCompany(""))
    dispatch(setMoveToSite(""))
    setShowMove(true)
  }

  const onHandlePrintQR = ()=>{
    printJS({printable: document.querySelector("#qr-canvas").toDataURL(), type: 'image', imageStyle: 'width:100%'});
	}

  return (
    <SubPageLayout
      title={
        <>
          Chambers List
        </>
      }
      extra={
        featureAddChambers && (
          <Link to="/chambers/add">
            <Button type="primary" icon={<PlusOutlined />}>
              Add Chambers
            </Button>
          </Link>
        )
      }
      subtitle="Chambers"
    >
      <div className="d-flex">
        <SearchFilterInput
          placeholder="Search."
          companyDropdown
          companyProps={{
            onChange: (val) => dispatch(ChambersActions.setSelectedCompany(val)),
            selectFirstOnLoad: true,
            placeholder: 'Select Company',
            value: chambersTableInfo.company,
          }}
        />
        <SearchFilterInput
          placeholder="Search."
          sitesDropdown
          siteProps={{
            onChange: (val) => {
              dispatch(ChambersActions.setSelectedSite(val));
              setPage(1);
            },
            selectFirstOnLoad: true,
            placeholder: 'Select Site',
            company: chambersTableInfo.company,
            value: chambersTableInfo.site,
          }}
        />
      </div>

      <MyTable
        loading={chambersListLoading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns(dispatch, isSuperAdmin, handleOnQrCode, handleOnMove)}
        dataSource={chambersList}
        pagination={{
          position: ['none', 'bottomCenter'],
          pageSize: DEFAULT_PAGE_SIZE,
          total: chambersTableInfo.totalPages * DEFAULT_PAGE_SIZE,
          currentPage,
          onChange: (page) => {
            dispatch(getPaginatedChamberList({ page: page - 1 }));
            setPage(page);
          },
        }}
        rowKey="id"
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyViewDescription} />,
        }}
      />
      <Modal 
        title={"Print QR"} 
        cancelButtonProps={{ style: { display: 'none' } }}
        visible={QRModalState} 
        onCancel={()=>setQRModalState(false)} 
        onOk={onHandlePrintQR}
        okText="Print"
      >
        <Spin spinning={QRModalStateLoading}>
          <div className="d-flex jc-center">
            <canvas id="qr-canvas" />
          </div>
        </Spin>
      </Modal>
      <MoveChamberModal 
        show={showMove}
        onCancel={handleOnCancelMove}
        onSubmit={handleOnMoveSubmit}
      />

    </SubPageLayout>
  );
};

export default Chambers;
