import React, { useEffect } from 'react';
import SubPageLayout from '../helpers/SubPageLayout';
import MyTable from '../helpers/MyTable';
import './styles/documents.scss';
import DocumentsAdd from './DocumentsAdd';
import { useDispatch, useSelector } from 'react-redux';
import { isFeatureEnabled } from '../../../features/auth.store';
import {
  getDocumentList,
  getDocumentCategoriesList,
  getDocumentLoading,
  getStandardDocumentList,
  getStanderedDocumentCategoriesList,
} from '../../../features/document.store';
import { format } from 'date-fns';
import { UPDATE_ON_FORMAT } from '../../../utils/constant';

const columns = [
  {
    title: 'Download',
    dataIndex: 'documentUrl',
    render: (data) => (
      <a target="_blank" download href={data} className="d-flex jc-center">
        <img src="/icons/download.svg" alt="download" />
      </a>
    ),
    width: 100,
  },
  { title: 'Name', dataIndex: 'name' },
  {
    title: 'Updated At',
    dataIndex: 'lastModifiedDate',
    render: (data) => format(new Date(data), UPDATE_ON_FORMAT),
    width: '50%',
  },
];

const Documents = () => {
  const getFeatureEnable = useSelector(isFeatureEnabled);
  const featureAddDocuments = getFeatureEnable('add_documents');

  const dispatch = useDispatch();
  const standardDocs = useSelector(getStandardDocumentList);
  const documentListLoading = useSelector(getDocumentLoading);
  const documentCategories = useSelector(getStanderedDocumentCategoriesList);

  useEffect(() => {
    dispatch(getDocumentCategoriesList())
    dispatch(getDocumentList());
  }, []);

  const commonProps = {
    bordered: true,
    columns: columns,
    pagination: false,
    loading: documentListLoading,
    rowKey: 'id',
  };

  const filterDocuments = (headerKey, documents) => 
    documents.filter(({ categoryEnum }) => categoryEnum.name === headerKey)

  const renderDocumentTable = (header, documents) => (
      <div key={header.name} className="rounded-border bg-white space-all-p space-bottom">
        <div className="table-header">{header.displayName}</div>
        <MyTable {...commonProps} dataSource={documents} />
      </div>
  )

  return (
    <SubPageLayout className="document-wrapper" title="Documents" extra={featureAddDocuments && <DocumentsAdd />}>
      {
        documentCategories.map((documentCategory) => {
          return renderDocumentTable(documentCategory, filterDocuments(documentCategory.name, standardDocs))
        })
      }
    </SubPageLayout>
  );
};

export default Documents;
