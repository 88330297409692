import React from 'react';
import { CompanySelectDropDown, SiteSelectDropdown } from './drop-downs-for-enums';

const SearchFilterInput = ({ companyDropdown, sitesDropdown, companyProps, siteProps,  ...props }) => {
  return (
    <div className="search-filter d-flex ai-center">
      {/*<Input {...props} />*/}
      {companyDropdown && <CompanySelectDropDown {...companyProps} />}
      {sitesDropdown && <SiteSelectDropdown {...siteProps} />}
    </div>
  );
};

export default SearchFilterInput;
