import axios from 'axios';
import { toAPIError } from '../utils/utils';
import to from '../utils/to';

/* eslint-disable no-undef */
const BASE_URL = process.env.REACT_APP_BASE_URL;
const CLOUDINARY_UPLOAD_URL = process.env.REACT_APP_IMAGE_UPLOAD;
const CLOUDINARY_PRESET = process.env.REACT_APP_CLOUDINARY_PRESET;
const CLOUDINARY_CLOUD = process.env.REACT_APP_CLOUDINARY_CLOUD;

const getBearerToken = () => `Bearer ${localStorage.getItem('accessToken')}`;

const getRefreshToken = () => localStorage.getItem('refreshToken');

const getRequestHeaders = (noAuthHeader) => {
  if (!noAuthHeader) {
    return { Authorization: getBearerToken() };
  }
  return {};
};

const getFullUrl = (url) => `${BASE_URL}/${url}`;

const successHandler = (res) => res.data;
const errorHandler = (err) => {
  throw toAPIError(err);
};

const nonRetryGET = async (url, options, { noAuthHeader } = {}) => {
  return await axios
    .get(getFullUrl(url), { headers: getRequestHeaders(noAuthHeader), ...options })
    .then(successHandler)
    .catch(errorHandler);
};

const nonRetryDELETE = async (url, body, options, { noAuthHeader } = {}) => {
  return await axios
    .delete(getFullUrl(url), { headers: getRequestHeaders(noAuthHeader), ...options })
    .then(successHandler)
    .catch(errorHandler);
};

const nonRetryPOST = (url, body, options, { noAuthHeader } = {}) => {
  return axios
    .post(getFullUrl(url), body, { headers: getRequestHeaders(noAuthHeader), ...options })
    .then(successHandler)
    .catch(errorHandler);
};

const nonRetryPUT = (url, body, options, { noAuthHeader } = {}) => {
  return axios
    .put(getFullUrl(url), body, { headers: getRequestHeaders(noAuthHeader), ...options })
    .then(successHandler)
    .catch(errorHandler);
};

const withAuthRetry = async (method, ...params) => {
  const [err, result] = await to(method(...params));
  if (err) {
    if (err.status === 401) {
      // regenerate the access token
      const [refreshError, data] = await to(
        nonRetryPOST('v1/token/refresh', { refreshToken: getRefreshToken() }, {}, { noAuthHeader: true }),
      );
      if (refreshError) {
        throw err;
      }
      localStorage.setItem('accessToken', data.accessToken);
      const [err2, result2] = await to(method(...params));
      if (err2) {
        throw err2;
      }
      return result2;
    }
    throw err;
  }
  return result;
};

const POST_IMAGE = (image) => {
  const formData = new FormData();
  formData.append('file', image);
  formData.append('upload_preset', CLOUDINARY_PRESET);
  formData.append('cloud_name', CLOUDINARY_CLOUD);
  return axios.post(CLOUDINARY_UPLOAD_URL, formData).then(successHandler).catch(errorHandler);
};

const GET = (...params) => withAuthRetry(nonRetryGET, ...params);
const POST = (...params) => withAuthRetry(nonRetryPOST, ...params);
const PUT = (...params) => withAuthRetry(nonRetryPUT, ...params);
const DELETE = (...params) => withAuthRetry(nonRetryDELETE, ...params);

const DOWNLOAD = (url) =>
  axios({
    url: getFullUrl(url),
    method: 'GET',
    responseType: 'blob',
    headers: getRequestHeaders(false),
  });

export { GET, POST, PUT, nonRetryGET, nonRetryPOST, POST_IMAGE, DOWNLOAD, DELETE };
