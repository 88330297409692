import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import QR from 'qrcode';
import moment from 'moment';

const QrCode = ({ id }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      setLoading(true);
      setTimeout(() => {
        const canvas = document.getElementById('qr-canvas');
        QR.toCanvas(canvas, id, { width: 250, height: 250 });
        setLoading(false);
      }, 400);
    }
  }, [show]);

  const download = () => {
    var link = document.createElement('a'); 
    var thisCanvas = document.getElementById('qr-canvas'); 
    link.download = `${moment()}.jpeg`; 
    link.href = thisCanvas.toDataURL("image/jpeg") 
    link.click(); 
  }

  return (
    <>
      <Modal
        visible={show}
        title="QR Code"
        cancelButtonProps={{ style: { display: 'none' } }}
        okText="Download"
        onOk={download}
        onCancel={() => setShow(false)}
        destroyOnClose
      >
        <Spin spinning={loading}>
          <div className="d-flex jc-center">
            <canvas id="qr-canvas" />
          </div>
          
        </Spin>
      </Modal>
      <Button  onClick={() => setShow(true)}>QR Code</Button>
    </>
  );
};

export default QrCode;
