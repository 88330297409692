import React, { useRef } from "react";
import { Formik } from "formik";
import { Drawer, Spin, DatePicker, Form, Button } from "antd";
import { FormFieldDrawer, FormFieldOverrideOnChangeDrawer } from "../../../helpers/FormField";
import { OptionsTypesDropdown } from "../../../helpers/drop-downs-for-enums";
import * as Yup from 'yup';
import FormSection from "../../../helpers/FormSection";

const ScheduleForm = ({ title, isOpen, onClose, editEvent, handleOnSubmit, isLoading }) => {

    const formRef = useRef();

    return (
        <Drawer title={title} visible={isOpen} onClose={onClose} >
            <FormSection>
                <Formik
                    innerRef={formRef}
                    initialValues={editEvent || initialValues}
                    validationSchema={YupValidation}
                    validateOnBlur
                    onSubmit={() => { }}
                >
                    {props =>
                        <Form>
                            <Spin spinning={isLoading}>
                                <div className="d-flex f-col jc-space-between h-80">
                                    <div className="d-flex f-col">
                                        <FormFieldDrawer name="technician" labelText="Technician" selectFirstOnLoad InputComp={OptionsTypesDropdown} />
                                        <FormFieldDrawer name="serialNumber" labelText="Serial Number" selectFirstOnLoad InputComp={OptionsTypesDropdown} />
                                        <FormFieldDrawer name="productionStage" labelText="Production Stage" selectFirstOnLoad InputComp={OptionsTypesDropdown} />
                                        <FormFieldOverrideOnChangeDrawer name="startDate" labelText="Start Date" InputComp={({ ...props }) => <DatePicker {...props} />} />
                                        <FormFieldOverrideOnChangeDrawer name="endDate" labelText="End Date" InputComp={({ ...props }) => <DatePicker {...props} />} />
                                    </div>
                                    <div className="d-flex jc-center">
                                        <Button className="drawer-button">Assign to Schedule</Button>
                                    </div>
                                </div>
                            </Spin>
                        </Form>}
                </Formik>
            </FormSection>
        </Drawer>
    )
}

const initialValues = {
    technician: '',
    serialNumber: '',
    productionStage: '',
    startDate: null,
    endDate: null,
    includeSaturday: false,
    includeSunday: false
};

const YupValidation = Yup.object({
    technician: Yup.string().required('Required'),
    serialNumber: Yup.string().required('Required'),
    productionStage: Yup.string().required('Required'),
    startDate: Yup.string().required('Required'),
    endDate: Yup.string().required('Required'),
    includeSaturday: Yup.bool(),
    includeSunday: Yup.bool()
});

export default ScheduleForm