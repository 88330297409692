import React, { useEffect, useState } from 'react';
import { ActionsColumn, DetailsCard } from '../helpers/Components';
import { Button, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import SearchFilterInput from '../helpers/SearchFilterInput';
import MyTable from '../helpers/MyTable';
import SubPageLayout from '../helpers/SubPageLayout';
import SiteDetails from '../helpers/SiteDetails';
import format from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';
import { isFeatureEnabled } from '../../../features/auth.store';
import { DEFAULT_PAGE_SIZE, UPDATE_ON_FORMAT } from '../../../utils/constant';
import {
  enableDisabledSite,
  getPaginatedSiteList,
  getSiteList,
  getSiteListLoading,
  getSiteTableInfo,
  SiteAction,
} from '../../../features/site.store';
import SubPageHeader from '../helpers/SubPageHeader';

const columns = (dispatch,{
  siteTableInfo,
  handleChangeOrder
}) => [
  {
    title: 'Site name',
    dataIndex: 'name',
    render: (name, { id, imageUrl }) => (
      <DetailsCard src={imageUrl} name={name} id={id} className="d-flex f-row ai-center" as="div" />
    ),
    sorter: true,
    sortDirections: [siteTableInfo.isAscending ? 'descend' : 'ascend'],
    onHeaderCell: (() => ({
      onClick: handleChangeOrder
    }))
  },
  {
    title: 'Company',
    dataIndex: 'tenantName',
  },
  {
    title: 'Update On',
    dataIndex: 'lastModifiedDate',
    render: (data) => format(new Date(data), UPDATE_ON_FORMAT),
  },
  {
    title: 'Enabled',
    dataIndex: 'enabled',
    render: (data, record) => (
      <Switch checked={data} onChange={() => dispatch(enableDisabledSite({ value: !data, id: record.id }))} />
    ),
  },

  {
    title: 'Actions',
    render: (data, record) => <ActionsColumn showEdit editUrl={`/sites/${record.id}/edit`} />,
    width: 130,
  },
];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled User',
    // Column configuration not to be checked
    name: record.name,
  }),
};

const Sites = () => {
  const getFeatureEnable = useSelector(isFeatureEnabled);
  const featureAddSites = getFeatureEnable('add_sites');
  const [currentPage, setPage] = useState(1);

  const dispatch = useDispatch();
  const siteTableInfo = useSelector(getSiteTableInfo);
  const siteList = useSelector(getSiteList);
  const siteListLoading = useSelector(getSiteListLoading);

  const [expandedRow, setExpandedRow] = useState([]);

  const handleChangeOrder = () => {
    dispatch(SiteAction.changeGridOrder(!siteTableInfo.isAscending));
  }

  return (
    <SubPageLayout
      title="Sites List"
      extra={
        featureAddSites && (
          <Link to="/sites/add">
            <Button type="primary" icon={<PlusOutlined />}>
              Add Site
            </Button>
          </Link>
        )
      }
    >
      <h3>Displaying sites information for company: </h3>
      <SearchFilterInput
        placeholder="Search."
        companyDropdown
        companyProps={{
          onChange: (val) => {
            dispatch(SiteAction.setSelectedCompany(val));
            setPage(1);
          },
          onLoad: (list) => dispatch(SiteAction.setSelectedCompany(list[0].identifier)),
          selectFirstOnLoad: true,
          placeholder: 'Select Company',
        }}
      />
      <SubPageHeader 
        defaultValue={siteTableInfo.searchKey}
        searchLoading={false}
        searchPlaceholder="Search Sites"
        onSearch={(value)=> dispatch(SiteAction.setSearch(value))} 
      />
      <MyTable
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns(dispatch,{
          siteTableInfo,
          handleChangeOrder
        })}
        dataSource={siteList}
        pagination={{
          position: ['none', 'bottomCenter'],
          pageSize: DEFAULT_PAGE_SIZE,
          total: siteTableInfo.totalPages * DEFAULT_PAGE_SIZE,
          currentPage,
          onChange: (page) => {
            dispatch(getPaginatedSiteList({ page: page - 1 }));
            setPage(page);
          },
        }}
        onChange={(pagination, filters, sorter, extra) => {
          dispatch(SiteAction.setPage(pagination.current - 1));
        }}
        defaultExpandAllRows
        expandable={{
          expandedRowKeys: [expandedRow],
          onExpand: (expanded, record) => (expanded ? setExpandedRow(record.id) : setExpandedRow([])),
          expandIconColumnIndex: 6,
          expandedRowRender: (record, index, indent, expanded) => <SiteDetails expanded={expanded} {...record} />,
          rowExpandable: () => true,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ),
        }}
        loading={siteListLoading}
        rowKey="id"
      />
    </SubPageLayout>
  );
};

export default Sites;
