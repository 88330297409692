export const serviceFormQuestions = {
  refugeChamberExternal: {
    visualChecks: null,
    mainDoor: null,
    window: null,
    externalFireExtinguisher: null,
    strobeLightsSirenAndAntenna: null,
    externalSignage: null,
    checkValves: null,
    structure: null,
    compressedAirFilterSystem: null,
    airConditionerOutDoorUnit: null,
    transformer: null,
    communicationsBox: null,
  },
  refugeChamberInternal: {
    internalLightingAndMotionSensor: null,
    internalSignage: null,
    manuals: null,
    internalCompressedAirSystem: null,
    internalFireBlanketOrExtinguisher: null,
    statsAndFlooring: null,
    toilet: null,
    communicationSystems: null,
    airConditionerIndoorUnit: null,
    scrubberSystem: null,
    escapeHatch: null,
    vaccumTest: null,
  },
  consumables: {
    freePourScrubberChemicals: null,
    cartridgeScrubberChemicals: null,
    auraFXGasMonitoring: null,
    digitalGasMonitor: null,
    manualGasSamplingPump: null,
    oxygenRegulators: null,
    medicalOxygenCylinders: null,
    oxygenCandle: null,
    firstAidWaterAndLongLifeFood: null,
    batteryVoltages: null,
    batterySystem: null,
  },
  chamberOptions: {
    ppms: null,
    airlockSystem: null,
    guardIAN: null,
    additionalLanguages: null,
    aods: null,
    wheels: null,
    towBar: null,
    blastResistance: null,
    fireResistance: null,
    thermalInsulation: null,
    maceyPlug: null,
    ppfs: null,
  },
  notes: [],
};
