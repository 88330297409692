import React, { useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import FormSection from '../helpers/FormSection';
import FormField from '../helpers/FormField';
import { Uploader } from '../helpers/Components';
import { FolderOpenOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Spin } from 'antd';
import * as Yup from 'yup';
import { DocumentCategoriesTypesDropdown } from '../helpers/drop-downs-for-enums';
import get from 'lodash.get';
import { useDispatch } from 'react-redux';
import { addNewDocument, getDocumentList } from '../../../features/document.store';
import { toast } from 'react-toastify';
import eng from '../../../utils/eng';
import to from '../../../utils/to';

const DocumentsAdd = () => {
  const [showAddNewDoc, setShowAddNewDoc] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeAddDocumentModal = () => {
    setShowAddNewDoc(false);
  };

  const fileUploadRef = useRef();
  const formRef = useRef();
  const dispatch = useDispatch();

  const handleOK = () => {
    formRef.current.submitForm();
  };

  const handleSave = async (values) => {
    setLoading(true);
    const file = get(fileUploadRef, ['current', 'fileList', 0, 'originFileObj'], null);
    if (!file) {
      setLoading(false);
      return toast.error(eng.toast.pleaseSelectFile);
    }
    const [err] = await to(dispatch(addNewDocument({ values, file })).unwrap());
    if (err) {
      setLoading(false);
      return toast.error(err.err || eng.somethingWentWrong);
    }
    toast.success(eng.toast.addDocumentSuccessfully);
    formRef.current.resetForm();
    setShowAddNewDoc(false);
    dispatch(getDocumentList());
    setLoading(false);
  };

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={() => setShowAddNewDoc(true)}>
        Add Documents
      </Button>
      <Modal
        title="Upload Document"
        visible={showAddNewDoc}
        onCancel={closeAddDocumentModal}
        footer={[
          <Button type="primary" onClick={handleOK}>
            Upload
          </Button>,
          <Button onClick={closeAddDocumentModal}>Cancel</Button>,
        ]}
      >
        <Formik innerRef={formRef} initialValues={initialValues} validationSchema={YupValidation} onSubmit={handleSave}>
          <Form>
            <Spin spinning={loading}>
              <FormSection>
                <FormField name="name" labelText="Document Name" placeholder="Name" />
                <FormField
                  name="category"
                  labelText="Document Category"
                  InputComp={DocumentCategoriesTypesDropdown}
                  placeholder="Select category"
                />
                <Uploader
                  accept="pdf/*"
                  beforeUpload={() => Promise.reject()}
                  maxCount={1}
                  ref={fileUploadRef}
                  icon={<FolderOpenOutlined />}
                  text="Select or Drop your Files"
                  className="space-bottom space-top"
                />
              </FormSection>
            </Spin>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

const initialValues = {
  name: '',
  category: null,
};

const YupValidation = Yup.object({
  name: Yup.string().required('Required'),
  category: Yup.string().typeError('Required').required('Required'),
});

export default DocumentsAdd;
