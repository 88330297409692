import React from 'react';
import { Link } from 'react-router-dom';
import FormSection from './FormSection';
import './styles/components.scss';
import { Avatar, Select, Upload, Button } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import { resizeCloudinaryImages } from '../../../utils/utils';
import format from 'date-fns/format';
import { UPDATE_ON_FORMAT } from '../../../utils/constant';

const { Dragger } = Upload;
const { Option } = Select;

export const DetailSection = ({ title, extra, className, children }) => {
  return (
    <FormSection title={title} extra={extra} className={className}>
      <hr className="detail-section-header-divider" />
      {children}
    </FormSection>
  );
};

export const StaticDetails = ({ title, value, valueProps }) => (
  <div className="static-details mulish">
    <h3 className="fw-700">{title}</h3>
    <div className="value fw-400" {...valueProps}>
      {value}
    </div>
  </div>
);

export const DetailsCard = ({ as, name, id, to, className, src }) => {
  let linkProp = { to };
  if (as) {
    linkProp = {};
  }

  return React.createElement(
    as || Link,
    {
      className: `d-flex f-row ai-center details-card ${className || ''}`,
      ...linkProp,
    },
    [
      <Avatar shape="square" size={48} src={<img src={resizeCloudinaryImages(src, 48, 48)} alt="" />} key={1} />,
      <div className="details d-flex f-col" key={2}>
        <div className="name fw-700">{name}</div>
        {id && <span className="id fw-400">#{id}</span>}
      </div>,
    ],
  );
};

export const ActionsColumn = ({ showDelete, showEdit, showChambers, showMove, editUrl, showQR, onQrCodeClick, onMoveClick }) => (
  <div className="d-flex ai-center">
    {showEdit && (
      <div className="icon space-right">
        <Link to={editUrl}>
          <img src="/icons/edit.svg" />
        </Link>
      </div>
    )}
    {showDelete && (
      <div className="icon space-right">
        <img src="/icons/delete.svg" />
      </div>
    )}
    {showChambers && (
      <div className="icon space-right">
        <img src="/icons/chambers.svg" />
      </div>
    )}
    {showQR && (
      <div className="icon space-right" onClick={onQrCodeClick}>
        <img src="/icons/qrcode.svg" />
      </div>
    )}
    {showMove && (
      <div className="icon space-right cursor-pointer" onClick={onMoveClick}>
        <img src="/icons/copy-icon.svg" />
      </div>
    )
    }
  </div>
);

export const Uploader = React.forwardRef(({ icon, text, className, ...rest }, ref) => (
  <div className={`uploader h-100 ${className || ''}`}>
    <Dragger {...rest} ref={ref}>
      <p className="ant-upload-drag-icon">{icon ? icon : <CameraOutlined />}</p>
      <p className="ant-upload-text">
        {text ? (
          text
        ) : (
          <>
            <a href="#">Upload</a> your company logo here
          </>
        )}
      </p>
    </Dragger>
  </div>
));

export const SelectInputWithSearch = ({ optionItems, ...rest }) => (
  <Select
    showSearch
    className="w-100"
    placeholder="Type"
    optionFilterProp="children"
    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
    {...rest}
  >
    {optionItems.map(({ value, text }) => (
      <Option value={value}>{text}</Option>
    ))}
  </Select>
);

export const SiteViewTile = ({ imageUrl, name, lastModifiedDate, id }) => (
  <div className="site-view-tile rounded-border bg-white space-all d-flex f-col">
    <img className="tile-image rounded-border" src={resizeCloudinaryImages(imageUrl, 260, 260)} alt={imageUrl} />
    <div className="tile-content d-flex f-row jc-space-between ai-center space-top">
      <div className="description">
        <div className="title fw-500">{name}</div>
        <div className="subtitle">Last Serviced</div>
        <div className="date">{format(new Date(lastModifiedDate), UPDATE_ON_FORMAT)}</div>
      </div>
      {/*<Link to={`/sites/${id}`}>*/}
      {/*  <Button type="primary" shape="circle" icon={<RightOutlined />} />*/}
      {/*</Link>*/}
    </div>
  </div>
);
