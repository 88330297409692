import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import get from 'lodash.get';
import SubPageLayout from '../helpers/SubPageLayout';
import { Row, Col, Tag, Button, Checkbox } from 'antd';
import { format } from 'date-fns';
import { InfoCircleOutlined } from '@ant-design/icons';
import { DATE_FORMAT } from '../../../utils/constant';
import MyTable from '../helpers/MyTable';
import './styles/detailed-reports.scss';
import { useDispatch, useSelector } from 'react-redux';
import { detailedReports, getDetailedReport, getReportsLoading, updateQuestion } from '../../../features/reports.store';
import { getIssueLists } from '../../../utils/utils';
import { downloadPdf } from '../../../utils/report-download';
import { getIsAdminApi, getIsUserEnabledToFixes } from '../../../features/auth.store';

const DetailedReport = () => {
  const { id } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState(false);

  const report = useSelector(detailedReports);
  const isLoading = useSelector(getReportsLoading);

  const tenantId = query.get('tenantId');
  useEffect(() => {
    dispatch(getDetailedReport({ id, tenantId }));
  }, [id, tenantId]);

  const {
    summary,
    issuesList
  } = getIssueLists(report);
  const isAdmin = useSelector(getIsAdminApi);
  const isUserEnabledToFixes = useSelector(getIsUserEnabledToFixes);

  const onDownload = async () => {
    setDownloading(true);
    await downloadPdf(id, isAdmin, tenantId, true);
    setDownloading(false);
  };

  const openCTA = () => {
    window.open('https://minearc.com/serviceappenquiry/', 'blank');
  };

  const handleOnFixedChange = (record) => {
    dispatch(updateQuestion({id, tenantId, ...record}))
  }

  const columns = [
    {
      title: 'Thumbnail',
      dataIndex: 'imageUrl',
      render: (src) => <img src={src} alt={src} width="100" />,
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      render: (val) => {
        if (val === 'LOW') return <Tag color="success">Low</Tag>;
        if (val === 'HIGH') return <Tag color="error">High</Tag>;
        if (val === 'MEDIUM') return <Tag color="warning">Medium</Tag>;
      },
    },
    {
      title: 'Fix',
      dataIndex: 'note',
      render: (note, { selectedAnswer }) => (
        <div>
          <p>{selectedAnswer}</p>
          <p>Note: {note}</p>
        </div>
      ),
    },
    {
      title: 'Issue Resolved by Site',
      dataIndex: 'fixed',
      render: (checked, record) => (
        <div>
          <Checkbox disabled={isUserEnabledToFixes || record.disabled} checked={checked} onChange={() => handleOnFixedChange(record)}/>
        </div>
      ),
    },
  ];

  return (
    <SubPageLayout
      className="detailed-reports"
      title="MineARC System - After Service Report"
      loading={isLoading}
      extra={[
        <Button type="primary" loading={downloading} onClick={onDownload} key="1">
          Download
        </Button>,
        <Button onClick={openCTA} key="2" className="space-left">
          Help
        </Button>,
      ]}
    >
      {report && (
        <>
          <Row>
            <Col span={6}>
              <div className="summery-box rounded-border space-all-p space-right">
                <div className="summery-title fw-500">Service Summary: {get(report, 'chamberName')}</div>
                <p className="summery-text fw-400">
                  Chamber Type: {get(report, 'chamberType')}
                  <br />
                  Site: {get(report, 'siteName')}
                  <br />
                  Location: {get(report, 'chamberLocation')}
                </p>
              </div>
            </Col>
            <Col span={5}>
              <div className="service-card rounded-border">
                <div className="title d-flex ai-center">
                  <img src="/icons/service-date-icon.svg" />
                  <div className="text">Serviced</div>
                </div>
                <div className="date">{format(new Date(get(report, 'servicedDate')), DATE_FORMAT)}</div>
              </div>
            </Col>
            <Col span={5}>
              <div className="service-card rounded-border">
                <div className="title d-flex ai-center">
                  <img src="/icons/service-date-icon.svg" />
                  <div className="text">Next Service Due</div>
                </div>
                <div className="date">{format(new Date(get(report, 'nextServiceDate')), DATE_FORMAT)}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="service-card rounded-border">
                <div className="title d-flex ai-center">
                  <div className="text">Reported By</div>
                </div>
                <div className="name">Name: {get(report, ['mineArcServiceTechnician', 'name'])}</div>
                <div className="email">Email: {get(report, ['mineArcServiceTechnician', 'email'])}</div>
              </div>
            </Col>
          </Row>
          <Row className="space-top">
            <div className="status-card flex">
              <div className="icon">
                <InfoCircleOutlined />
              </div>
              <div className="count">{summary.totalFails}</div>
              <div className="description">Total Chamber Faults</div>
            </div>
            <div className="status-card flex">
              <div className="icon">
                <img src="/icons/priority-red.svg" alt="h" />
              </div>
              <div className="count">{summary.highPriorityCount}</div>
              <div className="description">High Priority</div>
            </div>
            <div className="status-card flex">
              <div className="icon">
                <img src="/icons/priority-yellow.svg" alt="m" />
              </div>
              <div className="count">{summary.mediumPriorityCount}</div>
              <div className="description">Medium Priority</div>
            </div>
            <div className="status-card flex">
              <div className="icon">
                <img src="/icons/priority-green.svg" alt="l" />
              </div>
              <div className="count">{summary.lowPriorityCount}</div>
              <div className="description">Low Priority</div>
            </div>
          </Row>
          <Row>
            <MyTable
              dataSource={issuesList}
              rowKey="question"
              columns={columns}
              pagination={false}
              style={{ width: ' 100%' }}
            />
          </Row>
        </>
      )}
    </SubPageLayout>
  );
};

export default DetailedReport;
