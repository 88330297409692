import React, { useState } from 'react';
import { CameraOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Badge, Popover, Upload } from 'antd';
import { POST_IMAGE } from '../../../../service/base-api-service';
import to from '../../../../utils/to';
import { toast } from 'react-toastify';
import eng from '../../../../utils/eng';

const ImageUploadButton = ({ imageUrl, setImageUrl, additionalClass = '' }) => {
  const [uploading, setUploading] = useState(false);

  const onUpload = async (file) => {
    setUploading(true);
    const [err, data] = await to(POST_IMAGE(file.file));
    if (err) {
      return toast.error(eng.somethingWentWrong);
    }
    setUploading(false);
    setImageUrl(data.secure_url);
  };

  const badgeProps = {
    color: 'orange',
    offset: [-10, 0],
  };
  if (uploading) {
    badgeProps.count = <ClockCircleOutlined style={{ color: 'blue' }} />;
  } else if (imageUrl) {
    badgeProps.dot = true;
  }

  const uploadButton = (
    <Badge {...badgeProps}>
      <Upload
        accept="image/*"
        beforeUpload={() => Promise.reject()}
        maxCount={1}
        onChange={onUpload}
        showUploadList={false}
        className={`sf-image-upload ${additionalClass}`}
      >
        <CameraOutlined className="header-icon camera" />
      </Upload>
    </Badge>
  );

  if (imageUrl) {
    return (
      <Popover
        content={
          <a target="_blank" href={imageUrl}>
            open
          </a>
        }
        title="Already added an image"
        placement="bottomLeft"
      >
        {uploadButton}
      </Popover>
    );
  }

  return uploadButton;
};

export default ImageUploadButton;
