import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { GET, POST, POST_IMAGE } from '../service/base-api-service';
import to from '../utils/to';

const initialState = {
  documentList: [],
  documentCategoriesList: [],
  isLoading: false,
  isLoadingError: false,
};

export const addNewDocument = createAsyncThunk('document/addNew', async ({ values, file }, { rejectWithValue }) => {
  let err, data;
  if (file) {
    [err, data] = await to(POST_IMAGE(file));
    if (err) {
      return rejectWithValue({ err });
    }
  }
  return POST('v1/admin/document', { ...values, documentUrl: data.secure_url });
});

export const getDocumentList = createAsyncThunk('documentList/get', async () => {
  return GET('v1/document');
});

export const getDocumentCategoriesList = createAsyncThunk('documentCategoriesList/get', async () => {
  return GET('v1/enum/documentCategories');
})

export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {},
  extraReducers: {
    [getDocumentList.pending]: (state) => {
      state.isLoading = true;
    },
    [getDocumentList.rejected]: (state) => {
      state.isLoading = false;
      state.isLoadingError = true;
    },
    [getDocumentList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.documentList = action.payload;
    },
    [getDocumentCategoriesList.pending]: (state) => {
      state.isLoading = true;
    },
    [getDocumentCategoriesList.rejected]: (state) => {
      state.isLoading = false;
      state.isLoadingError = true;
    },
    [getDocumentCategoriesList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.documentCategoriesList = action.payload;
    },
  },
});

const getDocumentStore = (state) => state.document;

export const getDocumentLoading = createSelector(getDocumentStore, (state) => state.isLoading);

export const getStanderedDocumentCategoriesList = createSelector(getDocumentStore, (state) => state.documentCategoriesList);

export const getStandardDocumentList = createSelector(getDocumentStore, (state) => state.documentList)

export default documentSlice.reducer;
