import get from 'lodash.get';
import { ROLE } from './features';

const getAuraFxGasSensorQuestions = (value) => {
  const conditions = (c) => get(c, 'gasSensorTypeEnums', []).some((v) => v.name === value);
  const conditionsForCalibration = (c, userType) => {
    if (userType) {
      return get(c, 'gasSensorTypeEnums', []).some((v) => v.name === value) && userType === ROLE.MAST;
    }
    return get(c, 'gasSensorTypeEnums', []).some((v) => v.name === value);
  };

  return [
    {
      title: `${value} Sensor reading`,
      type: 'TEXT_FIELD',
      key: `sensorReadings`,
      gasType: value,
      subKey: 'reading',
      conditions,
    },
    {
      title: `${value} Sensor manufacture date`,
      key: `sensorReadings`,
      gasType: value,
      type: 'DATE_FIELD',
      subKey: 'manufactureDate',
      conditions,
    },
    {
      title: `${value} Sensor calibration due date`,
      key: `sensorReadings`,
      gasType: value,
      type: 'DATE_FIELD',
      subKey: 'calibrationDueDate',
      conditions: conditionsForCalibration,
    },
    {
      title: `${value} Sensor calibrated (if required)`,
      options: ['Other'],
      key: `sensorReadings`,
      subKey: `calibrated`,
      gasType: value,
      conditions: conditionsForCalibration,
    },
  ];
};

export const questionOptions = {
  refugeChamberExternal: {
    visualChecks: [
      {
        title: 'Is the refuge chamber currently connected to mains power?',
        options: [
          'The refuge chamber is not connected to the mains power supply - Site to ensure chamber is connected to mains power.',
          'The power is currently isolated in the area - Site to inspect and restore power to the refuge chamber.',
          'PORTABLE - The portable refuge chamber is not connected to mains power as it is being used in STANDBY (portable) mode.',
          'Other',
        ],
      },
      {
        title: 'Is the refuge chamber currently connected to compressed air?',
        options: [
          'NOT APPLICABLE - This model refuge chamber is not designed to be connected to an external compressed air supply.',
          'The refuge chamber is not connected to the compressed air supply - Site to ensure chamber is connected to compressed air.',
          'The compressed air is currently isolated in the area - Site to inspect and restore compressed air to the refuge chamber.',
          'Other',
        ],
      },
    ],
    mainDoor: [
      {
        title: 'Door handle condition',
        options: [
          'DAMAGED/SEIZED - 1x Door handle is damaged/seized and needs to be replaced - MineARC can supply replacement handles.',
          'DAMAGED/SEIZED - Both door handles are damaged/seized and need to be replaced - MineARC can supply replacement handles.',
          'TOO TIGHT - 1x Door handle is too tight and needs to be adjusted - Site to arrange for appropriate tradesperson to inspect and adjust door handle.',
          'TOO TIGHT - Both door handles are too tight and need to be adjusted - Site to arrange for appropriate tradesperson to inspect and adjust door handles.',
          'TOO LOOSE - 1x Door handle is too loose and needs to be adjusted - Site to arrange for appropriate tradesperson to inspect and adjust door handle.',
          'TOO LOOSE - Both door handles are too loose and need to be adjusted - Site to arrange for appropriate tradesperson to inspect and adjust door handles.',
          'LOOSE SLEEVE - 1x Door handle is loose inside the sleeve, causing a leak in the refuge chamber - MineARC can supply replacement door handles and sleeves.',
          'LOOSE SLEEVE - Both door handles are loose inside the sleeve, causing a leak in the refuge chamber - MineARC can supply replacement door handles and sleeves.',
          'Other',
        ],
      },
      {
        title: 'Door hinge condition',
        options: [
          'DAMAGED - 1x door hinge has been damaged - Site to arrange for appropriate tradesperson to inspect/repair. MineARC can supply replacement hinges if required.',
          'NEEDS ADJUSTMENT - The door hinges require adjustment, the door cannot close flush against the door frame. Site to arrange appropriate tradesperson to inspect and adjust as required.',
          'NEEDS GREASING - The door hinges require greasing - Site to arrange for door hinges to be greased.',
          'Other',
        ],
      },
      {
        title: 'General door operation and condition',
        options: [
          'DAMAGED - The chamber door has been damaged - Site to arrange for appropriate tradesperson to inspect and repair door. MineARC can supply a replacement door if required.',
          'DAMAGED - The chamber door has been damaged beyond repair and needs to be replaced - MineARC can supply a replacement door, contact MineARC for assistance.',
          'DOES NOT CLOSE - The refuge chamber door does not close correctly - The refuge chamber should not be put into service until the door is repaired. Contact MineARC for assistance.',
          'Other',
        ],
      },
      {
        title: 'SERVICEABLE COMPONENT: Door seal',
        options: [
          'POOR CONDITION - The door seal is in poor condition and needs to be replaced - MineARC can supply replacement door seals.',
          'DAMAGED - The door seal has been damaged and needs to be replaced - MineARC can supply replacement door seals.',
          'INCORRECTLY FITTED - The door seal has been installed poorly and needs to be replaced - MineARC can supply replacement door seals.',
          'NON-OEM PART - The door seal is a non-genuine aftermarket part - MineARC recommends replacing non-genuine parts with genuine MineARC OEM products that have been thoroughly tested for use in the emergency operation of this refuge chamber.',
          'Other',
        ],
      },
    ],
    window: [
      {
        title: 'Window condition',
        options: [
          'WINDOW DAMAGED - The window has been damaged and needs to be replaced - MineARC can supply replacement window.',
          'WINDOW CONDITION - The window is in poor condition and needs to be replaced - MineARC can supply replacement window.',
          'BOLTS CORRODED - The window bolts are heavily corroded and need to be replaced - MineARC can supply replacement bolts.',
          'BOLTS DAMAGED - The window bolts have been damaged and need to be replaced - MineARC can supply replacement bolts.',
          'Other',
        ],
      },
    ],
    externalFireExtinguisher: [
      {
        title: 'External fire extinguisher condition',
        options: [
          'OUT OF DATE - The fire extinguisher is due for testing - Site to arrange inspection.',
          'MISSING - The external fire extinguisher is missing from the refuge chamber - MineARC can supply replacement fire extinguisher if required.',
          'DAMAGED - The external fire extinguisher has been damaged - MineARC can supply replacement fire extinguisher if required.',
          'LOW PRESSURE - The external fire extinguisher pressure gauge shows low pressure - MineARC can supply replacement fire extinguisher if required.',
          'INCORRECT - The fire extinguisher is the incorrect type - MineARC can supply replacement fire extinguisher if required.',
          'NOT APPLICABLE - This model refuge chamber does not use an external fire extinguisher.',
          'Other',
        ],
        conditions: (c) => c.externalFireExtinguisher === true,
      },
      {
        title: 'External fire extinguisher bracket',
        options: [
          'DAMAGED - The fire extinguisher mounting bracket has been damaged - MineARC can supply replacement mounting bracket.',
          'MISSING - The fire extinguisher mounting bracket is missing from the refuge chamber - MineARC can supply replacement mounting bracket.',
          'INCORRECT - The fire extinguisher mounting bracket is the incorrect type - MineARC can supply replacement mounting bracket.',
          'NOT APPLICABLE - This model refuge chamber does not use an external fire extinguisher.',
          'Other',
        ],
        conditions: (c) => c.externalFireExtinguisher === true,
      },
      {
        title: 'External fire extinguisher last test date',
        type: 'DATE_FIELD',
        key: 'lastTestDate',
        conditions: (c) => c.externalFireExtinguisher === true,
      },
    ],
    strobeLightsSirenAndAntenna: [
      {
        title: 'Green strobe light condition',
        options: [
          'FAULTY - The green strobe light is faulty - MineARC can supply replacement green strobe light.',
          'DAMAGED - The green strobe light has been damaged - MineARC can supply replacement green strobe light.',
          'Other',
        ],
      },
      {
        title: 'Red strobe light condition',
        options: [
          'FAULTY - The red strobe light is faulty - MineARC can supply replacement red strobe light.',
          'DAMAGED - The red strobe light has been damaged - MineARC can supply replacement red strobe light.',
          'Other',
        ],
      },
      {
        title: 'Siren condition',
        options: [
          'FAULTY - The external siren is faulty - MineARC can supply replacement siren.',
          'DAMAGED - The external siren has been damaged - MineARC can supply replacement siren.',
          'NOT APPLICABLE - This model refuge chamber does not have an external siren.',
          'Other',
        ],
      },
      {
        title: 'Antenna condition',
        options: [
          'MISSING - The antenna is missing - MineARC can supply replacement antenna.',
          'DAMAGED - The antenna has been damaged - MineARC can supply replacement antenna.',
          'Other',
        ],
      },
    ],
    externalSignage: [
      {
        title: 'Green reflective stripe condition',
        options: [
          'MISSING/DAMAGED - The green reflective striping is either missing or damaged - MineARC can supply replacement stickers.',
          'Other',
        ],
      },
      {
        title: 'General external signage condition',
        options: [
          'MISSING/DAMAGED - The external stickers are either missing or damaged - MineARC can supply replacement stickers.',
          'Other',
        ],
      },
    ],
    checkValves: [
      {
        title: 'SERVICEABLE COMPONENT: 2 inch check valves',
        options: [
          'POOR CONDITION - The 2 inch check valves are in poor condition and needs to be replaced - MineARC can supply replacement check valves.',
          'SEIZED - The 2 inch check valves were seized and the technician was unable to replace them during the service - Site to arrange for appropriate tradesperson to inspect and replace check valves.',
          'NON-OEM PART - The 2 inch check valves are a non-genuine aftermarket part - MineARC recommends replacing non-genuine parts with genuine MineARC OEM products that have been thoroughly tested for use in the emergency operation of this refuge chamber. Non-OEM check valves are commonly not weighted correctly for MineARC refuge chambers.',
          'Other',
        ],
      },
      {
        title: 'Check valve direction: ↑ UP',
        options: ['INCORRECT INSTALLATION - Technician has re-installed so that the arrow is facing up.', 'Other'],
      },
    ],
    structure: [
      {
        title: 'Refuge chamber positioned correctly on level ground and is clear of obstructions',
        options: [
          'NOT LEVEL - The refuge chamber is not positioned on level ground - Site to arrange for refuge chamber to be adjusted or moved to level ground.',
          'OBSTRUCTED - Access to the refuge chamber is obstructed - Site to arrange for obstruction to be cleared or refuge chamber to be moved.',
          'Other',
        ],
      },
      {
        title: 'General structure condition',
        options: [
          'DAMAGED - The refuge chamber shell has been damaged and needs to be repaired - Contact MineARC for assistance.',
          'DAMAGED VERANDA - The veranda over the strobe lights and siren has been damaged - Site to have appropriate tradesperson inspect and repair.',
          'CORRODED - The refuge chamber shell is heavily corroded - Contact MineARC for assistance.',
          'BLAST DOORS - The refuge chamber blast doors have been damaged - Contact MineARC for assistance.',
          'Other',
          'Battery box condition',
        ],
      },
      {
        title: 'Battery box condition',
        options: [
          'DAMAGED DOORS - The battery bank doors have been damaged - Contact MineARC for replacement battery doors.',
          'DAMAGED HINGES - The battery bank door hinges have been damaged - Contact MineARC for replacement battery door components.',
          'DAMAGED LATCH - The battery bank door latch has been damaged - Contact MineARC for replacement battery door components.',
          'Other',
        ],
        conditions: (c) => c.batteryQuantity !== 'None',
      },
      {
        title: 'Towing, lifting and tie-down point condition',
        options: [
          'DAMAGED - The lifting lugs have been damaged - Contact MineARC for assistance.',
          'DAMAGED - The dragging points have been damaged - Contact MineARC for assistance.',
          'DAMAGED - The tie-down points have been damaged - Contact MineARC for assistance.',
          'Other',
        ],
      },
    ],
    compressedAirFilterSystem: [
      {
        title: 'Flood protector',
        options: [
          'HOUSING DAMAGED - The filter housing has been damaged - MineARC can supply a replacement filter housing.',
          'PULL CLIP DAMAGED - The white CAMS housing locking clip has been damaged - MineARC can supply replacement CAMS locking clips.',
          'SLEEVE DAMAGED - The internal flood protector sleeve has been damaged - MineARC can supply a replacement sleeve.',
          'FLOAT BALL MISSING - The stainless steel float ball is missing from the flood protector sleeve - MineARC can supply replacement float ball.',
          'GROMMET - The flood protector grommet is missing or damaged - MineARC can supply replacement grommet.',
          'NOT APPLICABLE - This model refuge chamber does not have an external compressed air system.',
          'Other',
        ],
        conditions: (c) => ['CAMS', 'CAFS'].indexOf(get(c, 'airFilterTypeEnum.name')) > -1,
      },
      {
        title: 'SERVICEABLE COMPONENT: Pre-filter element',
        options: [
          'HOUSING DAMAGED - The filter housing has been damaged - MineARC can supply a replacement filter housing.',
          'PULL CLIP DAMAGED - The white CAMS housing locking clip has been damaged - MineARC can supply replacement CAMS locking clips.',
          'POOR FILTER CONDITION - The Pre-filter element is in poor condition and needs to be replaced - MineARC can supply replacement pre-filter element.',
          'NON-OEM PART - The Pre-filter element is a non-genuine aftermarket part - MineARC recommends replacing non-genuine parts with genuine MineARC OEM products that have been thoroughly tested for use in the emergency operation of this refuge chamber.',
          'NOT APPLICABLE - This model refuge chamber does not have an external compressed air system.',
          'Other',
        ],
        conditions: (c) => c.batteryQuantity !== 'None',
      },
      {
        title: 'SERVICEABLE COMPONENT: Coalescing filter element',
        options: [
          'HOUSING DAMAGED - The filter housing has been damaged - MineARC can supply a replacement filter housing.',
          'PULL CLIP DAMAGED - The white CAMS housing locking clip has been damaged - MineARC can supply replacement CAMS locking clips.',
          'POOR FILTER CONDITION - The Coalescing filter element is in poor condition and needs to be replaced - MineARC can supply replacement coalescing filter element.',
          'NON-OEM PART - The Coalescing filter element is a non-genuine aftermarket part - MineARC recommends replacing non-genuine parts with genuine MineARC OEM products that have been thoroughly tested for use in the emergency operation of this refuge chamber.',
          'NOT APPLICABLE - This model refuge chamber does not have an external compressed air system.',
          'Other',
        ],
        conditions: (c) => get(c, 'airFilterTypeEnum.name') !== 'None',
      },
      {
        title: 'SERVICEABLE COMPONENT: Absorption filter element',
        options: [
          'HOUSING DAMAGED - The filter housing has been damaged - MineARC can supply a replacement filter housing.',
          'PULL CLIP DAMAGED - The white CAMS housing locking clip has been damaged - MineARC can supply replacement CAMS locking clips.',
          'POOR FILTER CONDITION - The Absorption filter element is in poor condition and needs to be replaced - MineARC can supply replacement absorption filter element.',
          'NON-OEM PART - The Absorption filter is a non-genuine aftermarket part - MineARC recommends replacing non-genuine parts with genuine MineARC OEM products that have been thoroughly tested for use in the emergency operation of this refuge chamber.',
          'NOT APPLICABLE - This model refuge chamber does not have an external compressed air system.',
          'Other',
        ],
        conditions: (c) => get(c, 'airFilterTypeEnum.name') !== 'None',
      },
      {
        title: 'SERVICEABLE COMPONENT: 1 inch check valve',
        options: [
          'POOR CONDITION - The 1 inch check valve is in poor condition and needs to be replaced - MineARC can supply replacement check valve.',
          'NON-OEM PART - The 1 inch check valve is a non-genuine aftermarket part - MineARC recommends replacing non-genuine parts with genuine MineARC OEM products that have been thoroughly tested for use in the emergency operation of this refuge chamber.',
          'NOT APPLICABLE - This model refuge chamber does not have an external compressed air system.',
          'Other',
        ],
        conditions: (c) => get(c, 'airFilterTypeEnum.name') !== 'None',
      },
      {
        title: '1 inch check valve  direction: → INTO CHAMBER',
        options: [
          'INCORRECT INSTALLATION - Technician has re-installed so that the arrow is facing towards the chamber.',
          'Other',
        ],
        conditions: (c) => get(c, 'airFilterTypeEnum.name') !== 'None',
      },
      {
        title: 'Auto-drain condition',
        options: [
          'DAMAGED - 1x Auto drain has been damaged and needs to be replaced - MineARC can supply replacement auto drains.',
          'FAULTY - 1x Auto drain is faulty and needs to be replaced - MineARC can supply replacement auto drains.',
          'TAP FAULTY - 1x Auto drain manual release tap is faulty and needs to be replaced - MineARC can supply replacement auto drain taps.',
          'NOT APPLICABLE - This model refuge chamber does not have an external compressed air system.',
          'Other',
        ],
        conditions: (c) => get(c, 'airFilterTypeEnum.name') !== 'None',
      },
      {
        title: 'Air fitting condition',
        options: [
          'BALL VALVE FAULTY - The external 1 inch ball valve is faulty or damaged and needs to be replaced - MineARC can supply replacement ball valves.',
          'FITTINGS DAMAGED - The stainless steel 1 inch fittings have been damaged or in poor condition - MineARC can supply replacement fittings.',
          'NOT APPLICABLE - This model refuge chamber does not have an external compressed air system.',
          'Other',
        ],
        conditions: (c) => get(c, 'airFilterTypeEnum.name') !== 'None',
      },
      {
        title: 'General air system condition / Check system for leaks',
        options: [
          'MANIFOLD DAMAGED - The CAMS manifold is damaged or in poor condition and needs to be replaced - MineARC can supply replacement CAMS manifold.',
          'AIR LEAK - There is a leak in the air fittings - MineARC can supply replacement stainless steel fittings as required.',
          'NON-OEM PART - The filter system is non-genuine - MineARC recommends replacing non-genuine parts with genuine MineARC OEM products that have been thoroughly tested for use in the emergency operation of this refuge chamber.',
          'UNABLE TO TEST - The technician was unable to test the filter system for leaks due to the compressed air not being available during the service.',
          'NOT APPLICABLE - This model refuge chamber does not have an external compressed air system.',
          'Other',
        ],
        conditions: (c) => get(c, 'airFilterTypeEnum.name') !== 'None',
      },
    ],
    airConditionerOutDoorUnit: [
      {
        title: 'Air conditioner manufacturer',
        type: 'AC_MANUFACTURE_SELECTION',
        key: 'manufacturer',
      },
      {
        title: 'Air conditioner capacity',
        type: 'TEXT_FIELD',
        key: 'capacity',
      },
      {
        title: 'Condenser condition',
        options: [
          'DAMAGED - The external air conditioner condenser has been damaged - Site to have electrician / fridge mechanic inspect.',
          'INCORRECT KW - The air conditioner cooling capacity is rated either too high or too low for this model refuge chamber - Contact MineARC for assistance.',
          'REQUIRES CLEANING - The external air conditioner condenser is full of dust and needs to be cleaned out - Site to arrange for condenser to be cleaned.',
          'DAMAGED STRAP - The air conditioner securing strap is damaged - MineARC can supply new strap if required.',
          'Other',
        ],
      },
      {
        title: 'Copper pipe condition',
        options: [
          'DAMAGED - The air conditioner copper piping has been damaged. The air conditioner will likely have to be re-gassed or replaced - Site to have electrician / fridge mechanic inspect.',
          'Other',
        ],
      },
      {
        title: 'External condensation drain condition',
        options: [
          'DAMAGED - The external condensation drain (metal) fittings have been damaged or in poor condition - MineARC can supply replacement fittings.',
          'DAMAGED PVC - The external condensation drain (PVC) fittings have been damaged or in poor condition - MineARC can supply replacement PVC fittings.',
          'Other',
        ],
      },
      {
        title: 'SERVICEABLE COMPONENT: 1/2 inch check valve condition',
        options: [
          'POOR CONDITION - The 1/2 inch check valve is in poor condition and needs to be replaced - MineARC can supply replacement check valve.',
          'NON-OEM PART - The 1/2 inch check valve is a non-genuine aftermarket part - MineARC recommends replacing non-genuine parts with genuine MineARC OEM products that have been thoroughly tested for use in the emergency operation of this refuge chamber.',
          'Other',
        ],
      },
      {
        title: '1/2 inch check valve direction: ↓ DOWN',
        options: ['INCORRECT INSTALLATION - Technician has re-installed so that the arrow is facing down.'],
      },
    ],
    transformer: [
      {
        title: 'Transformer condition',
        options: [
          'DAMAGED - The transformer has been damaged - Site to arrange for electrician to inspect. MineARC can supply replacement transformer if required, contact MineARC for assistance.',
          'FAULTY - The transformer is faulty - Site to arrange for electrician to inspect. MineARC can supply replacement transformer if required, contact MineARC for assistance.',
          'Other',
        ],
        conditions: (c) => c.transformer === true,
      },
      {
        title: 'Transformer voltage',
        type: 'TEXT_FIELD',
        key: 'voltage',
        conditions: (c) => c.transformer === true,
      },
      {
        title: 'Transformer sealing glands',
        options: ['Other'],
        conditions: (c) => c.transformer === true,
      },
      {
        title: 'Electrical enclosure and connections condition',
        options: ['Other'],
        conditions: (c) => c.transformer === true,
      },
    ],
    communicationsBox: [
      {
        title: 'Communications box condition',
        options: [
          'POOR CONDITION - The communications box has been damaged or in poor condition and needs to be replaced - MineARC can supply replacement communications box.',
          'Other',
        ],
        conditions: (c) => c.communicationsBox === true,
      },
      {
        title: 'Internal / External sealing glands',
        options: [
          'INTERNAL - The internal communications box glands are in poor condition and need to be replaced - MineARC can supply replacement glands.',
          'EXTERNAL - The external communications box glands are in poor condition and need to be replaced - MineARC can supply replacement glands.',
          'Other',
        ],
        conditions: (c) => c.communicationsBox === true,
      },
    ],
  },
  refugeChamberInternal: {
    internalLightingAndMotionSensor: [
      {
        title: 'Interior lighting - Fluorescent',
        options: [
          'BLOWN GLOBE - 1x Fluorescent light globe blown - MineARC can supply replacement globe.',
          'FAULTY FITTING - 1x Fluorescent light fitting is faulty and needs to be replaced - MineARC can supply replacement fluoro light fitting.',
          'Other',
        ],
        conditions: (c) => get(c, 'interiorLightingTypeEnum.name') === 'FLUORESCENT',
      },
      {
        title: 'Interior lighting - LED',
        options: [
          'FAULTY LED FITTING - 1x LED light fitting is faulty and needs to be replaced - MineARC can supply replacement LED light fitting.',
          'Other',
        ],
        conditions: (c) => get(c, 'interiorLightingTypeEnum.name') === 'LED',
      },
      {
        title: 'Motion sensor',
        options: [
          'FAULTY - The motion sensor is faulty and needs to be replaced - MineARC can supply replacement motion sensor.',
          'Other',
        ],
        conditions: (c) => ['SI', 'SII', 'SIII', 'SIV', 'Multi-purpose'].indexOf(get(c, 'scrubberTypeEnum.name')) > -1,
      },
    ],
    internalSignage: [
      {
        title: 'Emergency procedure stickers',
        options: [
          'INCORRECT - The emergency procedure stickers do not match the specific equipment in the refuge chamber and MUST be replaced - MineARC can supply replacement sticker kits.',
          'POOR CONDITION - The emergency procedure stickers are in poor condition and need to be replaced - MineARC can supply replacement sticker kits.',
          'MISSING - Parts of the emergency procedure stickers are missing and need to be replaced - MineARC can supply replacement sticker kits.',
          'Other',
        ],
      },
      {
        title: 'General internal signage condition',
        options: [
          'POOR CONDITION - The internal stickers are in poor condition - MineARC can supply replacement stickers kits.',
          'MISSING - Parts of the internal signage is missing from the refuge chamber - MineARC can supply replacement sticker kits.',
          'Other',
        ],
      },
    ],
    manuals: [
      {
        title: 'Photographic operating procedures',
        options: [
          'INCORRECT - The photographic operating procedures manual does not match the specific equipment in the refuge chamber and MUST be replaced - MineARC can supply replacement manuals.',
          'POOR CONDITION - The photographic operating procedure manual is in poor condition and needs to be replaced - MineARC can supply replacement manuals.',
          'MISSING - The photographic operating procedure manual is missing and needs to be replaced - MineARC can supply replacement manuals.',
          'Other',
        ],
      },
      {
        title: 'General overview and operating procedures',
        options: [
          'INCORRECT - The general overview and operating procedure manual does not match the specific equipment in the refuge chamber and MUST be replaced - MineARC can supply replacement manuals.',
          'POOR CONDITION - The general overview and operating procedure manual is in poor condition and needs to be replaced - MineARC can supply replacement manuals.',
          'MISSING - The general overview and operating procedure manual is missing and needs to be replaced - MineARC can supply replacement manuals.',
          'Other',
        ],
      },
      {
        title: 'Inspection and maintenance procedures',
        options: [
          'INCORRECT - The inspection and maintenance procedure manual does not match the specific equipment in the refuge chamber and MUST be replaced - MineARC can supply replacement manuals.',
          'POOR CONDITION - The inspection and maintenance procedure manual is in poor condition and needs to be replaced - MineARC can supply replacement manuals.',
          'MISSING - The inspection and maintenance procedure manual is missing and needs to be replaced - MineARC can supply replacement manuals.',
          'Other',
        ],
      },
      {
        title: 'Controller manual',
        options: [
          'INCORRECT - The controller manual does not match the specific equipment in the refuge chamber and MUST be replaced - MineARC can supply replacement manuals.',
          'POOR CONDITION - The controller manual is in poor condition and needs to be replaced - MineARC can supply replacement manuals.',
          'MISSING - The controller manual is missing and needs to be replaced - MineARC can supply replacement manuals.',
          'Other',
        ],
        conditions: (c) => get(c, 'scrubberTypeEnum.name') === 'None',
      },
      {
        title: 'Safety Data Sheets (SDS)',
        options: [
          'EXPIRED - The Safety Data Sheets have expired (SDS have an expiration date of 5 years) - MineARC can supply replacement SDS.',
          'MISSING - The Safety Data Sheets (SDS) are missing - MineARC can supply replacement SDS.',
          'Other',
        ],
      },
      {
        title: 'Additional manuals',
        options: ['Other'],
      },
    ],
    internalCompressedAirSystem: [
      {
        title: 'CAMS calibration - MineARC Technician Only',
        options: ['CLIENT TECHNICIAN - Unable to calibrate - Contact MineARC for assistance.'],
        conditions: (c) => get(c, 'airFilterTypeEnum.name') === 'CAMS',
      },
      {
        title: 'CAMS general operation',
        options: [
          'ALARMING - The CAMS ECU is alarming - Contact MineARC for assistance.',
          'UNKNOWN FAULT - The CAMS system is not operating correctly (unknown reason) - Contact MineARC for assistance.',
          'Other',
        ],
        conditions: (c) => get(c, 'airFilterTypeEnum.name') === 'CAMS',
      },
      {
        title: 'Solenoid condition',
        options: [
          'FAULTY - The CAMS solenoid is faulty and needs to be replaced - MineARC can supply replacement solenoid.',
          'UNABLE TO TEST - The technician was unable to test the CAMS solenoid operation due to the compressed air being unavailable during the service.',
          'Other',
        ],
        conditions: (c) => get(c, 'airFilterTypeEnum.name') === 'CAMS',
      },
      {
        title: "Test 'Occupied mode' button",
        options: ['Other'],
        conditions: (c) => get(c, 'airFilterTypeEnum.name') === 'CAMS',
      },
      {
        title: 'Oxygen (O2) Sensor condition',
        options: [
          'O2 SENSOR FAULTY - The CAMS O2 sensor is faulty and needs to be replaced - MineARC can supply replacement solenoid.',
          'REQUIRES CALIBRATION - The CAMS O2 sensor requires calibration by a MineARC technician - Contact MineARC for assistance.',
          'O2 SENSOR FAULTY (SHUT DOWN) - The CAMS oxygen sensor is faulty. The CAMS system has been powered down until the sensor can be replaced. The CAMS is currently operating as a standard manual compressed air system; this does not affect the refuge chamber emergency operation procedure - MineARC can supply replacement oxygen sensor.',
          'Other',
        ],
        conditions: (c) => get(c, 'airFilterTypeEnum.name') === 'CAMS',
      },
      {
        title: 'Ball valve operation',
        options: [
          'FAULTY - The internal compressed air ball valve is faulty and needs to be replaced - MineARC can supply replacement ball valve.',
          'DOES NOT CLOSE - The internal compressed air ball valve does not completely isolate the compressed air supply and MUST be replaced  - MineARC can supply replacement ball valve.',
          'UNABLE TO TEST - The technician was unable to test the ball valve operation due to the compressed air being unavailable during the service.',
          'NOT APPLICABLE - This model refuge chamber does not have an external compressed air system.',
          'Other',
        ],
        conditions: (c) => get(c, 'airFilterTypeEnum.name') === 'CAMS',
      },
      {
        title: 'Regulator condition',
        options: [
          'FAULTY - The compressed air regulator is faulty and needs to be replaced - MineARC can supply replacement regulator.',
          'DAMAGED - The compressed air regulator has been damaged and needs to be replaced - MineARC can supply replacement regulator.',
          'UNABLE TO TEST - The technician was unable to test regulator operation due to the compressed air being unavailable during the service.',
          'NOT APPLICABLE - This model refuge chamber does not have an external compressed air system.',
          'Other',
        ],
        conditions: (c) => get(c, 'airFilterTypeEnum.name') === 'CAMS',
      },
      {
        title: 'Set regulator flow rate (3 CFM per person)',
        options: [
          'FAULTY REGULATOR - The flow rate could not be adjusted due to the regulator being faulty - MineARC can supply replacement regulator.',
          'UNABLE TO ADJUST - The technician was unable to set the regulator flow rate due to the compressed air being unavailable during the service.',
          'NOT APPLICABLE - This model refuge chamber does not have an external compressed air system.',
          'Other',
        ],
      },
      {
        title: 'SERVICEABLE COMPONENT: Auto muffler',
        options: [
          'DAMAGED - The auto muffler is damaged and needs to be replaced - MineARC can supply replacement auto muffler.',
          'POOR CONDITION - The auto muffler is in poor condition and needs to be replaced - MineARC can supply replacement auto muffler.',
          'NON-OEM PART - The auto muffler is a non-genuine aftermarket part - MineARC recommends replacing non-genuine parts with genuine MineARC OEM products that have been thoroughly tested for use in the emergency operation of this refuge chamber.',
          'NOT APPLICABLE - This model refuge chamber does not have an external compressed air system.',
          'Other',
        ],
      },
      {
        title: 'CAMS manual',
        options: [
          'INCORRECT - The controller manual does not match the specific equipment in the refuge chamber and MUST be replaced - MineARC can supply replacement manuals.',
          'POOR CONDITION - The controller manual is in poor condition and needs to be replaced - MineARC can supply replacement manuals.',
          'MISSING - The controller manual is missing and needs to be replaced - MineARC can supply replacement manuals.',
          'Other',
        ],
        conditions: (c) => get(c, 'airFilterTypeEnum.name') === 'CAMS',
      },
    ],
    internalFireBlanketOrExtinguisher: [
      {
        title: 'Internal fire blanket',
        options: [
          'OUT OF DATE - The internal fire blanket is due for testing - Site to arrange inspection.',
          'MISSING - The internal fire blanket is missing from the refuge chamber - MineARC can supply replacement fire blanket.',
          'POOR CONDITION - The internal fire blanket is in poor condition - MineARC can supply replacement fire blanket.',
          'Other',
        ],
        conditions: (c) => c.fireBlanket === true,
      },
      {
        title: 'DATE - Internal fire blanket last test date',
        type: 'DATE_FIELD',
        key: 'fireBlanketLastTestDate',
        conditions: (c) => c.fireBlanket === true,
      },
      {
        title: 'Internal fire extinguisher',
        options: [
          'OUT OF DATE - The internal fire extinguisher is due for testing - Site to arrange inspection.',
          'MISSING - The internal fire extinguisher is missing from the refuge chamber - Site to arrange replacement, alternatively MineARC can supply replacement fire blanket.',
          'DAMAGED - The internal fire extinguisher has been damaged - Site to arrange replacement, alternatively MineARC can supply replacement fire blanket.',
          'LOW PRESSURE - The internal fire extinguisher pressure gauge shows low pressure - Site to arrange replacement, alternatively MineARC can supply replacement fire blanket.',
          'Other',
        ],
        conditions: (c) => c.internalFireExtinguisher === true,
      },
      {
        title: 'DATE - Internal fire extinguisher last test date',
        type: 'DATE_FIELD',
        key: 'fireExtinguisherLastTestDate',
        conditions: (c) => c.internalFireExtinguisher === true,
      },
    ],
    statsAndFlooring: [
      {
        title: 'Seat condition',
        options: [
          'POOR CONDITION - The chamber seating is in poor condition and needs to be replaced - MineARC can supply replacement seating.',
          'Other',
        ],
      },
      {
        title: 'Floor  condition',
        options: [
          'POOR CONDITION - The floor is in poor condition - MineARC can supply replacement if required.',
          'Other',
        ],
      },
    ],
    toilet: [
      {
        title: 'Toilet condition',
        options: [
          'DAMAGED - The toilet has been damaged and needs to be replaced - MineARC can supply replacement toilet.',
          'MISSING - The toilet is missing from the refuge chamber and needs to be replaced - MineARC can supply replacement toilet.',
          'Other',
        ],
        conditions: (c) => c.toilet === true,
      },
      {
        title: 'Toilet deodoriser chemical available',
        options: [
          'DEODORISER MISSING - The chemical toilet deodoriser is missing and needs to be replaced - MineARC can supply replacement toilet deodoriser.',
          'Other',
        ],
        conditions: (c) => c.toilet === true,
      },
      {
        title: 'Toilet deoderiser chemical expiry',
        type: 'DATE_FIELD',
        key: 'chemicalExpiry',
        conditions: (c) => c.toilet === true,
      },
      {
        title: 'Toilet paper available',
        options: [
          'TOILET PAPER MISSING - There is not toilet paper available in the refuge chamber - Site to arrange replacement toilet paper.',
          'Other',
        ],
        conditions: (c) => c.toilet === true,
      },
    ],
    communicationSystems: [
      {
        title: 'Test radio or phone operation',
        options: [
          'RADIO FAULTY - The radio is faulty and needs to be replaced - Site to arrange inspection/replacement.',
          'RADIO MISSING - The radio is missing from the refuge chamber - Site to arrange replacement.',
          'PHONE FAULTY - The phone is faulty and needs to be replaced - Site to arrange inspection/replacement.',
          'PHONE MISSING - The phone is missing from the refuge chamber - Site to arrange replacement.',
          'MOUNTED INCORRECTLY - The radio/phone is installed in the wrong position and is impeding scrubber operation - Site to arrange for radio/phone to be repositioned.',
          'Other',
        ],
      },
    ],
    airConditionerIndoorUnit: [
      {
        title: 'SERVICEABLE COMPONENT: Air conditioner remote batteries',
        options: [
          'LOW BATTERIES - The air conditioner remote control batteries have low charge and need to be replaced. Air conditioning is a critical requirement of the refuge chamber - Site to arrange replacement remote control batteries.',
          'MISSING - The air conditioner remote control batteries are missing and need to be replaced. Air conditioning is a critical requirement of the refuge chamber - Site to arrange replacement remote control batteries.',
          'Other',
        ],
      },
      {
        title: 'Air conditioner remote control operation',
        options: [
          'FAULTY - The air conditioner remote control is faulty and MUST be replaced. Air conditioning is a critical requirement of the refuge chamber - Site to arrange replacement. MineARC can supply a replacement remote control for Mitsubishi units.',
          'MISSING - The air conditioner remote control is missing and MUST be replaced. Air conditioning is a critical requirement of the refuge chamber - Site to arrange replacement. MineARC can supply a replacement remote control for Mitsubishi units.',
          'Other',
        ],
      },
      {
        title: 'Air conditioner indoor unit condition',
        options: [
          'DAMAGED - The air conditioner head unit is damaged and needs to be replaced.  Air conditioning is a critical requirement of the refuge chamber - Site to arrange for electrician / fridge mechanic to repair or replace air conditioner. MineARC can supply replacement air conditioner if required.',
          'FAULTY - The air conditioner is faulty and needs to be replaced.  Air conditioning is a critical requirement of the refuge chamber - Site to arrange for electrician / fridge mechanic to repair or replace air conditioner. MineARC can supply replacement air conditioner if required.',
          'LOOSE MOUNT - The air conditioner head unit is not mounted correctly - Site to arrange inspection and repair.',
          'Other',
        ],
      },
      {
        title: 'Internal condensation drain condition',
        options: [
          'DAMAGED - The air conditioner internal condensation drain has been damaged or in poor condition - Site to arrange repair, MineARC can supply replacement PVC fittings if required.',
          'LEAKING - The air conditioner internal condensation drain is leaking - Site to arrange repair, MineARC can supply replacement PVC fittings if required.',
          'Other',
        ],
      },
      {
        title: 'CRITICAL - IS THE AIR CONDITIONER COOLING EFFECTIVELY?',
        options: [
          'NOT COOLING - The air conditioner is not cooling effectively. Air conditioning is a critical requirement of the refuge chamber - Site to arrange for electrician / fridge mechanic to repair or replace air conditioner. MineARC can supply replacement air conditioner if required.',
          'Other',
        ],
      },
    ],
    scrubberSystem: [
      {
        title: 'Scrubber chemical tray condition',
        options: [
          'CARTRIDGE TRAY CONDITION - The cartridge style chemical tray is damaged or in poor condition and needs to be replaced - MineARC can supply a replacement plenum tray.',
          'CO2 TRAY CONDITION - The WHITE freepour Carbon Dioxide chemical tray is damaged or in poor condition and needs to be replaced - MineARC can supply a plenum tray (cartridge) upgrade kit for this refuge chamber, as the free-pour chemical is no longer available.',
          'MOISTURE REMOVAL TRAY CONDITION - The BLUE freepour moisture removal chemical tray is damaged or in poor condition and needs to be replaced - MineARC can supply a plenum tray (cartridge) upgrade kit for this refuge chamber, as the free-pour chemical is no longer available.',
          'CO TRAY CONDITION - The BLACK freepour carbon monoxide chemical tray is damaged or in poor condition and needs to be replaced - MineARC can supply a plenum tray (cartridge) upgrade kit for this refuge chamber, as the free-pour chemical is no longer available.',
          'Other',
        ],
        conditions: (c) => get(c, 'scrubberTypeEnum.name') !== 'None',
      },
      {
        title: 'Diverter cover installation',
        options: ['Other'],
        conditions: (c) => get(c, 'scrubberTypeEnum.name') !== 'None',
      },
      {
        title: 'Test scrubber operation',
        options: [
          'CO2 FAN FAULTY - The Carbon Dioxide (CO2) fan is faulty and needs to be replaced - MineARC can supply replacement scrubber fans.',
          'CO FAN FAULTY - The Carbon Monoxide (CO) fan is faulty and needs to be replaced - MineARC can supply replacement scrubber fans.',
          'ELVP FAN FAULTY - The single ELVP scrubber fan is faulty and needs to be replaced - MineARC can supply replacement scrubber fans.',
          'Other',
        ],
        conditions: (c) => get(c, 'scrubberTypeEnum.name') !== 'None',
      },
      {
        title: 'Run controller self-test and record any fault codes if alarming',
        options: ['Other'],
        conditions: (c) => ['SI', 'SIII', 'SIV'].indexOf(get(c, 'scrubberTypeEnum.name')) > -1,
      },
      {
        title: 'Battery isolator / selector switch',
        options: [
          'BATT ISOLATOR FAULTY - The battery isolator switch is faulty and needs to be replaced - MineARC can supply replacement isolator switch.',
          'BATT SELECTOR FAULTY - The battery selector switch is faulty and needs to be replaced - MineARC can supply replacement battery selector switch.',
          'Other',
        ],
        conditions: (c) => ['SI', 'SIII', 'SIV', 'ELV'].indexOf(get(c, 'scrubberTypeEnum.name')) > -1,
      },
      {
        title: 'Inverter operation',
        options: [
          'INVERTER FAULTY - The inverter is faulty and needs to be replaced - MineARC can supply replacement inverter.',
          'Other',
        ],
        conditions: (c) => get(c, 'scrubberTypeEnum.name') !== 'None',
      },
      {
        title: 'Voice prompt instructions (if applicable - Series 4 models only)',
        options: [
          'FAULTY - The voice prompt instructions are not operating correctly - Contact MineARC for assistance.',
          'Other',
        ],
        conditions: (c) => get(c, 'scrubberTypeEnum.name') === 'SIV',
      },
    ],
    escapeHatch: [
      {
        title: 'Escape hatch general condition',
        options: [
          'DAMAGED - The escape hatch has been damaged or in poor condition and needs to be replaced - MineARC can supply a replacement escape hatch. Contact MineARC for assistance.',
          'Other',
        ],
      },
      {
        title: 'Confirm escape hatch handles are tight',
        options: ['Other'],
      },
      {
        title: 'Escape hatch seal condition - Visual inspection only, do not open escape hatch',
        options: [
          'DAMAGED - The escape hatch seal is damaged or in poor condition and needs to be replaced - MineARC can supply replacement escape hatch neoprene seal.',
          'Other',
        ],
      },
    ],
    vaccumTest: [
      {
        title: 'Perform vacuum check',
        options: [
          'FAILED - The vacuum test failed due to an unidentified leak in the refuge chamber - Contact MineARC for assistance.',
          'UNABLE TO TEST - The vacuum test could not be performed due to the air conditioner being faulty - Site to repair air conditioner as soon as possible and re-test chamber for leaks.',
          'Other',
        ],
      },
    ],
  },
  consumables: {
    freePourScrubberChemicals: [
      {
        title: 'CO2 quantity',
        options: [],
        conditions: (c) => c.co2ChemicalQuantity !== 'None',
        subHeaderProvider: (c) => `Expected amount: ${c.co2ChemicalQuantity} cartridges`,
      },
      {
        title: 'CO2 expiry',
        type: 'DATE_FIELD',
        key: 'co2Expiry',
        conditions: (c) => c.co2ChemicalQuantity !== 'None',
      },
      {
        title: 'CO quantity',
        options: [],
        conditions: (c) => c.coChemicalQuantity !== 'None',
        subHeaderProvider: (c) => `Expected amount: ${c.coChemicalQuantity} cartridges`,
      },
      { title: 'CO expiry', type: 'DATE_FIELD', key: 'coExpiry', conditions: (c) => c.coChemicalQuantity !== 'None' },
      {
        title: 'Moisture removal quantity',
        options: [],
        conditions: (c) => c.moistureRemovalQuantity !== 'None',
        subHeaderProvider: (c) => `Expected amount: ${c.moistureRemovalQuantity} cartridges`,
      },
      {
        title: 'Moisture removal expiry',
        type: 'DATE_FIELD',
        key: 'moistureRemovalExpiry',
        conditions: (c) => c.moistureRemovalQuantity !== 'None',
      },
    ],
    cartridgeScrubberChemicals: [
      {
        title: 'CO2 quantity',
        options: [],
        conditions: (c) => c.co2ChemicalQuantity !== 'None',
        subHeaderProvider: (c) => `CO2 Quantity: ${c.co2ChemicalQuantity}`,
      },
      {
        title: 'CO2 expiry',
        type: 'DATE_FIELD',
        key: 'co2Expiry',
        conditions: (c) => c.co2ChemicalQuantity !== 'None',
      },
      {
        title: 'CO quantity',
        options: [],
        conditions: (c) => c.coChemicalQuantity !== 'None',
        subHeaderProvider: (c) => `CO Quantity: ${c.coChemicalQuantity}`,
      },
      { title: 'CO expiry', type: 'DATE_FIELD', key: 'coExpiry', conditions: (c) => c.coChemicalQuantity !== 'None' },
    ],
    auraFXGasMonitoring: [
      { title: 'Master board software version', type: 'TEXT_FIELD', key: 'masterBoardSoftwareVersion' },
      { title: 'Display board software version', type: 'TEXT_FIELD', key: 'displayBoardSoftwareVersion' },
      ...getAuraFxGasSensorQuestions('O2'),
      ...getAuraFxGasSensorQuestions('CO'),
      ...getAuraFxGasSensorQuestions('CO2'),
      ...getAuraFxGasSensorQuestions('H2S'),
      ...getAuraFxGasSensorQuestions('NH3'),
      ...getAuraFxGasSensorQuestions('CI'),
      ...getAuraFxGasSensorQuestions('CH4'),
      ...getAuraFxGasSensorQuestions('NO'),
      ...getAuraFxGasSensorQuestions('NO2'),
      ...getAuraFxGasSensorQuestions('SO2'),
      ...getAuraFxGasSensorQuestions('TEMP'),
    ],
    digitalGasMonitor: [
      { title: 'Brand/Model', type: 'TEXT_FIELD', key: 'model' },
      { title: 'Serial number', type: 'TEXT_FIELD', key: 'serialNumber' },
      { title: 'Next calibration due', type: 'DATE_FIELD', key: 'nextCalibrationDate' },
    ],
    manualGasSamplingPump: [
      {
        title: 'Oxygen (O2) gas tubes quantity',
        type: 'TEXT_FIELD',
        key: 'o2GasTubesQuantity',
        conditions: (c) => c.o2TubeQuantity !== 'None',
        subHeaderProvider: (c) => `Quantity: ${c.o2TubeQuantity}`,
      },
      {
        title: 'Oxygen (O2) gas tubes expiry',
        type: 'DATE_FIELD',
        key: 'o2GasTubesExpiry',
        conditions: (c) => c.o2TubeQuantity !== 'None',
      },
      {
        title: 'Carbon Dioxide (CO2) gas tubes quantity',
        type: 'TEXT_FIELD',
        key: 'co2GasTubesQuantity',
        conditions: (c) => c.co2TubeQuantity !== 'None',
        subHeaderProvider: (c) => `Quantity: ${c.co2TubeQuantity}`,
      },
      {
        title: 'Carbon Dioxide (CO2) gas tubes expiry',
        type: 'DATE_FIELD',
        key: 'co2GasTubesExpiry',
        conditions: (c) => c.co2TubeQuantity !== 'None',
      },
      {
        title: 'Carbon Monoxide (CO) gas tubes quantity',
        type: 'TEXT_FIELD',
        key: 'coGasTubesQuantity',
        conditions: (c) => c.coTubeQuantity !== 'None',
        subHeaderProvider: (c) => `Quantity: ${c.coTubeQuantity}`,
      },
      {
        title: 'Carbon Monoxide (CO) gas tubes expiry',
        type: 'DATE_FIELD',
        key: 'coGasTubesExpiry',
        conditions: (c) => c.coTubeQuantity !== 'None',
      },
    ],
    oxygenRegulators: [
      {
        title: 'Oxygen regulator condition',
        options: [
          'DAMAGED - The oxygen regulator is damaged or in poor condition and needs to be replaced - MineARC can supply replacement oxygen regulators.',
          'MISSING - The oxygen regulator is missing and needs to be replaced - MineARC can supply replacement oxygen regulators.',
          'ONLY 1 - There is only 1x oxygen regulator available in the refuge chamber. MineARC recommends having a spare regulator when possible - MineARC can supply replacement oxygen regulators.',
          'Other',
        ],
        conditions: (c) => c.medicalOxygenCylinders !== 'None',
      },
      {
        title: 'Confirm oxygen regulator and cylinder fittings are compatible',
        options: [
          'DOES NOT MATCH - The oxygen regulator does not match the oxygen cylinder fitting - MineARC can supply replacement oxygen regulators. Contact MineARC for assistance.',
          'Other',
        ],
        conditions: (c) => c.medicalOxygenCylinders !== 'None',
      },
      {
        title: 'Rubber (nitrile) gloves present',
        options: [
          'MISSING - There is no rubber gloves present for use with the oxygen regulator - MineARC can supply replacement rubber glove pack.',
          'Other',
        ],
        conditions: (c) => c.medicalOxygenCylinders !== 'None',
      },
      {
        title:
          'If the oxygen regulator requires a specific tool to be fitted, confirm the tool is present in the refuge chamber (if applicable)',
        options: [
          'MISSING - The tool required for fitting the oxygen regulator to the cylinders is missing - Site to arrange for required tool to be made available in the refuge chamber.',
          'Other',
        ],
      },
    ],
    medicalOxygenCylinders: [
      {
        title:
          'Confirm total oxygen quantity is correct (Minimum O2 litres required = Total occupants x 0.5 x 60 x emergency duration)',
        options: [
          "O2 QUANTITY INCORRECT - There is not enough medical oxygen available in the refuge chamber. Refer to the refuge chamber 'General overview & operation procedures' manual for required quantities or contact MineARC for assistance.",
          'Other',
        ],
        conditions: (c) => c.medicalOxygenCylinders !== 'None',
      },
      {
        title: 'Confirm all oxygen cylinders are full',
        options: [
          '1x CYLINDER NOT FULL - 1x Medical oxygen cylinder is not full - Site to arrange for oxygen cylinder to be replaced.',
          'MULTIPLE CYLINDERS NOT FULL - Multiple oxygen cylinders are not full - Site to arrange for oxygen cylinders to be replaced.',
          'Other',
        ],
        conditions: (c) => c.medicalOxygenCylinders !== 'None',
      },
      {
        title: 'Confirm all oxygen cylinders are secured safely',
        options: [
          'NOT SECURE - The medical oxygen cylinders are not secured - Site to ensure all cylinders are secured safely.',
          'Other',
        ],
        conditions: (c) => c.medicalOxygenCylinders !== 'None',
      },
      { title: 'Hydrostatic test date', type: 'DATE_FIELD', key: 'testDate' },
    ],
    oxygenCandle: [
      {
        title: 'Oxygen candle condition',
        options: [
          'EXPIRED - The oxygen candle has expired - MineARC can supply replacement oxygen candle.',
          'DAMAGED - The oxygen candle has been damaged / contaminated - MineARC can supply replacement oxygen candle.',
          'SEAL NOT INTACT - The oxygen candle seal has been opened - MineARC can supply replacement oxygen candle.',
          'MISSING - The oxygen candle is missing from the refuge chamber - MineARC can supply replacement oxygen candle.',
          'NOT APPLICABLE - This refuge chamber does not use an oxygen candle.',
          'Other',
        ],
        conditions: (c) => get(c, 'oxygenCandle') === true,
      },
      {
        title: 'Oxygen candle expiry',
        type: 'DATE_FIELD',
        key: 'oxygenCandleExpiry',
        conditions: (c) => get(c, 'oxygenCandle') === true,
      },
      {
        title: 'Candle holder',
        options: [
          'MISSING - The oxygen candle holder is missing from the refuge chamber - MineARC can supply replacement oxygen candle holder.',
          'DAMAGED - The oxygen candle holder has been damaged and needs to be replaced - MineARC can supply replacement oxygen candle holder.',
          'NOT APPLICABLE - This refuge chamber does not use an oxygen candle.',
          'Other',
        ],
        conditions: (c) => get(c, 'oxygenCandle') === true,
      },
      {
        title: 'Welding gloves available',
        options: [
          'MISSING - There are no welding gloves available to handle the oxygen candle - MineARC can supply replacement welding gloves if required.',
          'DAMAGED - The welding gloves are in poor condition and need to be replaced - MineARC can supply replacement welding gloves if required.',
          'NOT APPLICABLE - This refuge chamber does not use an oxygen candle.',
          'Other',
        ],
        conditions: (c) => get(c, 'oxygenCandle') === true,
      },
      {
        title: 'MPOG ignition match',
        options: [
          'EXPIRED - The oxygen candle MPOG match has expired - MineARC can supply replacement MPOG match.',
          'MISSING - The oxygen candle MPOG match is missing from the refuge chamber - MineARC can supply replacement MPOG match.',
          'OPENED - The MPOG match packaging has been opened. The unit has been prematurely exposed to air and cannot be guaranteed to work correctly in an emergency - MineARC can supply replacement MPOG match.',
          'NOT APPLICABLE - This refuge chamber does not use an oxygen candle.',
          'Other',
        ],
        conditions: (c) => get(c, 'oxygenCandle') === true,
      },
      {
        title: 'MPOG ignition match expiry',
        type: 'DATE_FIELD',
        key: 'mpogIgnitionMatchExpiry',
        conditions: (c) => get(c, 'oxygenCandle') === true,
      },
    ],
    firstAidWaterAndLongLifeFood: [
      {
        title: 'First aid kit',
        options: [
          'EXPIRED - The first aid kit is expired - Site to arrange replacement. MineARC can supply replacement first aid kit if required.',
          'MISSING - The first aid kit is missing - Site to arrange replacement. MineARC can supply replacement first aid kit if required.',
          'Other',
        ],
      },
      { title: 'First aid kit expiry ', type: 'DATE_FIELD', key: 'firstAidKitExpiry' },
      {
        title: 'Drinking water (recommended minimum of 1.5L per person, per 12 hour emergency duration)',
        subHeaderProvider: (c) => `Water Required: ${c.waterRequired || 'Not Set'}${c.waterRequired && 'L'}`,
        options: [
          'EXPIRED - The drinking water has expired - MineARC can supply replacement long-life water.',
          'NOT ENOUGH - There is not enough drinking water available in the refuge chamber. MineARC recommends at least 1.5L per person, per 12 hour duration. MineARC can supply  replacement long-life water.',
          'Other',
        ],
      },
      { title: 'Drinking water expiry', type: 'DATE_FIELD', key: 'drinkingWaterExpiry' },
      {
        title: 'Long-life food',
        options: [
          'EXPIRED - The long-life food has expired - MineARC can supply replacement long-life food if required.',
          'NOT ENOUGH - There is not enough long-life food available in the refuge chamber. MineARC recommends at least 2 packets per person. MineARC can supply replacement long-life food if required.',
          'Other',
          'Long-life food expiry',
        ],
        conditions: (c) => c.longLifeFood === true,
      },
      {
        title: 'Long-life food expiry',
        type: 'DATE_FIELD',
        key: 'longLifeFoodExpiry',
        conditions: (c) => c.longLifeFood === true,
      },
      {
        title: 'Additional Details',
        type: 'CONST',
        key: 'additionalDetails',
        defaultProvider: (c) => [`${c.waterRequired || 0} Litres required`]
      },
      {
        title: 'Additional Details Present',
        type: 'CONST',
        key: 'additionalDetailsPresent',
        defaultProvider: () => true
      }
    ],
    batteryVoltages: [
      {
        title: 'Battery Voltages',
        options: [],
      },
      {
        title: 'Battery Voltages',
        type: 'BATTERY_VOLTAGES',
        key: 'recordBatteryVoltages',
      },
    ],
    batterySystem: [
      {
        title: 'Batteries are the correct model (MineARC OEM 12v 220AH)',
        options: [
          'NON-GENUINE BATTERIES - The battery bank is made up of non-genuine batteries - MineARC recommends replacing non-genuine parts with genuine MineARC OEM products that have been thoroughly tested for use in the emergency operation of this refuge chamber.',
          'VEHICLE BATTERIES - The battery system is made up of vehicle starter batteries. The charger system is not designed for use with this battery type. THE BATTERY BANK MUST BE REPLACED AS SOON AS POSSIBLE - Contact MineARC for assistance.',
          'Other',
        ],
        conditions: (c) => c.batteryQuantity !== 'None',
      },
      {
        title: 'Battery condition',
        options: [
          'TERMINAL GREASE - The battery terminals require terminal grease to prevent corrosion - Site to arrange for battery terminals to be greased. MineARC can supply battery terminal grease if required.',
          'Other',
        ],
        conditions: (c) => c.batteryQuantity !== 'None',
      },
      {
        title: 'Battery cable condition',
        options: [
          'INCORRECT WIRING - The battery system has been wired up incorrectly - Contact MineARC for assistance.',
          'Other',
        ],
        conditions: (c) => c.batteryQuantity !== 'None',
      },
      {
        title: 'Batteries are secure',
        options: [
          'NOT SECURE - The batteries are not secured inside the battery cabinet - Site to ensure batteries are secure, contact MineARC for assistance.',
          'Other',
        ],
        conditions: (c) => c.batteryQuantity !== 'None',
      },
      {
        title: 'Battery fuse condition',
        options: ['Other'],
        conditions: (c) => c.batteryQuantity !== 'None',
      },
      {
        title: 'Battery probe condition',
        options: ['Other'],
        conditions: (c) => ['SIV'].indexOf(get(c, 'scrubberTypeEnum.name')) > -1,
      },
      {
        title: 'Inverter battery temperature probe condition',
        options: ['Other'],
        conditions: (c) =>
          ['SIII', 'SIV', 'ELV', 'ELVP', 'MULTI_PURPOSE'].indexOf(get(c, 'scrubberTypeEnum.name')) > -1,
      },
    ],
  },
  chamberOptions: {
    ppms: [
      {
        title: 'Test the manual push button on front of chamber',
        options: ['Other'],
        conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'PPMS'),
      },
      {
        title: 'Pressure display screen',
        options: ['Other'],
        conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'PPMS'),
      },
      {
        title: 'Compressed air cylinder check',
        options: [
          'NOT FULL - The PPMS compressed air cylinders are not at full capacity and need to be replaced - Site to arrange for compressed air cylinders to be replaced.',
          'NOT ENOUGH - There is not enough compressed air available for the PPMS system in this refuge chamber, this size refuge chamber requires additional cylinders - Site to arrange for additional compressed air cylinders to be installed, contact MineARC for assistance.',
          'Other',
        ],
        conditions: (c) => c.ppmsOxygenLevel !== 'None',
      },
      {
        title: 'Hydrostatic test date',
        options: ['Other'],
      },
      {
        title: 'Regulator condition - Optimal pressure: 10 psi / 0.68 bar',
        options: ['Other'],
        conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'PPMS'),
      },
      {
        title: 'Solenoid condition',
        options: [
          'FAULTY - The PPMS solenoid is faulty and needs to be replaced - MineARC can supply replacement solenoid.',
          'Other',
        ],
      },
      {
        title:
          'Test PPMS automatic ON/OFF function - Note: The auto ON/OFF function only operates while the scrubber is running in emergency mode',
        options: ['Other'],
        conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'PPMS'),
      },
    ],
    airlockSystem: [
      {
        title: 'General airlock system condition',
        options: ['Other'],
        conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'AIRLOCK'),
      },
      {
        title: 'Primary flushing system regulator condition',
        options: [
          'REGULATOR DAMAGED - The primary flushing regulator is damaged and needs to be replaced - MineARC can supply a replacement regulator.',
          'Other',
        ],
        conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'AIRLOCK'),
      },
      {
        title: 'Primary flushing system regulator CFM setting',
        type: 'TEXT_FIELD',
        key: 'flushingSystemRegulatorCFMSetting',
        conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'AIRLOCK'),
      },
      {
        title: 'SERVICEABLE COMPONENT: Primary flushing system auto muffler',
        options: [],
        conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'AIRLOCK'),
      },
      {
        title: 'BFS condition',
        options: [
          'NOT OPERATIONAL - BFS is not working correctly and needs to be fixed - MineARC technician can assist if required.',
          'LEAKING - BFS is leaking and needs to be fixed - MineARC technician can assist if required.',
          'Other',
        ],
        conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'AIRLOCK'),
      },
      {
        title: 'BFS cylinder pressure',
        type: 'TEXT_FIELD',
        key: 'bfsCylinderPressure',
        conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'AIRLOCK'),
      },
      {
        title: 'BFS regulator PSI',
        type: 'TEXT_FIELD',
        key: 'bfsRegulatorPSI',
        conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'AIRLOCK'),
      },
      {
        title: 'SERVICEABLE COMPONENT: Internal 2 inch check valves and vent caps',
        options: [
          'POOR CONDITION - The 2 inch check valves are in poor condition and needs to be replaced - MineARC can supply replacement check valves.',
          'SEIZED - The 2 inch check valves were seized and the technician was unable to replace them during the service - Site to arrange for appropriate tradesperson to inspect and replace check valves.',
          'NON-OEM PART - The 2 inch check valves are a non-genuine aftermarket part - MineARC recommends replacing non-genuine parts with genuine MineARC OEM products that have been thoroughly tested for use in the emergency operation of this refuge chamber. Non-OEM check valves are commonly not weighted correctly for MineARC refuge chambers.',
          'Other',
        ],
        conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'AIRLOCK'),
      },
      {
        title: 'Internal door handle condition',
        options: [
          'DAMAGED/SEIZED - 1x Door handle is damaged/seized and needs to be replaced - MineARC can supply replacement handles.',
          'DAMAGED/SEIZED - Both door handles are damaged/seized and need to be replaced - MineARC can supply replacement handles.',
          'TOO TIGHT - 1x Door handle is too tight and needs to be adjusted - Site to arrange for appropriate tradesperson to inspect and adjust door handle.',
          'TOO TIGHT - Both door handles are too tight and need to be adjusted - Site to arrange for appropriate tradesperson to inspect and adjust door handles.',
          'TOO LOOSE - 1x Door handle is too loose and needs to be adjusted - Site to arrange for appropriate tradesperson to inspect and adjust door handle.',
          'TOO LOOSE - Both door handles are too loose and need to be adjusted - Site to arrange for appropriate tradesperson to inspect and adjust door handles.',
          'LOOSE SLEEVE - 1x Door handle is loose inside the sleeve, causing a leak in the refuge chamber - MineARC can supply replacement door handles and sleeves.',
          'LOOSE SLEEVE - Both door handles are loose inside the sleeve, causing a leak in the refuge chamber - MineARC can supply replacement door handles and sleeves.',
          'Other',
        ],
        conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'AIRLOCK'),
      },
      {
        title: 'Internal door hinge condition',
        options: [
          'DAMAGED - 1x door hinge has been damaged - Site to arrange for appropriate tradesperson to inspect/repair. MineARC can supply replacement hinges if required.',
          'NEEDS ADJUSTMENT - 1x door hinge requires adjustment, the door cannot close flush against the door frame. Site to arrange appropriate tradesperson to inspect and adjust as required.',
          'NEEDS GREASING - The door hinges require greasing - Site to arrange for door hinges to be greased.',
          'Other',
        ],
        conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'AIRLOCK'),
      },
      {
        title: 'General internal door operation and condition',
        options: [
          'DAMAGED - The chamber door has been damaged - Site to arrange for appropriate tradesperson to inspect and repair door. MineARC can supply a replacement door if required.',
          'DAMAGED - The chamber door has been damaged beyond repair and needs to be replaced - MineARC can supply a replacement door, contact MineARC for assistance.',
          'DOES NOT CLOSE - The refuge chamber door does not close correctly - The refuge chamber should not be put into service until the door is repaired. Contact MineARC for assistance.',
          'Other',
        ],
        conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'AIRLOCK'),
      },
      {
        title: 'SERVICEABLE COMPONENT: Internal door seal',
        options: [
          'POOR CONDITION - The door seal is in poor condition and needs to be replaced - MineARC can supply replacement door seals.',
          'DAMAGED - The door seal has been damaged and needs to be replaced - MineARC can supply replacement door seals.',
          'INCORRECTLY FITTED - The door seal has been installed poorly and needs to be replaced - MineARC can supply replacement door seals.',
          'NON-OEM PART - The door seal is a non-genuine aftermarket part - MineARC recommends replacing non-genuine parts with genuine MineARC OEM products that have been thoroughly tested for use in the emergency operation of this refuge chamber.',
          'Other',
        ],
        conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'AIRLOCK'),
      },
      {
        title: 'Compressed air cylinders',
        options: [
          'NOT FULL - The airlock compressed air cylinders are not at full capacity and need to be replaced - Site to arrange for compressed air cylinders to be replaced.',
          'NOT ENOUGH - There is not enough compressed air available for the airlock in this refuge chamber, this size refuge chamber requires additional cylinders - Site to arrange for additional compressed air cylinders to be installed, contact MineARC for assistance.',
          'Other',
        ],
        conditions: (c) => c.airlockOxygenLevel !== 'None',
      },
      {
        title: 'Hydrostatic test date',
        options: ['Other'],
      },
      {
        title: 'Mine water connection checked',
        options: ['Other'],
        conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'AIRLOCK'),
      },
    ],
    guardIAN: [
      {
        title: 'Test Wi-Fi connection to GuardIAN',
        options: ['Other'],
      },
      {
        title: 'Confirm information is available over Wi-Fi',
        options: ['Other'],
      },
      {
        title: 'Is GuardIAN connected to the site network?',
        options: ['Other'],
      },
    ],
    additionalLanguages: [
      {
        title: 'Confirm additional instruction languages are correct',
        subHeaderProvider: (c) => `Additional Language: ${c.additionalLanguages}`,
        options: [
          'INCORRECT STICKERS - The additional language stickers (non-English) are incorrect and need to be replaced - MineARC can supply replacement sticker kits.',
          'INCORRECT MANUAL - The additional language manuals (non-English) are incorrect and need to be replaced - MineARC can supply replacement sticker kits.',
          'Other',
        ],
      },
    ],
    aods: [
      {
        title: 'AODS condition',
        options: ['Other'],
      },
    ],
    wheels: [
      {
        title: 'Wheel condition',
        options: ['Other'],
      },
    ],
    towBar: [
      {
        title: 'Tow bar condition',
        options: ['Other'],
      },
    ],
    blastResistance: [
      {
        title: 'Blast resistance condition',
        options: ['Other'],
      },
    ],
    fireResistance: [
      {
        title: 'Fire resistance condition',
        options: ['Other'],
      },
    ],
    thermalInsulation: [
      {
        title: 'Thermal insulation condition',
        options: ['Other'],
      },
    ],
    maceyPlug: [
      {
        title: 'Macey plug condition',
        options: ['Other'],
      },
    ],
    ppfs: [
      {
        title: 'PPFS condition',
        options: ['Other'],
      },
    ],
  },
};

export const questionMeta = {
  refugeChamberExternal: {
    _title: 'REFUGE CHAMBER EXTERNAL',
    visualChecks: { title: 'Visual Checks' },
    mainDoor: { title: 'Main Door' },
    window: { title: 'Window' },
    externalFireExtinguisher: {
      title: 'External fire extinguisher',
      conditions: (c) => c.externalFireExtinguisher === true,
    },
    strobeLightsSirenAndAntenna: { title: 'Strobe lights, siren and antenna' },
    externalSignage: { title: 'External signage' },
    checkValves: { title: 'Check valves' },
    structure: { title: 'Structure' },
    compressedAirFilterSystem: {
      title: 'Compressed Air Filter System',
      conditions: (c) => get(c, 'airFilterTypeEnum.name') !== 'None',
    },
    airConditionerOutDoorUnit: { title: 'Air conditioner Outdoor Unit' },
    transformer: { title: 'Transformer', conditions: (c) => c.transformer === true },
    communicationsBox: { title: 'Communications box', conditions: (c) => c.communicationsBox === true },
  },
  refugeChamberInternal: {
    _title: 'REFUGE CHAMBER INTERNAL',
    internalLightingAndMotionSensor: { title: 'Internal Lighting and motion sensor' },
    internalSignage: { title: 'Internal signage' },
    manuals: { title: 'Manuals' },
    internalCompressedAirSystem: {
      title: 'Internal compressed air system',
      conditions: (c) => c.internalCompressedAirSystem !== 'None',
    },
    internalFireBlanketOrExtinguisher: {
      title: 'Internal fire blanket OR extinguisher',
      conditions: (c) => c.fireBlanket === true,
    },
    statsAndFlooring: { title: 'Seats and flooring' },
    toilet: { title: 'Toilet', conditions: (c) => c.toilet === true },
    communicationSystems: { title: 'Communication systems' },
    airConditionerIndoorUnit: { title: 'Air conditioner Indoor Unit' },
    scrubberSystem: { title: 'Scrubber system', conditions: (c) => get(c, 'scrubberTypeEnum.name') !== 'None' },
    escapeHatch: { title: 'Escape hatch' },
    vaccumTest: { title: 'Vacuum test' },
  },
  consumables: {
    _title: 'CONSUMABLES',
    freePourScrubberChemicals: {
      title: 'Free pour scrubber chemicals',
      conditions: (c) => get(c, 'chemicalTypeEnum.name') === 'FREE_POUR',
    },
    cartridgeScrubberChemicals: {
      title: 'Cartridge scrubber chemicals',
      conditions: (c) => get(c, 'chemicalTypeEnum.name') === 'CARTRIDGE',
    },
    auraFXGasMonitoring: {
      title: 'Aura-FX gas monitoring',
      conditions: (c) => get(c, 'gasMonitorTypeEnum.name') === 'AURA_FX',
    },
    digitalGasMonitor: {
      title: 'Digital gas monitor',
      conditions: (c) => get(c, 'gasMonitorTypeEnum.name') === 'DGM',
    },
    manualGasSamplingPump: {
      title: 'Manual gas sampling pump',
      conditions: (c) => get(c, 'manualGasTubes') === true,
    },
    oxygenRegulators: { title: 'Oxygen regulators', conditions: (c) => c.medicalOxygenCylinders !== 'None' },
    medicalOxygenCylinders: {
      title: 'Medical oxygen cylinders',
      conditions: (c) => c.medicalOxygenCylinders !== 'None',
    },
    oxygenCandle: { title: 'Oxygen candle', conditions: (c) => get(c, 'oxygenCandle') === true },
    firstAidWaterAndLongLifeFood: { title: 'First aid, water and long-life food' },
    batteryVoltages: { title: 'Battery Voltages', conditions: (c) => c.batteryQuantity !== 'None' },
    batterySystem: { title: 'Battery system' },
  },
  chamberOptions: {
    _title: 'CHAMBER OPTIONS',
    ppms: {
      title: 'PPMS - Positive Pressure Management System',
      conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'PPMS'),
    },
    airlockSystem: {
      title: 'Airlock system',
      conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'AIRLOCK'),
    },
    guardIAN: {
      title: 'GuardIAN',
      conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'GUARD_IAN'),
    },
    additionalLanguages: { 
      title: 'Additional languages',
      conditions: (c) => get(c, 'additionalLanguages', "") !== "",
    },
    aods: { title: 'AODS', conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'AODS') },
    wheels: {
      title: 'Wheels',
      conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'WHEELS'),
    },
    towBar: {
      title: 'Tow bar',
      conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'TOW_BAR'),
    },
    blastResistance: {
      title: 'Blast resistance',
      conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'BLAST_RESISTANCE'),
    },
    fireResistance: {
      title: 'Fire resistance',
      conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'FIRE_RESISTANCE'),
    },
    thermalInsulation: {
      title: 'Thermal insulation',
      conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'THERMAL_INSULATION'),
    },
    maceyPlug: {
      title: 'Macey plug',
      conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'MACEY_PLUG'),
    },
    ppfs: { title: 'PPFS', conditions: (c) => get(c, 'optionsTypeEnums', []).some(({ name }) => name === 'PPFS') },
  },
  notes: {
    _title: 'Notes',
  },
};
