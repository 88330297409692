import React, { useEffect, useState } from 'react';
import { Select, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdminRoleIfNotInLocalStore, getAdminRoles, getAllEnum, getEnums, getEventTypes, getBuildLocations, fetchEventTypes } from '../../../features/enum.store';
import {
  fetchMinimalCompanyList,
  getMinimalCompanyList,
  getMinimalCompanyListLoading,
} from '../../../features/company.store';
import to from '../../../utils/to';
import get from 'lodash.get';
import isUndefined from 'lodash/isUndefined'
import remove from 'lodash/remove'
import { fetchMinimalSiteList, getMinimalSiteList, getMinimalSiteLoading } from '../../../features/site.store';
import { getEventUserList, getEventUserPersonList, getUsersLoading, fetchEventUserList, fetchEventUserPersonList } from '../../../features/user.store';
import { statusMapper, YesNo, recurrenceTypes, SCHEDULE_VIEW_TYPE, OnSiteOffSite } from '../../../utils/constant';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

export const CompanySelectDropDown = ({ onChange, selectFirstOnLoad, selectFirstIfValueIsEmpty, ...props }) => {
  const dispatch = useDispatch();
  const minimalCompanyList = useSelector(getMinimalCompanyList);
  const minimalCompanyListLoading = useSelector(getMinimalCompanyListLoading);
  const [value, setValue] = useState(props.value);

  const handleOnChange = (value) => {
    setValue(value);
    onChange && onChange(value);
  };

  useEffect(() => {
    (async () => {
      const [err, data] = await to(dispatch(fetchMinimalCompanyList()).unwrap());
      if (!err && selectFirstOnLoad) {
        const firstElement = get(data, [0, 'identifier']);
        setValue(firstElement);
        onChange && onChange(firstElement);
      }
      if(!err && selectFirstIfValueIsEmpty && value == ''){
        const firstElement = get(data, [0, 'identifier']);
        setValue(firstElement);
        onChange && onChange(firstElement);
      }
    })();
  }, []);

  return (
    <Select
      showSearch
      optionFilterProp="children"
      className="w-100"
      value={value}
      loading={minimalCompanyListLoading}
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      onChange={handleOnChange}
      {...props}
    >
      {minimalCompanyList.map(({ name, identifier }) => (
        <Option value={identifier} key={identifier}>
          {name}
        </Option>
      ))}
    </Select>
  );
};

export const SiteSelectDropdown = ({ hide, company, onChange, selectFirstOnLoad, selectFirstIfValueIsEmpty, ...rest }) => {

  const dispatch = useDispatch();
  const minimalSiteList = useSelector(getMinimalSiteList);
  const minimalSiteListLoading = useSelector(getMinimalSiteLoading);
  const [value, setValue] = useState(rest.value);

  const handleOnChange = (value) => {
    setValue(value);
    onChange && onChange(value);
  };
  useEffect(() => {
    (async () => {
      const [err, data] = await to(dispatch(fetchMinimalSiteList({ company })).unwrap());
      if (!err && selectFirstOnLoad) {
        const firstElement = get(data, ['list', 0, 'id']);
        if (firstElement) {
          setValue(String(firstElement));
          onChange && onChange(String(firstElement));
        } else {
          setValue(null);
          onChange && onChange(null);
        }
      }
      if(!err && selectFirstIfValueIsEmpty && value == ''){
        const firstElement = get(data, ['list', 0, 'id']);
        if (firstElement) {
          setValue(String(firstElement));
          onChange && onChange(String(firstElement));
        } else {
          setValue(null);
          onChange && onChange(null);
        }
      }
    })();
  }, [company]);

  if(hide && hide()){
    return <></>
  }

  return (
    <Select
      {...rest}
      onChange={handleOnChange}
      className="w-100"
      showSearch
      value={value}
      loading={minimalSiteListLoading}
      placeholder="Select"
    >
      {minimalSiteList.map(({ name, id }) => (
        <Option value={`${id}`} key={id}>
          {name}
        </Option>
      ))}
    </Select>
  );
};

export const SiteSelectMultiDropdown = ({ company, onChange, ...rest }) => {

  const dispatch = useDispatch();
  const minimalSiteList = useSelector(getMinimalSiteList);
  const minimalSiteListLoading = useSelector(getMinimalSiteLoading);
  const [value, setValue] = useState(rest.value);
  const [isLoaded, setIsLoaded] = useState(false)
  const [prevCompany, setPrevCompany] = useState(null);

  useEffect(() => {
    (async () => {
      const [err, data] = await to(dispatch(fetchMinimalSiteList({ company })).unwrap());
      if(!err && !isLoaded){
        setValue(value.map(v =>  {
          const f = data.list.find(f => f.id === v)
          return f && f.name
        }))
        setIsLoaded(true)
      }
      if(prevCompany !== null && prevCompany !== company){
        setValue([]);
        onChange([]);
      }
      setPrevCompany(company)
    })();
  }, [company]);

  const handleOnChange = (value, key) => {
    setValue(value);
    onChange && onChange(key.map(k=>k.id));
  };

  return (
    <Select
      {...rest}
      onChange={handleOnChange}
      className="w-100"
      showSearch
      value={value}
      loading={minimalSiteListLoading}
      placeholder="Select"
      options={minimalSiteList.map(o=>({
        value: o.name,
        key: o.id,
        id: o.id
      }))}
    />
  );
};

export const RegionSelectMultiDropdown = ({ onChange, ...rest }) => {

  const dispatch = useDispatch();
  const [value, setValue] = useState(rest.value);
  useEffect(() => {
    dispatch(getEnums({ type: 'buildLocations' }));
  }, []);

  const buildLocations = useSelector(getBuildLocations);

  const handleOnChange = (value, key) => {
    setValue(value);
    onChange && onChange(value);
  };
  return (
    <Select
      {...rest}
      className="w-100"
      showSearch
      value={value}
      placeholder="Select"
      onChange={handleOnChange}
    >
      {buildLocations.map(({ label, value }, id) => (
        <Option value={value} key={id}>
          {label}
        </Option>
      ))}
    </Select>
  );
};

export const AdminRolesDropdown = ({ ...props }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAdminRoleIfNotInLocalStore());
  }, []);

  const adminRoles = useSelector(getAdminRoles);

  return (
    <Select {...props}>
      {adminRoles.map(({ displayName, id }) => (
        <Option value={`${id}`} key={id}>
          {displayName}
        </Option>
      ))}
    </Select>
  );
};

export const AvailableUsers = ({ users, ...props }) => (
  <Select {...props}>
    {users.map(({ fullName, id }) => (
      <Option value={`${id}`} key={id}>
        {fullName}
      </Option>
    ))}
  </Select>
);

export const TaskTypesDropDown = ({ onChange, value, viewType, includeAll, selectFirstOnLoad, ...rest }) => {

  const dispatch = useDispatch();
  const eventTypes = useSelector(getEventTypes);

  useEffect( () => {
    dispatch(fetchEventTypes(viewType))
  }, [viewType]);

  useEffect(() => {
    if(selectFirstOnLoad && eventTypes.length >0)
      onChange && onChange(eventTypes[isUndefined(includeAll)?1:0].name);
  }, [eventTypes]);

  return (
    <Select
      {...rest}
      onChange={onChange}
      className="w-100"
      value={value}
      loading={false}
      showSearch
    >
      {eventTypes
      .filter(event => isUndefined(includeAll) && event.name === 'ALL' ? false : event )
      .map(({displayName, name}, index) => (
        <Option value={name} key={index}>
          {displayName}
        </Option>
      ))}
    </Select>
  )
}

export const RecurranceTypesDropDown = ({ onChange, value, selectFirstOnLoad, ...rest }) => {

  useEffect(()=>{
    if(selectFirstOnLoad){
      onChange && onChange(recurrenceTypes[0].value)
    }
  },[])

  return (
    <Select
      {...rest}
      onChange={onChange}
      className="w-100"
      value={value}
      loading={false}
      showSearch
    >
      {recurrenceTypes.map(({displayName, value}, index) => (
        <Option value={value} key={index}>
          {displayName}
        </Option>
      ))}
    </Select>
  )
}

export const ScheduleStatusDropDown = ({ onChange, value, viewType, selectFirstOnLoad, ...rest }) => {

  useEffect(()=>{
    if(selectFirstOnLoad){
      onChange && onChange(Object.keys(statusMapper[viewType || SCHEDULE_VIEW_TYPE.EMP_SCHEDULE])[0])
    }
  },[])

  return (
    <Select
      {...rest}
      onChange={onChange}
      className="w-100"
      value={value}
      loading={false}
      showSearch
    >
      {Object.keys(statusMapper[viewType || SCHEDULE_VIEW_TYPE.EMP_SCHEDULE]).map((keyValue, index) => (
        <Option value={keyValue} key={index}>
          {keyValue.replaceAll('_', ' ')}
        </Option>
      ))}
    </Select>
  )
}

export const YesNoDropDown = ({ onChange, value, ...rest }) => {

  useEffect(()=>{
    onChange && onChange(YesNo[0].value)
  },[])

  return (
    <Select
      {...rest}
      onChange={onChange}
      className="w-100"
      value={value}
      loading={false}
    >
      {YesNo.map(({displayName, value}, index) => (
        <Option value={value} key={index}>
          {displayName}
        </Option>
      ))}
    </Select>
  )
}

export const LocationDropDown = ({ onChange, value, ...rest }) => {

  useEffect(()=>{
    onChange && onChange(OnSiteOffSite[0].value)
  },[])

  return (
    <Select
      {...rest}
      onChange={onChange}
      className="w-100"
      value={value}
      loading={false}
    >
      {OnSiteOffSite.map(({displayName, value}, index) => (
        <Option value={value} key={index}>
          {displayName}
        </Option>
      ))}
    </Select>
  )
}


export const EventUserMultiSearchDropDown = ({ onChange, viewType, value, person, ...rest }) => {

  const isPerson = !isUndefined(person);
  const dispatch = useDispatch();
  const usersList = isPerson ? useSelector(getEventUserPersonList) : useSelector(getEventUserList);
  const usersListIsLoading = useSelector(getUsersLoading);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleOnSelect = (selectedValue, c) => {
    onChange && onChange([...value, {
      id: selectedValue,
      email: c.children
    }])
  };

  const handleOnDeSelect = (selectedValue) => {
    onChange && onChange(value.filter(v => v.email !== selectedValue))
  };

  useEffect(() => {
    if(isPerson)
      dispatch(fetchEventUserPersonList({ viewType }))
    else
      dispatch(fetchEventUserList({ viewType }))
  }, []);

  useEffect(() => {
    value && setSelectedItems(value.map(k=>k.email))
  }, [value]);
  return (
    <Select
      {...rest}
      onDeselect={handleOnDeSelect}
      onSelect={handleOnSelect}
      className="w-100"
      value={selectedItems}
      loading={usersListIsLoading}
      mode="multiple"
      allowClear
      showSearch
    >
      {usersList && usersList
      .filter(v => value.findIndex(i=>i.id ===v.id) === -1)
      .map((user) => (
        <Option value={user.id} key={user.id}>
          {user.email}
        </Option>
      ))}
    </Select>
  )
}

export const DateRangeSelector = ({ onChange, value, ...props }) => {
  const handleOnChange = (date) => {
    onChange && onChange(date.map(v=>v.format('YYYY-MM-DD')))
  }
  const dateValues = value && value.map(v=>moment(v, 'YYYY-MM-DD'))
  return <RangePicker allowClear={false} onChange={handleOnChange} value={dateValues} className="w-100" />
}

////////////////////////////////////////////////////////////////////////////////////////
// form elements for Add Chambers
////////////////////////////////////////////////////////////////////////////////////////

export const CommonDropdown = (props) => <Select showSearch className="w-100" {...props} />;

export const generateEnumDropdown = (type) => (props) => {
  const dispatch = useDispatch();
  const options = useSelector(getAllEnum)[type];
  useEffect(() => {
    dispatch(getEnums({ type }));
  }, []);
  return <CommonDropdown options={options || []} loading={!options} {...props} />;
};

export const BuildLocationDropdown = generateEnumDropdown('buildLocations');
export const ChamberTypesDropdown = generateEnumDropdown('chamberTypes');
export const ScrubberTypesDropdown = generateEnumDropdown('scrubberTypes');
export const ChemicalTypesDropdown = generateEnumDropdown('chemicalTypes');
export const OptionsTypesDropdown = generateEnumDropdown('optionsTypes');
export const InteriorLightingTypesDropdown = generateEnumDropdown('interiorLightingTypes');
export const GasSensorTypesDropdown = generateEnumDropdown('gasSensorTypes');
export const GasMonitorTypesDropdown = generateEnumDropdown('gasMonitorTypes');
export const DocumentCategoriesTypesDropdown = generateEnumDropdown('documentCategories');
export const AirFilterTypesDropdown = generateEnumDropdown('airFilterTypes');
//export const TaskTypesDropDown = generateEnumDropdown('taskTypes');
