import React, { useEffect, useState } from 'react';
import SubPageLayout from '../helpers/SubPageLayout';
import { Button, Switch } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import MyTable from '../helpers/MyTable';
import { DetailsCard } from '../helpers/Components';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { isFeatureEnabled } from '../../../features/auth.store';
import {
  CompanyAction,
  enableDisabledCompany,
  getCompanyList,
  getCompanyLoading,
  getCompanyTableInfo,
  getPaginatedCompanyList
} from '../../../features/company.store';
import { DEFAULT_PAGE_SIZE, UPDATE_ON_FORMAT } from '../../../utils/constant';
import SubPageHeader from '../helpers/SubPageHeader';

const columns = (dispatch, {
  companyTableInfo,
  handleChangeOrder
}) => [
    {
      title: 'Company name',
      dataIndex: 'name',
      render: (name, record) => (
        <DetailsCard
          key={record.identifier}
          name={name}
          src={record.imageUrl}
          className="d-flex f-row ai-center"
          to={`companies/${record.identifier}/details`}
        />
      ),
      sorter: true,
      sortDirections: [companyTableInfo.isAscending ? 'descend' : 'ascend'],
      onHeaderCell: (() => ({
        onClick: handleChangeOrder
      }))
    },
    {
      title: 'Admin Name',
      dataIndex: ['admin', 'fullName'],
    },
    {
      title: 'Update On',
      dataIndex: 'lastModifiedDate',
      render: (data) => format(new Date(data), UPDATE_ON_FORMAT),
    },
    {
      title: 'Disable',
      dataIndex: 'isEnabled',
      render: (data, record) => (
        <Switch
          checked={data}
          onChange={() => dispatch(enableDisabledCompany({ value: !data, id: record.identifier }))}
        />
      ),
    },
  ];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled User',
    // Column configuration not to be checked
    name: record.name,
  }),
};

const Companies = () => {
  const getFeatureEnabled = useSelector(isFeatureEnabled);
  const featureAddCompany = getFeatureEnabled('add_company');

  const dispatch = useDispatch();
  const companyTableInfo = useSelector(getCompanyTableInfo);
  const companyList = useSelector(getCompanyList);
  const companyListLoading = useSelector(getCompanyLoading);

  useEffect(() => {
    dispatch(getPaginatedCompanyList({ page: 0, ...companyTableInfo, sortColumn: 'name' }));
  }, []);

  const handleChangeOrder = () => {
    dispatch(CompanyAction.setSortColumn('name', !companyTableInfo.isAscending));
  }

  return (
    <SubPageLayout
      title="Company List"
      extra={
        featureAddCompany && (
          <Link to="/companies/add">
            <Button type="primary" icon={<PlusOutlined />}>
              Add Company
            </Button>
          </Link>
        )
      }
    >
      <SubPageHeader 
        defaultValue={companyTableInfo.searchKey}
        searchLoading={false}
        searchPlaceholder="Search Companies"
        onSearch={(value)=> dispatch(CompanyAction.setSearch(value))} 
      />
      <MyTable
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns(dispatch, {
          companyTableInfo,
          handleChangeOrder
        })}
        dataSource={companyList}
        pagination={{
          position: ['none', 'bottomCenter'],
          pageSize: DEFAULT_PAGE_SIZE,
          total: companyTableInfo.totalPages * DEFAULT_PAGE_SIZE,
        }}
        onChange={(pagination, filters, sorter, extra) => {
          dispatch(CompanyAction.setPage(pagination.current - 1));
        }}
        loading={companyListLoading}
        rowKey="identifier"
      />
    </SubPageLayout>
  );
};

export default Companies;
