import React, { useEffect, useRef, useState } from 'react';
import { Button, Spin } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import SubPageLayout from '../helpers/SubPageLayout';
import FormSection from '../helpers/FormSection';
import FormField from '../helpers/FormField';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewSite,
  clearSiteEditing,
  fetchSiteById,
  getAddNewSiteLoading,
  getAvailableUsersForTenant,
  getEditingSite,
  getUsersForTenant,
  getUsersForTenantLoading,
} from '../../../features/site.store';
import { Form, Formik } from 'formik';
import { AvailableUsers, CompanySelectDropDown } from '../helpers/drop-downs-for-enums';
import { Uploader } from '../helpers/Components';
import get from 'lodash.get';
import to from '../../../utils/to';
import { toast } from 'react-toastify';
import eng from '../../../utils/eng';

const SitesAdd = () => {
  const formRef = useRef();
  const imageRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showForm, setShowForm] = useState(false);
  const editingSite = useSelector(getEditingSite);

  const { id } = useParams();

  const addNewSiteLoading = useSelector(getAddNewSiteLoading);
  const usersForTenant = useSelector(getUsersForTenant) || [];
  const usersForTenantLoading = useSelector(getUsersForTenantLoading);

  const [filteredUsersForTenant, setFilteredUsers] = useState({
    mainContact: { list: [], disabled: false },
    secondContact: { list: [], disabled: true },
    thirdContact: { list: [], disabled: true },
    fourthContact: { list: [], disabled: true },
  });

  useEffect(() => {
    (async () => {
      if (id) {
        dispatch(clearSiteEditing());
        await dispatch(fetchSiteById({ id }));
        setShowForm(true);
      } else {
        setShowForm(true);
      }
    })();
  }, [id]);

  useEffect(() => {
    setFilteredUsers({
      mainContact: { list: usersForTenant, disabled: false },
      secondContact: { list: usersForTenant, disabled: true },
      thirdContact: { list: usersForTenant, disabled: true },
      fourthContact: { list: usersForTenant, disabled: true },
    });
  }, [usersForTenant]);

  const filterRestUsers = (v, key) => {
    const { mainContact, secondContact, thirdContact, fourthContact } = formRef.current.values;
    const s = { mainContact, secondContact, thirdContact, fourthContact, [key]: v };
    const f = (arr) => usersForTenant.filter(({ id }) => arr.indexOf(String(id)) === -1);
    setFilteredUsers({
      mainContact: { list: f([s.secondContact, s.thirdContact, s.fourthContact]), disabled: false },
      secondContact: { list: f([s.mainContact, s.thirdContact, s.fourthContact]), disabled: !s.mainContact },
      thirdContact: { list: f([s.mainContact, s.secondContact, s.fourthContact]), disabled: !s.secondContact },
      fourthContact: { list: f([s.mainContact, s.secondContact, s.thirdContact]), disabled: !s.thirdContact },
    });
  };

  const handleSave = async (value) => {
    const image = get(imageRef, ['current', 'fileList', 0, 'originFileObj'], null);

    let err;

    if (id) {
      [err] = await to(dispatch(addNewSite({ formValue: { ...value, id }, image, isEditing: true })).unwrap());
    } else {
      const { name, address, tenantIdentifier, mainContact, secondContact, thirdContact, fourthContact } = value;
      const formValue = {
        name,
        address,
        tenantIdentifier,
        mainContactUserId: parseInt(mainContact),
        secondContactUserId: secondContact ? parseInt(secondContact) : null,
        thirdContactUserId: thirdContact ? parseInt(thirdContact) : null,
        fourthContactUserId: fourthContact ? parseInt(fourthContact) : null,
      };
      [err] = await to(dispatch(addNewSite({ formValue, image, isEditing: false })).unwrap());
    }
    if (err) {
      return toast.error(err.err || eng.somethingWentWrong);
    }
    history.push('/sites');
    toast.success(eng.toast.addSiteSuccessfully);
  };

  let editingSiteInitValue = {};
  if (editingSite) {
    editingSiteInitValue = {
      ...editingSite,
      mainContact: editingSite.mainContact.fullName,
      secondContact: editingSite.secondContact?.fullName,
      thirdContact: editingSite.thirdContact?.fullName,
      fourthContact: editingSite.fourthContact?.fullName,
    };
  }

  return (
    <SubPageLayout title="Add Sites" loading={addNewSiteLoading}>
      {showForm ? (
        <Formik
          initialValues={id ? editingSiteInitValue : initialValue}
          onSubmit={handleSave}
          className="d-flex f-col"
          validate={(values) => validate(values, usersForTenant.length === 0 || usersForTenantLoading, id)}
          innerRef={formRef}
        >
          <Form>
            <FormSection title="Site Details" className="space-bottom">
              <div className="d-flex">
                <div className="flex space-right">
                  <FormField
                    labelText="Site Company"
                    disabled={!!id}
                    InputComp={CompanySelectDropDown}
                    placeholder="Select Company"
                    name="tenantIdentifier"
                    extraOnChange={(tenantId) => {
                      dispatch(getAvailableUsersForTenant({ tenantId }));
                    }}
                  />
                  <FormField name="name" labelText="Site Name" placeholder="Input here" />
                  <FormField name="address" labelText="Site Address" placeholder="Input here.." />
                </div>
                <div className="flex space-left">
                  <Uploader
                    accept="image/*"
                    beforeUpload={() => Promise.reject()}
                    className="w-200px h-200px"
                    maxCount={1}
                    ref={imageRef}
                  />
                </div>
              </div>
            </FormSection>

            <FormSection title="Contacts">
              <div className="d-flex">
                <div className="flex space-right">
                  <FormField
                    name="mainContact"
                    labelText="First Contact"
                    placeholder="Input here.."
                    InputComp={AvailableUsers}
                    extraOnChange={(v) => filterRestUsers(v, 'mainContact')}
                    users={filteredUsersForTenant.mainContact.list}
                    disabled={id || filteredUsersForTenant.mainContact.list.length === 0 || usersForTenantLoading}
                  />
                  <FormField
                    name="secondContact"
                    labelText="Second Contact"
                    placeholder="Input here.."
                    InputComp={AvailableUsers}
                    extraOnChange={(v) => filterRestUsers(v, 'secondContact')}
                    users={filteredUsersForTenant.secondContact.list}
                    disabled={
                      id ||
                      filteredUsersForTenant.secondContact.disabled ||
                      filteredUsersForTenant.secondContact.list.length === 0 ||
                      usersForTenantLoading
                    }
                  />
                </div>
                <div className="flex space-left">
                  <FormField
                    name="thirdContact"
                    labelText="Third Contact"
                    placeholder="Input here.."
                    InputComp={AvailableUsers}
                    extraOnChange={(v) => filterRestUsers(v, 'thirdContact')}
                    users={filteredUsersForTenant.thirdContact.list}
                    disabled={
                      id ||
                      filteredUsersForTenant.thirdContact.disabled ||
                      filteredUsersForTenant.thirdContact.list.length === 0 ||
                      usersForTenantLoading
                    }
                  />
                  <FormField
                    name="fourthContact"
                    labelText="Fourth Contact"
                    placeholder="Input here.."
                    InputComp={AvailableUsers}
                    extraOnChange={(v) => filterRestUsers(v, 'fourthContact')}
                    users={filteredUsersForTenant.fourthContact.list}
                    disabled={
                      id ||
                      filteredUsersForTenant.fourthContact.disabled ||
                      filteredUsersForTenant.fourthContact.list.length === 0 ||
                      usersForTenantLoading
                    }
                  />
                </div>
              </div>
            </FormSection>

            <div className="d-flex jc-end space-top space-top-p space-bottom">
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        </Formik>
      ) : (
        <Spin spinning>
          <div style={{ width: '100%', height: 200 }} />
        </Spin>
      )}
    </SubPageLayout>
  );
};

const initialValue = {
  name: '',
  address: '',
  tenantIdentifier: null,
  mainContact: null,
  secondContact: null,
  thirdContact: null,
  fourthContact: null,
};

const validate = async (values, hasAvailableUsers, isEditing) => {
  const errors = {};
  const { name, address, tenantIdentifier, mainContact } = values;

  if (!name || !name.trim()) {
    errors.name = 'Required';
  }
  if (!address || !address.trim()) {
    errors.address = 'Required';
  }
  if (!tenantIdentifier) {
    errors.tenantIdentifier = 'Required';
  }

  if (!mainContact && !isEditing) {
    errors.mainContact = 'Required';
  }
  if (hasAvailableUsers && !isEditing) {
    errors.mainContact = "You don't have available user in this company";
  }

  return errors;
};

export default SitesAdd;
