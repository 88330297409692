import React, {useLayoutEffect, useRef, useState} from "react";
import { range } from 'lodash'
import './styles.scss'

const Chart = ({data}) =>{

    const ref = useRef(null);
    const [width, setWidth] = useState(0);

    let maxValue = 0;
    const keys = Object.keys(data);

    keys.forEach(key => {
        if(data[key] > maxValue)
            maxValue = data[key]
    });
    
    const ks = range(parseInt(maxValue/2) > 12 ? 12 : parseInt(maxValue/2))
    const it = parseInt(maxValue/2) > 12 ? parseInt(maxValue/12) : 2

    const calcWidth = (value) => {
        let v = value/maxValue * 100
        v = (v - (v/9))
        v = value == maxValue ? v - 5 : v
        return `${parseInt(v)}%`
    }

    useLayoutEffect(() => {
        setWidth(ref.current.offsetWidth);
    }, []);

    return(
        <div className="chart" ref={ref}>
            <div className="wrapper">
                {
                    keys.map(key=>(
                        <div className="d-flex chart-item">
                            <div className="item-title">{key}</div>
                            <div style={{width: calcWidth(data[key])}} className="item-bar"></div>
                        </div>
                    ))
                }
            </div>
            <div className="d-flex w-100 lower-bar jc-end">
                {ks.map(i => (
                    <div className="w-100">
                        {i*it}
                    </div>
                ))}
                <div>
                    {ks.length*it}
                </div>
            </div>
        </div>
    )
}

export default Chart;