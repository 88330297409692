import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { GET, POST, POST_IMAGE, PUT } from '../service/base-api-service';
import to from '../utils/to';
import { DEFAULT_PAGE_SIZE } from '../utils/constant';
import { getIsAdminApi } from './auth.store';

const initialState = {
  companyList: [],
  minimalCompanyList: [],
  minimalCompanyListLoading: false,
  tableInfo: {
    totalPages: 0,
    currentPage: -1,
    sortColumn: 'id',
    isAscending: true,
    searchKey: ''
  },
  isLoading: false,
  addNewCompanyLoading: false,
  isLoadingError: false,
  selectedCompany: {},
};

export const getPaginatedCompanyList = createAsyncThunk(
  'companyList/get',
  async ({ page, sortColumn = 'id', isAscending = false, searchKey }, { dispatch }) => {
    dispatch(setTableInfo({ currentPage: page, sortColumn, isAscending, searchKey }));
    return GET('v1/admin/tenant', { params: { page, sortColumn, isAscending, size: DEFAULT_PAGE_SIZE, searchKey } });
  },
);

export const fetchMinimalCompanyList = createAsyncThunk('companyList/minimal', async (payload, { getState }) => {
  const minimalCompanyList = getMinimalCompanyList(getState());
  if (minimalCompanyList.length === 0) {
    let url = `v1/site/all/minimal`;
    if (getIsAdminApi(getState())) url = `v1/admin/tenant/minimal`;
    return GET(url);
  }
  return minimalCompanyList;
});

export const enableDisabledCompany = createAsyncThunk('company/enableDisable', async ({ value, id }) => {
  return POST(`v1/admin/tenant/${id}/${value ? 'enable' : 'disable'}`);
});

export const getCompanyDetailsById = createAsyncThunk('company/getById', async ({ id }) => {
  return GET(`v1/admin/tenant/${id}`);
});

export const addNewCompany = createAsyncThunk('company/addNew', async ({ formValue, image }, { rejectWithValue }) => {
  let err, data;
  if (image) {
    [err, data] = await to(POST_IMAGE(image));
    if (err) {
      return rejectWithValue({ err });
    }
  }
  return POST('v1/admin/tenant', { ...formValue, imageUrl: data ? data.secure_url : null });
});

export const updateCompany = createAsyncThunk('company/update', async ({ id, body, image }, { rejectWithValue }) => {
  let err, data;
  if (image) {
    [err, data] = await to(POST_IMAGE(image));
    if (err) {
      return rejectWithValue({ err });
    }
    body.imageUrl = data.secure_url;
  }
  return PUT('v1/admin/tenant/' + id, body);
});

export const CompanyAction = {
  setPage: (page) => (dispatch, getState) => {
    const tableInfo = getCompanyTableInfo(getState());
    if(tableInfo.currentPage !== page)
     dispatch(getPaginatedCompanyList({ page, ...tableInfo, sortColumn: 'name' }));
  },
  setSortColumn: (sortColumn, isAscending) => (dispatch, getState) => {
    const tableInfo = getCompanyTableInfo(getState());
    dispatch(getPaginatedCompanyList({ page: 0, ...tableInfo, sortColumn, isAscending}));
  },
  setSearch: (searchKey) => (dispatch, getState) => {
    const tableInfo = getCompanyTableInfo(getState());
    dispatch(getPaginatedCompanyList({ 
      page: 0, 
      ...tableInfo,
      searchKey
    }));
  }
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setTableInfo: (state, { payload }) => {
      state.tableInfo = { ...state.tableInfo, ...payload };
    },
    setSelectedCompany: (state, { payload }) => {
      state.selectedCompany = payload;
    },
  },
  extraReducers: {
    [getPaginatedCompanyList.pending]: (state) => {
      state.isLoading = true;
      state.isLoadingError = false;
    },
    [getPaginatedCompanyList.rejected]: (state) => {
      state.isLoading = false;
      state.isLoadingError = true;
    },
    [getPaginatedCompanyList.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { results, currentPage, pageSize, totalPages } = action.payload;
      state.companyList = results;
      state.tableInfo = { ...state.tableInfo, currentPage, pageSize, totalPages };
    },
    [fetchMinimalCompanyList.pending]: (state) => {
      state.minimalCompanyListLoading = true;
    },
    [fetchMinimalCompanyList.rejected]: (state) => {
      state.minimalCompanyListLoading = false;
    },
    [fetchMinimalCompanyList.fulfilled]: (state, { payload }) => {
      state.minimalCompanyListLoading = false;
      state.minimalCompanyList = payload;
    },
    [getCompanyDetailsById.pending]: (state) => {
      state.isLoading = true;
    },
    [getCompanyDetailsById.rejected]: (state) => {
      state.isLoading = false;
    },
    [getCompanyDetailsById.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.selectedCompany = payload;
    },
    [enableDisabledCompany.pending]: (state) => {
      state.isLoading = true;
    },
    [enableDisabledCompany.rejected]: (state) => {
      state.isLoading = false;
    },
    [enableDisabledCompany.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.companyList = state.companyList.map((company) =>
        company.identifier === payload.identifier ? payload : company,
      );
    },
    [addNewCompany.pending]: (state) => {
      state.addNewCompanyLoading = true;
    },
    [addNewCompany.rejected]: (state) => {
      state.addNewCompanyLoading = false;
    },
    [addNewCompany.fulfilled]: (state) => {
      state.addNewCompanyLoading = false;
      state.companyList = [];
      state.minimalCompanyList = [];
      state.tableInfo.currentPage = -1;
    },
  },
});

export const { setTableInfo, setSelectedCompany, setSearch } = companySlice.actions;
const getCompanyStore = (state) => state.company;

export const getCompanyList = createSelector(getCompanyStore, (state) => state.companyList);
export const getCompanyLoading = createSelector(getCompanyStore, (state) => state.isLoading);
export const getAddNewCompanyLoading = createSelector(getCompanyStore, (state) => state.addNewCompanyLoading);
export const getCompanyTableInfo = createSelector(getCompanyStore, (state) => state.tableInfo);
export const getSelectedCompany = createSelector(getCompanyStore, (state) => state.selectedCompany);
export const getMinimalCompanyList = createSelector(getCompanyStore, (state) => state.minimalCompanyList);
export const getMinimalCompanyListLoading = createSelector(getCompanyStore, (state) => state.minimalCompanyListLoading);
export default companySlice.reducer;
