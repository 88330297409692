import React, { useEffect, useState } from 'react';
import { Button, Empty, Modal, DatePicker } from 'antd';
import { useParams, useHistory, Link, useLocation } from 'react-router-dom';
import get from 'lodash.get';
import SubPageLayout from '../../helpers/SubPageLayout';

import './service-form.scss';
import { questionOptions } from '../../../../utils/serviceFormOptions';
import { QuestionLeftPanel } from './QuestionLeftPanel';
import PassFailQuestion from './PassFailQuestion';
import DateFieldQuestion from './DateFieldQuestion';
import TextFieldQuestion from './TextFieldQuestion';
import { useDispatch, useSelector } from 'react-redux';
import {
  approveServiceForm,
  enableSubmitButton2,
  getChamberDetailsById,
  getChamberDetailsLoading,
  getFormUpdated,
  getServiceFormForChamber,
  getServiceFormLoading,
  getServiceFormLoadingError,
  getSFAnswers,
  getSFChamber,
  saveServiceForm,
  submitServiceForm,
  updateAnswer,
  updateLocation,
  updateServiceDate,
} from '../../../../features/service-form.store';
import to from '../../../../utils/to';
import { toast } from 'react-toastify';
import eng from '../../../../utils/eng';
import { StopOutlined } from '@ant-design/icons';
import { getUserRole, isFeatureEnabled } from '../../../../features/auth.store';
import AcManufactureSelection from './ACManufactureSelection';
import BatteryValtage from './BatteryValtage';
import { NonFormikFormField } from '../../helpers/FormField';
import NoteSection from './NoteSection';
import '../../../../utils/select-by-code';
import ConstFieldQuestion from './ConstFieldQuestion';
import moment from 'moment';

const ServiceForm = () => {
  const [selected, setSelected] = useState('');
  const { search } = useLocation();
  const type = new URLSearchParams(search).get('type');

  const history = useHistory();

  const chamber = useSelector(getSFChamber);
  const answers = useSelector(getSFAnswers);
  const userType = useSelector(getUserRole);

  const getFeatureEnabled = useSelector(isFeatureEnabled);
  const canApproveServiceForm = getFeatureEnabled('approve_the_service_form');

  const chamberLoading = useSelector(getChamberDetailsLoading);
  const serviceFormLoading = useSelector(getServiceFormLoading);
  const serviceFormLoadingError = useSelector(getServiceFormLoadingError);

  const hasLocalUpdates = useSelector(getFormUpdated);

  const enableSubmitApproveButton = useSelector(enableSubmitButton2);

  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getChamberDetailsById({ id: params.id }));
    dispatch(getServiceFormForChamber({ id: params.id, type }));
  }, [type]);

  const path = selected.split('-');

  const onSave = async () => {
    if (!hasLocalUpdates || answers.location === '') {
      return;
    }
    const [err, data] = await to(dispatch(saveServiceForm({ type })).unwrap());
    if (err) {
      return toast.error(eng.unableToSave);
    }
    toast.success(eng.toast.saveServiceForm);
  };

  const onSubmit = async () => {
    const [err, data] = await to(dispatch(submitServiceForm()).unwrap());
    if (err) {
      return toast.error(eng.unableToSubmit);
    }
    toast.success(eng.toast.submitServiceForm);
    history.push('/chambers');
  };

  const onApprove = async () => {
    const [err, data] = await to(dispatch(approveServiceForm()).unwrap());
    if (err) {
      return toast.error(eng.unableToApprove);
    }
    toast.success(eng.toast.approveServiceFormSuccess);
    history.push('/chambers');
  };

  const filteredQuestions = get(questionOptions, path, []).filter(
    ({ conditions }) => !(conditions && !conditions(chamber, userType)),
  );

  return (
    <SubPageLayout
      title={chamberLoading ? '' : chamber.name}
      className="service-form poppins"
      loading={chamberLoading && serviceFormLoading}
      subtitle={
        <div className="form-section d-flex" style={{ padding: 0, background: 'transparent' }}>
          <NonFormikFormField
            className="space-all"
            labelText="Location"
            value={answers.chamberLocation}
            onChange={(e) => {
              dispatch(updateLocation(e.target.value));
            }}
          />
          <NonFormikFormField
            className="space-all"
            labelText="Service Date"
            value={moment(answers.servicedDate)}
            onChange={(e) => {valueOf
              dispatch(updateServiceDate(e.valueOf()));
            }}
            allowClear ={false}
            InputComp={(props) => <DatePicker {...props} className="w-100" />}
          />
        </div>
      }
      extra={
        <>
          <Button className="space-right" onClick={onSave} disabled={!hasLocalUpdates || answers.location === ''}>
            Save
          </Button>
          {/* TODO: set the disabled logic */}
          {canApproveServiceForm ? (
            <Button type="primary" onClick={onApprove} disabled={!enableSubmitApproveButton}>
              Approve
            </Button>
          ) : (
            <Button type="primary" onClick={onSubmit} disabled={!enableSubmitApproveButton}>
              Submit
            </Button>
          )}
        </>
      }
    >
      <div className="d-flex">
        <div className="q-selector">
          <QuestionLeftPanel
            answers={answers}
            selected={selected}
            onSelectionChange={(v) => setSelected(v.target.value)}
            onMainItemChanges={onSave}
          />
        </div>
        <div className="q-container">
          {selected ? (
            path[0] === 'notes' ? (
              <NoteSection answers={answers} />
            ) : (
              filteredQuestions.map(({ title, options, type, key, subHeaderProvider, ...rest }) => {
                const commonProps = {
                  options,
                  title,
                  key: title,
                  path,
                  answers: get(answers, path),
                  answerKey: key,
                  subHeader: subHeaderProvider ? subHeaderProvider(chamber) : null,
                  ...rest,
                };

                if (!type) {
                  return <PassFailQuestion {...commonProps} />;
                } else if (type === 'DATE_FIELD') {
                  return <DateFieldQuestion {...commonProps} />;
                } else if (type === 'TEXT_FIELD') {
                  return <TextFieldQuestion {...commonProps} />;
                } else if (type === 'AC_MANUFACTURE_SELECTION') {
                  return <AcManufactureSelection {...commonProps} />;
                } else if (type === 'BATTERY_VOLTAGES') {
                  return <BatteryValtage {...commonProps} chamber={chamber} />;
                } else if(type === 'CONST'){
                  return <ConstFieldQuestion {...commonProps} chamber={chamber}/>
                }
              })
            )
          ) : (
            <Empty description="Select a question to load here..." />
          )}
        </div>
      </div>
      <Modal
        visible={serviceFormLoadingError.hasError}
        footer={null}
        closable={false}
        width={450}
        className="resume-service-modal"
        title={get(serviceFormLoadingError, ['info', 'message'])}
      >
        <div className="resume-service-modal-content">
          <div className="header-icon space-top space-bottom">
            <StopOutlined />
          </div>
          <div className="fw-600 space-top space-bottom">{get(serviceFormLoadingError, ['info', 'details', 0])}</div>

          <div className="buttons d-flex jc-space-around space-top space-bottom">
            <Link to="/chambers">
              <Button type="primary" className="flex space-left space-right">
                OK
              </Button>
            </Link>
          </div>
        </div>
      </Modal>
    </SubPageLayout>
  );
};

export default ServiceForm;
