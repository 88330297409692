import React, { useEffect, useRef, useState } from 'react';
import FormField, { FormFieldOverrideOnChange, FormFieldOverrideMomentOnChange } from '../helpers/FormField';
import { Uploader } from '../helpers/Components';
import FormSection from '../helpers/FormSection';
import { Button, Modal, Spin, DatePicker } from 'antd';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { phoneRegEx } from '../../../utils/constant';
import { AdminRolesDropdown, CompanySelectDropDown, SiteSelectMultiDropdown, RegionSelectMultiDropdown } from '../helpers/drop-downs-for-enums';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewUser,
  fetchUserById,
  getEditingUser,
  getEditingUserLoading,
  UsersAction,
  getUserTableInfo,
  deleteUserById
} from '../../../features/user.store';
import to from '../../../utils/to';
import {isEmpty, get, isEqual, find} from 'lodash'
import { toast } from 'react-toastify';
import eng from '../../../utils/eng';
import { USER_TYPES } from '../../../utils/constant'
import moment from 'moment';
import { isCompanyFieldHideInUserEdit } from '../../../utils/utils';
import { fetchAdminRoleIfNotInLocalStore, getAdminRoles } from '../../../features/enum.store'

const UserEdit = ({ id, fullname, disabled }) => {
  const [showEditUser, setShowEditUser] = useState(false);
  const [hideCompanySelector, setHideCompanySelector] = useState(false);
  const [hideSiteSelector, setHideSiteSelector] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState({
    showModal: false,
    name: ''
  });
  const loading = useSelector(getEditingUserLoading);
  const user = useSelector(getEditingUser);
  const tableInfo = useSelector(getUserTableInfo);
  const adminRoles = useSelector(getAdminRoles);

  const closeAddUserModal = () => {
    setShowEditUser(false);
  };

  const closeDeleteUserModal = () => {
    setShowDeleteModal({
      showModal: false,
      name: ''
    });
  };

  const dispatch = useDispatch();

  const formRef = useRef();
  const imageUploadRef = useRef();

  const handleOK = () => {
    formRef.current.submitForm();
  };

  useEffect(() => {
    if (id && showEditUser) {
      dispatch(fetchUserById({ id }));
      dispatch(fetchAdminRoleIfNotInLocalStore())
    }
  }, [id, showEditUser]);
  

  useEffect(()=>{
    if(user){
      const isFeatureEnabled = !isCompanyFieldHideInUserEdit(user.role.name);
      setHideCompanySelector(isFeatureEnabled)
    }
  },[user])

  const handleSave = async (values) => {
    const image = get(imageUploadRef, ['current', 'fileList', 0, 'originFileObj'], null);
    if (isNaN(values.role.id)) {
      values.role = user.role;
    }
    const [err] = await to(dispatch(addNewUser({ formValue: {
      ...values,
      certificationExpiry: get(values,'certificationExpiry', moment()).valueOf()
    }, image, isEditing: true })).unwrap());
    if (err) {
      toast.error(err.message || eng.somethingWentWrong);
    } else {
      toast.success(eng.toast.addUserSuccessfully);
      formRef.current.resetForm();
      setShowEditUser(false);
      dispatch(UsersAction.setPage(0));
    }
  };

  const handleDeleteUser = async () => {
    setShowDeleteModal({
      showModal: false,
      name: ''
    })
    const err = await dispatch(deleteUserById({id}))
    if(err){
      toast.error(get(err,'payload.error.details[0]', 'Delete Failed!'));
    }
  }

  const handleRoleOnChange = async(value) => {
    if(!isEmpty(adminRoles)){
      const role = adminRoles.find(({id}) => id == value);
      const isFeatureEnabled = !isCompanyFieldHideInUserEdit(role.name);
      setHideCompanySelector(isFeatureEnabled)
      setHideSiteSelector(value !== USER_TYPES.ROLE_SITE_USER.id &&
        value !== USER_TYPES.SERVICE_PROVIDER.id && 
        value !== USER_TYPES.SITE_SERVICE_TECHNICIAN.id)
    }
  }

  let displayUser = null;
  if (user) {
    displayUser = { ...user, role: { id: user.role.displayName }, assignedSiteIds: user.assignedSites.map(u=>u.id) };
  }

  const isRegionSelectionHide = () =>{
    const roleId = get(formRef, ['current','values','role','id'], '');
    return roleId !== USER_TYPES.MINE_ARC_USER.id &&
    roleId !== USER_TYPES.MINE_ARC_USER.name &&
    roleId !== USER_TYPES.MINE_ARC_SERVICE_COORDINATOR.id &&
    roleId !== USER_TYPES.MINE_ARC_SERVICE_COORDINATOR.name &&
    roleId !== USER_TYPES.MINE_ARC_SERVICE_TECHNICIAN.id &&
    roleId !== USER_TYPES.MINE_ARC_SERVICE_TECHNICIAN.name
  }

  return (
    <>
      <div className='d-flex f-row'>
        <div className="icon space-right cursor-pointer" onClick={() => !disabled && setShowEditUser(true)}>
          <img src="/icons/edit.svg" />
        </div>
        <div className="icon space-right cursor-pointer" onClick={() => !disabled && 
        setShowDeleteModal({
          showModal: true,
          name: fullname
        })}>
          <img src="/icons/delete.svg" />
        </div>
      </div>
      <Modal
        title="Edit User"
        visible={showEditUser}
        onOk={handleOK}
        onCancel={closeAddUserModal}
        width={1120}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        destroyOnClose
      >
        <FormSection>
          {user ? (
            <Formik
              innerRef={formRef}
              initialValues={displayUser}
              validationSchema={YupValidation}
              validateOnBlur
              onSubmit={handleSave}
            >
              <Form>
                <Spin spinning={loading}>
                  <div className="d-flex">
                    <div className="flex space-right">
                      <FormField name="fullName" labelText="User Name" placeholder="Input here" />
                      <FormField name="email" disabled labelText="Email Address" placeholder="Input here" />
                      <FormField name="telephoneNumber" labelText="Telephone Number" placeholder="+61" />
                      <FormField
                        name="tenantIdentifier"
                        labelText="Company"
                        placeholder="Input Here"
                        hide={()=>hideCompanySelector}
                        InputComp={CompanySelectDropDown}
                        extraOnChange={(val) => {
                          dispatch(UsersAction.setSelectedCompanyToTable(val))
                        }}
                      />
                    </div>
                    <div className="flex space-left">
                      <FormField
                        name="role.id"
                        labelText="Company Role"
                        placeholder="Input Here"
                        InputComp={AdminRolesDropdown}
                        extraOnChange={handleRoleOnChange}
                      />
                      <FormField name="certificationNumber" labelText="Certification Number" placeholder="Input here" />
                      <FormFieldOverrideMomentOnChange
                        name="certificationExpiry"
                        labelText="Certification Expiry"
                        InputComp={(props) => <DatePicker {...props} className="w-100" />}
                      />
                      <FormFieldOverrideOnChange
                        allowClear 
                        mode="multiple"
                        hide={() => hideSiteSelector}
                        name="assignedSiteIds"
                        labelText="Sites"
                        placeholder="Input Here"
                        company={tableInfo.company}
                        InputComp={SiteSelectMultiDropdown}
                      />
                      <FormFieldOverrideOnChange
                        allowClear 
                        mode="multiple"
                        hide={isRegionSelectionHide}
                        name="regions"
                        labelText="Regions"
                        placeholder="Input Here"
                        InputComp={RegionSelectMultiDropdown}
                      />
                      <Uploader
                        accept="image/*"
                        beforeUpload={() => Promise.reject()}
                        className="w-200px h-200px"
                        maxCount={1}
                        ref={imageUploadRef}
                      />
                    </div>
                  </div>
                </Spin>
              </Form>
            </Formik>
          ) : (
            <Spin spinning />
          )}
        </FormSection>
      </Modal>
      <Modal
        title="Delete User"
        visible={showDeleteModal.showModal}
        onOk={handleDeleteUser}
        onCancel={closeDeleteUserModal}
        width={400}
        destroyOnClose
      >
        <div className='d-flex'>
          <div className="flex space-left">
              {`Are You Sure to Delete User ${showDeleteModal.name}?`}
          </div>
        </div>
      </Modal>
    </>
  );
};

const YupValidation = Yup.object({
  fullName: Yup.string().required('Required'),
  email: Yup.string().required('Required').email('Invalid email address'),
  telephoneNumber: Yup.string().required('Required').matches(phoneRegEx, 'Invalid Phone Number'),
  tenantIdentifier: Yup.string().required('Required'),
  assignedSiteIds: Yup.array().of(Yup.string()),
  certificationExpiry: Yup.string().required('Required'),
  certificationNumber: Yup.string().required('Required'),
  regions: Yup.array().of(Yup.string()),
});

export default UserEdit;
