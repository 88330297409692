import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spin } from 'antd';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { PlusOutlined, ExportOutlined } from '@ant-design/icons';
import moment from 'moment'
import {
    SchedulerAction,
    getEvents,
    getCalenderIsLoading,
    setTaskType,
    setSelectedUsers,
    getTaskType,
    getSelectedUsers,
    setSelectedYearAndMonth,
    getSelectedYear,
    getSelectedMonth,
    addEvent,
    getEvent,
    setEditEvent,
    updateEvent
} from '../../../features/scheduler.store'
import { getIsTrainingCoordinator, getIsBusinessDevelopmentManager } from '../../../features/auth.store'
import SubPageLayout from '../helpers/SubPageLayout'
import ServiceScheduleEventForm from './ServiceScheduleEventForm';
import './styles/schedule.scss';
import { SCHEDULE_VIEW_TYPE } from '../../../utils/constant';
import {
    TaskTypesDropDown,
    EventUserMultiSearchDropDown,
} from '../helpers/drop-downs-for-enums';
import ExportEventReport from '../helpers/ExportEventReport';

const localizer = momentLocalizer(moment)

const ServiceSchedule = () => {

    const dispatch = useDispatch();
    const [showModel, setShowModal] = useState(false);

    const events = useSelector(getEvents)
    const isLoading = useSelector(getCalenderIsLoading);
    const taskType = useSelector(getTaskType);
    const selectedUsers = useSelector(getSelectedUsers);
    const selectedYear = useSelector(getSelectedYear);
    const selectedMonth = useSelector(getSelectedMonth);
    const isTrainingCoordinator = useSelector(getIsTrainingCoordinator);
    const isBusinessDevelopmentManager = useSelector(getIsBusinessDevelopmentManager);
    const viewType = SCHEDULE_VIEW_TYPE.EMP_SCHEDULE;
    const [firstTime, setFirstTime] = useState(true);
    const [isExportModalVisible, setIsExportModalVisible] = useState(false)

    useEffect(() => {
        if (firstTime) {
            dispatch(setSelectedYearAndMonth({
                year: moment().year(),
                month: moment().month()
            }))
        }
        setFirstTime(false)
    }, []);

    const { defaultDate, views } = useMemo(
        () => ({
            defaultDate: new Date(),
            views: {
                month: true,
                day: true
            },
        }),
        []
    )

    useEffect(() => {
        dispatch(SchedulerAction.setEvents({ viewType }));
    }, [taskType, selectedUsers, selectedYear, selectedMonth]);

    const handleSelectedUsers = (val) => {
        dispatch(setSelectedUsers(val))
    }

    const handleTaskType = (val) => {
        dispatch(setTaskType(val))
    }

    const handleSelectedDate = (val) => {
        const momentValue = moment(val)
        dispatch(setSelectedYearAndMonth({
            year: momentValue.year(),
            month: momentValue.month()
        }))
    }

    const handleOnAddEvent = () => {
        dispatch(setEditEvent(null));
        setShowModal(true)
    }

    const handleSubmit = (value, isUpdate) => {
        const dataObj = {
            formValue: {
                description: value.description,
                start: value.dateRange[0],
                end: value.dateRange[1],
                invitees: value.invitees.map(i => i.id),
                location: null,
                name: value.name,
                notes: value.notes,
                persons: [],
                recurrence: value.recurrence,
                site: value.site,
                status: value.status,
                type: value.type,
                vehicle: value.vehicle
            }
        }
        if (isUpdate) {
            dispatch(updateEvent(dataObj))
        } else {
            dispatch(addEvent(dataObj))
        }

        setShowModal(false)
    }

    const handleEventClick = ({ id }) => {
        dispatch(getEvent({ id, viewType }))
        setShowModal(true)
    }

    const handleOnExport = () => {
        setIsExportModalVisible(true)
    }

    return (
        <SubPageLayout
            title="Employee Scheduling"
            extra={
                <div className="d-flex">
                    {
                        !isTrainingCoordinator && !isBusinessDevelopmentManager &&
                        <Button type="primary" className='space-right-1' icon={<PlusOutlined />} onClick={handleOnAddEvent}>
                            Schedule
                        </Button>
                    }
                    <Button type="secondary" icon={<ExportOutlined />} onClick={handleOnExport}>
                        Export
                    </Button>
                </div>
            }
        >
            <div className="d-flex">
                <div className='task-type-selector'>
                    <TaskTypesDropDown
                        placeholder='Select Task Type'
                        onChange={handleTaskType}
                        selectFirstOnLoad
                        includeAll
                        value={taskType}
                        viewType={viewType}
                    />
                </div>
                <div className='user-multi-selector'>
                    <EventUserMultiSearchDropDown
                        placeholder="Select Technician"
                        onChange={handleSelectedUsers}
                        value={selectedUsers}
                        viewType={viewType}
                    />
                </div>
            </div>
            <div>
                <Spin spinning={isLoading}>
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        views={views}
                        onNavigate={handleSelectedDate}
                        onSelectEvent={handleEventClick}
                    >
                        <Event />
                    </Calendar>
                </Spin>
            </div>
            <ServiceScheduleEventForm onSubmit={handleSubmit} onCancel={() => setShowModal(false)} showModel={showModel} />
            <ExportEventReport show={isExportModalVisible} onCancel={()=>setIsExportModalVisible(false)}/>
        </SubPageLayout>
    );
};

export default ServiceSchedule;
