import React from 'react';
import { Tabs } from 'antd';
import SubPageLayout from '../helpers/SubPageLayout';
import { PasswordEditForm, ProfileInfoEdit } from '../helpers/user-profile-edit-forms';

const { TabPane } = Tabs;

const EditProfile = () => {
  return (
    <SubPageLayout title="Edit User Account">
      <Tabs type="card">
        <TabPane tab="User Information" key="1">
          <ProfileInfoEdit />
        </TabPane>
        <TabPane tab="Password" key="2">
          <PasswordEditForm />
        </TabPane>
      </Tabs>
    </SubPageLayout>
  );
};

export default EditProfile;
