import React from 'react';
import './styles/sub-page-layout.scss';
import { Spin } from 'antd';

const SubPageLayout = ({ title, subtitle, extra, children, upper, className, loading = false }) => {
  return (
    <Spin spinning={loading} size="large">
      <div className={`sub-page-layout d-flex f-col ${className || ''}`}>
        {upper}
        <div className="sp-header d-flex f-row jc-space-between ai-center">
          <div className="left">
            <h1 className="fw-500">{title}</h1>
            <span>{subtitle}</span>
          </div>
          <div className="right">{extra}</div>
        </div>
        <div className="sb-content">{children}</div>
      </div>
    </Spin>
  );
};

export default SubPageLayout;
