import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import FormSection from '../../helpers/FormSection';
import { NonFormikFormField } from '../../helpers/FormField';
import { useDispatch } from 'react-redux';
import { updateAnswer } from '../../../../features/service-form.store';

const { Panel } = Collapse;

const ConstFieldQuestion = ({ path, answerKey, subKey, defaultProvider, chamber }) => {

  const [value, setValue] = useState('');
  const [touched, setTouched] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setTouched(true);
    if (touched) {
      dispatch(
        updateAnswer({
          isArray: false,
          path: [...path, answerKey, subKey],
          value,
        }),
      );
    }
  }, [value]);

  useEffect(() => {
    setValue(defaultProvider(chamber));
    setTouched(true)
  }, []);

  return (
    <></>
  );
};

export default ConstFieldQuestion;
