import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { GET, POST, PUT } from '../service/base-api-service';
import { getStatusMapper, SCHEDULE_VIEW_TYPE } from '../utils/constant';
import moment from 'moment';
import { pick, isEmpty } from 'lodash'
import { exportData } from './util';

const initialState = {
  viewType: '',
  events: [],
  taskType: '',
  isLoading: false,
  isFileProcesing: false,
  isFileError: false,
  isFileDownloaded: false,
  isLoadingError: false,
  users: [],
  selectedYear: moment().year(),
  selectedMonth: moment().month(),
  editEvent: null,
};

const formatEvents = (eventsData, viewType) => {
  const formattedEventData = [];

  eventsData.forEach(event => {
    let formattedData = {
      ...event,
      allDay: true,
      title: event.name,
      taskType: event.type,
      isRepeated: event.updateRecurring,
      status: getStatusMapper(viewType, event.status),
      statusValue: event.status,
      repeateOption: event.recurrence,
      user: {
        name: event.persons?.fullName,
        url: event.persons?.profilePictureURL
      },
      style: {
        ...getStatusMapper(viewType, event.status)
      },
    };

    if(formattedData.eventUsers && formattedData.eventUsers.length>0){
      formattedData.eventUsers.forEach(eventUser => {
        formattedEventData.push({
          ...formattedData,
          user: {
            name: eventUser?.fullName,
            url: eventUser?.profilePictureURL
          },
        })
      })
    }else{
      formattedEventData.push({
        ...formattedData,
        user: {
          name: null,
          url: null
        }
      })
    }

  })

  return formattedEventData;
}

const filterEvents = ({ events }) => {
  return events;
}

export const getEventsList = createAsyncThunk(
  'events/get',
  async ({ viewType }, { dispatch, getState }) => {

    dispatch(setViewType(viewType))

    const type = getTaskType(getState());
    if(isEmpty(type))
      return
    const users = getSelectedUsers(getState());
    const year = getSelectedYear(getState());
    const month = getSelectedMonth(getState()) + 1;
    const ids = users ? users.map(u=>u.id).join(',') : null

    const response = await GET(`v1/admin/${viewType.toLowerCase()}/events`, { params: { type, ids, year, month } });
    return formatEvents(response, viewType)
  },
);

export const getExportFile = createAsyncThunk(
  'events/get/csv',
  async ({ from, to }, { getState }) => {
    const viewType = getViewType(getState());
    const response = await GET(`v1/admin/${viewType.toLowerCase()}/events/file`, { params: { from, to } });
    exportData(`export-${viewType}-shecule-data`, response)
    return ""
  },
);

export const getEvent = createAsyncThunk(
  'events/get/event',
  async ({ id, viewType }) => {
    const response = await GET(`v1/admin/${viewType.toLowerCase()}/events/${id}`);
    return pick({
      dateRange: [response.start, response.end],
      invitees: response.eventUsers,
      persons: response.eventPersons,
      ...response
    }, [
      "id",
      "calendar",
      "description",
      "invitees",
      "persons",
      "updateRecurring",
      "name",
      "notes",
      "recurrence",
      "recurringId",
      "site",
      "status",
      "type",
      "vehicle","dateRange"])
  },
);

export const addEvent = createAsyncThunk(
  'events/post',
  async ({ formValue }, { dispatch, getState }) => {
    const viewType = getViewType(getState());
    const postResponse = await POST(`v1/admin/${viewType.toLowerCase()}/events`, {  ...formValue })
    dispatch(getEventsList({ viewType }))
    return postResponse;
  },
);

export const updateEvent = createAsyncThunk(
  'events/put',
  async ({ formValue }, { dispatch, getState }) => {
    const viewType = getViewType(getState());
    const { id, recurringId, updateRecurring } = getEditEvent(getState())
    const postResponse = await PUT(`v1/admin/${viewType.toLowerCase()}/events/${id}`, { recurringId, updateRecurring, ...formValue })
    dispatch(getEventsList({ viewType }))
    return postResponse;
  },
);

export const SchedulerAction = {
  setEvents: ({ viewType }) => (dispatch) => {
    dispatch(getEventsList({ viewType }));
  },
};

export const schedulerSlice = createSlice({
  name: 'scheduler',
  initialState,
  reducers: {
    setTaskType: (state, { payload }) => {
      state.taskType = payload;
    },
    setSelectedUsers: (state, { payload }) => {
      state.users = payload
    },
    setSelectedYearAndMonth: (state, { payload: { year, month } }) => {
      state.selectedYear = year
      state.selectedMonth = month
    },
    setEditEvent: (state, { payload }) => {
      state.editEvent = payload
    },
    setViewType: (state, { payload }) => {
      state.viewType = payload
    },
  },
  extraReducers: {
    [getEventsList.pending]: (state) => {
      state.isLoading = true;
      state.isLoadingError = false;
    },
    [getEventsList.rejected]: (state) => {
      state.isLoading = false;
      state.isLoadingError = true;
    },
    [getEventsList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isLoadingError = false;
      state.events = payload;
    },
    [addEvent.pending]: (state) => {
      state.isLoading = true;
    },
    [addEvent.rejected]: (state) => {
      state.isLoading = false;
    },
    [addEvent.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [updateEvent.pending]: (state) => {
      state.isLoading = true;
    },
    [updateEvent.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateEvent.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getEvent.pending]: (state) => {
      state.isLoading = true;
      state.isLoadingError = false;
    },
    [getEvent.rejected]: (state) => {
      state.isLoading = false;
      state.isLoadingError = true;
    },
    [getEvent.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isLoadingError = false;
      state.editEvent = payload;
    },
    [getExportFile.pending]: (state) => {
      state.isFileProcesing = true;
      state.isFileError = false
      state.isFileDownloaded = false
    },
    [getExportFile.rejected]: (state) => {
      state.isFileProcesing = false;
      state.isFileError = true
      state.isFileDownloaded = false
    },
    [getExportFile.fulfilled]: (state, { payload }) => {
      state.isFileProcesing = false;
      state.isFileError = false
      state.isFileDownloaded = true
    },
  },
});

const getScheduleStore = (state) => state.scheduler;

export const { setTaskType, setSelectedUsers, setSelectedYearAndMonth, setViewType, setEditEvent } = schedulerSlice.actions;

export const getEvents = createSelector(getScheduleStore, (state) => filterEvents(state));
export const getCalenderIsLoading = createSelector(getScheduleStore, (state) => state.isLoading);
export const getTaskType = createSelector(getScheduleStore, (state) => state.taskType);
export const getSelectedUsers = createSelector(getScheduleStore, (state) => state.users);
export const getSelectedYear = createSelector(getScheduleStore, (state) => state.selectedYear);
export const getSelectedMonth = createSelector(getScheduleStore, (state) => state.selectedMonth);
export const getEditEvent = createSelector(getScheduleStore, (state) => state.editEvent);
export const getViewType = createSelector(getScheduleStore, (state) => state.viewType);
export const getFileProcessing = createSelector(getScheduleStore, (state) => state.isFileProcesing);
export const getFileIsDownloaded = createSelector(getScheduleStore, (state) => state.isFileDownloaded);

export default schedulerSlice.reducer;
