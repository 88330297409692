import { Progress, Collapse, Radio } from 'antd';
import { serviceFormQuestions } from '../../../../utils/serviceFormQuestions';
import get from 'lodash.get';
import { questionMeta, questionOptions } from '../../../../utils/serviceFormOptions';
import React from 'react';
import { useSelector } from 'react-redux';
import { getSFChamber } from '../../../../features/service-form.store';
import { getUserRole } from '../../../../features/auth.store';

const { Panel } = Collapse;

const header = (key, number) => (
  <>
    <div className="q-header fw-500">{get(questionMeta, [key, '_title'])}</div>
    <div className="q-sub-title fw-500">{number + 1} of 5</div>
  </>
);

const getProgressProps = (answers, keys, chamber) => {
  const options = get(answers, keys) || {};
  const x = Object.values(get(options, 'questions') || {});
  let isAllPassed = !x.some((a) => a.pass === false);

  let staticAnswersCount = Object.values(options).filter((val) => !!val).length;
  if (options.questions) {
    staticAnswersCount--;
  }

  const allOptions = get(questionOptions, keys).filter(({ conditions }) => {
    return !(conditions && !conditions(chamber));
  }).length;

  const res = { percent: ((x.length + staticAnswersCount) / allOptions) * 100 };

  if (keys[1] === 'auraFXGasMonitoring') {
    staticAnswersCount = 0;
    isAllPassed = true;
    if (options.masterBoardSoftwareVersion) {
      staticAnswersCount += 1;
    }
    if (options.displayBoardSoftwareVersion) {
      staticAnswersCount += 1;
    }
    const c =
      options?.sensorReadings?.reduce((p, c) => {
        isAllPassed = isAllPassed && c.calibrated?.pass !== false;
        return p + Object.values(c).filter((v) => !!v).length+1;
      }, 0) || 0;
    const sac = staticAnswersCount + c;
    res.percent = (sac / allOptions) * 100;
  } else if (keys[1] === 'batteryVoltages') {
    let questionAnswer;
    if (options.questions) {
      questionAnswer = get(Object.values(options.questions), [0, 'pass']);
    }
    const p = questionAnswer === true || questionAnswer === false ? 50 : 0;
    const failed = questionAnswer === false;

    const batteryCount = options.recordBatteryVoltages || [];
    const hasAnyValue = batteryCount.some((data) => !!data);
    isAllPassed = !batteryCount.some((v) => !isNaN(v) && parseFloat(v) < 12.5) && !failed;
    res.percent = p + (hasAnyValue ? 50 : 0);
  }

  if (isAllPassed) {
    res.status = 'Success';
    res.strokeColor = '#008540';
  } else {
    res.status = 'exception';
    res.strokeColor = 'red';
  }
  res.showInfo = x.length + staticAnswersCount >= allOptions;
  if (keys[1] === 'auraFXGasMonitoring' || keys[1] === 'batteryVoltages') {
    res.showInfo = res.percent >= 100;
  }
  return res;
};

const getOptions = (key, chamber, answers, userType) =>
  Object.keys(questionMeta[key])
    .filter((k) => {
      if (k === '_title') {
        return false;
      }
      const conditions = get(questionMeta, [key, k, 'conditions']);
      if (conditions) {
        return conditions(chamber, userType);
      }
      return true;
    })
    .map((k) => ({
      label: (
        <div className="q-selector">
          {get(questionMeta, [key, k, 'title'])}
          <Progress type="circle" {...getProgressProps(answers, [key, k], chamber, userType)} width={20} />
        </div>
      ),
      value: [key, k].join('-'),
    }));

export const QuestionLeftPanel = ({ onSelectionChange, selected, answers, onMainItemChanges }) => {
  const chamber = useSelector(getSFChamber);
  const userType = useSelector(getUserRole);

  return (
    <Collapse
      accordion
      expandIcon={() => null}
      defaultActiveKey={['refugeChamberExternal']}
      ghost
      onChange={(k) => {
        if (k === 'notes') {
          onSelectionChange({ target: { value: 'notes' } });
        }
        onMainItemChanges();
      }}
    >
      {Object.keys(serviceFormQuestions).map((key, index) => {
        return (
          <Panel header={header(key, index)} key={key}>
            <Radio.Group
              value={selected}
              onChange={onSelectionChange}
              options={getOptions(key, chamber, answers, userType)}
              optionType="button"
            />
          </Panel>
        );
      })}
    </Collapse>
  );
};
