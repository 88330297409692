import { ROLE } from './features';
import get from 'lodash.get';
import { format } from 'date-fns';
import { DATE_FORMAT } from './constant';

export const toAPIError = (axiosError) => {
  return {
    status: axiosError.response.status,
    error: axiosError.response.data,
  };
};

export const resizeCloudinaryImages = (url, width, height) => {
  if (!url) {
    return url;
  }
  if (!url.startsWith('https://res.cloudinary.com')) {
    return url;
  }
  return url.replace(/\/v\d{10}\//g, `/w_${width},h_${height}/`);
};

export const contactUtil = (id) => {
  switch (id) {
    case 0:
      return { key: 'mainContact', label: 'Main Contact' };
    case 1:
      return { key: 'secondContact', label: 'Second Contact' };
    case 2:
      return { key: 'thirdContact', label: 'Third Contact' };
    case 3:
      return { key: 'fourthContact', label: 'Fourth Contact' };
  }
};

export const yesNo = (bool) => (bool ? 'Yes' : 'No');

export const toQuestionHashmap = (result) => {
  const out = Object.assign({}, result);
  Object.keys(out).forEach((k1) => {
    if (typeof out[k1] === 'object') {
      Object.keys(out[k1]).forEach((k2) => {
        if (out[k1][k2].questions) {
          out[k1][k2].questions = out[k1][k2].questions.reduce((p, c) => {
            p[c.question] = c;
            return p;
          }, {});
        }
      });
    }
  });
  return out;
};

export const toQuestionArray = (result) => {
  const out = JSON.parse(JSON.stringify(result));
  Object.keys(out).forEach((k1) => {
    if (typeof out[k1] === 'object' && k1 !== 'note') {
      Object.keys(out[k1]).forEach((k2) => {
        if (out[k1][k2] && out[k1][k2].questions) {
          out[k1][k2].questions = Object.values(out[k1][k2].questions);
        }
      });
    }
  });
  return out;
};

export const isAdminApiType = (userType) => {
  return [ROLE.TA, ROLE.SU, ROLE.ST].indexOf(userType) === -1;
};

export const isUserEnabledToFixes = (userType) => {
  return [ROLE.TA, ROLE.SU, ROLE.SC].indexOf(userType) === -1;
};

export const isCompanyFieldHideInUserEdit= (userType) => {
  return [ROLE.MAV, ROLE.SA, ROLE.ST, ROLE.SC].indexOf(userType) === -1;
};

export const monthToNumbers = (month) => {
  if (month === 'j-a') {
    return { from: '01', to: '04' };
  }
  if (month === 'm-a') {
    return { from: '05', to: '08' };
  }
  if (month === 's-d') {
    return { from: '09', to: '12' };
  }
};

const pathList = {
  consumables: [
    ['freePourScrubberChemicals.co2Expiry', 'Free pour scrubber chemicals > CO2 expiry'],
    ['freePourScrubberChemicals.coExpiry', 'Free pour scrubber chemicals > CO expiry'],
    ['freePourScrubberChemicals.moistureRemovalExpiry', 'Free pour scrubber chemicals > Moisture removal expiry'],
    ['cartridgeScrubberChemicals.co2Expiry', 'Cartridge scrubber chemicals > CO2 expiry'],
    ['cartridgeScrubberChemicals.coExpiry', 'Cartridge scrubber chemicals > CO expiry'],
    ['manualGasSamplingPump.o2GasTubesExpiry', 'Manual gas sampling pump > Oxygen (O2) gas tubes expiry'],
    ['manualGasSamplingPump.co2GasTubesExpiry', 'Manual gas sampling pump > Carbon Dioxide (CO2) gas tubes expiry'],
    ['manualGasSamplingPump.coGasTubesExpiry', 'Manual gas sampling pump > Carbon Monoxide (CO) gas tubes expiry'],
    ['oxygenCandle.oxygenCandleExpiry', 'Oxygen candle > Oxygen candle expiry'],
    ['oxygenCandle.mpogIgnitionMatchExpiry', 'Oxygen candle > MPOG ignition match expiry'],
    ['firstAidWaterAndLongLifeFood.firstAidKitExpiry', 'First aid, water and long-life food > First aid kit expiry'],
    ['firstAidWaterAndLongLifeFood.drinkingWaterExpiry', 'First aid, water and long-life food > Drinking water expiry'],
    ['firstAidWaterAndLongLifeFood.longLifeFoodExpiry', 'First aid, water and long-life food > Long-life food expiry'],
  ],
  refugeChamberInternal: [['toilet.chemicalExpiry', 'Toilet condition > Toilet deoderiser chemical expiry']],
};

export const getIssueLists = (report) => {
  if (!report) {
    return [];
  }

  let highPriorityCount= 0, mediumPriorityCount= 0, lowPriorityCount =0, totalFails=0

  const servicedDate = report.servicedDate;
  const getQuestions = (obj, expiryPaths) => {
    const result = Object.values(obj)
      .map((v) => v.questions || [])
      .reduce((p, c) => {
        return [...p, ...c];
      }, [])
      .filter((q) => !q.pass);

    expiryPaths.forEach(([key, title]) => {
      const value = get(obj, key);
      if (value) {
        const note = `${title} is ${format(new Date(value), DATE_FORMAT)}`;
        if (value <= servicedDate) {
          result.push({
            note,
            pass: false,
            question: key,
            priority: 'HIGH',
            disabled: true
          });
        } else if (servicedDate < value &&  value <= servicedDate + 120000 * 3600 * 24) {
          result.push({
            note,
            pass: false,
            question: key,
            priority: 'MEDIUM',
            disabled: true
          });
        }
      }
    });
    
    return result;
  };
  const temp = [
    ...getQuestions(report.refugeChamberExternal, []),
    ...getQuestions(report.refugeChamberInternal, pathList.refugeChamberInternal),
    ...getQuestions(report.consumables, pathList.consumables),
    ...getQuestions(report.chamberOptions, []),
  ];
  const out = { HIGH: [], LOW: [], MEDIUM: [] };
  temp.forEach((issue) => {
    out[issue.priority].push(issue);
  });

  highPriorityCount += out.HIGH.length
  mediumPriorityCount += out.MEDIUM.length
  lowPriorityCount += out.LOW.length
  totalFails += (out.HIGH.length + out.MEDIUM.length + out.LOW.length)

  return {
    issuesList: [...out.HIGH, ...out.MEDIUM, ...out.LOW],
    summary: {
      highPriorityCount,
      mediumPriorityCount,
      lowPriorityCount,
      totalFails
    }
  };
};
