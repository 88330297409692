import React from 'react';
import { CaretUpFilled, CaretDownFilled } from '@ant-design/icons';
import { Input, Button } from 'antd';

const SubPageHeader = ({ searchLoading = false, searchPlaceholder, onSearch, isAscendingOrder, onChangeOrder, defaultValue }) => {
  return (
    <div className='d-flex f-row jc-space-between sub-page-header'>
      <div className='left'>
        <Input.Search 
          defaultValue={defaultValue}
          placeholder={searchPlaceholder}
          enterButton 
          size="large" 
          loading={searchLoading}
          onSearch={onSearch}
        />
      </div>
      {
        isAscendingOrder != null && <div className='right'>
          <Button 
            onClick={onChangeOrder} 
            type="primary" 
            icon={isAscendingOrder ? <CaretDownFilled/> : <CaretUpFilled/>} 
            size='large'
          />
        </div>
      }
    </div>
  );
};

export default SubPageHeader;