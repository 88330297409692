import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { get } from 'lodash'
import { GET, POST, POST_IMAGE, PUT, DELETE } from '../service/base-api-service';
import to from '../utils/to';
import { DEFAULT_PAGE_SIZE } from '../utils/constant';
import { getIsAdminApi, getUser } from './auth.store';

const initialState = {
  userList: [],
  isLoading: false,
  tableInfo: {
    company: '',
    totalPages: 0,
    currentPage: -1,
    sortColumn: 'id',
    isAscending: true,
    searchKey: ''
  },
  editingUser: null,
  editingUserLoading: false,
  isLoadingError: false,
  eventUserList: [],
  eventUserPersonList: []
};

export const fetchEventUserList = createAsyncThunk('events/userlist/get', ({ viewType }) => GET(`v1/admin/${viewType.toLowerCase()}/events/users`));
export const fetchEventUserPersonList = createAsyncThunk('events/userpersonlist/get', ({ viewType }) => GET(`v1/admin/${viewType.toLowerCase()}/events/users?persons=true`));

export const getPaginatedUserList = createAsyncThunk(
  'userList/get',
  async ({ page, sortColumn = 'id', isAscending = false, company, searchKey }, { dispatch, getState }) => {
    dispatch(setTableInfo({ currentPage: page, sortColumn, isAscending, company, searchKey }));

    let url = 'v1/users';
    let params = { page, sortColumn, isAscending, size: DEFAULT_PAGE_SIZE, searchKey }
    if (getIsAdminApi(getState())) {
      url = 'v1/admin/users';
      if(company){
        params = {...params, tenantId: company}
      }
    }

    return GET(url, { params });
  },
);

export const fetchUserById = createAsyncThunk('user/get-by-id', async ({ id },{ dispatch }) => {
  const data = await GET('v1/admin/users/' + id);
  dispatch(UsersAction.setSelectedCompanyToTable(data.tenantIdentifier));
  return data;
});

export const deleteUserById = createAsyncThunk('user/delete-by-id', async ({ id },  { dispatch, getState }) => {
  try{
    const response = await DELETE('v1/admin/users/' + id);
    const tableInfo = getUserTableInfo(getState());
    dispatch(getPaginatedUserList({ page: 0, ...tableInfo }));
    return null;
  }catch(err){
    return err
  }
});

export const addNewUser = createAsyncThunk(
  'user/addNew',
  async ({ formValue, image, isEditing }, { rejectWithValue }) => {
    let err, data;
    if (image) {
      [err, data] = await to(POST_IMAGE(image));
      if (err) {
        return rejectWithValue({ err });
      }
    }
    if (isEditing) {
     return PUT('v1/admin/users/' + formValue.id, { ...formValue, profilePictureURL: data ? data.secure_url : null });
    }
    return POST('v1/admin/users/register', { ...formValue, profilePictureURL: data ? data.secure_url : null });
  },
);

export const updateUser = createAsyncThunk('user/update', async ({ userId, body }) => {
  return PUT('v1/admin/users/' + userId, body);
});

export const UsersAction = {
  setPage: (page) => (dispatch, getState) => {
    const tableInfo = getUserTableInfo(getState());
    if (tableInfo.currentPage !== page) dispatch(getPaginatedUserList({ 
      page,
      sortColumn: tableInfo.sortColumn, 
      isAscending: tableInfo.isAscending, 
      company: tableInfo.company, 
      searchKey: tableInfo.searchKey
    }));
  },
  setSelectedCompany: (company) => (dispatch, getState) => {
    const previousCompany = getUserTableInfo(getState()).company;
    if (company !== previousCompany) {
      dispatch(getPaginatedUserList({ page: 0, company, isAscending: false, sortColumn: 'id', searchKey: '' }));
    }
  },
  setSelectedCompanyToTable: (company) => (dispatch, getState) => {
    const previousCompany = getUserTableInfo(getState()).company;
    if (company !== previousCompany) {
      dispatch(setTableInfo({ site: null, company}));
    }
  },
  setSortColumn: ({sortColumn, isAscending}) => (dispatch, getState) => {
    const tableInfo = getUserTableInfo(getState());
    dispatch(getPaginatedUserList({ 
      page: 0,
      ...tableInfo, 
      sortColumn, 
      isAscending
    }));
  },
  setSearch: (searchKey) => (dispatch, getState) => {
    const tableInfo = getUserTableInfo(getState());
    dispatch(getPaginatedUserList({
      page: tableInfo.currentPage, 
      sortColumn: tableInfo.sortColumn, 
      isAscending: tableInfo.isAscending, 
      company: tableInfo.company, 
      searchKey
    }))
  }
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setTableInfo: (state, { payload }) => {
      state.tableInfo = { ...state.tableInfo, ...payload };
    },
  },
  extraReducers: {
    [getPaginatedUserList.pending]: (state) => {
      state.isLoading = true;
    },
    [getPaginatedUserList.rejected]: (state) => {
      state.isLoading = false;
      state.isLoadingError = true;
    },
    [getPaginatedUserList.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { results, currentPage, pageSize, totalPages } = action.payload;
      state.userList = results;
      state.tableInfo = { ...state.tableInfo, currentPage, pageSize, totalPages };
    },
    [addNewUser.pending]: (state) => {
      state.editingUserLoading = true;
    },
    [addNewUser.rejected]: (state) => {
      state.editingUserLoading = false;
    },
    [addNewUser.fulfilled]: (state) => {
      state.tableInfo.currentPage = -1;
      state.editingUserLoading = false;
    },
    [updateUser.pending]: (state) => {
      state.isLoading = true;
    },
    [updateUser.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.userList = state.userList.map((user) => (user.id === payload.id ? payload : user));
      state.isLoading = false;
    },
    [fetchUserById.pending]: (state) => {
      state.editingUserLoading = true;
      state.editingUser = null;
    },
    [fetchUserById.rejected]: (state) => {
      state.editingUserLoading = false;
    },
    [fetchUserById.fulfilled]: (state, { payload }) => {
      state.editingUserLoading = false;
      state.editingUser = payload;
    },
    [fetchEventUserList.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchEventUserList.rejected]: (state) => {
      state.isLoading = false;
      state.isLoadingError = true;
    },
    [fetchEventUserList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.eventUserList =  action.payload;
    },
    [fetchEventUserPersonList.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchEventUserPersonList.rejected]: (state) => {
      state.isLoading = false;
      state.isLoadingError = true;
    },
    [fetchEventUserPersonList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.eventUserPersonList =  action.payload;
    },
  },
});

export const { setTableInfo } = userSlice.actions;

const getUserStore = (state) => state.user;

export const getUsersList = createSelector(getUserStore, (state) => state.userList);
export const getEventUserList = createSelector(getUserStore, (state) => state.eventUserList);
export const getEventUserPersonList = createSelector(getUserStore, (state) => state.eventUserPersonList);
export const getUsersLoading = createSelector(getUserStore, (state) => state.isLoading);
export const getUserTableInfo = createSelector(getUserStore, (state) => state.tableInfo);

export const getEditingUser = createSelector(getUserStore, (store) => store.editingUser);
export const getEditingUserLoading = createSelector(getUserStore, (store) => store.editingUserLoading);

export default userSlice.reducer;
