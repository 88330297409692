import React, {useState, useEffect} from "react";
import { Modal } from "antd";
import { DateRangeSelector } from "./drop-downs-for-enums";
import { isEmpty} from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { getExportFile, getFileProcessing, getFileIsDownloaded } from "../../../features/scheduler.store";

const ExportEventReport = ({
    show,
    onCancel
}) => {

    const [dateRange, setDateRange] = useState([])
    const dispatch = useDispatch()
    const isFileProcessing = useSelector(getFileProcessing)
    const isFileIsDownloaded = useSelector(getFileIsDownloaded)

    useEffect(()=>{
        if(isFileIsDownloaded){
            onCancel()
        }
    }, [isFileIsDownloaded])

    const handleOnSubmit = () => {
        dispatch(getExportFile({ from: dateRange[0], to: dateRange[1] }))
    }

    return (
        <Modal
            visible={show}
            width={450}
            onCancel={onCancel}
            title={`Export CSV`}
            okText="Export"
            onOk={handleOnSubmit}
            okButtonProps={{
                disabled: isEmpty(dateRange),
                loading: isFileProcessing
            }}
        >
            <div className="d-flex space-all">
                <DateRangeSelector onChange={(data)=>setDateRange(data)}/>
            </div>
        </Modal>
    )
}

export default ExportEventReport