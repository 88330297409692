import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { nonRetryPOST, POST, POST_IMAGE, PUT } from '../service/base-api-service';
import jwtDecode from 'jwt-decode';
import to from '../utils/to';
import history from '../utils/history';
import { fromFeatureMatrix, ROLE } from '../utils/features';
import eng from '../utils/eng';
import { isAdminApiType, isUserEnabledToFixes } from '../utils/utils';

const UserLogoutAction = createAction('USER_LOGOUT');

const initialState = {
  authData: {},
  isAuthenticated: false,
  isLoading: false,
  isValidating: true,
  isInitialLogin: false,
  changeFormLoading: false,
};

export const loginUser = createAsyncThunk(
  'auth/login',
  async ({ email, password, rememberMe }, { rejectWithValue }) => {
    try {
      const [err, result] = await to(
        nonRetryPOST('v1/token/generate', { email, password }, {}, { noAuthHeader: true }),
      );
      if (err) {
        return rejectWithValue(err);
      }
      localStorage.setItem('accessToken', result.accessToken);
      if (rememberMe) {
        localStorage.setItem('refreshToken', result.refreshToken);
      }
      return result;
    } catch (e) {
      return rejectWithValue({ message: eng.somethingWentWrong });
    }
  },
);

export const forgotPassword = createAsyncThunk('auth/forgot-password', async ({ email }, { rejectWithValue }) => {
  const [err, result] = await to(nonRetryPOST('v1/users/password/forgot', { email }, {}, { noAuthHeader: true }));
  if (err) {
    return rejectWithValue(err);
  }
  return result;
});

export const changeForgotPassword = createAsyncThunk(
  'auth/change-forgot-password',
  async ({ token, password }, { rejectWithValue }) => {
    const [err, result] = await to(
      nonRetryPOST(
        'v1/users/password/forgot/change',
        { verificationToken: token, newPassword: password },
        {},
        { noAuthHeader: true },
      ),
    );
    if (err) {
      return rejectWithValue(err);
    }
    return result;
  },
);

export const validateByRefreshToken = createAsyncThunk('auth/validate', async (payload, { rejectWithValue }) => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      return {};
    }
    const [err, result] = await to(nonRetryPOST('v1/token/refresh', { refreshToken }, {}, { noAuthHeader: true }));
    if (err) {
      localStorage.setItem('accessToken', '');
      localStorage.setItem('refreshToken', '');
      history.push('/login');
      return rejectWithValue(err);
    }
    localStorage.setItem('accessToken', result.accessToken);
    if (!history.location.pathname.startsWith('/dashboard')) {
      history.push('/dashboard');
    }
    return result;
  } catch (e) {
    return rejectWithValue({ message: eng.somethingWentWrong });
  }
});

export const logout = () => (dispatch) => {
  localStorage.setItem('refreshToken', '');
  localStorage.setItem('accessToken', '');
  dispatch(UserLogoutAction());
  history.push('/login');
  dispatch(logoutUser());
};

export const changePassword = createAsyncThunk('auth/change-password', async ({ password, newPassword }) => {
  return await POST('v1/users/password/change', {
    password,
    newPassword,
  });
});

export const updateProfileInfo = createAsyncThunk(
  'auth/update-info',
  async ({ fullName, telephoneNumber, image }, { rejectWithValue }) => {
    let err, data;
    if (image) {
      [err, data] = await to(POST_IMAGE(image));
      if (err) {
        return rejectWithValue({ err });
      }
    }
    const payload = { fullName, telephoneNumber };
    if (data) {
      payload.profilePictureURL = data.secure_url;
    }
    return await PUT('v1/users/', payload);
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    logoutUser: (state) => {
      state.authData = {};
      state.isAuthenticated = false;
      state.isValidating = false;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.isLoading = true;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.authData = jwtDecode(action.payload.accessToken);
      state.isAuthenticated = true;
      state.isLoading = false;
      state.isInitialLogin = true;
      state.isValidating = false;
    },
    [loginUser.rejected]: (state) => {
      state.isLoading = false;
    },
    [forgotPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [forgotPassword.rejected]: (state) => {
      state.isLoading = false;
    },
    [forgotPassword.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [validateByRefreshToken.pending]: (state) => {
      state.isValidating = true;
    },
    [validateByRefreshToken.rejected]: (state) => {
      state.isAuthenticated = false;
      state.isValidating = false;
    },
    [validateByRefreshToken.fulfilled]: (state, action) => {
      state.isAuthenticated = true;
      state.isValidating = false;
      if (action.payload && action.payload.accessToken) {
        state.authData = jwtDecode(action.payload.accessToken);
      }
    },
    [changePassword.pending]: (state) => {
      state.changeFormLoading = true;
    },
    [changePassword.rejected]: (state) => {
      state.changeFormLoading = false;
    },
    [changePassword.fulfilled]: (state) => {
      state.changeFormLoading = false;
    },
    [updateProfileInfo.pending]: (state) => {
      state.changeFormLoading = true;
    },
    [updateProfileInfo.rejected]: (state) => {
      state.changeFormLoading = false;
    },
    [updateProfileInfo.fulfilled]: (state, { payload }) => {
      state.changeFormLoading = false;
      state.authData.user_full_name = payload.fullName;
      state.authData.user_telephone_number = payload.telephoneNumber;
      state.authData.user_profile_picture = payload.profilePictureURL;
    },
  },
});

export const { setLoading, logoutUser } = authSlice.actions;

const getAuthStore = (state) => state.auth;
// selectors
export const getIsAuthenticated = createSelector(getAuthStore, (state) => state.isAuthenticated);
export const getUser = createSelector(getAuthStore, (state) => state.authData);
export const getLoginInProgress = createSelector(getAuthStore, (state) => state.isLoading);
export const getIsValidating = createSelector(getAuthStore, (state) => state.isValidating);
export const getIsInitialLogin = createSelector(getAuthStore, (state) => state.isInitialLogin);

export const getUserRole = createSelector(getUser, (user) => user.scope);
export const isFeatureEnabled = createSelector(getUserRole, (role) => (key) => fromFeatureMatrix(role, key));
export const getIsSuperAdmin = createSelector(getUserRole, (role) => role === ROLE.SA);
export const getIsAdminApi = createSelector(getUserRole, (role) => isAdminApiType(role));
export const getIsTrainingCoordinator = createSelector(getUserRole, (role) => role === ROLE.TC);
export const getIsBusinessDevelopmentManager = createSelector(getUserRole, (role) => role === ROLE.BDM);
export const getIsUserEnabledToFixes = createSelector(getUserRole, (role) => isUserEnabledToFixes(role));
export const getChangeFormLoading = createSelector(getAuthStore, (store) => store.changeFormLoading);

export default authSlice.reducer;
