import React from "react";
import { Space } from "antd";
import Search from "antd/lib/transfer/search";
import { CommonDropdown } from "../../../helpers/drop-downs-for-enums";

const mockItems = [
    { value: 'one', label: 'Item 1' },
    { value: 'two', label: 'Item 2' },
    { value: 'three', label: 'Item 3' }]


export const TopBar = () => {
    return (
        <Space direction="horizontal" className="filter-bar">
            <CommonDropdown
                options={mockItems}
                placeholder="Chamber Type"
            />
            <Search placeholder="Search by serial #" />
        </Space>
    )
}