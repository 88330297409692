import React, {useState} from "react";
import SubPageLayout from "../../../helpers/SubPageLayout";
import { DatePicker } from "antd";
import { TopBar } from "./topBar";
import MyTable from "../../../helpers/MyTable";
import { mockCalender } from "../../../../../features/mockData/build";
import { MonthSelector, StatusSelector } from "./components";
import moment from "moment";
import AdminDrawer from './drawer'
import './styles.scss'

const columns = [
    { title: 'Serial #', dataIndex: ['chamberInfo','name'], sorter: true, width: 150},
    { title: 'Customer', dataIndex: ['chamberInfo','site','mainContact','fullName'], sorter: true, width: 150},
    { title: 'Site', dataIndex: ['chamberInfo','site','name'], sorter: true, width: 150 },
    { title: 'Type', dataIndex: ['chamberInfo','chamberTypeEnum','displayName'], sorter: true, width: 150 },
    { title: 'Model', dataIndex: ['chamberInfo','rspKitNo'], sorter: true, width: 150 },
    { title: 'Invoice Month', dataIndex: ['chamberInfo','nextServiceDate'], sorter: true, render: MonthSelector, width: 150 },
    { title: 'Original Confirmed ETD', dataIndex: ['chamberInfo','nextServiceDate'], sorter: true, render: (value) => <DatePicker value={moment(value)}/> , width: 180},
    { title: 'Scheduled Completion Date', dataIndex: ['chamberInfo','nextServiceDate'], sorter: true, render: (value) => <DatePicker value={moment(value)}/> , width: 180},
    { title: 'Actual Completion Date', dataIndex: ['chamberInfo','nextServiceDate'], sorter: true, render: (value) => <DatePicker value={moment(value)}/>, width: 180 },
    { title: 'Shipping Date', dataIndex: ['chamberInfo','nextServiceDate'], sorter: true, render: (value) => <DatePicker value={moment(value)}/> , width: 180},
    { title: 'Fabricator', dataIndex: ['chamberInfo','nextServiceDate'], sorter: true, render: (value) => <DatePicker value={moment(value)}/> , width: 180},
    { title: 'ETA From Paint', dataIndex: ['chamberInfo','nextServiceDate'], sorter: true, render: (value) => <DatePicker value={moment(value)}/>, width: 180 },
    { title: 'Pick By', dataIndex: ['chamberInfo','nextServiceDate'], sorter: true, render: (value) => <DatePicker value={moment(value)}/> , width: 180},
    { title: 'Status', dataIndex: ['chamberInfo','status'], sorter: true, render: StatusSelector , width: 150},
  ];

const AdminView = () => {

    const [isOpen, setDrawerIsOpen ] = useState(false)

    const handlOnSelect = (record, rowIndex) => {
      return {
        onClick: event => setDrawerIsOpen(true)
      };
    }

    return (
        <SubPageLayout title="Build > Admin View">
            <TopBar />
            <div className="d-flex build-admin-view">
                <MyTable 
                    columns={columns}
                    loading={false}
                    rowKey="id"
                    pagination={{
                        pageSize: 10,
                      }}
                      scroll={{
                        y: 350,
                      }}
                    dataSource={mockCalender}
                    onRow ={handlOnSelect}
                />
            </div>
            <AdminDrawer title="MA 1849" isOpen={isOpen} onClose={()=>setDrawerIsOpen(false)}/>
        </SubPageLayout>
    )
}

export default AdminView