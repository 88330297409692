export const DEFAULT_PAGE_SIZE = 25;
export const UPDATE_ON_FORMAT = 'HH:mm aa, MMM dd yyyy';
export const DATE_FORMAT = 'dd/MM/yyyy';
export const phoneRegEx =
  /^((\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const serviceFormTypes = [
  {
    name: 'SERVICE',
    displayName: 'Conduct Service',
  },
  {
    name: 'AUDIT',
    displayName: 'Conduct Audit',
  },
  {
    name: 'COMMISSION',
    displayName: 'Conduct Commission',
  },
];

export const serviceFormTypesObj = {
  SERVICE: {
    name: 'SERVICE',
    displayName: 'Conduct Service',
    action: 'a service',
  },
  AUDIT: {
    name: 'AUDIT',
    displayName: 'Conduct Audit',
    action: 'an audit',
  },
  COMMISSION: {
    name: 'COMMISSION',
    displayName: 'Conduct Commission',
    action: 'a commission',
  },
};

export const SCHEDULE_VIEW_TYPE = {
  EMP_SCHEDULE: 'SERVICE',
  PRODUCT: 'PRODUCT',
  TRAINING: 'TRAINING'
}

export const statusMapper =  {
  [SCHEDULE_VIEW_TYPE.EMP_SCHEDULE]: {
    "RECURRING_APPOINTMENT_NOT_YET_APPROVED_BY_CLIENT": {
        backgroundColor: '#fbafaf',
        color: '#ff0000',
        title: 'Recurring Appointment, not yet approved by client'
    },
    "AWAITING_CLIENT_CONFIRMATION": {
        backgroundColor: '#ffdc96',
        color: '#ff8100',
        title: 'Awaiting Client Confirmation'
    },
    "CONFIRMED": {
        backgroundColor: '#5cff3fe6',
        color: '#126202',
        title: 'Confirmed'
    },
    "CAR": {
        backgroundColor: '#9fcdff',
        color: '#0947d9',
        title: 'Car'
    },
    "AL_OR_DAYS_IN_OFFICE_OR_PH": {
        backgroundColor: '#c1a5ff',
        color: '#3d009a',
        title: 'AL/Days in Office/PH'
    },
    "REFURB_AND_SERVICE_SCHOOL": {
        backgroundColor: '#fff782',
        color: '#d09202',
        title: 'Refurb, Service School'
    },
  },
  [SCHEDULE_VIEW_TYPE.PRODUCT]: {
    "RECURRING_APPOINTMENT_NOT_YET_APPROVED_BY_CLIENT": {
        backgroundColor: '#fbafaf',
        color: '#ff0000',
        title: 'Recurring Appointment, not yet approved by client'
    },
    "AWAITING_CLIENT_CONFIRMATION": {
        backgroundColor: '#ffdc96',
        color: '#ff8100',
        title: 'Awaiting Client Confirmation'
    },
    "CONFIRMED": {
        backgroundColor: '#5cff3fe6',
        color: '#126202',
        title: 'Confirmed'
    },
    "CAR": {
        backgroundColor: '#9fcdff',
        color: '#0947d9',
        title: 'Car'
    },
    "AL_OR_DAYS_IN_OFFICE_OR_PH": {
        backgroundColor: '#c1a5ff',
        color: '#3d009a',
        title: 'AL/Days in Office/PH'
    },
    "REFURB_AND_SERVICE_SCHOOL": {
        backgroundColor: '#fff782',
        color: '#d09202',
        title: 'Refurb, Service School'
    },
  },
  [SCHEDULE_VIEW_TYPE.TRAINING]: {
    "AWAITING_CLIENT_CONFIRMATION": {
      backgroundColor: '#ffdc96',
      color: '#ff8100',
      title: 'Awaiting Client Confirmation'
    },
    "CONFIRMED": {
      backgroundColor: '#5cff3fe6',
      color: '#126202',
      title: 'Confirmed'
   },
  }
}

export const getStatusMapper = (viewType,statusKey) => {
  return statusMapper[viewType][statusKey] || {
    backgroundColor: '#ffffff',
    color: '#000',
    title: 'Status Not Defined'
  }
}

export const SITE_SERVICE_TECHNICIAN_ROLE_ID = '7';
export const SITE_SERVICE_TECHNICIAN_ROLE_NAME = 'Site Service Technician Role';
export const MINE_ARC_SERVICE_TECHNICIAN_ROLE_ID = '3';

export const YesNo = [
  {
    displayName: 'YES',
    value: 'YES'
  },
  {
    displayName: 'NO',
    value: 'NO'
  }
]

export const OnSiteOffSite = [
  {
    displayName: 'OFFICE',
    value: 'OFFICE'
  },
  {
    displayName: 'ON SITE',
    value: 'ONSITE'
  }
]

export const recurrenceTypes = [
  {
    displayName: 'NONE',
    value: 'NONE'
  },
  {
    displayName: 'ANNUALLY',
    value: 'ANNUALLY'
  },
  {
    displayName: 'DAILY',
    value: 'DAILY'
  },
  {
    displayName: 'FORTNIGHT',
    value: 'FORTNIGHT'
  },
  {
    displayName: 'FOUR MONTHLY',
    value: 'FOUR_MONTHLY'
  },
  {
    displayName: 'HALF YEARLY',
    value: 'HALF_YEARLY'
  },
  {
    displayName: 'QUARTERLY',
    value: 'QUARTERLY'
  },
  {
    displayName: 'WEEKLY',
    value: 'WEEKLY'
  },
]
export const USER_TYPES = {
  MINE_ARC_USER :{
    id: '2',
    name: 'MineArc User Role'
  },
  MINE_ARC_SERVICE_TECHNICIAN: {
    id: '3',
    name: 'MineArc Service Technician Role'
  },
  MINE_ARC_SERVICE_COORDINATOR: {
    id: 4,
    name: 'MineArc Service Coordinator Role'
  },
  SITE_SERVICE_TECHNICIAN:{
    id: '7',
    name: 'Site Service Technician Role'
  },
  ROLE_SITE_USER:{
    id: '6',
    name: 'Site User Role'
  },
  SERVICE_PROVIDER:{
    id: '10',
    name: 'Service Provider'
  }
}
