import React from "react";
import { Space, Button } from "antd";
import { LabelCommon } from '../components/labels'
import { LeftCircleOutlined, RightCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { CommonDropdown } from "../../../helpers/drop-downs-for-enums";
import moment from "moment";

const mockItems = [
    { value: 'one', label: 'Item 1' },
    { value: 'two', label: 'Item 2' },
    { value: 'three', label: 'Item 3' }]

export const DateController = ({onChange, date}) =>{

    const handleUp = () => {
        onChange(moment(date).add(1,'months'))
    }

    const handleDown = () => {
        onChange(moment(date).add(-1,'months'))
    }

    return (
        <div className="d-flex date-controller">
            <LeftCircleOutlined className="button" onClick={handleDown}/>
            <RightCircleOutlined className="button" onClick={handleUp}/>
            <LabelCommon fontSize={18} fontWeight={800} className="text">{date.format('MMM, yyyy')}</LabelCommon>
        </div>
    )
}

export const TopBar = ({onDateChange, dateValue, onRightButtonClick}) => {
    return (
        <Space direction="horizontal" className="filter-bar">
            <DateController onChange={onDateChange} date={dateValue}/>
            <Space wrap className="filter-selector">
                <CommonDropdown
                    options={mockItems}
                    placeholder="Technician"
                />
                <CommonDropdown
                    options={mockItems}
                    placeholder="Task"
                />
                <CommonDropdown
                    options={mockItems}
                    placeholder="Stage"
                />
            </Space>
            <Button onClick={onRightButtonClick}>
                <PlusOutlined className="font-size-bold"/>
                Assign Work
            </Button>
        </Space>
    )
}