import { configureStore, combineReducers } from '@reduxjs/toolkit';
import counterReducer from './features/counter/counterSlice';
import authReducer from './features/auth.store';
import companyReducer from './features/company.store';
import enumReducer from './features/enum.store';
import userReducer from './features/user.store';
import siteReducer from './features/site.store';
import chamberReducer from './features/chamber.store';
import documentReducer from './features/document.store';
import serviceFormReducer from './features/service-form.store';
import reportsReducer from './features/reports.store';
import schedulerReducer from './features/scheduler.store';

const rootReducer = combineReducers({
  counter: counterReducer,
  auth: authReducer,
  company: companyReducer,
  enum: enumReducer,
  user: userReducer,
  site: siteReducer,
  chamber: chamberReducer,
  document: documentReducer,
  serviceForm: serviceFormReducer,
  reports: reportsReducer,
  scheduler: schedulerReducer
});

// reset the store as here: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
export const store = configureStore({
  reducer: (state, action) => {
    if (action.type === 'USER_LOGOUT') {
      return rootReducer(undefined, action);
    }
    return rootReducer(state, action);
  },
});
