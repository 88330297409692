import React, { useEffect, useRef, useState } from 'react';
import SubPageLayout from '../helpers/SubPageLayout';
import { Pagination, Spin } from 'antd';
import ChambersViewTile from '../helpers/ChambersViewTile';

import './styles/chambers-view.scss';
import SearchFilterInput from '../helpers/SearchFilterInput';
import {
  ChambersActions,
  getChamberList,
  getChamberListLoading,
  getChamberTableInfo,
  getPaginatedChamberList,
} from '../../../features/chamber.store';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRole } from '../../../features/auth.store';
import { ROLE } from '../../../utils/features';
import to from '../../../utils/to';
import { getPaginatedSiteList } from '../../../features/site.store';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constant';

const ChambersView = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setPage] = useState(1);

  const dispatch = useDispatch();
  const chambersTableInfo = useSelector(getChamberTableInfo);
  const chambersList = useSelector(getChamberList);
  const chambersListLoading = useSelector(getChamberListLoading);
  const userRole = useSelector(getUserRole);
  // ROLE_SUPER_ADMIN','ROLE_MINE_ARC_SERVICE_TECHNICIAN','ROLE_MINE_ARC_USER','ROLE_MINE_ARC_SERVICE_COORDINATOR
  const companyDropdown = [ROLE.SA, ROLE.MAST, ROLE.MAV, ROLE.SC].indexOf(userRole) > -1;
  const sitesDropdown = [ROLE.SU].indexOf(userRole) === -1;

  useEffect(() => {
    if (!sitesDropdown) {
      (async () => {
        setLoading(true);
        const [err, site] = await to(dispatch(getPaginatedSiteList({})).unwrap());
        if (!err && site) {
          dispatch(getPaginatedChamberList({ site: site.id }));
        }
        setLoading(false);
      })();
    }
  }, [sitesDropdown]);

  return (
    <SubPageLayout title="Chambers" className="chambers-view poppins">
      <div className="site-sub-title fw-400">MineArc System</div>

      <div className="d-flex">
        {companyDropdown && (
          <SearchFilterInput
            placeholder="Search."
            companyDropdown
            companyProps={{
              onChange: (val) => dispatch(ChambersActions.setSelectedCompany(val)),
              selectFirstIfValueIsEmpty: true,
              placeholder: 'Select Company',
              value: chambersTableInfo.company,
            }}
          />
        )}
        {sitesDropdown && (
          <SearchFilterInput
            placeholder="Search."
            sitesDropdown
            siteProps={{
              onChange: (val) => {
                dispatch(ChambersActions.setSelectedSite(val));
                setPage(1);
              },
              selectFirstIfValueIsEmpty: true,
              placeholder: 'Select Site',
              company: chambersTableInfo.company,
              value: chambersTableInfo.site,
            }}
          />
        )}
      </div>

      <Spin className="chambers-list" spinning={chambersListLoading || loading}>
        <div className="d-flex jc-center space-top">
          {!chambersListLoading && !loading && chambersTableInfo.totalPages > 1 && (
            <Pagination
              total={chambersTableInfo.totalPages * DEFAULT_PAGE_SIZE}
              pageSize={DEFAULT_PAGE_SIZE}
              current={currentPage}
              onChange={(page) => {
                dispatch(getPaginatedChamberList({ page: page - 1 }));
                setPage(page);
              }}
            />
          )}
        </div>
        {chambersList.map((c) => (
          <ChambersViewTile {...c} key={c.id} />
        ))}
      </Spin>
    </SubPageLayout>
  );
};

export default ChambersView;
