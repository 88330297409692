import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, Radio, Spin } from 'antd';
import moment from 'moment';
import { useParams, Link } from 'react-router-dom';
import { LoadingOutlined, RightOutlined } from '@ant-design/icons';
import './styles/reports.scss';
import {
  fetchReports,
  getMonth,
  setMonth,
  getReports,
  getReportsLoading,
  getYear,
  setYear,
  getTenant,
  setTenant,
  initiate
} from '../../../features/reports.store';
import SubPageLayout from '../helpers/SubPageLayout';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../../../utils/constant';
import { downloadPdf } from '../../../utils/report-download';
import SearchFilterInput from '../helpers/SearchFilterInput';
import { getIsAdminApi } from '../../../features/auth.store';

const ReportTile = ({ chamberName, servicedDate, siteName, serviceFormId, type, tenantId }) => {
  const [loading, setLoading] = useState(false);
  const isAdmin = useSelector(getIsAdminApi);

  const onDownloadPdf = async () => {
    setLoading(true);
    await downloadPdf(serviceFormId, isAdmin, tenantId);
    setLoading(false);
  };

  return (
    <div className="report-tile d-flex jc-space-between rounded-border bg-white ai-center">
      {type === 'serviced' && (
        <>{loading ? <LoadingOutlined /> : <img src="/icons/download.svg" onClick={onDownloadPdf} />}</>
      )}
      <div className="name">{chamberName}</div>
      <div className="date">{siteName}</div>
      <div className="date">{format(new Date(servicedDate), DATE_FORMAT)}</div>
      {type === 'after-service' && (
        <Link to={`/reports/after-service/${serviceFormId}?tenantId=${tenantId}`}>
          <RightOutlined />
        </Link>
      )}
    </div>
  );
};

const dateRangeFilter = [
  { label: 'Jan-Apr', value: 'j-a' },
  { label: 'May-Aug', value: 'm-a' },
  { label: 'Sep-Dec', value: 's-d' },
];

const Reports = () => {
  const { type } = useParams();
  const reports = useSelector(getReports)(type);
  const selectedYear = useSelector(getYear)(type);
  const selectedMonth = useSelector(getMonth)(type);
  const selectedTenant =  useSelector(getTenant)(type);
  const isLoading = useSelector(getReportsLoading);

  const isAdminType = useSelector(getIsAdminApi);

  const dispatch = useDispatch();

  const changeYear = (value) => {
    dispatch(setYear({type, value: value.year().toString()}));
  };

  const changeTenant = (value) => {
    if(selectedTenant == ''){
      dispatch(initiate(value));
    }else{
      dispatch(setTenant({type, value}));
    }
    
  };

  useEffect(() => {
    if (!isAdminType || selectedTenant) {
      dispatch(fetchReports({ month: 'j-a', type, company: selectedTenant }));
    }
  }, [selectedYear, type, selectedTenant]);

  const onMonthChange = (e) => {
    dispatch(setMonth({type, value: e.target.value}))
    dispatch(fetchReports({ month: e.target.value, type, company: selectedTenant }));
  };

  return (
    <SubPageLayout title="Reports" subtitle={type === 'serviced' ? 'Serviced' : 'After Service'}>
      <div className="search-filter d-flex ai-center">
        {isAdminType && (
          <SearchFilterInput
            placeholder="Search."
            companyDropdown
            selectFirstIfValueIsEmpty
            companyProps={{
              onChange: changeTenant,
              selectFirstIfValueIsEmpty: true,
              placeholder: 'Select Company',
              value: selectedTenant,
            }}
            
          />
        )}
        <DatePicker value={moment(selectedYear)} picker="year" className="w-100" onChange={changeYear} />
      </div>
      <div className="reports poppins">
        <Radio.Group
          options={dateRangeFilter}
          onChange={onMonthChange}
          value={selectedMonth}
          optionType="button"
          className="reports-date-range-selector"
        />

        <Spin spinning={isLoading}>
          <div className="tile-container">
            {reports.map((value) => (
              <ReportTile key={value.serviceFormId} {...value} type={type} tenantId={selectedTenant} />
            ))}
          </div>
        </Spin>
      </div>
    </SubPageLayout>
  );
};

export default Reports;
