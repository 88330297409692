import React, { useCallback } from 'react';
import { NonFormikFormField } from '../../helpers/FormField';
import { Radio } from 'antd';
import FormSection from '../../helpers/FormSection';

const priorityOptions = [
  { label: 'Low', value: 'LOW', key: 'LOW' },
  { label: 'Medium', value: 'MEDIUM', key: 'MEDIUM' },
  { label: 'High', value: 'HIGH', key: 'HIGH' },
];

const PriorityOptionSelector = ({ priority, setPriority }) => {
  const onChange = useCallback((e) => setPriority(e.target.value), []);
  return (
    <NonFormikFormField
      className="priority-radio-group"
      labelText="Priority"
      value={priority}
      InputComp={Radio.Group}
      options={priorityOptions}
      onChange={onChange}
    />
  );
};

export default PriorityOptionSelector;
