import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Checkbox, DatePicker, Spin } from 'antd';
import SubPageLayout from '../helpers/SubPageLayout';
import FormSection from '../helpers/FormSection';
import FormField, { FormFieldOverrideOnChange, InputFiledWithNone } from '../helpers/FormField';
import {
  AirFilterTypesDropdown,
  BuildLocationDropdown,
  ChamberTypesDropdown,
  ChemicalTypesDropdown,
  CompanySelectDropDown,
  GasMonitorTypesDropdown,
  GasSensorTypesDropdown,
  InteriorLightingTypesDropdown,
  OptionsTypesDropdown,
  ScrubberTypesDropdown,
  SiteSelectDropdown,
  CommonDropdown
} from '../helpers/drop-downs-for-enums';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewChamber,
  addNewChamberLoading,
  ChambersActions,
  clearChamberEditing,
  getChamberById,
  getChamberTableInfo,
  getEditingChamber,
  setTableInfo,
  updateChamber,
} from '../../../features/chamber.store';
import to from '../../../utils/to';
import { toast } from 'react-toastify';
import eng from '../../../utils/eng';
import moment from 'moment';
import { map, range } from 'lodash';

const ChambersAdd = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [showForm, setShowForm] = useState(false);

  const editingChamber = useSelector(getEditingChamber);

  useEffect(() => {
    (async () => {
      if (id) {
        dispatch(clearChamberEditing());
        await dispatch(getChamberById({ id }));
        setShowForm(true);
      } else {
        setShowForm(true);
        dispatch(setTableInfo({ resetResult: true, company: '', site: null }));
      }
    })();
  }, [id]);

  const tableInfo = useSelector(getChamberTableInfo);
  const addNewLoading = useSelector(addNewChamberLoading);

  const history = useHistory();

  const onSave = async ({ expectedDelivery, siteId, ...values }) => {
    values.expectedDelivery = expectedDelivery.toDate ? expectedDelivery.toDate().getTime() : expectedDelivery;
    values.siteId = Number(siteId);
    if (id) {
      const [err] = await to(dispatch(updateChamber({ values, id })));
      if (err) {
        return toast.error(err.err || eng.somethingWentWrong);
      }
      toast.success(eng.toast.updateChamberSuccessfully);
    } else {
      const [err] = await to(dispatch(addNewChamber({ values })).unwrap());
      if (err) {
        return toast.error(err.err || eng.somethingWentWrong);
      }
      toast.success(eng.toast.addChamberSuccessfully);
    }
    history.push('/chambers');
  };

  const getServiceMonthsList = () => map(range(1,13), month => ({label: `${month}`, value: `${month}`}))

  return (
    <SubPageLayout title="Add Chambers" loading={addNewLoading}>
      {showForm ? (
        <Formik
          initialValues={id ? editingChamber : initialValue}
          onSubmit={onSave}
          alidateOnBlur
          validationSchema={YupValidation}
        >
          <Form>
            <FormSection title="Site Details" className="space-bottom">
              <div className="d-flex">
                <div className="flex space-right">
                  <FormField
                    name="tenantIdentifier"
                    labelText="Company"
                    placeholder="Input Here"
                    InputComp={CompanySelectDropDown}
                    extraOnChange={(val) => dispatch(ChambersActions.setSelectedCompany(val))}
                  />
                  <FormField
                    name="siteId"
                    labelText="Site"
                    placeholder="Input Here"
                    company={tableInfo.company}
                    value={tableInfo.site}
                    extraOnChange={(val) => dispatch(ChambersActions.setSelectedSite(val))}
                    InputComp={SiteSelectDropdown}
                  />
                  <FormField
                    name="chamberID"
                    labelText="Chamber ID"
                    placeholder="Input Here"
                  />
                  <FormField name="name" labelText="Chamber Name" placeholder="Input here" />
                </div>
                <div className="flex space-left">
                  <FormField
                    name="buildLocation"
                    labelText="Build Location"
                    placeholder="Input Here"
                    InputComp={BuildLocationDropdown}
                  />
                  <FormFieldOverrideOnChange
                    name="expectedDelivery"
                    labelText="Expected Delivery"
                    InputComp={(props) => <DatePicker {...props} className="w-100" />}
                  />
                </div>
              </div>
            </FormSection>

            <FormSection title="Chamber Details">
              <div className="d-flex">
                <div className="flex space-right">
                  <FormField
                    name="chamberType"
                    labelText="Chamber Type"
                    placeholder="Input Here"
                    InputComp={ChamberTypesDropdown}
                  />
                  <FormField
                    name="scrubberType"
                    labelText="Scrubber Type"
                    placeholder="Input Here"
                    InputComp={ScrubberTypesDropdown}
                  />
                  <FormField
                    name="chemicalType"
                    labelText="Chemical Type"
                    placeholder="Input Here"
                    InputComp={ChemicalTypesDropdown}
                  />
                  <FormField
                    name="airFilterType"
                    labelText="Compressed air filtration"
                    placeholder="Input Here"
                    InputComp={AirFilterTypesDropdown}
                  />
                  <FormField
                    name="gasMonitorType"
                    labelText="Digital gas monitoring"
                    placeholder="Input Here"
                    InputComp={GasMonitorTypesDropdown}
                  />
                  <FormFieldOverrideOnChange
                    mode="multiple"
                    allowClear
                    name="gasSensorTypes"
                    labelText="Gas sensors"
                    placeholder="Input Here"
                    InputComp={GasSensorTypesDropdown}
                  />
                  <FormField
                    name="interiorLightingType"
                    labelText="Interior Lighting"
                    placeholder="Input Here"
                    InputComp={InteriorLightingTypesDropdown}
                  />
                  <FormFieldOverrideOnChange
                    mode="multiple"
                    allowClear
                    name="optionsTypes"
                    labelText="Options"
                    placeholder="Input Here"
                    InputComp={OptionsTypesDropdown}
                  />
                  <InputFiledWithNone
                    name="coTubeQuantity"
                    labelText="CO tube quantity"
                    placeholder="CO tube quantity"
                  />
                  <InputFiledWithNone
                    name="co2TubeQuantity"
                    labelText="CO2 tube quantity"
                    placeholder="CO2 tube quantity "
                  />
                  <InputFiledWithNone
                    name="o2TubeQuantity"
                    labelText="O2 tube quantity"
                    placeholder="O2 tube quantity"
                  />
                  <InputFiledWithNone
                    name="batteryQuantity"
                    labelText="Battery quantity"
                    placeholder="Battery quantity"
                  />

                  <FormField
                    name="additionalLanguages"
                    labelText="Additional Language"
                    placeholder="Additional Language"
                  />
                  <FormField name="rspKitNo" labelText="RSP Kit Number" placeholder="RSP Kit Number" />
                  <FormField name="waterRequired" labelText="Water Required" placeholder="Water Required" />
                  <FormField 
                    name="serviceFrequency" 
                    labelText="Service Frequency (Months)" 
                    placeholder="Select Months"
                    options={getServiceMonthsList()}
                    InputComp={CommonDropdown}
                  />
                </div>
                <div className="flex space-right">
                  <FormField name="capacity" labelText="Capacity" placeholder="capacity" />
                  <FormField name="duration" labelText="Duration" placeholder="duration" />
                  <InputFiledWithNone name="coChemicalQuantity" labelText="CO chemical quantity" placeholder="CO" />
                  <InputFiledWithNone name="co2ChemicalQuantity" labelText="CO2 chemical quantity" placeholder="CO2" />
                  <InputFiledWithNone
                    name="moistureRemovalQuantity"
                    labelText="Moisture removal quantity"
                    placeholder="Moisture removal quantity"
                  />
                  <InputFiledWithNone
                    name="mainChamberOxygenLevel"
                    labelText="Medical oxygen cylinders (main chamber) quantity"
                    placeholder="Medical oxygen cylinders (main chamber) quantity"
                  />
                  <InputFiledWithNone
                    name="ppmsOxygenLevel"
                    labelText="Medical oxygen cylinders (PPMS) quantity"
                    placeholder="Medical oxygen cylinders (PPMS) quantity"
                  />
                  <InputFiledWithNone
                    name="airlockOxygenLevel"
                    labelText="Medical oxygen cylinders (Airlock) quantity"
                    placeholder="Medical oxygen cylinders (Airlock) quantity"
                  />
                  <FormField
                    name="oxygenCandle"
                    type="checkbox"
                    InputComp={(props) => <Checkbox {...props}>Oxygen candle</Checkbox>}
                  />
                  <FormField
                    name="manualGasTubes"
                    type="checkbox"
                    InputComp={(props) => <Checkbox {...props}>Manual gas tubes</Checkbox>}
                  />

                  <FormField
                    name="externalFireExtinguisher"
                    type="checkbox"
                    InputComp={(props) => <Checkbox {...props}>Fire extinguisher - external</Checkbox>}
                  />
                  <FormField
                    name="internalFireExtinguisher"
                    type="checkbox"
                    InputComp={(props) => <Checkbox {...props}>Fire extinguisher - internal</Checkbox>}
                  />
                  <FormField
                    name="fireBlanket"
                    type="checkbox"
                    InputComp={(props) => <Checkbox {...props}>Fire blanket - internal</Checkbox>}
                  />
                  <FormField
                    name="transformer"
                    type="checkbox"
                    InputComp={(props) => <Checkbox {...props}>Transformer</Checkbox>}
                  />
                  <FormField
                    name="communicationsBox"
                    type="checkbox"
                    InputComp={(props) => <Checkbox {...props}>Communications box</Checkbox>}
                  />
                  <FormField
                    name="toilet"
                    type="checkbox"
                    InputComp={(props) => <Checkbox {...props}>Toilet</Checkbox>}
                  />
                  <FormField
                    name="longLifeFood"
                    type="checkbox"
                    InputComp={(props) => <Checkbox {...props}>Long-life food</Checkbox>}
                  />
                </div>
              </div>
            </FormSection>

            <div className="d-flex jc-center space-top-p">
              <Button type="primary" htmlType="submit">
                {id ? 'Update' : 'Submit'}
              </Button>
            </div>
          </Form>
        </Formik>
      ) : (
        <Spin spinning>
          <div style={{ width: '100%', height: '200px' }} />
        </Spin>
      )}
    </SubPageLayout>
  );
};

const initialValue = {
  tenantIdentifier: null,
  siteId: null,
  name: '',
  buildLocation: null,
  expectedDelivery: null,
  chamberType: null,
  scrubberType: null,
  chemicalType: null,
  airFilterType: null,
  gasMonitorType: null,
  gasSensorTypes: [],
  interiorLightingType: null,
  optionsTypes: [],
  coTubeQuantity: '',
  co2TubeQuantity: '',
  o2TubeQuantity: '',
  batteryQuantity: '',
  additionalLanguages: '',
  rspKitNo: '',
  waterRequired: '',
  capacity: '',
  duration: '',
  coChemicalQuantity: '',
  co2ChemicalQuantity: '',
  moistureRemovalQuantity: '',
  mainChamberOxygenLevel: '',
  ppmsOxygenLevel: '',
  airlockOxygenLevel: '',
  oxygenCandle: false,
  manualGasTubes: false,
  externalFireExtinguisher: false,
  internalFireExtinguisher: false,
  fireBlanket: false,
  transformer: false,
  communicationsBox: false,
  toilet: false,
  longLifeFood: false,
};

const numberWithNon = Yup.string().test(
  'NoneOrNumber',
  'Must be None or Valid Number',
  (val) => !isNaN(val) || val === 'None',
);

const YupValidation = Yup.object({
  tenantIdentifier: Yup.string().required('Required').nullable(),
  siteId: Yup.string().required('Required').nullable(),
  name: Yup.string().required('Required'),
  buildLocation: Yup.string().required('Required').nullable(),
  expectedDelivery: Yup.string().required('Required').nullable(),
  chamberType: Yup.string().required('Required').nullable(),
  scrubberType: Yup.string().required('Required').nullable(),
  chemicalType: Yup.string().required('Required').nullable(),
  airFilterType: Yup.string().required('Required').nullable(),
  gasMonitorType: Yup.string().required('Required').nullable(),
  gasSensorTypes: Yup.array().of(Yup.string()).max(10, 'Select only 10'),
  interiorLightingType: Yup.string().required('Required').nullable(),
  optionsTypes: Yup.array().of(Yup.string()).min(1, 'Select at least 1'),
  coTubeQuantity: Yup.string().required('Required'),
  co2TubeQuantity: Yup.string().required('Required'),
  o2TubeQuantity: Yup.string().required('Required'),
  batteryQuantity: numberWithNon,
  additionalLanguages: Yup.string(),
  capacity: numberWithNon,
  duration: numberWithNon,
  coChemicalQuantity: numberWithNon,
  co2ChemicalQuantity: numberWithNon,
  moistureRemovalQuantity: numberWithNon,
  mainChamberOxygenLevel: numberWithNon,
  ppmsOxygenLevel: numberWithNon,
  airlockOxygenLevel: numberWithNon,
  oxygenCandle: Yup.boolean(),
  manualGasTubes: Yup.boolean(),
  externalFireExtinguisher: Yup.boolean(),
  internalFireExtinguisher: Yup.boolean(),
  fireBlanket: Yup.boolean(),
  transformer: Yup.boolean(),
  communicationsBox: Yup.boolean(),
  toilet: Yup.boolean(),
  longLifeFood: Yup.boolean(),
  rspKitNo: Yup.string().required('Required'),
  waterRequired: Yup.string(),
});

export default ChambersAdd;
